<template>
	<div style="">
		<div id="cabecera-ficha" :class=" infoCan == 0 ? 'azul white--text' : infoCan == -1 ? 'celeste white--text': 'azul white--text'" style="height: 20px; margin-bottom:6px; margin-right:4px;">
			<div style="display:inline-flex; width:100%; padding-left:4px; ">
				<div 
					style="	width: calc(100% - 124px);     
							height:20px;
							word-break: break-all;
							text-align: start;
							font-size:11px;
							overflow: hidden;" 
				>
					{{ nombreCliente }}
				</div>
				<div style="width:118px; text-align:end; margin-right:0px; text-transform: uppercase; font-size: 9.2px; height:20px; line-height: 1.8;">
					{{ !coordenadaFecha ? 'NO DISPONIBLE' :  moment.utc(coordenadaFecha.$date).local().locale('es').fromNow() }} 
					<!--<span >
						<i aria-hidden="true" class="v-icon notranslate mdi mdi-map-marker theme--light azul--text"></i>
					</span-->
				</div>
			</div>
		</div>
		
		<div style="margin-bottom:4px; height: 48px; display: inline-flex; width:100%; ">
				<div class="white" style=" border: thin solid #ccc!important; border-radius:4px;">
					<img :title="modeloTexto" 
						:src="urlImagen"
						class="pa-1" style="width: 100%; width: 48px; height:48px; margin-bottom: auto;">
				</div>
				<div style=" width: calc(100% - 76px); word-break: break-all; text-align: start; margin-top:3px; ">
					<div style="margin-left:8px; ">
						<div class="" style="line-height: 1; font-size:75%;">
							<span> {{ vin }} </span>
						</div>
						<div class="" style="line-height: 1;">
							<span > {{ nombre }} </span>
						</div>
						<div class="mt-1" 
							style="font-size:10.5px; line-height: 1.5; height: 16px; width: calc(100% - 80px); word-break: break-all; text-align: start; overflow: hidden;">
							<span> {{ contratroTexto }}  </span>
						</div>
					</div>
				</div>
				<div class="" style="margin-right:6px;">
					<!-- <div class="pa-1 pt-0 pr-0" style="display: flex; flex-wrap: wrap; padding-top:3px!important; padding-right:6px!important;">
						<button type="button" 
							class=" mt-0   azul--text pl-2 pr-2  v-btn v-btn--fab v-btn--outlined v-btn--round theme--light v-size--small azul--text" 
							style="margin-left: auto; margin-right: auto !important;">
							<span class="v-btn__content">
								<i aria-hidden="true" class="v-icon notranslate mdi mdi-map-marker theme--light azul--text"></i>
							</span>
						</button>
					</div> -->
					<div>


						<div style="display:inline-flex; height: 24px; border-radius: 4px!important; border: thin solid #ccc" class="white">
							<div :title="!coordenadaFecha ? 'NO DISPONIBLE' : moment.utc(coordenadaFecha.$date).local().locale('es').fromNow()" class="ml-0 mr-1">
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve" 
								:style="'height: 22px; width: 18px; fill:'+ colorCoordenada +';'">
									<g><g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)"><path d="M4703.6,5003.3c-858.4-102.8-1568.6-432.8-2128.1-989.9c-353.9-351.5-571.5-671.9-765.2-1119.1c-253.5-588.2-337.2-1308-215.2-1855.5c205.6-923,877.6-2250,2003.8-3959.7c571.5-870.4,1188.4-1738.4,1291.2-1819.6c148.3-117.2,208-64.6,674.3,588.2C6927.4-2241.9,7905.4-534.6,8276,574.9c143.5,428,184.1,662.4,184.1,1018.6c-2.4,1623.6-1178.8,3034.4-2797.6,3354.8C5442.5,4991.3,4878.1,5024.8,4703.6,5003.3z M5358.8,3329.5c389.8-95.6,743.7-385,918.2-750.8c119.6-253.5,157.8-442.4,143.5-712.6c-23.9-368.3-167.4-681.5-435.2-942.1c-241.5-236.7-645.6-401.7-982.8-401.7c-327.6,0-741.3,167.4-973.2,389.8c-282.2,270.2-420.8,576.3-442.4,973.2C3536.7,2848.9,4409.5,3559,5358.8,3329.5z"/></g></g>
								</svg>
							</div>
							<div :title="!odometroFecha ? 'NO DISPONIBLE' : moment.utc(odometroFecha.$date).local().locale('es').fromNow()" class="ml-1 mr-1">
								<svg 
									xmlns="http://www.w3.org/2000/svg" 
									xmlns:xlink="http://www.w3.org/1999/xlink" 
									version="1.1" id="Layer_1" x="0px" y="0px" 
									viewBox="0 0 123.22 123.38" s
									:style="'background-image: none; background-position: 0% 0%; display: inline; fill:'+ colorOdometro +'; height: 22px; width: 18px;'"
									xml:space="preserve"
								>								
								<g>
									<path 
										class="st0" 
										d="M0.34,123.13L38.94,0.25h7.2L11.69,123.13H0.34L0.34,123.13z M59.95,7.96h3.93l0.54,13.55h-5.29L59.95,7.96 L59.95,7.96z M55.68,94.71h12.53l0.99,21.71l-15.06,0.13L55.68,94.71L55.68,94.71z M56.85,63.86h9.84l1.14,19.21h-11.9L56.85,63.86 L56.85,63.86z M58.64,33.12h6.55l0.86,19.21h-8.33L58.64,33.12L58.64,33.12z M48.34,0.25h26.68l27.17,122.88H21.81L48.34,0.25 L48.34,0.25z M77.2,0.25h7.74l37.94,122.88H112.1L77.2,0.25L77.2,0.25z" 
										:style="'fill:'+ colorOdometro +';'"/></g></svg>
							</div>
							<div :title="!horometroFecha ? 'NO DISPONIBLE' : moment.utc(horometroFecha.$date).local().locale('es').fromNow()" class="ml-1 mr-1">
								<svg xmlns="http://www.w3.org/2000/svg" 
									viewBox="0 0 34 34" 
									class="icon-machine-hours" 
									:style="'background-image: none; background-position: 0% 0%; display: inline; fill:'+ colorHorometro +'; height: 24.5px; width: 24.5px;'"
								>
									<path d="M24.562 3.532v23.8H7.456v-23.8h17.106zm-2.249 21.551c0-.838-.083-1.608-.248-2.312-.165-.701-.39-1.352-.673-1.947s-.605-1.143-.965-1.639c-.36-.495-.729-.965-1.107-1.407s-.752-.856-1.124-1.239a17.553 17.553 0 0 1-1.019-1.143c.295-.377.632-.762 1.01-1.15.378-.391.755-.803 1.133-1.24.378-.437.747-.902 1.107-1.398.359-.496.682-1.039.965-1.629a9.518 9.518 0 0 0 .673-1.939c.165-.703.248-1.484.248-2.347H9.67c0 .839.082 1.614.248 2.329a9.38 9.38 0 0 0 .673 1.957c.283.59.604 1.133.965 1.629.359.496.726.962 1.098 1.398.372.438.744.85 1.116 1.24.371.389.711.773 1.018 1.15-.295.378-.632.759-1.009 1.143-.378.383-.753.797-1.125 1.239s-.738.912-1.098 1.407a9.85 9.85 0 0 0-.956 1.639 10.465 10.465 0 0 0-.673 1.947 9.727 9.727 0 0 0-.257 2.312h12.643z"
									/>
								</svg>
							</div>
							<div :title=" !nivelCombustibleFecha ? 'NO DISPONIBLE' : moment.utc(nivelCombustibleFecha.$date).local().locale('es').fromNow() " class="ml-1 mr-0">
								<svg 
									xmlns="http://www.w3.org/2000/svg" 
									version="1" 
									id="Layer_1" 
									viewBox="0 0 33 33" 
									class="icon-fuel" 
									:style="'background-image: none; background-position: 0% 0%; display: inline; fill:'+ colorNivelCombustible +'; height: 22.5px; width: 20px;'"
								>
									<path 
										id="icon-fuel" 
										class="primary" 
										d="M27.073 12.693c-.26-2.037 1.395-2.445 1.395-2.445-2.3-8.833-9.013-8.702-9.013-8.702v2.493c2.674.068 4.61 5.171 4.802 6.055.195.881-.16 2.656-.16 2.656s2.977 8.396 1.802 10.658c-1.837 3.533-3.564 1.545-4.105 0-.68-1.95.142-4.075-.109-7.476-.215-2.883-2.642-3.316-3.371-3.381.006-3.299.009-8.768-.033-9.379-.066-.884-.864-.806-.864-.806s-12.138-.17-12.751.245c-.741.511-.612 1.259-.612 1.259L3.99 28.708H2v2.054h18.482v-2.054h-2.054V14.2c1.813-.239 2.063 1.823 2.063 1.823s-.266 6.455-.266 7.542c0 1.087.932 2.759 2.098 2.995 3.724.758 4.619-.201 5.376-.884 1.035-.936 1.3-3.197 1.3-3.197l-1.926-9.786zm-10.698-2.467H6.107v-6.16h10.268v6.16z" 
										:style="'fill:'+ colorNivelCombustible +';'"/>
								</svg>
							</div>
							
						</div>


						<div style="position:relative">
							<div class="white azul--text pl-1 pr-2" style="border:1px solid #002855!important; border-radius:4px; font-size: 10px; width:100px; position:absolute; right:0px; text-align:center; ">
								{{ sucursal }}
							</div>
														
						</div>
					</div>


				</div>
			</div>

	</div>
</template>

<script>
	import moment from "moment";
	export default {
		props: {
			fechaTexto: String,
			nombreCliente: String,
			vin: String,
			nombre: String,
			urlImagen: String,
			modeloTexto:String,
			contratroTexto: String,
			infoCan: Number,
			horometroFecha: Object,
			odometroFecha: Object,
			coordenadaFecha:  Object,
			nivelCombustibleFecha: Object,
			sucursal: String,
			colorHorometro: String,
			colorOdometro:String,
			colorNivelCombustible:String,
			colorCoordenada:String
		}
	}
</script>

<style>
	#titulo-ficha-nombre{
		letter-spacing: -.25px!important;
		font-weight: 300;
		line-height: 1.4;
		padding-top: 2px;
		font-size: 56%;
		word-break: break-word;
		margin-right:auto;
	}
</style>
<style type="text/css">.st0{fill-rule:evenodd;clip-rule:evenodd;}</style>
