import BoiletComponentBase from '@/shared/application/boilet-component-base';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class BlockRecoTextComponent extends BoiletComponentBase {
    @Prop({default: 0})  public valor!: number;
    @Prop({default: 0})  public total!: number;
    @Prop({default: 100})  public ancho!: number;
    @Prop({default: ''})  public titulo!: string;
    @Prop({default: ''})  public color!: string;
    @Prop({default: ''})  public color2!: string;
    @Prop({default: '11'}) public tituloTamanoLetra!: string;
    @Prop({default: '76'}) public altoCaja!: string;
    @Prop({default: '30'}) public altoBloque!: string;
    
    
    get porcentaje(){
        return this.total >= 1 ? Math.round((100*this.valor)/this.total) : 0;
    }
}

