import BoiletComponentBase from '@/shared/application/boilet-component-base';
import { objectTraps } from 'immer/dist/internal';
import { Component, Prop, Watch } from 'vue-property-decorator';

@Component
export default class AuditoriaContadores extends BoiletComponentBase{
    public cabecera = [
        {
            text: 'Holding',
            align: 'start',
            sortable: true,
            value: 'holding',
        },
        {
            text: 'RUT',
            align: 'start',
            sortable: true,
            value: 'rut',
        },
        {
            text: 'N° Clientes',
            align: 'start',
            sortable: true,
            value: 'clientes',
        },
        {
            text: 'N° Activos',
            align: 'start',
            sortable: true,
            value: 'equipos',
        },
        {
            text: 'N° Contactos Operativos',
            align: 'start',
            sortable: true,
            value: 'operativos',
        },
        {
            text: 'N° Contactos Bloqueados',
            align: 'start',
            sortable: true,
            value: 'bloqueados',
        },
        {
            text: 'N° Contactos',
            align: 'start',
            sortable: true,
            value: 'contactos',
        }
    ];
    public cabeceraContacto = [
        {
            text: 'Nombre',
            align: 'start',
            sortable: true,
            value: 'Nombrecontacto',
        },
        {
            text: 'Apellido',
            align: 'start',
            sortable: true,
            value: 'Apellidocontacto',
        },
        {
            text: 'Correo',
            align: 'start',
            sortable: true,
            value: 'Correo',
        },
        {
            text: 'Celular',
            align: 'start',
            sortable: true,
            value: 'Formatocelular',
        },
        {
            text: 'Salud Celular',
            align: 'start',
            sortable: true,
            value: 'Saludcelular',
        },
        {
            text: 'Salud Correo',
            align: 'start',
            sortable: true,
            value: 'Saludcorreo',
        },
        {
            text: 'Reporte Celular',
            align: 'start',
            sortable: true,
            value: 'Reportecelular',
        },
        {
            text: 'Reporte Correo',
            align: 'start',
            sortable: true,
            value: 'Reportecorreo',
        }
    ];
    public cabeceraEquipo = [
        {
            text: 'VIN',
            align: 'start',
            sortable: true,
            value: 'Vin',
        },
        {
            text: 'Identificador',
            align: 'start',
            sortable: true,
            value: 'Identificador',
        },
        {
            text: 'Patente',
            align: 'start',
            sortable: true,
            value: 'Patente',
        },
        {
            text: 'Numero interno',
            align: 'start',
            sortable: true,
            value: 'Numerointerno',
        },
        {
            text: 'Sector',
            align: 'start',
            sortable: true,
            value: 'Sector',
        },
        {
            text: 'Familia',
            align: 'start',
            sortable: true,
            value: 'Tipoequipo',
        },
        {
            text: 'Marca',
            align: 'start',
            sortable: true,
            value: 'Marca',
        },
        {
            text: 'Modelo',
            align: 'start',
            sortable: true,
            value: 'Modelo',
        },
        {
            text: 'Año fabricación',
            align: 'start',
            sortable: true,
            value: 'Añoequipo',
        }
    ];
    public search = '' as string;
    public searchTimer: ReturnType<typeof setTimeout> | null = null;
    public dialog = false;
    public expanded = [] as any[];
    public holdings = [] as any[];
    public bloqueados = [] as any[];
    public holding = {} as any;
    public rutcliente = {} as any;
    public filtrosvin = [] as any[];
    async mounted(){
        await this.GetHoldings();
    }

    @Watch('search')
    FuncionVin(newValue: string, oldValue: string){
        // Aquí puedes realizar alguna acción inmediata cuando el valor de búsqueda cambia
        // Pero también se activará cuando el usuario siga escribiendo
        console.log('Search value changed:', newValue);
        if(newValue){
            if(newValue.length >= 7){
                this.GetHoldingPorVin(newValue.toUpperCase().trim());
            }else{
                this.filtrosvin = [] as any[];
            }
        }else{
            this.filtrosvin = [] as any[];
        }
        
    }

    startSearchTimer() {
        // Cancelar el temporizador anterior si existe
        if (this.searchTimer) {
          clearTimeout(this.searchTimer);
        }
    
        // Iniciar un nuevo temporizador
        this.searchTimer = setTimeout(() => {
          // Ejecutar una acción después de que el usuario deja de escribir por un tiempo determinado
          console.log('Search value stopped changing:', this.search);
          // Aquí puedes realizar la búsqueda o cualquier acción deseada
          this.searchTimer = null; // Limpiar el temporizador
        }, 500); // 500 ms de inactividad antes de que se ejecute la acción
      }


    public async GetHoldingPorVin(vin: string){
        await this.boiletService.get<any>('/api/Auditoria/holding/porvin/'+ vin,false)
        .then( async a => {
            if(!a.isError){
                this.filtrosvin = JSON.parse(a.content) as any[];
            }
        });
    }
    
    public async GetRutHolding(rut : string){
        await this.boiletService.get<any>('/api/Auditoria/holding/detalle/'+ rut,false)
        .then( async a => {
            if(!a.isError){
                this.holding = JSON.parse(a.content) as any[];
                this.rutcliente = rut;
                await this.GetRutHoldingBloqueados(this.rutcliente);
                this.dialog = true;
            }
        });
    }

    public async GetRutHoldingBloqueados(rut : string){
        await this.boiletService.get<any>('/api/Auditoria/holding/bloqueados/'+ rut,false)
        .then( a => {
            if(a.content){
                this.bloqueados = JSON.parse(a.content) as any[];
            }else{
                this.bloqueados = [] as any[];
            }
        });
    }

    public async GetHoldings(){
        await this.boiletService.get<any>('/api/Auditoria/holding',false)
        .then( a => {
            if(!a.isError){
                this.holdings = JSON.parse(a.content) as any[];
            }
        });
    }


    

    get GetClientes(){
        const tabla = [] as any[];
      
            if(this.filtrosvin.length > 0){
                const tr = [] as any[];
                if(this.holdings ? this.holdings.length > 0 : false){
                    for(let k = 0; k < this.filtrosvin.length; k ++){
                        const ix = this.holdings.findIndex( t => t.rut == this.filtrosvin[k].Rutholding);
                        if(ix > -1){
                            tr.push(this.holdings[ix]);
                        }
                    }
                }
                for(let i = 0; i < tr.length; i ++){
                    const contactos = tr[i].clientes.map( (r: any) => r.ContadorContactos).reduce((a:number,b:number) => a+b);
                    const bloqueados = tr[i].clientes.map( (r: any) => r.ContadorContactosBloqueados).reduce((a:number,b:number) => a+b);
                    const operativos = tr[i].clientes.map( (r: any) => r.ContadorContactosOperativos).reduce((a:number,b:number) => a+b);
                    const equipos = tr[i].clientes.map( (r: any) => r.ContadorEquipos).reduce((a:number,b:number) => a+b);
                    const tclientes = [] as any[];
                    tr[i].clientes.forEach((element: any) => {
                        const g = element;
                        g.color = this.filtrosvin.findIndex(e => e.Rutcliente == element.RutCliente  ) > -1 ? 'primary' : 'transparent';
                        tclientes.push(g);
                    });
                    const ojb = {
                        holding: tr[i].holding,
                        rut: tr[i].rut,
                        contactos: contactos,
                        bloqueados: bloqueados,
                        operativos: operativos,
                        equipos: equipos,
                        clientes: tr[i].clientes.length,
                        detalle: tclientes
                    };
                    const ixr = tabla.findIndex(r => r.rut == ojb.rut);
                    if(ixr == -1){
                        tabla.push(ojb);
                    }
                }
                return tabla;
            }
            else if(this.holdings.length > 0){
                for(let i = 0; i < this.holdings.length; i ++){
                    const contactos = this.holdings[i].clientes.map( (r: any) => r.ContadorContactos).reduce((a:number,b:number) => a+b);
                    const bloqueados = this.holdings[i].clientes.map( (r: any) => r.ContadorContactosBloqueados).reduce((a:number,b:number) => a+b);
                    const operativos = this.holdings[i].clientes.map( (r: any) => r.ContadorContactosOperativos).reduce((a:number,b:number) => a+b);
                    const equipos = this.holdings[i].clientes.map( (r: any) => r.ContadorEquipos).reduce((a:number,b:number) => a+b);
                    const ojb = {
                        holding: this.holdings[i].holding,
                        rut: this.holdings[i].rut,
                        contactos: contactos,
                        bloqueados: bloqueados,
                        operativos: operativos,
                        equipos: equipos,
                        clientes: this.holdings[i].clientes.length,
                        detalle: this.holdings[i].clientes
                    };
                    tabla.push(ojb);                    
                }
                return tabla;
            }
        
        
       
    }

    get GetCliente(){
        if(this.holding.clientes){
            const index = this.holding.clientes.findIndex((u : any) => u.rut == this.rutcliente);
            if(index > -1){
                const cliente = this.holding.clientes[index];
                return cliente;
            }else{
                return null;
            }
        }
        return null;
    }

    Bloqueado(email : string){
        const index = this.bloqueados.findIndex((u : any) => u.Correo == email);
        if(index == -1){
            return'SI';
        }else{
            return 'NO';
        }
    }
}