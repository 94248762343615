import { defineStore } from "pinia";

export const CoordenadasActivosStore = defineStore({
    id: 'coordenada',
    state: () => ({
        coordenadas: [] as any[],
    }),
    getters:{
        coordenadasArray: (state: any) => state.coordenadas 

        
            // state.coordenadas.reduce((items: any[], item: any) => {
            //     const existingItem = items.find((it) => it === item)    
            //     if (!existingItem) {
            //       items.push(item)
            //     } else {
            //       existingItem.amount++
            //     }
            //     return items
            //   }, []),
    },
    actions:{
        bulk(t : any[]){
            this.coordenadas = t;
         },
    }
})