import BoiletComponentBase from '@/shared/application/boilet-component-base';
import { Component } from 'vue-property-decorator';


@Component({
    components: {
        ChangePassword: require('@/admin/components/profile/change-password/change-password.vue').default,
        MiniItem: require('@/gestion/components/mini-item/mini-item.vue').default
    }
})
export default class TopMenuComponent extends BoiletComponentBase {
    public drawer = true;

    public changePasswordDialogChanged(dialog: boolean) {
        this.$root.$emit('changePasswordDialogChanged', dialog);
    }

    public drawerChanged() {
        this.$root.$emit('drawerChanged');
    } 

    public logOut() {
        this.authStore.removeToken();
        this.$router.push({ path: '/account/login' });
    }

    get titulo(){
        if(this.$route.path == '/platform/gestion-alerta'){
            return 'Gestión de Alertas';
        }else if(this.$route.path == '/seguimiento/plantilla-experta'){
            return 'Plantilla Recomendaciones Expertas'
        }else if(this.$route.path == '/seguimiento/plantilla-dtc'){
            return 'Plantilla Recomendaciones DTC F2'
        }else if(this.$route.path == '/seguimiento/plantilla-f2'){
            return 'Plantilla Alertas F2'
        }else if(this.$route.path == '/seguimiento/plantilla-organizacion'){
            return 'Plantilla Organizaciones'
        }
        else if(this.$route.path == '/seguimiento/plantilla-sucursales'){
            return 'Plantilla Sucursales'
        }else if(this.$route.path == '/acceso-cliente/recomendacion'){
            return 'Seguimiento Recomendación'
        }
        else{
        return null;
        }
    }
}