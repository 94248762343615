import { render, staticRenderFns } from "./acceso-externo-layout.vue?vue&type=template&id=3d6ddc80&"
import script from "./acceso-externo-layout.ts?vue&type=script&lang=js&"
export * from "./acceso-externo-layout.ts?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VMain } from 'vuetify/lib/components/VMain';
installComponents(component, {VContainer,VFooter,VMain})
