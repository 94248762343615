import BoiletComponentBase from '@/shared/application/boilet-component-base';
import { Mensaje, RecomendacionCliente } from '@/shared/models/shared/consolidados/RecomendacionCliente';
// import { sum } from 'd3-array';
import moment from 'moment';
import { Component, Prop } from 'vue-property-decorator';
// import { useGestionStore } from '@/gestion/stores/assets/gestion';

@Component({
    components: {
        Gaugue180: require('@/gestion/components/gaugue-180/gaugue-180.vue').default,
        FichaContador: require('@/gestion/components/ficha-contador/ficha-contador.vue').default,
        FichaSimpleHorizontal: require('@/gestion/components/ficha-simple-horizontal/ficha-simple-horizontal.vue').default,
        BlockRecoText: require('@/gestion/components/block-reco-text/block-reco-text.vue').default
    }
})
export default class ResumenAlertaRecomendacionComponent extends BoiletComponentBase {
    @Prop({default: ''})  public alertasprop!: RecomendacionCliente[];
    @Prop({default: ''})  public expertasprop!: RecomendacionCliente[];
    @Prop({default: ''})  public gestionprop!: RecomendacionCliente[];
    // public useAlertasStore = useGestionStore();
    

    get Totalbiertas(){
        return this.gestionesAbiertas.length;
    }

    get EnTiempo(){
        const sin = this.gestionesAbiertas.filter( a => {
            const r = a.fechaCierre ? false : true;
            if(r == true){
                var hoy = moment();
                var b = moment(a.fechaEnvio);
               const diffdias =  hoy.diff(b, 'days') +1 ;
               return diffdias <= 4;
            }else{
                return false;
            }
        }) as RecomendacionCliente[];
        return sin.length;
    }

    get Retrasadas(){
        const sin = this.gestionesAbiertas.filter( a => {
            const r = a.fechaCierre ? true : false;
            if(r == true){
                var hoy = moment();
                var b = moment(a.fechaEnvio);
               const diffdias =  hoy.diff(b, 'days') +1 ;
               return diffdias > 4 && diffdias <= 13
            }else{
                return false;
            }
        }) as RecomendacionCliente[];
        return sin.length;
    }

    get FueraDeTiempo(){
        const sin = this.gestionesAbiertas.filter( a => {
            const r = a.fechaCierre ? true : false;
            if(r == true){
                var hoy = moment();
                var b = moment(a.fechaEnvio);
                return hoy.diff(b, 'days') +1 >= 14
            }else{
                return false;
            }
        }) as RecomendacionCliente[];
        return sin.length;
    }

    get PorcentajeSinAtender(){
        return this.gestionesAbiertas.length > 0 ?(Math.round((100* this.SinAtender)/this.gestionesAbiertas.length)):0;
    }

    get ExpertasSinAtender(){
        return this.gestionesAbiertas.length > 0 ?(Math.round((100* this.SinAtender)/this.gestionesAbiertas.length)):0;
    }

    get ServicioProgramado(){
        const sin = this.gestionesAbiertas.filter( a => {
            const o = a.mensajes ? true : false;
            const u = a.fechaServicio ? true : false;
            return o && u;
        });
        return sin.length;
    }

    get PrimerContacto(){
        const sin = this.gestionesAbiertas.filter( a => {
            const o = a.mensajes ? false : true;
            const u = a.fechaServicio ? false : true;
            return o && u;
        });
        return sin.length;
    }

    get EnProceso(){
        const sin = this.gestionesAbiertas.filter( a => {
            const o = a.mensajes ? true : false;
            const u = a.fechaServicio ? false : true;
            return o && u;
        });
        return sin.length;
    }


    get SinAtender(){
        const d = this.gestionesAbiertas.filter(a => (a.mensajes != undefined && a.mensajes != null) ? a.mensajes.length > 0 :false) as RecomendacionCliente[];
        const r = d.filter( a => a.mensajes.map( a => a.esMiembro).filter( x => x == true).length == 0);
        return (r as RecomendacionCliente[]).length;
    }

    get alertasSinAtender(){
        const d = this.alertasprop.filter(a => (a.mensajes != null && a.mensajes != undefined) ? 
                                                    a.mensajes.length > 0 ?
                                                    (
                                                        this.DefineClasesEstado(a) == 'pink-td-class'
                                                    )
                                                    : false
                                                : false
                                            ) as RecomendacionCliente[];
        if(d.length > 0){
            return d;
        }else{
            return [] as RecomendacionCliente[];
        }
    }


    DefineClasesEstado(item: RecomendacionCliente){
     
        if(item.fechaCierre == null){
            const notienemensajes = item.mensajes ? item.mensajes.length == 0 : true;
            if(notienemensajes){
                return 'white-td-class';
            }
            
            if(!notienemensajes){
                let mensajes = item.mensajes;
                let ultimoMensaje = {} as Mensaje;
                for(let i = 0; i < mensajes.length; i++){
                    if(i< mensajes.length-1){
                        const uno = mensajes[i];
                        const dos = mensajes[i+1];
                        if(uno.fecha && dos.fecha){
                            if(moment(uno.fecha).unix <= moment(dos.fecha).unix){
                                ultimoMensaje = dos;
                            }else{
                                ultimoMensaje = uno;
                            }
                        }
                    }
                    else{
                        ultimoMensaje = mensajes[i];
                    }
                }
                if(ultimoMensaje.fecha){
                    if(ultimoMensaje.esMiembro){
                        return 'white-td-class';
                    }else{
                        return 'pink-td-class';
                    }
                }  
            }         
        }else{
            return 'white-td-class';
        }        
    }

    get expertasSinAtender(){
        const d = this.expertasprop.filter(a => (a.mensajes != null && a.mensajes != undefined) ? 
                                                    a.mensajes.length > 0 ?
                                                    (
                                                        this.DefineClasesEstado(a) == 'pink-td-class'
                                                    )
                                                    : false
                                                : false
                                            ) as RecomendacionCliente[];
        if(d.length > 0){
            return d;
        }else{
            return [] as RecomendacionCliente[];
        }
    }

    get Descartadas(){
        const sin = this.gestionesCerradas.filter(r => r.destinatarios ? r.destinatarios.length < 1 : true);
        return sin.length;
    }

    get CerradosServicio(){
        const sin = this.gestionesCerradas.filter(r => r.destinatarios ? r.destinatarios.length >= 1 : false);
        return sin.length;
    }

    get gestiones(){
        return this.gestionprop as RecomendacionCliente[];
    }

    get gestionesCerradas(){
        return this.gestionprop.filter( a => a.fechaCierre ? true : false) as RecomendacionCliente[];
    }

    get gestionesAbiertas(){
        return this.gestionprop.filter( a => a.fechaCierre == null || a.fechaCierre == undefined) as RecomendacionCliente[];
    }

    get expertasCerradas(){
        return this.expertasprop.filter( a => a.fechaCierre) as RecomendacionCliente[];
    }
    get expertasCerradasRawCount(){
        return  this.expertasCerradas.length > 0 ? 
                this.expertasCerradas.map( a => a.contadorAlertas).reduce((a,b) => a+b) :
                0;
    }

    get expertas(){
        return this.expertasprop as RecomendacionCliente[];
    }
    get alertasCerradas(){
        return this.alertasprop.filter( a => a.fechaCierre) as RecomendacionCliente[];
    }
    get alertasCerradasRawCount(){
        return  this.alertasCerradas.length > 0 ? 
                this.alertasCerradas.map( a => a.contadorAlertas).reduce((a,b) => a+b) :
                0;
    }
    get alertas(){
        return this.alertasprop as RecomendacionCliente[];
    }

    get expertasRawCount(){
        return  this.expertas.length > 0 ? 
                this.expertas.map( a => a.contadorAlertas).reduce((a,b) => a+b) :
                0;
    }

    get alertasRawCount(){
        return this.alertas.length > 0 ? 
                this.alertas.map( a => a.contadorAlertas).reduce((a,b) => a+b) :
                0;
    }
    
    // get Size(){
    //     return this.useAlertasStore.tamano;
    // }
    get FullHd(){
        // return this.Size.x > 1880
        return true;
    }

   

    // get gestionesRawCount(){
    //     return this.gestiones.length > 0 ? 
    //             this.gestiones.map( a => a.count).reduce((a,b) => a+b) : 
    //             0;
    // }
}