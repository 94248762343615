import BoiletComponentBase from '@/shared/application/boilet-component-base';
import { Component } from 'vue-property-decorator';

@Component
export default class AppComponent extends BoiletComponentBase {
    get BackgroundColor(){
        if(this.$route.path == '/account/login' || this.$route.path == '/account/forgot-password'){
            return '#002855';
        }else{
            return '#FFF'
        }
    }
    get carga(){
        return true
    }
}