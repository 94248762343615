import BoiletComponentBase from '@/shared/application/boilet-component-base';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class Gaugue180Component extends BoiletComponentBase{
    @Prop({default: 0})  public  value!: number;
    @Prop({default: 0})  public  maxValue!: number;
    @Prop({default: 0})  public  minValue!: number;
    @Prop({default: ''})  public  nombre!: string;
    @Prop({default: ''})  public  unitmet!: string;
    @Prop({default: ''})  public  subverso!: string;
    @Prop({default: ''})  public  subverso2!: string;

    get MaxValue(){
        if(this.maxValue > this.value){
            return this.maxValue;
        }else{
            return this.value;
        }
    }
    get MinValue(){
        if(this.minValue < this.value){
            return this.minValue;
        }else{
            return this.value;
        }
    }

    get Grados(){
        return 'transform: rotate('+this.Deg+'deg) !important;'
    }

    get Range(){
        const d = this.MaxValue - this.MinValue;
        return d > 0 ? d : 1;
    }

    get Value(){
        const d = this.value - this.MinValue;
        return d;
    }

    get porcentaje() {
        return Math.round(100 * this.Value / this.MaxValue);
    }

    get Deg() {
        const d = (this.Value / this.MaxValue) * 180;
        return Math.round(d);
    }
   
    get Leyenda(){
        if(this.Deg >= 135){
            return 'Crítico'
        }
        else if(this.Deg >= 90){
            return 'Atención'
        }
        else if(this.Deg >= 45){
            return 'Precaución'
        }
        else if(this.Deg >= 0){
            return 'Baja'
        }else{
            'Error'
        }
    }

    get Recomendacion() {
        if (this.Deg >= 135) {
            return 'Es crítico que empieze a reportar'
        }
        else if (this.Deg >= 90) {
            return 'Atención esta a punto de caer en falta'
        }
        else if (this.Deg >= 45) {
            return 'Precaución esta perdiendo su nivel de atención'
        }
        else if (this.Deg >= 0) {
            return 'Mantenga su nivel de atención'
        } else {
            'Error'
        }
    }

    get Color(){
        if(this.Deg >= 135){
            return 'rojo white--text'
        }
        else if(this.Deg >= 90){
            return 'naranja white--text'
        }
        else if(this.Deg >= 45){
            return 'amarillo black--text'
        }
        else if(this.Deg >= 0){
            return 'verde white--text'
        }
    }

    get Name() {
        return 'Nivel de congestión';
    }
}