

import BoiletComponentBase from '@/shared/application/boilet-component-base';
import { Cliente, Contacto, Correo , Contrato, InfoActivo} from '@/shared/models/shared/consolidados/InfoActivo';
import { Component, Watch } from 'vue-property-decorator';
import moment from 'moment';

@Component
export default class MantenedorActivosComponent extends BoiletComponentBase {
    public ingresando_nuevo_activo = false;
    public ingresando_nuevo_cliente = false;
    public ingresando_nuevo_contacto_cliente = false;
    public modalfechaFinContrato = false;
    public modalfechaInicioContrato = false;
    public modalfechaFinGarantia = false;
    public modalfechaInicioGarantia = false;
    public modalfechaFinPermisoCirculacion = false;
    public search = "" as string;
    public dialogoEdicionCliente = false;
    public dialogoEdicionActivo = false;
    public dialogoEdicionClientePop = false;
    public dialogEditarGrupoCliente = false;
    public dialogoEditarContactoCliente = false;
    public activo_edicion = {} as InfoActivo;
    public activo_vacio = {} as InfoActivo;
    public activo_carga_inicial = {} as InfoActivo;
    public cliente_vacio = {} as Cliente;
    public cliente_carga_inicial = {} as Cliente;
    public cliente_edicion_contacto = { correo: {} as Correo } as Contacto;
    public errors = [] as any[];
    public selected = [] as any[];
    public bloqueConsulta = 300;
    public old_cambio_del_cliente = {} as any;
    public marcas_seleccionados = [] as any[];
    public modelos_seleccionados = [] as any[];
    public familias_seleccionados = [] as any[];
    public clientes_seleccionados = [] as any[];
    public activos = [] as InfoActivo[];
    public cabecera = [
        { text:'', alig: 'start', value:'accion'},
        { text: 'VIN', align: 'start', value: 'vin', width:'180px' },
        { text: 'NOMBRE', value: 'nombre'  ,align: 'start' },
        { text: 'MARCA', value: 'marca'  ,align: 'start' ,width:'180px' },
        { text: 'MODELO', value: 'modelo'  ,align: 'start' },
        { text: 'FAMILIA', value: 'familia'  ,align: 'start'},
        { text: 'CLIENTE', value: 'nombreCliente'  ,align: 'start' },
        { text: 'CONTRATO', value: 'nombreContrato'  ,align: 'start' },
    ];
    public cabeceraCliente = [
        { text:'', alig: 'start', value:'accion'},
        { text: 'NOMBRE', value: 'nombre'  ,align: 'start' },
        { text: 'CONTACTOS', value: 'contactos'  ,align: 'start' },
        { text: 'GRUPOS', value: 'contratos'  ,align: 'start' },
        { text: 'CONTRATOS', value: 'contratos'  ,align: 'start' },  
        { text: 'ACTIVOS', value: 'activos'  ,align: 'start' },       
    ];
    public unidadMantenimiento = [{key:'Ninguno', val:''},{key:'Hora', val:'Hr'}, {key:'Kilómetro', val:'Km'}]
    public cambio_de_cliente = {} as any;
    public clientes = [] as Cliente[];
    public cliente_edicion = {} as Cliente;
    public limpiando = false;
    async created(){
        await this.GetCliente();
        await this.CargaSecuencial(true)
            .then((pepe: any[]) => 
            {                
            this.activos  =pepe.filter( (a : any) => a.vin);
            }
        )
    }

    public get InmutableMarcas(){
        return this.activos.map(r => r.marca).filter(this.onlyUnique).sort();
    }
    public get InmutableModelos(){
        return this.activos.map(r => r.modelo).filter(this.onlyUnique).sort();
    }
    public get InmutableFamilias(){
        return this.activos.map(r => r.familia).filter(this.onlyUnique).sort();
    }
    public get InmutableCliente(){
        return this.activos.map(r => r.nombreCliente).filter(this.onlyUnique).sort();
    }
    public get Filtrados(){
        const arr = this.activos.slice(0);
        let  rxw= Object.assign([] as any[], arr) as any[];
        let clientes = [] as any[];
        let familias = [] as any[];
        let modelos = [] as any[];
        let marcas = [] as any[];
        if( this.clientes_seleccionados.length > 0 ){
            clientes = rxw.filter(r => this.clientes_seleccionados.includes(r.nombreCliente));
        }
        if( this.familias_seleccionados.length > 0 ){
            familias = rxw.filter(r => this.familias_seleccionados.includes(r.familia));
        }
        if( this.modelos_seleccionados.length > 0){
            modelos = rxw.filter(r => this.modelos_seleccionados.includes(r.modelo));
        }
        if( this.marcas_seleccionados.length > 0 ){
            marcas = rxw.filter(r => this.marcas_seleccionados.includes(r.marca));
        }
        return  (this.clientes_seleccionados.length > 0     || 
                this.familias_seleccionados.length > 0      ||
                this.modelos_seleccionados.length > 0       ||
                this.marcas_seleccionados.length > 0  )     ? 
                    [...clientes, ...familias,...modelos,...marcas].filter(this.onlyUnique).sort((a: any, b : any)=> a.vin - b.vin)
                                                            :
                                                            rxw.sort((a: any, b : any)=> a.vin - b.vin);
    }
    public get ComputadoEdicion(){
        return this.activo_edicion.fechaFinPermisoCirculacion? moment(this.activo_edicion.fechaFinPermisoCirculacion).format('DD-MM-YYYY') : null
    }
    onlyUnique(value: any, index:any, self:any) {
        return self.indexOf(value) === index;
    }

    @Watch('cambio_de_cliente')
    private CambioDelCliente(newVal : any, oldVal : any){
        this.old_cambio_del_cliente = oldVal;
        this.cambio_de_cliente = newVal;
        if(!this.limpiando){
            if(this.old_cambio_del_cliente != newVal){
                if(newVal == null || newVal.length == 0 || newVal == ''){
                    if(oldVal !== null ? oldVal != undefined ? oldVal.length > 0 : false : false){
                        const ix = this.clientes.findIndex( t => t._id == oldVal);
                        if(ix > -1){
                            const ixActiv = this.clientes[ix].activos.findIndex(p => p == oldVal);
                            if(ixActiv > -1){
                                this.clientes[ix].activos.splice(ixActiv,1);
                                this.clientes.splice(ix,1,this.clientes[ix]);
                            }
                        }
                    }
                    this.activo_edicion.cliente_id = '';
                    this.activo_edicion.nombreCliente = '';
                    
                }
                if(newVal != null ? newVal != undefined ? newVal.length > 0 : false : false){
                    if(oldVal !== null ? oldVal != undefined ? oldVal.length > 0 : false : false){
                        const ix = this.clientes.findIndex( t => t._id == oldVal);
                        if(ix > -1){
                            const ixActiv = this.clientes[ix].activos.findIndex(p => p == oldVal);
                            if(ixActiv > -1){
                                this.clientes[ix].activos.splice(ixActiv,1);
                                this.clientes.splice(ix,1,this.clientes[ix]);
                            }
                        }
                    }
                    const ixx = this.clientes.findIndex( t => t._id == newVal);
                    if(ixx > -1){
                        const ixActiv = this.clientes[ixx].activos.findIndex(p => p == newVal);
                        if(ixActiv == -1){
                            this.clientes[ixx].activos.push(this.activo_edicion._id);
                            this.clientes.splice(ixx,1,this.clientes[ixx]);
                        }
                        this.activo_edicion.cliente_id = newVal;
                        this.activo_edicion.nombreCliente = this.clientes[ixx].nombre;
                    }
                    
                }
            }
        }
        this.limpiando = false;
    }
    public CambioSmuInicioGarantia(newVal: any){
        if(newVal && (this.activo_edicion.rangoGarantia ? this.activo_edicion.rangoGarantia > 0 : false)){
            //@ts-ignore
            this.activo_edicion.smuFinGarantia = parseInt(newVal) + parseInt(this.activo_edicion.rangoGarantia.toString());
        }else{
            if( this.activo_edicion.smuFinGarantia ? this.activo_edicion.smuFinGarantia > 0  : false ){
                //@ts-ignore
                if(this.activo_edicion.smuFinGarantia < newVal){
                    this.activo_edicion.smuFinGarantia = newVal;
                }
            }
        }
    }
    public CambioSmuFinGarantia(newVal: any){
        if(newVal && (this.activo_edicion.rangoGarantia ? this.activo_edicion.rangoGarantia > 0 : false)){
            // @ts-ignore
            this.activo_edicion.smuInicioGarantia = newVal - this.activo_edicion.rangoGarantia;
        }else{
            if( this.activo_edicion.smuInicioGarantia ? this.activo_edicion.smuInicioGarantia > 0  : false ){
                //@ts-ignore
                if(this.activo_edicion.smuInicioGarantia > newVal){
                    this.activo_edicion.smuInicioGarantia = newVal;
                }
            }
        }
    }
    public CambioSmuRangoGarantia(newVal: any){
        if(newVal){
            const val: number = parseInt(newVal);
            if(val > 0){
                if(this.activo_edicion.smuInicioGarantia){
                    this.activo_edicion.smuFinGarantia = this.activo_edicion.smuInicioGarantia + newVal;
                }else{
                    if(this.activo_edicion.smuFinGarantia){
                        this.activo_edicion.smuInicioGarantia = this.activo_edicion.smuFinGarantia + newVal;
                    }
                }
            }
        }else{
            this.activo_edicion.smuFinGarantia = null;
            this.activo_edicion.smuInicioGarantia= null;
        }
    }
    public CambioSmurangoContrato( newVal : any){
        if(newVal){
            const val: number = parseInt(newVal);
            if(val > 0){
                if(this.activo_edicion.smuInicioContrato){
                    this.activo_edicion.smuFinContrato = this.activo_edicion.smuInicioContrato + newVal;
                }else{
                    if(this.activo_edicion.smuFinContrato){
                        this.activo_edicion.smuInicioContrato = this.activo_edicion.smuFinContrato + newVal;
                    }
                }
            }
        }else{
            this.activo_edicion.smuFinContrato = null;
            this.activo_edicion.smuInicioContrato = null;
        }
    }
    public CambioSmuInicioContrato( newVal : any){
        if(newVal && (this.activo_edicion.rangoSmuContrato ? this.activo_edicion.rangoSmuContrato > 0 : false)){
            //@ts-ignore
            this.activo_edicion.smuFinContrato = parseInt(newVal) + parseInt(this.activo_edicion.rangoSmuContrato.toString());
        }else{
            if( this.activo_edicion.smuFinContrato ? this.activo_edicion.smuFinContrato > 0  : false ){
                //@ts-ignore
                if(this.activo_edicion.smuFinContrato < newVal){
                    this.activo_edicion.smuFinContrato = newVal;
                }
            }
        }
    }
    public CambioSmuFinContrato( newVal : any){
        if(newVal && (this.activo_edicion.rangoSmuContrato ? this.activo_edicion.rangoSmuContrato > 0 : false)){
            // @ts-ignore
            this.activo_edicion.smuInicioContrato = newVal - this.activo_edicion.rangoSmuContrato;
        }else{
            if( this.activo_edicion.smuInicioContrato ? this.activo_edicion.smuInicioContrato > 0  : false ){
                //@ts-ignore
                if(this.activo_edicion.smuInicioContrato > newVal){
                    this.activo_edicion.smuInicioContrato = newVal;
                }
            }
        }
    }
    
    public async GetCliente(){
        this.boiletService.get<string>('/api/Cliente/', true).then( t =>{
            if(!t.isError){
                this.clientes = JSON.parse(t.content) as unknown as Cliente[];
                this.clientes = this.clientes ? this.clientes.length > 0 ? this.clientes.sort( (a,b) => a.nombre > b.nombre ? 1 : a.nombre < b.nombre ? -1 :0 ) : this.clientes : this.clientes;
            }
            else{
                this.errors = t.errors;
                return [] as Cliente[]
            }
        })
    }
    public async GetActivoPorIndice(indice: any, cargador: boolean) {      
        const op = await this.boiletService.get<string>('/api/Activo/'+indice+'/'+this.bloqueConsulta ,cargador)
                .then( r => {
                        if(!r.isError){                            
                            const indiceT = JSON.parse(r.content) as unknown as InfoActivo[];                           
                            return indiceT;
                        }else{
                            this.errors = r.errors;
                            return [] as InfoActivo[]
                        }
                });
  
        return op;
    }
    public async CargaSecuencial(cargador: boolean = true){
        var t = [] as InfoActivo[];
        let index = 0;
        const r = await this.GetActivoPorIndice(index, cargador) as InfoActivo[];
        if(r.length > 0){
            r.forEach((o: InfoActivo) =>{ 
                o.fechaFinPermisoCirculacion = o.fechaFinPermisoCirculacion ? (o.fechaFinPermisoCirculacion as any).$date.substring(0,10) : null; 
                o.fechaFinContrato = o.fechaFinContrato ? (o.fechaFinContrato as any).$date.substring(0,10) : null;
                o.fechaFinGarantia= o.fechaFinGarantia ? (o.fechaFinGarantia as any).$date.substring(0,10) : null;
                o.fechaInicioContrato = o.fechaInicioContrato ? (o.fechaInicioContrato as any).$date.substring(0,10) : null;
                o.fechaInicioGarantia = o.fechaInicioGarantia ? (o.fechaInicioGarantia as any).$date.substring(0,10) : null;
                t.push(o);
            });
            index++;
            if(r.length == this.bloqueConsulta){
                while(index > -1){
                    let op = await this.GetActivoPorIndice(index, cargador) as InfoActivo[];
                    if(op.length == 0){
                        index = -1;
                    }else{
                        index++;
                        op.forEach((o:InfoActivo) => {
                            o.fechaFinPermisoCirculacion = o.fechaFinPermisoCirculacion ?  (o.fechaFinPermisoCirculacion as any).$date.substring(0,10) : null;
                            o.fechaFinContrato = o.fechaFinContrato ? (o.fechaFinContrato as any).$date.substring(0,10) :  null;
                            o.fechaFinGarantia= o.fechaFinGarantia ? (o.fechaFinGarantia as any).$date.substring(0,10) :  null;
                            o.fechaInicioContrato = o.fechaInicioContrato ? (o.fechaInicioContrato as any).$date.substring(0,10) :  null;
                            o.fechaInicioGarantia = o.fechaInicioGarantia ? (o.fechaInicioGarantia as any).$date.substring(0,10) :  null;
                            t.push(o)
                        });
                    }
                }
            }
        }
        return t;         
    }

    public NuevoCliente(){
        const cliente = {} as Cliente;
        cliente._id = this.generateUUID();
        cliente.activos = [] as string[];
        cliente.contactos = [] as Contacto[];
        cliente.contratos = [] as Contrato[];
        cliente.grupos = [] as Contrato[];
        cliente.nombre = "";
        this.cliente_edicion = cliente;
        this.dialogoEdicionClientePop = true;

    }

    public CargaCliente(item: string){
        const rx = this.clientes.slice(0);
        const ixrx = rx.findIndex( t => t._id == item);
        const prx = rx[ixrx] as Cliente;

        // const ixCliente = this.clientes.findIndex( t => t._id == item);
        // const p = this.clientes[ixCliente] as Cliente;
        this.cliente_carga_inicial = Object.assign({}, prx);
        this.cliente_edicion = Object.assign({},  this.cliente_carga_inicial );
        this.ingresando_nuevo_cliente = false;
        this.dialogoEdicionClientePop = true;
    }

    public CancelarEdicionCliente(){
        const ixCliente = this.clientes.findIndex( t => t._id == this.cliente_edicion._id);
        const ixClienteNombre = this.clientes.findIndex( t => t.nombre == this.cliente_edicion.nombre);
        const ix = ixCliente != undefined && ixCliente != null && ixCliente > -1? ixCliente : ixClienteNombre ? ixClienteNombre : -1 ;
        if(ix > -1){
            this.clientes.splice(ix,1,this.cliente_carga_inicial);
            this.cliente_edicion = {} as Cliente; 
            this.cliente_carga_inicial = {} as Cliente;;
            this.dialogoEdicionClientePop = false;
        }
        // this.dialogoEdicionClientePop = false;
    }

    public CargarContactoCliente(item0: Contacto){
        const rx = this.cliente_edicion.contactos.slice(0);
        const ixrx = rx.findIndex( t => t == item0);
        const item = rx[ixrx] as Contacto;

        this.cliente_edicion_contacto = Object.assign({}, item);
        this.dialogoEditarContactoCliente = true; 
        this.ingresando_nuevo_contacto_cliente = false;     
    }

    public NuevoContactoCliente(){
        this.cliente_edicion_contacto = {  correo:{} as Correo } as Contacto;
        this.dialogoEditarContactoCliente = true;
        this.ingresando_nuevo_contacto_cliente = true;
    }

    public AgregarNuevoContactoCliente(){
        this.cliente_edicion.contactos.push(this.cliente_edicion_contacto);
        this.dialogoEditarContactoCliente = false;
        this.ingresando_nuevo_contacto_cliente = false;
    }

    public AceptarCambiosContactoCliente(){
        this.dialogoEditarContactoCliente = false;
        this.ingresando_nuevo_contacto_cliente = false;

        const ixrx = this.cliente_edicion.contactos.findIndex( t => t == this.cliente_edicion_contacto);
        this.cliente_edicion.contactos.splice(ixrx,1,this.cliente_edicion_contacto);
        this.dialogoEditarContactoCliente = false;
        this.ingresando_nuevo_contacto_cliente = false; 
    }


    public CancelarNuevoContactoCliente(){
        this.cliente_edicion_contacto = Object.assign({},  {  correo:{} as Correo } as Contacto); 
        this.dialogoEditarContactoCliente = false;
        this.ingresando_nuevo_contacto_cliente = false;
    }

    public CargaActivo( item0 : InfoActivo){
        const rx = this.Filtrados.slice(0);
        console.log(8888)
        const ixrx = rx.findIndex(t => t._id == item0._id);
        const item = rx[ixrx];
        const ixCliente = this.clientes.findIndex( t => t._id == item.cliente_id);
        const ixClienteNombre = this.clientes.findIndex( t => t.nombre == item.nombreCliente);
        if(ixCliente > -1){
            this.activo_edicion.cliente_id = this.clientes[ixCliente]._id;
            this.activo_edicion.nombreCliente = this.clientes[ixCliente].nombre;
            this.cambio_de_cliente = this.clientes[ixCliente]._id
        }else
        if(ixClienteNombre > -1){
            this.activo_edicion.cliente_id = this.clientes[ixClienteNombre]._id;
            this.activo_edicion.nombreCliente = this.clientes[ixClienteNombre].nombre;
            this.cambio_de_cliente = this.clientes[ixClienteNombre]._id
        }
        else{
            this.limpiando = true;
            this.activo_edicion.cliente_id = '';
            this.activo_edicion.nombreCliente = '';
            this.cambio_de_cliente = '';
        }
        if(ixCliente > -1 || ixClienteNombre > -1){
            this.ingresando_nuevo_activo = false;
            this.activo_edicion = Object.assign({}, item);
            this.activo_carga_inicial = Object.assign({}, item);
            this.dialogoEdicionActivo = true;
        }else{
            this.ingresando_nuevo_activo = false;
            this.activo_edicion = Object.assign({}, item);
            this.activo_carga_inicial = Object.assign({}, item);
            this.dialogoEdicionActivo = true;
        }
    }

    public NuevoActivo(){
        this.limpiando = true;
        this.activo_edicion.cliente_id = '';
        this.activo_edicion.nombreCliente = '';
        this.cambio_de_cliente = '';
        let item: InfoActivo = {}  as InfoActivo;
        item._id = this.generateUUID();
        this.ingresando_nuevo_activo = true;
        this.activo_edicion = Object.assign({}, item);
        this.dialogoEdicionActivo = true;
    }

    public async GuardaCambioCliente0000(){
        this.boiletService.put<any>('/api/Cliente/'+this.cliente_edicion._id, JSON.stringify(this.cliente_edicion), false)
        .then(async r => {
            if(!r.isError){
                this.dialogoEdicionCliente = false; 
                this.ingresando_nuevo_contacto_cliente = false; 
                await this.GetCliente();
                this.CancelarEdicionCliente();
            }else{
                this.errors = r.errors;
            }
        });
        

        
    }
    public async GuardaCambioCliente(){
        if(this.activo_carga_inicial.vin && this.activo_edicion.vin){
            if(this.activo_carga_inicial !== this.activo_edicion){
                await this.GuardarActivo();                
            }
            return 1;
        }
        if(this.activo_edicion.vin && !this.activo_carga_inicial.vin){
            if(this.activo_carga_inicial !== this.activo_edicion){
                await this.GuardarActivo();
            }
            return 1;
        }
        return 0;
    }
    public async BorrarEdicionActivo(){
        this.dialogoEdicionActivo = false;
        this.activo_edicion = Object.assign({}, this.activo_vacio);
        this.activo_carga_inicial = Object.assign({}, this.activo_vacio);
        await this.GetCliente();
    }
    public async GuardarActivo(){
            this.activo_edicion.modelo = this.activo_edicion.modelo ? this.activo_edicion.modelo.trim().toUpperCase():'';
            this.activo_edicion.nombre = this.activo_edicion.nombre ? this.activo_edicion.nombre.trim().toUpperCase():'';
            this.activo_edicion.familia = this.activo_edicion.familia ? this.activo_edicion.familia.trim().toUpperCase():'';
            this.activo_edicion.numeroMotor = this.activo_edicion.numeroMotor ? this.activo_edicion.numeroMotor.trim().toUpperCase():'';
            this.activo_edicion.numeroSerie = this.activo_edicion.numeroSerie ? this.activo_edicion.numeroSerie.trim().toUpperCase():'';
            //@ts-ignore
            this.activo_edicion.fechaCoordenadas = this.activo_edicion.fechaCoordenadas ? this.activo_edicion.fechaCoordenadas.$date : null ;
            //@ts-ignore  
            this.activo_edicion.fechaFinContrato = this.activo_edicion.fechaFinContrato ? this.activo_edicion.fechaFinContrato.$date : null ;
            //@ts-ignore
            this.activo_edicion.fechaFinGarantia = this.activo_edicion.fechaFinGarantia ? this.activo_edicion.fechaFinGarantia.$date : null ;
            //@ts-ignore
            this.activo_edicion.fechaFinPermisoCirculacion = this.activo_edicion.fechaFinPermisoCirculacion ? this.activo_edicion.fechaFinPermisoCirculacion.$date : null ;
            //@ts-ignore
            this.activo_edicion.fechaInicioContrato = this.activo_edicion.fechaInicioContrato ? this.activo_edicion.fechaInicioContrato.$date : null ;
            //@ts-ignore
            this.activo_edicion.fechaInicioGarantia = this.activo_edicion.fechaInicioGarantia ? this.activo_edicion.fechaInicioGarantia.$date : null ;
            //@ts-ignore
            this.activo_edicion.payloadFecha = this.activo_edicion.payloadFecha ? this.activo_edicion.payloadFecha.$date : null ;
            //@ts-ignore
            this.activo_edicion.odolitroFecha = this.activo_edicion.odolitroFecha ? this.activo_edicion.odolitroFecha.$date : null ;
            //@ts-ignore
            this.activo_edicion.odometroFecha = this.activo_edicion.odometroFecha ? this.activo_edicion.odometroFecha.$date : null ;
            //@ts-ignore
            this.activo_edicion.horometroFecha = this.activo_edicion.horometroFecha ? this.activo_edicion.horometroFecha.$date : null ;
            //@ts-ignore
            this.activo_edicion.loadCountFecha = this.activo_edicion.loadCountFecha ? this.activo_edicion.loadCountFecha.$date : null ;
            //@ts-ignore
            this.activo_edicion.odolitroRalentiFecha = this.activo_edicion.odolitroRalentiFecha ? this.activo_edicion.odolitroRalentiFecha.$date : null ;
            //@ts-ignore
            this.activo_edicion.horometroRalentiFecha = this.activo_edicion.horometroRalentiFecha ? this.activo_edicion.horometroRalentiFecha.$date : null ;
            //@ts-ignore
            this.activo_edicion.nivelCatalizadorFecha = this.activo_edicion.nivelCatalizadorFecha ? this.activo_edicion.nivelCatalizadorFecha.$date : null ;
            //@ts-ignore
            this.activo_edicion.nivelCombustibleFecha = this.activo_edicion.nivelCombustibleFecha ? this.activo_edicion.nivelCombustibleFecha.$date : null ;
            //@ts-ignore
            this.activo_edicion.nivelRefrigeranteFecha = this.activo_edicion.nivelRefrigeranteFecha ? this.activo_edicion.nivelRefrigeranteFecha.$date : null ;
            this.activo_edicion.coordenadas = null;
            this.activo_edicion.horometro = null;
            this.activo_edicion.odometro = null;
            this.activo_edicion.loadCount = null;
            this.activo_edicion.odolitro = null;
            this.activo_edicion.payload = null;
            this.activo_edicion.odolitroRalenti = null;
            this.activo_edicion.horometroRalenti = null;
            this.activo_edicion.nivelCombustible = null;
            this.activo_edicion.nivelCatalizador = null;
            this.activo_edicion.nivelRefrigerante = null;



            await this.boiletService.put<any>('/api/Activo',JSON.stringify(this.activo_edicion)).then(async r =>{
                if(!r.isError){

                    const ix = this.activos.findIndex( t => t.vin == this.activo_edicion.vin);
                    const ixclientNew = this.clientes.findIndex(o => o._id == this.activo_edicion.cliente_id);
                    const ixclientOld = this.clientes.findIndex(o => o._id == this.old_cambio_del_cliente);
                    if(ixclientNew > -1){
                        this.boiletService.put<any>('/api/Cliente/'+this.activo_edicion.cliente_id, JSON.stringify(this.clientes[ixclientNew]), false);
                    }
                    if(ixclientOld > -1){
                       await this.boiletService.put<any>('/api/Cliente/'+ this.old_cambio_del_cliente, JSON.stringify(this.clientes[ixclientOld]), false);
                    }
                    this.swalToast(2000, 'success', this.$t('Successful').toString());                   
                    
                    if(ix > -1){
                        this.activos.splice(ix,1,this.activo_edicion);
                    }
                    else{
                        this.activos.push(this.activo_edicion);
                    }
                    await this.BorrarEdicionActivo();    
                }else{
                    var errorText = "";
                    r.errors.forEach(error => {
                        errorText += this.$t(error.name) + '<br>';
                    });

                    this.swalAlert('error', errorText);
                }
            })
    }
    public CargaColorInfoActivo(it: any){
        return it.infoCan == -1? 'celeste-t-td-class' :'white-t-td-class'
    }
    public CargaColorFecha(fecha:Date | null | undefined): String{
        if(fecha){
            const dd = moment.utc().diff(fecha, 'hour');
            if(dd > 24){
                return "grey white--text";
            }else 
            if(dd > 14){
                return "rojo white--text"
            }else if(dd > 8){
                return "naranja white--text"
            }else{
                return "verde white--text";
            }
        }else{
            return "grey ligthten-4 ";
        }
    }
    public FormatoFecha(val: Date | null){
        if(val){
            return moment(val).format('YYYY-MM-DD');
        }
        return ''
    }

    public  generateUUID() : string { // Public Domain/MIT
        var d = new Date().getTime();//Timestamp
        var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16;//random number between 0 and 16
            if(d > 0){//Use timestamp until depleted
                r = (d + r)%16 | 0;
                d = Math.floor(d/16);
            } else {//Use microseconds since page-load if supported
                r = (d2 + r)%16 | 0;
                d2 = Math.floor(d2/16);
            }
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }

}