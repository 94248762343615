import BoiletComponentBase from '@/shared/application/boilet-component-base';
import { Component } from 'vue-property-decorator';

@Component
export default class AsideMenuComponent extends BoiletComponentBase {
    drawer = false;
    isAdmin = false;

    get mainMenuItems() {
        return [
            // { icon: 'mdi-home', text: this.$t('Home'), link: '/admin/home' },
            { icon: 'mdi-web', text: this.$t('Mapa'), link: '/gestion/mapa' },
            { icon: 'mdi-message-bulleted', text: this.$t('Seguimiento Recomendaciones'), link: '/gestion/recomendacion' },
            { icon: 'mdi-chart-bar-stacked', text: this.$t('Creación de reportes'), link: '/gestion/reporte-flota' },
            { icon: 'mdi-cards-variant', text: this.$t('Ranking Códigos'), link: '/gestion/ranking' },
        ];
    }

    get adminMenuItems() {
        return [
            { icon: 'mdi-account-multiple', text: this.$t('Users'), link: '/admin/user-list' },
            { icon: 'mdi-briefcase-account', text: this.$t('Roles'), link: '/admin/role-list' },
            {icon: 'mdi-email-check', text: this.$t('Plantilla Recomendaciones Expertas'), link: '/gestion/diccionarios' },
            // { icon: 'mdi-email-check-outline', text: this.$t('Plantilla Recomendaciones DTC'), link: '/seguimiento/plantilla-dtc' },
            // { icon: 'mdi-email-send', text: this.$t('Plantilla Recomendaciones DTC F1'), link: '/seguimiento/plantilla-dtc-f1' },
            // { icon: 'mdi-bookmark-plus', text: this.$t('Plantilla Alertas'), link: '/seguimiento/plantilla-f2' },
            { icon: 'mdi-office-building', text: this.$t('Plantilla Organizaciones'), link: '/gestion/auditoria' },
            { icon: 'mdi-subtitles', text: this.$t('Plantilla Sucursales'), link: '/seguimiento/plantilla-sucursales' },
            // { icon: 'mdi-cards-variant', text: this.$t('Ranking Códigos'), link: '/seguimiento/ranking-codigos' },
            // { icon: 'mdi-cards-variant', text: this.$t('Ranking Códigos Equipos'), link: '/seguimiento/ranking-codigo-equipos' },
        ];
    }
    
    MensajeLink(s: string){
        window.open(s);
    }
    mounted() {
        this.$root.$on('drawerChanged',
            () => {
                this.drawer = !this.drawer;
            });
    }
}

