import { Component } from 'vue-property-decorator';
import BoiletComponentBase from '@/shared/application/boilet-component-base';
import LanguageStore from '@/stores/language-store';
@Component({
    components: {

        TopMenu: require('@/acceso-externo/components/top-menu/top-menu.vue').default,
        Footer: require('@/gestion/components/footer/footer.vue').default
    }
})
export default class AccesoExternoLayoutComponent extends BoiletComponentBase {
    public selectedLanguage = {} as ILanguageDto;

    public created() {
        this.boilet.auth.removeProps();
    }

    public beforeMount() {
        this.selectedLanguage = LanguageStore.getLanguage();
    }

    public changeLanguage(languageCode: string, languageName: string) {
        this.$i18n.locale = languageCode;
        this.selectedLanguage = { languageName, languageCode } as ILanguageDto;
        this.$vuetify.lang.current = languageCode;

        LanguageStore.setLanguage({
            languageCode,
            languageName
        } as any);
    }
}