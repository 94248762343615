import { useGestionStore } from '@/gestion/stores/assets/gestion';
import BoiletComponentBase from '@/shared/application/boilet-component-base';
import { Maquina } from '@/shared/models/shared/consolidados/Maquina';
import { Organizacion } from '@/shared/models/shared/consolidados/Organizacion';
import { AsignacionResponsable, RecomendacionCliente, Mensaje} from '@/shared/models/shared/consolidados/RecomendacionCliente';
import { Sucursales } from '@/shared/models/shared/consolidados/Sucursales';
import moment from 'moment';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { DataTableCompareFunction } from 'vuetify';
import { getDistance } from 'geolib';



@Component({
    components: {
        PostVenta: require('@/gestion/components/post-venta/post-venta.vue').default,
        ResumenAlertaRecomendacion: require('@/gestion/modules/resumen-alerta-recomendacion/resumen-alerta-recomendacion.vue').default,
        CasoZero: require('@/gestion/components/caso-zero/caso-zero.vue').default,
        } 
})
export default class Recomendacion extends BoiletComponentBase {
    public emitidas = true;
    public casozerodialog = false;
    public buscarPorVin = "";
    public buscarPorCodigo = "";
    public buscarPor = "";
    public buscarPorAnalista = "";
    public filtroMensajes = 'todos';
    public marcas = [] as string[];
    public modelos = [] as string[];
    public familias = [] as string[];
    public clientes = [] as string[];
    public tipos = [] as string[];
    public estados = [] as string[];

    // public useAlertasStore = useGestionStore();
    public asyncpool = {} as any;
    public itemEditado: RecomendacionCliente | null = null;
    public controlEstado: any =  1;
    public organizaciones = [] as Organizacion[];
    public sucursales = [] as Sucursales[];
    public errors = [] as any[];
    public items = [] as RecomendacionCliente[];
    public analistas = [] as any[];
    public responsable = '';
    public dialog= false;
    public hasta = new Date().toISOString().substr(0, 10);
    public desde = moment().add(-31, 'days').toISOString().substr(0, 10);
    public menuDesde= false;
    public menuHasta= false;
    public dialogoResponsable=false;
    public idSeguimiento = "";
    public region = "";
    public ordentabla = [] as string[];
    public ordentablaSorter = [] as boolean[];
    public equipos = [] as any[];

    public cabeceraDescarga = [
        {
            text: 'RUT',
            value: 'estado',
        },
        {
            text: 'ESTADO ACTUAL',
            value: 'org_id',
        },
        {
            text: 'TIPO',
            value: 'tipoCodigo',
        },
        {
            text: 'SUCURSAL',
            value: 'region',
        },
        {
            text: 'RESPONSABLE',
            value: 'responsable',
        },
        {
            text: 'CLIENTE',
            value: 'nombreCliente',
        },
        {
            text: 'FAMILIA',
            value: 'familia',
        },
        {
            text: 'MARCA',
            value: 'marca',
        },
        {
            text: 'MODELO',
            value: 'modelo',
        },
        {
            text: 'VIN',
            value: 'vin',
        },
        {
            text: 'CÓDIGO',
            value: 'codigo',
        },
        {
            text: 'SPN',
            value: 'spn',
        },
        {
            text: 'FMI',
            value: 'fmi',
        },
        
        {
            text: 'Color',
            value: 'color',
        },
        {
            text: 'Severidad',
            value: 'severidad',
        },
        {
            text: 'Fecha Envio',
            value: 'fechaEnvio',
        },
    ];

    public cabecera=[
        {
            text: 'ESTADO ACTUAL',
            align: 'start',
            sortable: true,
            value: 'estado',
            // width: '180px'
        },
        {
            text: 'TIPO',
            align: 'start',
            sortable: true,
            value: 'tipoCodigo',
            // width: '100px'
        },
        {
            text: 'PRIMER CONTACO',
            align: 'start',
            sortable: true,
            value: 'dias',
            // width: '160px'
        },
        {
            text: 'SUCURSAL',
            align: 'start',
            sortable: true,
            value: 'region',
            // width: '160px'
        },        
        {
            text: 'RESPONSABLE',
            align: 'start',
            sortable: true,
            value: 'responsable',
            // width: '200px'
        },       
        {
            text: 'CLIENTE',
            align: 'start',
            sortable: true,
            value: 'nombreCliente',
            
        },
        {
            text: 'MODELO',
            align: 'start',
            sortable: true,
            value: 'modelo',
            // width: '140px'
        },
        {
            text: 'VIN',
            align: 'start',
            sortable: true,
            value: 'vin',
            // width: '140px'
        },
        // {
        //     text: 'FECHA DE ENVIO',
        //     align: 'start',
        //     sortable: true,
        //     value: 'fechaEnvio',
            
        // },
        
        // {
        //     text: 'COLOR',
        //     align: 'start',
        //     sortable: true,
        //     value: 'color',
        //     width: '110px'
        // },
        {
            text: 'CÓDIGO',
            align: 'start',
            sortable: true,
            value: 'codigo',
            
        },
        
        
        
        {
            text: 'FAMILIA',
            // align: 'start',
            sortable: true,
            value: 'familia',
            align: ' d-none' 
        },
        {
            text: 'MARCA',
            align: 'start',
            sortable: true,
            value: 'marca',
            // width: '140px'
        },
        
        
        
        
    ];

    public cabeceraSincontacto=[
        {
            text: 'RUT CLIENTE',
            align: 'start',
            sortable: true,
            value: 'estado',
            // width: '180px'
        },
        {
            text: 'TIPO',
            align: 'start',
            sortable: true,
            value: 'tipoCodigo',
            // width: '100px'
        },
        {
            text: 'PRIMER CONTACO',
            align: 'start',
            sortable: true,
            value: 'dias',
            // width: '160px'
        },
        {
            text: 'SUCURSAL',
            align: 'start',
            sortable: true,
            value: 'region',
            // width: '160px'
        },
        
        {
            text: 'RESPONSABLE',
            align: 'start',
            sortable: true,
            value: 'responsable',
            // width: '200px'
        },
        
        {
            text: 'CLIENTE',
            align: 'start',
            sortable: true,
            value: 'nombreCliente',
            
        },
        {
            text: 'MODELO',
            align: 'start',
            sortable: true,
            value: 'modelo',
            // width: '140px'
        },
        {
            text: 'VIN',
            align: 'start',
            sortable: true,
            value: 'vin',
            // width: '140px'
        },
        // {
        //     text: 'FECHA DE ENVIO',
        //     align: 'start',
        //     sortable: true,
        //     value: 'fechaEnvio',
            
        // },
        
        // {
        //     text: 'COLOR',
        //     align: 'start',
        //     sortable: true,
        //     value: 'color',
        //     width: '110px'
        // },
        {
            text: 'CÓDIGO',
            align: 'start',
            sortable: true,
            value: 'codigo',
            
        },
        
        
        
        {
            text: 'FAMILIA',
            // align: 'start',
            sortable: true,
            value: 'familia',
            align: ' d-none' 
        },
        {
            text: 'MARCA',
            align: 'start',
            sortable: true,
            value: 'marca',
            // width: '140px'
        },
        
        
        
        
    ];

    mounted(){
        this.GetOrganizaciones().then( async () =>{
            this.GetSucursales().then( async () =>{
                this.GetAnalistas().then( async () => {
                    this.Get();
                });
            })
        });          
    }
    created(){
        this.asyncpool = setInterval(this.ActualizandoDatos, 30000);     
    }
    destroy() {
        clearInterval(this.asyncpool)
    }
    beforeDestroy () {
        clearInterval(this.asyncpool)
    }
    // get Cargar(){
    //     return this.useAlertasStore.estado;
    // }
    // @Watch('Cargar')
    // cambiaCarga(value: boolean){
    //     if(value == true){
    //         this.Get().then( async () =>{
    //             this.useAlertasStore.actualiza(false);
    //         })
    //     }
    // }

    getObjectValueByPath (obj: any, path: string, fallback?: any): any {
        // credit: http://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-with-string-key#comment55278413_6491621

        if (obj == null || !path || typeof path !== 'string')
        {
            return fallback
        }else
        if (obj[path] !== undefined){
            return obj[path]
        } else
        if(path === 'dias'){
            return  moment(obj['fechaEnvio']? obj['fechaEnvio']:obj['fechaServicio'] ).unix();
        }else if(path === 'orga'){
           return obj ? obj.org_id ? this.GetOrganizacionNombre(obj.org_id):"":"";
        }else if(path === 'estado'){
            return this.DefineEstado(obj);
         }else if(path === 'tipoCodigo'){
            return obj.tipoCodigo ? obj.tipoCodigo == "DTAC" ? "AE": obj.tipoCodigo : ""
         }
        else{
            path = path.replace(/\[(\w+)\]/g, '.$1') // convert indexes to properties
            path = path.replace(/^\./, '') // strip a leading dot
            return this.getNestedValue(obj, path.split('.'), fallback)
        }
    }

    getNestedValue (obj: any, path: (string | number)[], fallback?: any): any {
        const last = path.length - 1
        
        if (last < 0) return obj === undefined ? fallback : obj
        
        for (let i = 0; i < last; i++) {
            if (obj == null) {
            return fallback
            }
            obj = obj[path[i]]
        }
        
        if (obj == null) return fallback
        
        return obj[path[last]] === undefined ? fallback : obj[path[last]]
    }

    sortItems<RecomendacionCliente> ( 
        items: RecomendacionCliente[], 
        sortBy: string[], 
        sortDesc: boolean[], 
        locale: string, 
        customSorters?: Record<string, DataTableCompareFunction<RecomendacionCliente>> 
      ): any[] { 
        if (sortBy === null || !sortBy.length) return items 
        const stringCollator = new Intl.Collator(locale, { sensitivity: 'accent', usage: 'sort' }) 
       
        return items.sort((a, b) => { 
          for (let i = 0; i < sortBy.length; i++) { 
            const sortKey = sortBy[i] 
       
            let sortA = this.getObjectValueByPath(a, sortKey) 
            let sortB = this.getObjectValueByPath(b, sortKey) 
       
            if (sortDesc[i]) { 
              [sortA, sortB] = [sortB, sortA] 
            } 
       
            if (customSorters && customSorters[sortKey]) { 
              const customResult = customSorters[sortKey](sortA, sortB) 
       
              if (!customResult) continue 
       
              return customResult 
            } 
       
            // Check if both cannot be evaluated 
            if (sortA === null && sortB === null) { 
              continue 
            } 
       
            [sortA, sortB] = [sortA, sortB].map(s => (s || '').toString().toLocaleLowerCase()) 
       
            if (sortA !== sortB) { 
              if (!isNaN(sortA) && !isNaN(sortB)) return Number(sortA) - Number(sortB) 
              return stringCollator.compare(sortA, sortB) 
            } 
          } 
       
          return 0 
        }) 
    } 

    public async ActualizandoDatos(){
        this.GetOrganizaciones().then( async () =>{
            this.GetSucursales().then( async () =>{
                this.GetAnalistas().then( async () => {
                    this.Get();
                });
            })
        });
    }
    public async GetAnalistas(){
        await this.boiletService.get<any[]>('/api/Latidos/analistas',false)
        .then( a => {
            if(!a.isError){
                const analistas = a.content as string[];
                if(analistas.length > 0){
                    this.analistas = analistas.filter( a => a != 'testadmin@mail.com' && a != 'admin@mail.com' && a != 'memberuser@mail.com') as string[];
                    
                }else{
                    this.analistas = [] as string[];
                }
                
            }else{
                this.errors = a.errors;
            }
        });
    }
    public async GetOrganizaciones(){
        await this.boiletService.get<Organizacion[]>('/api/Organizaciones',false)
        .then( a => {
            if(!a.isError){
                this.organizaciones = a.content as Organizacion[];
            }else{
                this.errors = a.errors;
            }
        });
    }
    public async GetSucursales(){
        await this.boiletService.get<Sucursales[]>('/api/Sucursales',false)
        .then( a => {
            if(!a.isError){
                this.sucursales = a.content as Sucursales[];
            }else{
                this.errors = a.errors;
            }
        });
    }
    public async Get(){
        await this.boiletService.get<RecomendacionCliente[]>('/api/Recomendaciones/desdehasta/'+this.desde+'/'+this.hasta, false)
        .then( a =>{
            if(!a.isError){
                this.items = a.content as RecomendacionCliente[];
                // this.items.forEach( async f =>{
                //     this.CargadorCoordenadas(f);
                // });
            }
            else{
                this.errors = a.errors;
            }
        }); 
    }
    onlyUnique(value: any, index:any, self:any) {
        return self.indexOf(value) === index;
    }
    get C_Marcas(){
        let d = this.PlantillaPorFecha;
        if(this.buscarPor.length > 0){
           d = d.filter( a =>  moment(a.fechaEnvio).unix().toString().includes(this.buscarPor) )
        }
        if(this.buscarPorVin.length > 0){
            d = d.filter( a => a.vin.includes(this.buscarPorVin.toLocaleUpperCase()) )
        }
        if(this.buscarPorCodigo.length > 0){
            d = d.filter( a => a.codigo.startsWith(this.buscarPorCodigo.toLocaleUpperCase()) )
        }
        // if(this.marcas.length > 0){
        //     d = d.filter( a => this.marcas.findIndex( o => o == a.marca ) > -1);
        // }
        if(this.modelos.length > 0){
            d = d.filter( a => this.modelos.findIndex( o => o == a.modelo ) > -1);
        }
        if(this.familias.length > 0){
            d = d.filter( a => this.familias.findIndex( o => o == a.familia ) > -1);
        }
        if(this.clientes.length > 0){
            d = d.filter( a => this.clientes.findIndex( o => o == a.nombreCliente ) > -1);
        }
        if(this.tipos.length > 0){
            d = d.filter( a => this.tipos.findIndex( item => (a.tipoCodigo ? a.tipoCodigo == "DTAC" ? "AE":a.tipoCodigo:"") == item ) > -1);
        }
        if(this.estados.length > 0){
            d = d.filter( a => this.estados.findIndex( item => this.DefineEstado(a)== item ) > -1);
        }
        const r = d.map( t => t.marca).filter(this.onlyUnique);
        return r.sort();
    }
    get C_Modelos(){
        let d = this.PlantillaPorFecha;
        if(this.buscarPor.length > 0){
           d = d.filter( a =>  moment(a.fechaEnvio).unix().toString().includes(this.buscarPor) )
        }
        if(this.buscarPorVin.length > 0){
            d = d.filter( a => a.vin.includes(this.buscarPorVin.toLocaleUpperCase()) )
        }
        if(this.buscarPorCodigo.length > 0){
            d = d.filter( a => a.codigo.startsWith(this.buscarPorCodigo.toLocaleUpperCase()) )
        }
        if(this.marcas.length > 0){
            d = d.filter( a => this.marcas.findIndex( o => o == a.marca ) > -1);
        }
        // if(this.modelos.length > 0){
        //     d = d.filter( a => this.modelos.findIndex( o => o == a.modelo ) > -1);
        // }
        if(this.familias.length > 0){
            d = d.filter( a => this.familias.findIndex( o => o == a.familia ) > -1);
        }
        if(this.clientes.length > 0){
            d = d.filter( a => this.clientes.findIndex( o => o == a.nombreCliente ) > -1);
        }
        if(this.tipos.length > 0){
            d = d.filter( a => this.tipos.findIndex( item => (a.tipoCodigo ? a.tipoCodigo == "DTAC" ? "AE":a.tipoCodigo:"") == item ) > -1);
        }
        if(this.estados.length > 0){
            d = d.filter( a => this.estados.findIndex( item => this.DefineEstado(a)== item ) > -1);
        }
        const r = d.map( t => t.modelo).filter(this.onlyUnique);
        return r.sort();
    }
    get C_Familias(){
        let d = this.PlantillaPorFecha;
        if(this.buscarPor.length > 0){
           d = d.filter( a =>  moment(a.fechaEnvio).unix().toString().includes(this.buscarPor) )
        }
        if(this.buscarPorVin.length > 0){
            d = d.filter( a => a.vin.includes(this.buscarPorVin.toLocaleUpperCase()) )
        }
        if(this.buscarPorCodigo.length > 0){
            d = d.filter( a => a.codigo.startsWith(this.buscarPorCodigo.toLocaleUpperCase()) )
        }
        if(this.marcas.length > 0){
            d = d.filter( a => this.marcas.findIndex( o => o == a.marca ) > -1);
        }
        if(this.modelos.length > 0){
            d = d.filter( a => this.modelos.findIndex( o => o == a.modelo ) > -1);
        }
        // if(this.familias.length > 0){
        //     d = d.filter( a => this.familias.findIndex( o => o == a.familia ) > -1);
        // }
        if(this.clientes.length > 0){
            d = d.filter( a => this.clientes.findIndex( o => o == a.nombreCliente ) > -1);
        }
        if(this.tipos.length > 0){
            d = d.filter( a => this.tipos.findIndex( item => (a.tipoCodigo ? a.tipoCodigo == "DTAC" ? "AE":a.tipoCodigo:"") == item ) > -1);
        }
        if(this.estados.length > 0){
            d = d.filter( a => this.estados.findIndex( item => this.DefineEstado(a)== item ) > -1);
        }
        const r = d.map( t => t.familia).filter(this.onlyUnique);
        return r.sort();
    }
    get C_Clientes(){
        let d = this.PlantillaPorFecha;
        if(this.buscarPor.length > 0){
           d = d.filter( a =>  moment(a.fechaEnvio).unix().toString().includes(this.buscarPor) )
        }
        if(this.buscarPorVin.length > 0){
            d = d.filter( a => a.vin.includes(this.buscarPorVin.toLocaleUpperCase()) )
        }
        if(this.buscarPorCodigo.length > 0){
            d = d.filter( a => a.codigo.startsWith(this.buscarPorCodigo.toLocaleUpperCase()) )
        }
        if(this.marcas.length > 0){
            d = d.filter( a => this.marcas.findIndex( o => o == a.marca ) > -1);
        }
        if(this.modelos.length > 0){
            d = d.filter( a => this.modelos.findIndex( o => o == a.modelo ) > -1);
        }
        if(this.familias.length > 0){
            d = d.filter( a => this.familias.findIndex( o => o == a.familia ) > -1);
        }
        // if(this.clientes.length > 0){
        //     d = d.filter( a => this.clientes.findIndex( o => o == a.nombreCliente ) > -1);
        // }
        if(this.tipos.length > 0){
            d = d.filter( a => this.tipos.findIndex( item => (a.tipoCodigo ? a.tipoCodigo == "DTAC" ? "AE":a.tipoCodigo:"") == item ) > -1);
        }
        if(this.estados.length > 0){
            d = d.filter( a => this.estados.findIndex( item => this.DefineEstado(a)== item ) > -1);
        }
        const r = d.map( t => t.nombreCliente).filter(this.onlyUnique);
        return r.sort();
    }
    get C_tipoCodigo(){
        let d = this.PlantillaPorFecha;
        if(this.buscarPor.length > 0){
           d = d.filter( a =>  moment(a.fechaEnvio).unix().toString().includes(this.buscarPor) )
        }
        if(this.buscarPorVin.length > 0){
            d = d.filter( a => a.vin.includes(this.buscarPorVin.toLocaleUpperCase()) )
        }
        if(this.buscarPorCodigo.length > 0){
            d = d.filter( a => a.codigo.startsWith(this.buscarPorCodigo.toLocaleUpperCase()) )
        }
        if(this.marcas.length > 0){
            d = d.filter( a => this.marcas.findIndex( o => o == a.marca ) > -1);
        }
        if(this.modelos.length > 0){
            d = d.filter( a => this.modelos.findIndex( o => o == a.modelo ) > -1);
        }
        if(this.familias.length > 0){
            d = d.filter( a => this.familias.findIndex( o => o == a.familia ) > -1);
        }
        if(this.clientes.length > 0){
            d = d.filter( a => this.clientes.findIndex( o => o == a.nombreCliente ) > -1);
        }
        // if(this.tipos.length > 0){
        //      d = d.filter( a => this.tipos.findIndex( item => (a.tipoCodigo ? a.tipoCodigo == "DTAC" ? "AE":a.tipoCodigo:"") == item ) > -1);
        // }
        if(this.estados.length > 0){
            d = d.filter( a => this.estados.findIndex( item => this.DefineEstado(a)== item ) > -1);
        }
        const r = d.map( item => item.tipoCodigo ? item.tipoCodigo == "DTAC" ? "AE":item.tipoCodigo:"").filter(this.onlyUnique);
        return r.sort();
    }

    get C_Estados(){
        let d = this.PlantillaPorFecha;
        if(this.buscarPor.length > 0){
           d = d.filter( a =>  moment(a.fechaEnvio).unix().toString().includes(this.buscarPor) )
        }
        if(this.buscarPorVin.length > 0){
            d = d.filter( a => a.vin.includes(this.buscarPorVin.toLocaleUpperCase()) )
        }
        if(this.buscarPorCodigo.length > 0){
            d = d.filter( a => a.codigo.startsWith(this.buscarPorCodigo.toLocaleUpperCase()) )
        }
        if(this.marcas.length > 0){
            d = d.filter( a => this.marcas.findIndex( o => o == a.marca ) > -1);
        }
        if(this.modelos.length > 0){
            d = d.filter( a => this.modelos.findIndex( o => o == a.modelo ) > -1);
        }
        if(this.familias.length > 0){
            d = d.filter( a => this.familias.findIndex( o => o == a.familia ) > -1);
        }
        if(this.clientes.length > 0){
            d = d.filter( a => this.clientes.findIndex( o => o == a.nombreCliente ) > -1);
        }
        if(this.tipos.length > 0){
             d = d.filter( a => this.tipos.findIndex( item => (a.tipoCodigo ? a.tipoCodigo == "DTAC" ? "AE":a.tipoCodigo:"") == item ) > -1);
        }
        // if(this.estados.length > 0){
        //     d = d.filter( a => this.estados.findIndex( item => this.DefineEstado(a)== item ) > -1);
        // }
        const r = d.map( item => this.DefineEstado(item) ).filter(this.onlyUnique);
        return r.sort();
    }

    // CargadorCoordenadas(item: any){
    //     const ix = this.equipos.findIndex( x => x.vin == item.vin);
    //     if(ix != -1){
    //     }
    //     else{
    //         this.boiletService.get<Maquina>('/api/Maquinas/'+ item.vin , false).then( r =>{
    //             let p = r.content as any;
    //             const la = r.content.coordenadas?.latitude;
    //             const lo = r.content.coordenadas?.longitude; 
    //             let ub = this.MuestraSucursal(la != undefined ? la : 0, lo != undefined ? lo : 0);
    //             p.sucursal = ub;
    //             this.equipos.push(r.content);
    //         });
    //     }
    // }

    // GetRegion(item: RecomendacionCliente){

    //     if(item.coord ? item.coord.length == 2 : false){
    //         return this.MuestraSucursal(item.coord[0], item.coord[1]);
    //     }
    //     else{
    //         const ix = this.equipos.findIndex( x => x.vin == item.vin);
    //         if(ix > -1){
    //             const ee = this.equipos[ix];
    //             return ee.sucursal+ ' _**';
    //         }else{
    //             return '**';
    //         }
    //     }
    // }

    GetOrganizacionNombre(org_id: string){
        const r = this.organizaciones.find( a => a._id == org_id);
        if(r){
            return r.nombre;
        }else{
            return "";
        }
    }
    Formateado(item: any){
        return  (moment(item).local().locale('es').format(' DD MMM YY HH:mm:ss')).toUpperCase();
    }
    FormateoDias(item: any){
        if(item.fechaEnvio){
            return  (moment(item.fechaEnvio).local().locale('es').fromNow()).toUpperCase();
        }else if(item.fechaServicio){
            return  (moment(item.fechaServicio).local().locale('es').fromNow()).toUpperCase();
        }else{
            return  (moment(null).local().locale('es').fromNow()).toUpperCase();
        }
    }
    async AbrirGestion(item: any){
        this.controlEstado++;
        this.idSeguimiento = item._id;
        // this.region = await this.GetRegion(item);
        this.dialog = true;
    }
    CerrarGestion(){
        this.region = "";
        this.idSeguimiento = "";
        this.dialog = false;
    }
    AbrirResponsable(item: any){
        if(item){
            if(item.responsable){
                this.itemEditado = item;
                this.responsable = item.responsable;
                this.dialogoResponsable = true;
            }else{
                this.itemEditado = item;
                this.responsable = this.analistas.find( r => r.includes(this.authStore.name));
                this.dialogoResponsable = true;
            }
        }
    }
    GuardarResponsable(item: any){
        if(this.responsable){
            if(this.itemEditado){
                this.itemEditado.responsable = this.responsable;
                if(this.itemEditado.historicoResponsables){
                    this.itemEditado.historicoResponsables.push( { 
                        emailDeQuienAsigna: this.authStore.getTokenData().sub,  
                        emailResponsable: this.responsable,
                        fecha:  moment.utc().toDate()
                    } as AsignacionResponsable)
                }
                else{
                    this.itemEditado.historicoResponsables = [] as AsignacionResponsable[];
                    let rdd = { 
                        emailDeQuienAsigna: this.authStore.getTokenData().sub,  
                        emailResponsable: this.responsable,
                        fecha:  moment.utc().toDate()
                    } as AsignacionResponsable;
                    this.itemEditado.historicoResponsables.push(rdd);
                }
                this.boiletService.put<RecomendacionCliente>('/api/Recomendaciones/completo', JSON.stringify(this.itemEditado))
                .then( r => {
                    if(!r.isError){
                        // Object.assign(this.itemEditado,{}, null);
                        this.itemEditado = null;
                        this.dialogoResponsable = false;
                    }else{
                        this.errors = r.errors;
                    }
                });
            }
        }
    }
    CancelarrResponsable(){
        this.responsable = '';
        var ty  = null;
        this.itemEditado =null;
        this.dialogoResponsable = false;
    }

    get P_Abiertas(){
        return this.BuscarPorTicket.filter(a => a.fechaCierre == null) as RecomendacionCliente[];
    }
    get P_Cerradas(){
        return this.BuscarPorTicket.filter(a => a.fechaCierre != null) as RecomendacionCliente[];
    }
    get P_SinRespuesta(){
        const d = this.P_Abiertas.filter(a => (a.mensajes != null && a.mensajes != undefined) ? 
                                                    a.mensajes.length > 0 ?
                                                    (
                                                        this.DefineClasesEstado(a) == 'pink-td-class'
                                                    )
                                                    : false
                                                : false
                                            ) as RecomendacionCliente[];
        if(d.length > 0){
            return d;
        }else{
            return [] as RecomendacionCliente[];
        }
    }
    get RecomendacioneClienteSinContacto(){
        return this.items.filter( a =>  a.fechaEnvio == null);
    }
    get P_SinRespuestaConFecha(){
        return this.P_SinRespuesta.filter( a => a.fechaServicio != null) as RecomendacionCliente[];
    }
    get P_SinRespuestaSinFecha(){
        return this.P_SinRespuesta.filter( a => a.fechaServicio == null) as RecomendacionCliente[];
    }
    get P_ConRespuesta(){
        const d = this.P_Abiertas.filter(a => (a.mensajes != null && a.mensajes != undefined) ? 
                                                    a.mensajes.length > 0 ?
                                                    (
                                                        this.DefineClasesEstado(a) == 'white-td-class'
                                                    )
                                                    : false
                                                : false
                                            ) as RecomendacionCliente[];
        if(d.length > 0){
            return d;
        }else{
            return [] as RecomendacionCliente[];
        }
    }
    get P_ConRespuestaConFecha(){
        return this.P_ConRespuesta.filter( a => a.fechaServicio != null) as RecomendacionCliente[];
    }
    get P_ConRespuestaSinFecha(){
        return this.P_ConRespuesta.filter( a => a.fechaServicio == null) as RecomendacionCliente[];
    }
    get PlantillaPorFecha(){
        // if(this.filtroMensajes == 'todos'){
            const P_Abiertas = this.items.filter(a => a.fechaCierre == null && a.fechaEnvio != null) as RecomendacionCliente[];
            const y =  this.items.filter(a =>  a.fechaEnvio != null).sort( (a,b) => -(moment(a.fechaEnvio).diff(b.fechaEnvio, 'seconds'))); 
            const P_SinRespuesta = P_Abiertas.filter(a => (a.mensajes != null && a.mensajes != undefined) ? 
                    a.mensajes.length > 0 ?
                    (
                        this.DefineClasesEstado(a) == 'pink-td-class'
                    )
                    : false
                : false
            ) as RecomendacionCliente[];
            const lista = [] as RecomendacionCliente[];
            for(let i = 0; i < P_SinRespuesta.length;i++){
                lista.push( P_SinRespuesta[i]);
            }
            for(let i = 0; i < y.length;i++){
                const ix =  lista.findIndex( a => a._id == y[i]._id);
                if(ix == -1)
                lista.push(y[i]);
            }
            return lista;
        // }
        // else if(this.filtroMensajes == 'sinrespuesta'){
        //     return this.P_SinRespuesta;
        // }else if(this.filtroMensajes == 'sinrespuestaconfecha'){
        //     return this.P_SinRespuestaConFecha;
        // }else if(this.filtroMensajes == 'sinrespuestasinfecha'){
        //     return this.P_SinRespuestaSinFecha;
        // }else if(this.filtroMensajes == 'conrespuesta'){
        //     return this.P_ConRespuesta;
        // }else if(this.filtroMensajes == 'conrespuestafecha'){
        //     return this.P_ConRespuestaConFecha;
        // }else if(this.filtroMensajes == 'conrespuestasinfecha'){
        //     return this.P_ConRespuestaSinFecha;
        // }else{
        //     return this.items.sort( (a,b) => -(moment(a.fechaEnvio).diff(b.fechaEnvio, 'seconds'))); 
        // }
    }
    
    get VistaTabla(){
        var f = this.PlantillaPorFecha;
        const tabla = [] as any[];

        return f;
    }
    get BuscarPorTicket(){
        let d = this.PlantillaPorFecha;
        if(this.buscarPor.length > 0){
           d = d.filter( a =>  moment(a.fechaEnvio).unix().toString().includes(this.buscarPor) )
        }
        if(this.buscarPorVin.length > 0){
            d = d.filter( a => a.vin.includes(this.buscarPorVin.toLocaleUpperCase()) )
        }
        if(this.buscarPorCodigo.length > 0){
            d = d.filter( a => a.codigo.startsWith(this.buscarPorCodigo.toLocaleUpperCase()) )
        }
        if(this.buscarPorAnalista.length > 0){
            d = d.filter( a => a.responsable.toLocaleUpperCase().startsWith(this.buscarPorAnalista.toLocaleUpperCase()) )
        }
        if(this.marcas.length > 0){
            d = d.filter( a => this.marcas.findIndex( o => o == a.marca ) > -1);
        }
        if(this.modelos.length > 0){
            d = d.filter( a => this.modelos.findIndex( o => o == a.modelo ) > -1);
        }
        if(this.familias.length > 0){
            d = d.filter( a => this.familias.findIndex( o => o == a.familia ) > -1);
        }
        if(this.clientes.length > 0){
            d = d.filter( a => this.clientes.findIndex( o => o == a.nombreCliente ) > -1);
        }
        if(this.tipos.length > 0){
            d = d.filter( a => this.tipos.findIndex( item => (a.tipoCodigo ? a.tipoCodigo == "DTAC" ? "AE":a.tipoCodigo:"") == item ) > -1);
        }
        if(this.estados.length > 0){
            d = d.filter( a => this.estados.findIndex( item => this.DefineEstado(a)== item ) > -1);
        }
        // else{
            // d = this.PlantillaPorFecha;
        // }
        return d;
    }
    get C_Alertas(){
        if(this.items){
            if(this.items.length > 0){
                return this.items.filter( a => a.tipoCodigo == 'F1' || a.tipoCodigo == 'F2' );
            }else{
                return [] as RecomendacionCliente[];
            }
        }else{
            return [] as RecomendacionCliente[];
        }
    }
    get C_Expertas(){
        if(this.items){
            if(this.items.length > 0){
                return this.items.filter( a => a.tipoCodigo == 'DTAC');
            }else{
                return [] as RecomendacionCliente[];
            }
        }else{
            return [] as RecomendacionCliente[];
        }
    }
    DefineColorEstado(item: RecomendacionCliente){
        if(item){
            const contactos = item.destinatarios ? item.destinatarios.length > 0 : false;
            if(!contactos){
                return 'mdi-sign-caution'
            }else
            if(item.fechaCierre == null){
                const notienemensajes = item.mensajes ? item.mensajes.length == 0 : true;
                // const notienefecha = item.fechaServicio ? false : true;
                // if(notienemensajes && notienefecha)
                if(notienemensajes ){
                    return 'secondary';
                }else{
                    const r = item.mensajes;
                    if(r){
                        if(r.length > 0){
                            const t = r.filter( u => u.esMiembro);
                            if(t){
                                if(t.length > 0){
                                    return 'celeste white--text';
                                }else{
                                    //Hay mensajes del cliente pero no del analista
                                    return 'naranja';
                                }
                            }else{
                                //Hay mensajes del cliente pero no del analista
                                return 'naranja';
                            }
                        }
                    }else{
                        return 'secondary';
                    }
                }
            }else{
                // if(item.estadoDeCierre.toLowerCase() != ("Servicio programado").toLowerCase()){
                //     return 'verde white--text';
                // }else{
                //     return 'verde white--text';
                // }
                return 'verde white--text';
            }
        }
    }
    DefineEstado(item: RecomendacionCliente){
        
        if(item){
            const notienemensajes = item.mensajes ? item.mensajes.length == 0 : true;
            const notienefecha = item.fechaEnvio ? false : true;
            const notienefechaServicio = item.fechaServicio ? false : true;
            const contactos = item.destinatarios ? item.destinatarios.length > 0 : false;
            if(item.fechaCierre == null){
                
                
                // if(notienemensajes && notienefecha)
                if(notienemensajes && !notienefecha)
                {
                    return 'PRIMER CONTACTO';
                }
                else if(!contactos ){
                    return 'SIN CONTACTOS';
                }else if(!notienemensajes){
                    const r = item.mensajes;
                    if(r){
                        if(r.length > 0){
                            const t = r.filter( u => u.esMiembro);
                            if(t){
                                if(t.length > 0){
                                    return 'EN PROCESO';
                                }else{
                                    return 'SIN GESTIÓN';
                                }
                            }else{
                                //Hay mensajes del cliente pero no del analista
                                return 'PENDIENTE';
                            }
                        }
                    }else{
                        return 'PRIMER CONTACTO';
                    }
                }
            }else{
                if(item.fechaCierre != null){
                    return 'CERRADO';
                }
                else if(!contactos){
                    return 'SIN CONTACTOS';
                }else{
                    return 'DESCONOCIDO';
                }
            }
        }else{
            return '';
        }
    }
    DefineIconoEstado(item: RecomendacionCliente){
        if(item){
            const contactos = item.destinatarios ? item.destinatarios.length > 0 : false;
            if(!contactos){
                return 'mdi-sign-caution'
            }else
            if(item.fechaCierre == null){
                const notienemensajes = item.mensajes ? item.mensajes.length == 0 : true;
                // const notienefecha = item.fechaServicio ? false : true;
                // if(notienemensajes && notienefecha)
                if(notienemensajes){
                    return 'mdi-email';
                }else{
                    const r = item.mensajes;
                    if(r){
                        if(r.length > 0){
                            const t = r.filter( u => u.esMiembro);
                            if(t){
                                if(t.length > 0){
                                    return 'mdi-progress-wrench';
                                }else{
                                    return 'mdi-alert';
                                }
                            }else{
                                //Hay mensajes del cliente pero no del analista
                                return 'mdi-alert';
                            }
                        }
                    }else{
                        return 'mdi-email';
                    }
                }
            }else{
                if(item.estadoDeCierre){
                if(item.estadoDeCierre.toLowerCase() != ("Servicio programado").toLowerCase()){
                    return 'mdi-sign-caution';
                }else{
                    return 'mdi-calendar-clock';
                }
            }else{
                return 'mdi-sign-caution';
            }
            }
        }
    }
    DefineClasesEstado(item: RecomendacionCliente){
     
            if(item.fechaCierre == null){
                const notienemensajes = item.mensajes ? item.mensajes.length == 0 : true;
                if(notienemensajes){
                    return 'white-td-class';
                }
                
                if(!notienemensajes){
                    let mensajes = item.mensajes;
                    let ultimoMensaje = {} as Mensaje;
                    for(let i = 0; i < mensajes.length; i++){
                        if(i< mensajes.length-1){
                            const uno = mensajes[i];
                            const dos = mensajes[i+1];
                            if(uno.fecha && dos.fecha){
                                if(moment(uno.fecha).unix <= moment(dos.fecha).unix){
                                    ultimoMensaje = dos;
                                }else{
                                    ultimoMensaje = uno;
                                }
                            }
                        }
                        else{
                            ultimoMensaje = mensajes[i];
                        }
                    }
                    if(ultimoMensaje.fecha){
                        if(ultimoMensaje.esMiembro){
                            return 'white-td-class';
                        }else{
                            return 'pink-td-class';
                        }
                    }  
                }        
            }else{
                return 'normal-td-class';
            }        
    }
    // get LargoTabla(){
    //     let o = (this.Size as any).y;
    //     if(this.FullHd){
    //         return Math.round(o*.58)
    //     }else{
    //         return Math.round(o*.48)
    //     }
    // }

    // get Size(){
    //    return this.useAlertasStore.tamano;
    // }
    get FullHd(){
        // return this.Size.x > 1880
        return true;
    }
    MuestraSucursal(lat: number, lon: number) {
        let sucursal = '**';
        let distancia = 0;
        const lista = [] as any[];
    //   console.log(lat, lon)
        this.sucursales.forEach((sucursal) => {
          const d = getDistance({ lat, lon }, { lat: sucursal.coord[1], lon: sucursal.coord[0] });
      
          lista.push({ distancia: d, sucursal: sucursal._id });
        });
      
        lista.sort((a, b) => a.distancia - b.distancia);
        return lista[0].sucursal ?? sucursal;
      }      
    CancelarCasoZero(){
        this.casozerodialog = false;
    }  


    CsvExporta(cabecera: any[], datos: any[], nombreArchivo: string){
        const aline = [] as string[];
        const arrData = [] as any[];
        // console.log(datos)
        datos.forEach((e:any) => {
            let a = [] as any[];
            let line = [] as string[];
            cabecera.filter(
                (t:any) => 
                    t.value != "alertaExperta" 
                ).forEach((t:any) =>{
                    if(!(e[t.value] != null &&e[t.value] != undefined)){                                                
                        if(t.value == 'estado'){  
                            const est =  this.DefineEstado(e);        
                            a[t.value] =  est; 
                            e[t.value] =  est; 
                            console.log(e[t.value])
                        }else
                        if(t.value == 'region'){
                            a[t.value] = e.coord ? this.MuestraSucursal(e.coord[0], e.coord[1]) : '**'  ;
                            e[t.value] = e.coord ? this.MuestraSucursal(e.coord[0], e.coord[1]) : '**'  ;
                        }else
                        if(t.value == 'tipoCodigo'){
                            a[t.value] = e.tipoCodigo ? e.tipoCodigo == "DTAC" ? "AE":e.tipoCodigo:"";
                        }else
                        if(t.value == 'responsable'){
                            a[t.value] =  e.responsable ? e.responsable : 'SIN ASIGNAR'
                        }else
                        if(t.value == 'orga'){
                            a[t.value] = e ? e.nombreCliente :""
                        }else
                        if(t.value == 'dias'){
                            a[t.value] = e.fechaEnvio ? this.FormateoDias(e.fechaEnvio) : ''
                        }else if(t.value == 'fechaEnvio'){
                            a[t.value] =  e.fechaEnvio ? this.FormateoDias(e.fechaEnvio) : ''
                        }else{
                            a[t.value] = e[t.value]; 
                        }
                    }
                    else{
                      
                        console.log(e);
                        if(t.value == 'estado'){
                            
                            a[t.value] =  this.DefineEstado(e)
                        }else
                        if(t.value == 'region'){
                            // a[t.value] =  this.GetRegion(e)
                            a[t.value] = e.coord ? this.MuestraSucursal(e.coord[0], e.coord[1]) : '**'  ;
                        }else
                        if(t.value == 'tipoCodigo'){
                            a[t.value] = e.tipoCodigo ? e.tipoCodigo == "DTAC" ? "AE":e.tipoCodigo:"";
                        }else
                        if(t.value == 'responsable'){
                            a[t.value] =  e.responsable ? e.responsable : 'SIN ASIGNAR'
                        }else
                        if(t.value == 'orga'){
                            a[t.value] = e ? e.nombreCliente :""
                        }else
                        if(t.value == 'dias'){
                            a[t.value] = e.fechaEnvio ? this.FormateoDias(e.fechaEnvio) : ''
                        }else if(t.value == 'fechaEnvio'){
                            a[t.value] =  e.fechaEnvio ? this.FormateoDias(e.fechaEnvio) : ''
                        }else{
                            a[t.value] = e[t.value]; 
                        }
                    }
                line.push((e[t.value] ? e[t.value]: '').toString().replace(/;/ig," ")
                                                .replace(/-/ig," ")
                                                .replace(/#/ig, ' ')
                                                .replace(/\[/ig, '')
                                                .replace(/\]/ig, '')
                                                .replace(/\(/ig, '')
                                                .replace(/\)/ig, '')
                                                .replace(/\&/ig, '')
                                                .replace(/\[/ig, '')
                                                .replace(/\%/ig, '')
                                                .replace(/\~/ig, '')
                                                .replace(/\n/ig,"")); 
                                                arrData.push(a);            
            })
            aline.push(line.join('|'));
        }); 
        let final = "data:text/csv;charset=utf-8,";
        final += Object.keys(arrData[0]).join("|") + "\n";
        final += aline.join("\n")
          .replace(/(^\[)|(\]$)/gm, "");
        const data = encodeURI(final);
        const link = document.createElement("a");
        link.setAttribute("href", data);
        link.setAttribute("download", nombreArchivo + ".csv");
        link.click();
        
        
        
        
    }

    public async DescargaConsolidados(){

            await this.boiletService.get<RecomendacionCliente[]>('/api/Recomendaciones/abiertosycerrados', false)
            .then( a =>{
                if(!a.isError){
                    const r = a.content as RecomendacionCliente[];
                    this.CsvExporta(this.cabeceraCsv,r,"alertasexpertas");
                    // this.items.forEach( async f =>{
                    //     this.CargadorCoordenadas(f);
                    // });
                }
                else{
                    this.errors = a.errors;
                }
            });         
    }

    public cabeceraCsv=[
        {
            value: 'responsable',
            // width: '180px'
        },
        {
            value: 'fechaEnvio',
            // width: '180px'
        },
        {
            value: 'fechaCierre',
            // width: '100px'
        },
        {
            value: 'fechaUltimaAlerta',
            // width: '160px'
        },
        {
            value: 'vin',
            // width: '160px'
        },
        {
            value: 'nombreCliente',
            // width: '160px'
        },
        {
            value: 'tipoCodigo',
        },
        {
            value: 'severidad',
        }, 
        {
            value: 'color',  
        },
        {           
            value: 'codigo',           
        },
        {
            value: 'spn',            
        },
        {
            value: 'fmi',       
        },
        {
            value: 'motivoDeCierre',           
        },
        {
            value: 'marca',
        },
        {
            value: 'modelo',
        },
        {
            value: 'familia',
        } 
    ];
}