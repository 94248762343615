import Vue from 'vue';
import VueRouter from 'vue-router';
import AuthStore from '@/stores/auth-store';
import accountLayout from '@/account/account-layout.vue';
import adminLayout from '@/admin/admin-layout.vue';
import gestionLayout from '@/gestion/gestion-layout.vue';
import accesoExternoLayout from '@/acceso-externo/acceso-externo-layout.vue';

// import activos from '@/gestion/components/activos/activos';


Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes: [
        { path: '/', redirect: '/gestion/recomendacion' },
        {
            path: '/acceso-cliente',
            component: accesoExternoLayout,
            meta: { requiresAuth: false },
            children: [
                // { path: 'seguimiento', component: require('@/acceso-externo/views/seguimiento-recomendacion/seguimiento-recomendacion.vue').default },
                { path: 'seguimiento', component: require('@/acceso-externo/views/acceso-responsivo/acceso-responsivo.vue').default },
            ]
        },
        {
            path: '/account',
            component: accountLayout,
            children: [
                { path: 'login', component: require('@/account/views/login/login.vue').default },
                { path: 'register', component: require('@/account/views/register/register.vue').default },
                { path: 'forgot-password', component: require('@/account/views/manage/forgot-password.vue').default },
                { path: 'reset-password', component: require('@/account/views/manage/reset-password.vue').default }
            ]
        },
        {
            path: '/admin',
            component: adminLayout,
            meta: { requiresAuth: true },
            children: [
                { path: 'home', component: require('@/admin/views/home/home.vue').default },
                { path: 'user-list', component: require('@/admin/views/users/user-list.vue').default },
                { path: 'role-list', component: require('@/admin/views/roles/role-list.vue').default }
            ]
        },
        {
            path: '/gestion',
            component: gestionLayout,
            meta: { requiresAuth: true },
            children: [
                //  { path: 'tabla-activo-component', component: require('@/gestion/components/activos/activos.vue').default},                
                // { path: 'activos-component', component: require('@/gestion/components/calendario-heat-map/heat-map/heat-map.vue').default },

                { path: 'auditoria', component: require('@/gestion/views/auditoria/auditoria-contadores.vue').default },
                { path: 'mapa', component: require('@/gestion/views/mapa-principal/mapa-principal.vue').default },
                { path: 'ranking', component: require('@/gestion/views/ranking-codigos/ranking-codigos.vue').default },
                { path: 'diccionarios', component: require('@/gestion/views/grilla-diccionarios/grilla-diccionarios.vue').default },
                { path: 'activos', component: require('@/gestion/views/mantenedor-activos/mantenedor-activos.vue').default },
                // { path: 'activos', component: require('@/gestion/views/grilla-activos/grilla-activos.vue').default },
                { path: 'recomendacion', component: require('@/gestion/views/recomendaciones/recomendaciones.vue').default },
                { path: 'reporte-flota',  component: require('@/gestion/components/reporte-flota-influxdb/reporte-flota.vue').default },
                // { path: 'mantenedor-activo',  component: require('@/gestion/views/mantenedor-activos/mantenedor-activos.vue').default },
            ]
        }
    ]
});

router.beforeEach((to: any, from: any, next: any) => {
    if (to.matched.some((record: any) => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!AuthStore.isSignedIn()) {
            next({
                path: '/account/login',
                query: { redirect: to.fullPath }
            });
        }
    }
    next(); // make sure to always call next()!
});

export default router;