export default class AuthStore {
    public static storageKey: string = 'token';
    public static clientStorage: string = 'client';

    public static getToken() {
        return localStorage.getItem(AuthStore.storageKey);
    }

    public static getRecomendacion(id: string) {
        // console.log('BUSCANDO STORE')
        return localStorage.getItem(id);
    }

    public static setRecomendacion(id:string, token: string) {
        console.log('CARGANDO STORE')
        localStorage.setItem(id, token);
    }

    public static setToken(token: string) {
        localStorage.setItem(AuthStore.storageKey, token);
    }

    public static removeToken(): void {
        localStorage.removeItem(AuthStore.storageKey);
    }

    public static isSignedIn(): boolean {
        return !!AuthStore.getToken();
    }


    public static getTokenData() {
        const token = AuthStore.getToken();
        if (token) {
            return JSON.parse(atob(token.split('.')[1]));
        }

        return {};
    }
}