import BoiletComponentBase from '@/shared/application/boilet-component-base';
import moment from 'moment';
import { Component, Prop } from 'vue-property-decorator';


@Component({
    components:{
        // ReporteActivo: require('@/gestion/components/calendario-heat-map/heat-map/heat-map.vue').default,
        ReporteActivo: require('@/gestion/components/reporte-multimarca-influxdb/reporte-multimarca.vue').default,
    }
})
export default class ReporteFlotaComponent extends BoiletComponentBase{
    public hasta = new Date().toISOString().substr(0, 10);
    public desde = moment().add(-30, 'days').toISOString().substr(0, 10);
    public errors = [] as any[];
    public bloqueConsulta = 300;
    public activos = [] as any[];
    public selected = [] as any[];
    public search = "" as string;
    public horometros = [] as any[];
    public horometrosRalenti = [] as any[];
    public odometros = [] as any[];
    public odolitros = [] as any[];
    public odolitrosEnMarcha = [] as any[];
    public nivelCombustible = [] as any[];
    public marcas_seleccionados = [] as any[];
    public modelos_seleccionados = [] as any[];
    public familias_seleccionados = [] as any[];
    public clientes_seleccionados = [] as any[];
    public activaFlota = false;
    public cabecera = [
          { text: 'VIN', align: 'start', value: 'vin', width:'180px' },
          { text: '', align: 'start', value: 'accion', width:'30px' , sort:false},
          { text: 'NOMBRE', value: 'nombre'  ,align: 'start'  ,width:'180px' },
          { text: 'MARCA', value: 'marca'  ,align: 'start' ,width:'180px' },
          { text: 'MODELO', value: 'modelo'  ,align: 'start' ,width:'140px' },
          { text: 'FAMILIA', value: 'familia'  ,align: 'start' ,width:'180px' },
          { text: 'CLIENTE', value: 'nombreCliente'  ,align: 'start'   },
          { text: 'HORÓMETRO', value: 'horometro'  ,align: 'start'   },
          { text: 'ODÓMETRO', value: 'odometro'  ,align: 'start'   },
          { text: 'ODÓLITRO', value: 'odolitro'  ,align: 'start'   },
          { text: 'HORÓMETRO RALENTÍ', value: 'horometroRalenti'  ,align: 'start'   },
          { text: 'ODÓLITRO RALENTÍ', value: 'odolitroRalenti'  ,align: 'start'   },
          { text: 'NIVEL COMBUSTIBLE', value: 'nivelCombustible'  ,align: 'start'   },
    ];
    async created(){
        await this.CargaSecuencial(true)
            .then((pepe: any[]) => 
            {                
            this.activos  =pepe.filter( (a : any) => a.vin);
            }
        )
    }

    public async GetActivoPorIndice(indice: any, cargador: boolean) {      
        const op = await this.boiletService.get<string>('/api/Activo/'+indice+'/'+this.bloqueConsulta+"/clientes" ,cargador)
        // const op = await this.boiletService.get<string>('/api/Activo/'+indice+'/'+this.bloqueConsulta ,cargador)
                .then( r => {
                        if(!r.isError){                            
                            const indiceT = JSON.parse(r.content) as unknown as any[];                           
                            return indiceT;
                        }else{
                            this.errors = r.errors;
                            return [] as any[]
                        }
                });
  
        return op;
    }
    public async CargaSecuencial(cargador: boolean = true){
        var t = [] as any[];
        let index = 0;
        const r = await this.GetActivoPorIndice(index, cargador) as any[];
        if(r.length > 0){
            r.forEach((o:any) =>{  
                o.marca = (o.marca ? o.marca : 'NO DISPONIBLE');
                o.modelo = (o.modelo ? o.modelo : 'NO DISPONIBLE');
                o.familia = (o.familia ? o.familia : 'NO DISPONIBLE');
                o.nombreCliente = (o.nombreCliente ? o.nombreCliente : '');
                o.infoCan = (o.apiBase ? o.apiBase == 'GEOTAB' ? o.odoltitro ? 1: -1 : 0 : 0);
                o.colorFechaCoordenadas = this.CargaColorFecha(o.fechaCoordenadas ?o.fechaCoordenadas.$date:  null);
                o.colorFechaOdometro = this.CargaColorFecha(o.odometroFecha ?o.odometroFecha.$date:  null);
                o.colorFechaHorometro = this.CargaColorFecha(o.horometroFecha ?o.horometroFecha.$date:  null);
                o.colorFechaHorometroRalenti = this.CargaColorFecha(o.horometroRalentiFecha ?o.horometroRalentiFecha.$date:  null);
                o.colorFechaOdolitro = this.CargaColorFecha(o.odolitroFecha ?o.odolitroFecha.$date:  null);
                o.colorFechaOdolitroRalenti = this.CargaColorFecha(o.odolitroFecha ?o.odolitroFecha.$date:  null);
                o.infoCan = (o.apiBase ? o.apiBase == 'GEOTAB' ? o.odoltitro ? 1: -1 : 0 : 0)
                t.push(o);
            });
            index++;
            if(r.length == this.bloqueConsulta){
                while(index > -1){
                    let op = await this.GetActivoPorIndice(index, cargador) as any[];
                    if(op.length == 0){
                        index = -1;
                    }else{
                        index++;
                        op.forEach((o:any) => {
                            o.marca = (o.marca ? o.marca : 'NO DISPONIBLE');
                            o.modelo = (o.modelo ? o.modelo : 'NO DISPONIBLE');
                            o.familia = (o.familia ? o.familia : 'NO DISPONIBLE');
                            o.nombreCliente = (o.nombreCliente ? o.nombreCliente : '');
                            o.infoCan = (o.apiBase ? o.apiBase == 'GEOTAB' ? o.odoltitro ? 1: -1 : 0 : 0);
                            o.colorFechaCoordenadas = this.CargaColorFecha(o.fechaCoordenadas);
                            o.colorFechaOdometro = this.CargaColorFecha(o.odometroFecha);
                            o.colorFechaHorometro = this.CargaColorFecha(o.horometroFecha);
                            o.colorFechaHorometroRalenti = this.CargaColorFecha(o.horometroRalentiFecha);
                            o.colorFechaOdolitro = this.CargaColorFecha(o.odolitroFecha);
                            o.colorFechaOdolitroRalenti = this.CargaColorFecha(o.odolitroFecha);
                            o.infoCan = (o.apiBase ? o.apiBase == 'GEOTAB' ? o.odoltitro ? 1: -1 : 0 : 0)
                            t.push(o)
                        });
                    }
                }
            }
        }
        return t.filter( (r: any) => r.nombreCliente ? (r.nombreCliente as string).length > 0 : false ) as any[];         
    }
    public async CargaVinHorometro(vin: string){
        const op = await this.boiletService.get<string>('/api/Activo/horometro31/activo/'+ vin, true)
                        .then(( u : any) => {
                            if(!u.isError){                            
                                const indiceT = JSON.parse(u.content) as unknown as any[];                           
                                return indiceT;
                            }else{
                                this.errors  = u.errors;
                                return [] as any[]
                            }
                        });
        
        return op;
    }
    public async CargaVinHorometroRalenti(vin: string){
        const op = await this.boiletService.get<string>('/api/Activo/horometroRalenti31/activo/'+ vin, true)
                        .then(( u : any) => {
                            if(!u.isError){                            
                                const indiceT = JSON.parse(u.content) as unknown as any[];                           
                                return indiceT;
                            }else{
                                this.errors  = u.errors;
                                return [] as any[]
                            }
                        });
        
        return op;
    }
    public async CargaVinOdolitroRalenti(vin: string){
        const op = await this.boiletService.get<string>('/api/Activo/odolitrosRalenti31/activo/'+ vin, true)
                        .then(( u : any) => {
                            if(!u.isError){                            
                                const indiceT = JSON.parse(u.content) as unknown as any[];                           
                                return indiceT;
                            }else{
                                this.errors  = u.errors;
                                return [] as any[]
                            }
                        });
        
        return op;
    }
    public async CargaVinOdolitroEnMarcha(vin: string){
        const op = await this.boiletService.get<string>('/api/Activo/odolitrosEnMarcha31/activo/'+ vin, true)
                        .then(( u : any) => {
                            if(!u.isError){                            
                                const indiceT = JSON.parse(u.content) as unknown as any[];                           
                                return indiceT;
                            }else{
                                this.errors  = u.errors;
                                return [] as any[]
                            }
                        });
        
        return op;
    }
    public async CargaVinOdolitro(vin: string){
        const op = await this.boiletService.get<string>('/api/Activo/odolitros31/activo/'+ vin, true)
                        .then(( u : any) => {
                            if(!u.isError){                            
                                const indiceT = JSON.parse(u.content) as unknown as any[];                           
                                return indiceT;
                            }else{
                                this.errors  = u.errors;
                                return [] as any[]
                            }
                        });
        
        return op;
    }
    public async CargaVinOdometro(vin: string){
        const op = await this.boiletService.get<string>('/api/Activo/odometro31/activo/'+ vin, true)
                        .then(( u : any) => {
                            if(!u.isError){                            
                                const indiceT = JSON.parse(u.content) as unknown as any[];                           
                                return indiceT;
                            }else{
                                this.errors  = u.errors;
                                return [] as any[]
                            }
                        });
        
        return op;
    }
    public async CargaVinCombustible(vin: string){
        const op = await this.boiletService.get<string>('/api/Activo/nivelCombustible31/activo/'+ vin, true)
                        .then(( u : any) => {
                            if(!u.isError){                            
                                const indiceT = JSON.parse(u.content) as unknown as any[];                           
                                return indiceT;
                            }else{
                                this.errors  = u.errors;
                                return [] as any[]
                            }
                        });
        
        return op;
    }


    public get InmutableMarcas(){
        return this.activos.map(r => r.marca).filter(this.onlyUnique).sort();
    }
    public get InmutableModelos(){
        return this.activos.map(r => r.modelo).filter(this.onlyUnique).sort();
    }
    public get InmutableFamilias(){
        return this.activos.map(r => r.familia).filter(this.onlyUnique).sort();
    }
    public get InmutableCliente(){
        return this.activos.map(r => r.nombreCliente).filter(this.onlyUnique).sort();
    }
    public get Filtrados(){
        const r = this.activos;
        let arr = [] as any[];
        let clientes = [] as any[];
        let familias = [] as any[];
        let modelos = [] as any[];
        let marcas = [] as any[];
        if( this.clientes_seleccionados.length > 0 ){
            clientes = this.activos.filter(r => this.clientes_seleccionados.includes(r.nombreCliente));
        }
        if( this.familias_seleccionados.length > 0 ){
            familias = this.activos.filter(r => this.familias_seleccionados.includes(r.familia));
        }
        if( this.modelos_seleccionados.length > 0){
            modelos = this.activos.filter(r => this.modelos_seleccionados.includes(r.modelo));
        }
        if( this.marcas_seleccionados.length > 0 ){
            marcas = this.activos.filter(r => this.marcas_seleccionados.includes(r.marca));
        }
        return  (this.clientes_seleccionados.length > 0     || 
                this.familias_seleccionados.length > 0      ||
                this.modelos_seleccionados.length > 0       ||
                this.marcas_seleccionados.length > 0  )     ? 
                    [...clientes, ...familias,...modelos,...marcas].filter(this.onlyUnique).sort((a: any, b : any)=> a.vin - b.vin)
                                                            :
                    this.activos.sort((a: any, b : any)=> a.vin - b.vin);
    }
    onlyUnique(value: any, index:any, self:any) {
        return self.indexOf(value) === index;
    }

    public CargaColorFecha(fecha:Date | null | undefined): String{
        if(fecha){
            const dd = moment.utc().diff(fecha, 'hour');
            if(dd > 24){
                return "grey white--text";
            }else 
            if(dd > 14){
                return "rojo white--text"
            }else if(dd > 8){
                return "naranja white--text"
            }else{
                return "verde white--text";
            }
        }else{
            return "grey ligthten-4 ";
        }
    }

    public CargaColorInfoActivo(it: any){
        return it.infoCan == -1? 'celeste-t-td-class' :'white-t-td-class'
    }
}