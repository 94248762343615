import { Activo, Maquina } from "@/shared/models/shared/consolidados/Maquina";
import { Organizacion } from "@/shared/models/shared/consolidados/Organizacion";
import { RecomendacionCliente } from "@/shared/models/shared/consolidados/RecomendacionCliente";
import { Sucursales } from "@/shared/models/shared/consolidados/Sucursales";
import { defineStore } from "pinia";
//
export const GestionActivosStore = defineStore({
    id: 'gestion',
    state: () => ({
        activos: [] as any,
        organizaciones: [] as Organizacion[],
        maquinas: [] as Activo[],
        sucursales: [] as Sucursales[],
        recomendaciones: [] as RecomendacionCliente[],
        grupos: [] as any[],
        contratos: [] as any[],
        garantias: [] as any[],
        consolidados: [] as any[],
    }),
    getters:{
        activosConsolidadosArray: (state: any) =>
        state.activos.reduce((items: any[], item: any) => {
            const existingItem = items.find((it) => it === item)    
            if (!existingItem) {
              items.push(item)
            } else {
              existingItem.amount++
            }
            return items
          }, []),
        activosArray: (state: any) =>
        state.activos.reduce((items: any[], item: any) => {
            const existingItem = items.find((it) => it === item)    
            if (!existingItem) {
              items.push(item)
            } else {
              existingItem.amount++
            }
            return items
          }, []),
        organizacionesArray: (state: any) =>
        state.organizaciones.reduce((items: any[], item: any) => {
            const existingItem = items.find((it) => it === item)    
            if (!existingItem) {
              items.push(item)
            } else {
              existingItem.amount++
            }
            return items
          }, []),
        maquinasArray: (state: any) =>
        state.maquinas.reduce((items: any[], item: any) => {
            const existingItem = items.find((it) => it._id === item._id)    
            if (!existingItem) {
              items.push(item)
            } else {
              existingItem.amount++
            }
            return items
          }, []),
        sucursalesArray: (state: any) =>
        state.sucursales.reduce((items: any[], item: any) => {
            const existingItem = items.find((it) => it._id === item._id)    
            if (!existingItem) {
              items.push(item)
            } else {
              existingItem.amount++
            }
            return items
          }, []),
        recomendacionesArray: (state: any) =>
        state.recomendaciones.reduce((items: any[], item: any) => {
            const existingItem = items.find((it) => it._id === item._id)    
            if (!existingItem) {
                items.push(item)
            } else {
                existingItem.amount++
            }
            return items
        }, []),
    },
    actions:{
        bulkConsolidado(t: any[]){
            this.consolidados = t;
        },
        bulkActivos(t: any[]){
            this.activos = t;
        },
        addActivo(t: any){
            console.log(t);
            const ixa = this.activos.findIndex( (x: any) => 
                    x.vin == t.vin
                );
            if(ixa > -1){
                this.activos.splice(ixa,1,t);
            }else{
                this.activos.push(t);
            }
        },
        addOrganizacion(t: Organizacion){
            const ixa = this.organizaciones.findIndex( x => 
                    x._id == t._id
                );
            if(ixa > -1){
                    this.organizaciones.splice(ixa,1,t);
            }else{
                this.organizaciones.push(t);
            }
        },
        bulkOrganizaciones(t : Organizacion[]){
            this.organizaciones = t;
         },
        removeOrganizacion(t: string) {
            const i = this.organizaciones.findIndex(a => a._id == t)
            if (i > -1) this.organizaciones.splice(i, 1)
        },
        addMaquina(t: Activo){
            const ixa = this.maquinas.findIndex( x => 
                    x._id == t._id
                );
            if(ixa > -1){
                this.maquinas.splice(ixa,1,t);
            }else{
                this.maquinas.push(t);
            }
        },
        bulkMaquinas(t : Activo[]){
           this.maquinas.splice(0);
           this.maquinas.concat(t);
        },
        removeMaquina(t: string) {
            const i = this.maquinas.findIndex(a => a._id == t)
            if (i > -1) this.maquinas.splice(i, 1)
        },
        addSucursal(t: Sucursales){
            const ixa = this.sucursales.findIndex( x => 
                    x._id == t._id
                );
            if(ixa > -1){
                this.sucursales.splice(ixa,1,t);
            }else{
                this.sucursales.push(t);
            }
        },
        bulkSucursal(t : Sucursales[]){
           this.sucursales = t;
        },
        removeSucursal(t: string) {
            const i = this.sucursales.findIndex(a => a._id == t)
            if (i > -1) this.sucursales.splice(i, 1)
        },
        addRecomendacion(t: RecomendacionCliente){
            const ixa = this.recomendaciones.findIndex( x => 
                    x._id == t._id
                );
            if(ixa > -1){
                this.recomendaciones.splice(ixa,1,t);
            }else{
                this.recomendaciones.push(t);
            }
        },
        bulkRecomendacion(t : RecomendacionCliente[]){
           this.recomendaciones = t;
        },
        removeRecomendacion(t: string) {
            const i = this.recomendaciones.findIndex(a => a._id == t)
            if (i > -1) this.recomendaciones.splice(i, 1)
        },
    
    }

})