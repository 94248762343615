import './assets/sass/site.css';
import Vue from 'vue';
import Element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/reset.css'
// import locale from 'element-ui/lib/locale/lang/es';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import VueI18n from 'vue-i18n';
import LanguageStore from '@/stores/language-store';
import VueCompositionApi from '@vue/composition-api';
import {  createPinia, PiniaPlugin } from "pinia";
import VueVirtualScroller from 'vue-virtual-scroller';
import VueApexCharts from 'vue-apexcharts';
import moment from 'moment';
Vue.prototype.moment = moment;
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

import VuePdfApp from "vue-pdf-app";
Vue.use(Element);

Vue.use(VueVirtualScroller);
Vue.use(VueI18n);
//Siempre debe estar en la cabecera para poder cargar los pluggins en este caso pinia
Vue.use(VueCompositionApi);
Vue.use(PiniaPlugin);
const pinia = createPinia();
Vue.component("vue-pdf-app", VuePdfApp);
Vue.config.productionTip = false;
// Vue.use(createPinia());
// const pinia = createPinia();
const locales = {
    es: require('@/assets/localizations/es.json'),
    en: require('@/assets/localizations/en.json'),
    tr: require('@/assets/localizations/tr.json')
};

const i18n = new VueI18n({
    locale: LanguageStore.getLanguage().languageCode,
    fallbackLocale: 'es',
    messages: locales
});

Vue.config.productionTip = false;

new Vue({
    i18n,
    router,
    vuetify,
    pinia,
    render: h => h(App)
}).$mount('#app');


