<template>
    <div>
        <div class="map" ref="mapa" id="mapa"></div>   
    </div>
</template>

<script>
import 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet.markercluster';
import moment from 'moment';
import { getDistance } from 'geolib';
export default {
    props:{
        idMapa: String,

        horometroIcon: String,
        odometroIcon: String,
        remanenteIcon: String,
        goIcon: String,
        alertIcon: String,
        stopIcon: String,
        sucursalIcon: String,
        
        sucursales: Array,
        marcadores: Array,
        setView: Object,        
    },

    data(){
        return{
            map: null,
            control:null,
            id_prop_marcadores:  L.markerClusterGroup(),
            prop_marcadores:  L.markerClusterGroup(),
            prop_sucursales: L.layerGroup(),
            makers: L.markerClusterGroup(),
            overlays: {
                'Sucursales': this.prop_sucursales
            },
            capasMapa: [
                {  
                    url: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/rastertiles/voyager/{z}/{x}/{y}.png' ,
                    nombre: 'OSM - MAP',
                    attribution:  'OSM',
                    maxZoom: 18
                    },
                    {  
                    url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png' ,
                    nombre: 'OSM - TOPO',
                    attribution:  'OSM',
                    maxZoom: 18
                    },
                    {  
                    url: 'https://cartodb-basemaps-a.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png' ,
                    nombre: 'OSM - CARTO',
                    attribution:  'OSM',
                    maxZoom: 21
                    },
                    {  
                    url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}' ,
                    nombre: 'ESRI - TOPO',
                    attribution:  'ESRI',
                    maxZoom: 21
                    },
                    {  
                    url: 'https://server.arcgisonline.com/ArcGIS/rest/services/Reference/World_Transportation/MapServer/tile/{z}/{y}/{x}',
                    nombre: 'ESRI - RUTA',
                    attribution:  'ESRI',
                    maxZoom: 20
                    },
                    {  
                    url: 'http://www.google.cn/maps/vt/lyrs=r&x={x}&y={y}&z={z}',
                    nombre: 'GOOGLE - MAP',
                    attribution:  'GOOGLE',
                    maxZoom: 21
                    },
                    {  
                    url:'https://mt1.google.com/vt?lyrs=h@159000000,traffic|seconds_into_week:-1&style=3&x={x}&y={y}&z={z}',
                    nombre: 'GOOGLE - TRÁFICO',
                    attribution:  'GOOGLE',
                    maxZoom: 21
                    },
                    {  
                    url: 'http://mt0.google.com/maps/vt/lyrs=y&x={x}&y={y}&z={z}',
                    nombre: 'GOOGLE - HÍBRIDO',
                    attribution:  'GOOGLE',
                    maxZoom: 21,
                    subdomains:['mt0','mt1','mt2','mt3']
                    },
                    {  
                    url: 'http://www.google.cn/maps/vt/lyrs=h&x={x}&y={y}&z={z}',
                    nombre: 'GOOGLE - RUTA',
                    attribution:  'GOOGLE',
                    maxZoom: 21
                    },
                ],
        }
    },
    mounted(){
        
      this.MontaMapa();
    },
    beforeDestroy(){
        this.map != null ? this.map.remove(): null;
        this.prop_marcadores = null;
        this.prop_sucursales = null;
        this.id_prop_marcadores = null;
    },
    methods:{
        MontaMapa5(){  
               
            let g = this.setView ? [this.setView.latitude, this.setView.longitude] : [-30,70];
            try{
                this.map = L.map('mapa').setView(g,4);
                 const osmmap = L.tileLayer(this.capasMapa[0].url,{
                        maxZoom: this.capasMapa[0].maxZoom,
                        attribution: this.capasMapa[0].attribution,
                        active: false
                    });
            const esritopo = L.tileLayer(this.capasMapa[3].url,{
                        maxZoom: this.capasMapa[3].maxZoom,
                        attribution: this.capasMapa[3].attribution,
                        active: false
                    });
            const googlehibrido = L.tileLayer(this.capasMapa[7].url,{
                    maxZoom: this.capasMapa[7].maxZoom,
                    attribution: this.capasMapa[7].attribution,
                    // active: false
                });
            this.control = L.control.layers({
                        'OSM-MAP': osmmap,
                        // 'OSM-TOPO': osmtopo,
                        // 'OSM-CARTO': osmcarto,
                        'ESRI-TOPO': esritopo,
                        // 'ESRI-RUTA': esriruta,
                        // 'GOOGLE-MAP': googlemap,
                        // 'GOOGLE-TRÁFICO': googletrafico.addTo(this.map),
                        'GOOGLE-HÍBRIDO': googlehibrido.addTo(this.map)
                        // ,'GOOGLE-RUTA': googleruta.addTo(this.map)
                    }, /*this.overlays*/ ).addTo(this.map);
                               

                // setTimeout(function () {
                //     this.map.invalidateSize(false);
                // }, 100);
               
            }catch(ex){
                console.log(ex)
                // setTimeout(function () {
                //    this.map.invalidateSize(false);
                // }, 100);
            }

           
        },
        setMarkers(data) {
            data.forEach(function (obj) {

                let leyenda = this.CreaLeyendaMarcadoresraw(obj);
                let popUp = this.CreaPopUpMarcadoresRaw(obj);
                let marcador = L.marker(
                                { lat: a.coordenadas[1], lng: a.coordenadas[0] },
                                { icon: icondinamico },                           
                                { zIndexOffset: 4 }
                            )
                            .bindPopup(popUp, { width: '25vw;',  height: window.innerHeight })
                            .bindTooltip(leyenda);
                if (!this.markers.hasOwnProperty(obj.id)) {
                    
                    this.markers[obj.id] = new L.Marker([obj.coordenadas[0], obj.coordenadas[1]]).addTo(map);
                    markers[obj.id].previousLatLngs = [];
                } else {
                    markers[obj.id].previousLatLngs.push(markers[obj.id].getLatLng());
                    markers[obj.id].setLatLng([obj.coordenadas[0], obj.coordenadas[1]]);
                }
            });
        },
        CreaLeyendaMarcadoresraw(a){
                let vin = a.vin ? a.vin : 'NO DISPONIBLE';
            let familia = a.familia ? a.familia : 'NO DISPONIBLE';
            let modelo = a.modelo ? a.modelo : 'NO DISPONIBLE';
            let marca = a.marca ? a.marca : 'NO DISPONIBLE';
            let nombre = a.nombre ? a.nombre : vin;
            let org = a.nombreOrganizacion ? a.nombreOrganizacion : 'NO DISPONIBLE';
            let nf = new Intl.NumberFormat('es-CL');
            let leyenda =  `
                        <div style="width:15vw; min-width:300px">
                            <div 
                                class="pa-0 pt-0 v-card v-sheet theme--light elevation-0 rounded-0" 
                                style="border: thin solid red;">
                                    <div no-gutters="" class="v-card__title pa-0 pl-2 `+(a.infoCan == 0 ? 'azul white--text' : a.infoCan == -1 ? 'celeste white--text': 'azul white--text') +`">
                                    <div class="row no-gutters">
                                        <div >
                                            <div  style=" text-align: end; padding-right: 4px;">
                                                <span  class="titulo-ficha" 
                                                        style="line-height: 1; padding-top: 2px; text-transform: uppercase; font-size:60%; word-break: break-word;">`
                                                        +org+
                                                `</span> 
                                            </div>
                                        </div>
                                        <div style="margin-left:auto;" >
                                            <div  style=" text-align: end; padding-right: 4px; ">
                                                <span  class="titulo-ficha" style="line-height: 1; padding-top: 2px; text-transform: uppercase; font-size:60%"> 
                                                    `+ moment.utc(a.fecha).local().locale('es').fromNow()+`
                                                </span> 
                                                <i aria-hidden="true" class="v-icon notranslate mdi mdi-map-marker theme--light white--text" 
                                                    style="font-size: 11.5px; margin-left: 2px; margin-top: 2px !important;">
                                                </i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div  class="v-card__text pa-0">
                                    <div class="row no-gutters">
                                        <div class="pa-2 pt-0  col col-3" style="max-width: 76px !important;">
                                            <img src="`+a.url+`" class="pa-1" style="width: 100%; max-width: 60px; margin-bottom: auto;">
                                        </div>
                                        <div class="col">
                                            <div class="v-card__title pa-0" style="line-height: 1;">
                                                    <span class="subtitulo-ficha" style="font-size:14px; margin-top:4px;"> 
                                                        `+vin+`
                                                    </span>
                                                </div>
                                                <div class="v-card__title pa-0" style="line-height: 1;">
                                                    <span class="subtitulo-ficha-bolder"style="font-size:16px; margin-top:2px; font-weight:600"> `+nombre+` </span>
                                                </div>
                                                <div class="v-card__title pa-0" style="line-height: 1;">
                                                    <span class="subtitulo-ficha" style="font-size:14px; margin-top:4px; word-break: break-word;"> Contrato: `+(a.nombreContrato ? a.nombreContrato : "")+` </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div style="display:flex; font-size:14px; font-weight:600" class="pl-2 pt-1 grey lighten-4">
                                            <div class="" style="">
                                                <img src=`+this.odometroIcon +` style="width: 17px; margin-top:0px; margin-left:0px; margin-right:4px;" />
                                            </div>
                                            <div class="pr-2" style="font-size:13x;  font-weight:bold;">
                                            `+(a.odometro != null ? nf.format(Math.round(a.odometro))+'Kms': '')+`
                                            </div>
                                            <div class="">
                                                <img src="`+this.horometroIcon +`" style="width:20px; margin-top:0px; margin-left:0px;" />
                                            </div>
                                            <div class="pr-2" style="font-size:13px; font-weight:bold">
                                                `+(a.horometro != null ? nf.format(Math.round(a.horometro))+'Hrs': '')+`
                                            </div>
                                            <div class="" >
                                                <img src="`+this.remanenteIcon+`" style="width: 16px; margin-top:2px; margin-left:0px;" />
                                            </div>
                                            <div class="pr-0" style="font-size:13px; font-weight:bold">
                                                `+ Math.round(a.nivelCombustible)+ '%'+`
                                            </div>
                                        </div>
                                        <div style="display:flex; font-size:14px; font-weight:600" class="pl-2 pt-0 grey lighten-3">    
                                            <div class="pr-0" style="font-size:13px; font-weight:lighter; width:160px;">
                                                `+'Status Garantía : '+`
                                            </div>                                            
                                            <div class="" >
                                                <img src="`+this.goIcon+`" style="width: 16px; margin-top:2px; margin-left:0px;" />
                                            </div>                                                
                                        </div>
                                        <div style="display:flex; font-size:14px; font-weight:600" class="pl-2 pt-0 grey lighten-3">    
                                            <div class="pr-0" style="font-size:13px; font-weight:lighter; width: 160px;">
                                                `+'Status Muestra Aceite : '+`
                                            </div>                                            
                                            <div class="">
                                                <img src="`+this.stopIcon+`" style="width: 14px; margin-top:2px; margin-left:0px;" />
                                            </div>                                                
                                        </div>
                                        <div style="display:flex; font-size:14px; font-weight:600" class="pl-2 pt-0 grey lighten-3">    
                                            <div class="pr-0" style="font-size:13px; font-weight:lighter; width:160px;" >
                                                `+'Próxima Mantención : '+`
                                            </div>                                            
                                            <div class="" >
                                                <img src="`+this.alertIcon+`" style="width: 16px; margin-top:1px; margin-left:0px;" />
                                            </div>                                                
                                        </div>
                                        <div>
                                            <div style="position: absolute; top:-27px; right:-16px;">
                                                <div class=" mt-0 white azul--text pl-2 pr-2 pl-2 "  
                                                    style="font-size: 70%; border-radius: 4px 4px 0 0; width: 150px; text-align: center; margin-left: auto; margin-right: 8px; line-height: 2; font-weight:600;">
                                                        `+ ( a.sucursal) +`
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    `;
            return leyenda
        },
        CreaPopUpMarcadoresRaw(a){
            let vin = a.vin ? a.vin : 'NO DISPONIBLE';
            let familia = a.familia ? a.familia : 'NO DISPONIBLE';
            let modelo = a.modelo ? a.modelo : 'NO DISPONIBLE';
            let marca = a.marca ? a.marca : 'NO DISPONIBLE';
            let nombre = a.nombre ? a.nombre : vin;
            let org = a.nombreOrganizacion ? a.nombreOrganizacion : 'NO DISPONIBLE';
            let nf = new Intl.NumberFormat('es-CL');
            let leyendaPop =  `
                        <div style="width:28vw; min-width:420px">
                            <div 
                                class="pa-0 pt-0 v-card v-sheet theme--light elevation-0 rounded-0" 
                                style="border: thin solid red; margin-right: 16px; margin-top: 18px; margin-bottom: 16px;">
                                    <div no-gutters="" class="v-card__title pa-0 pl-2 `+(a.infoCan == 0 ? 'azul white--text' : a.infoCan == -1 ? 'celeste white--text': 'azul white--text') +`">
                                    <div class="row no-gutters">
                                        <div >
                                            <div  style=" text-align: end; padding-right: 4px;">
                                                <span  class="titulo-ficha" 
                                                        style="line-height: 1; padding-top: 2px; text-transform: uppercase; font-size:60%; word-break: break-word;">`
                                                        +org+
                                                `</span> 
                                            </div>
                                        </div>
                                        <div style="margin-left:auto;" >
                                            <div  style=" text-align: end; padding-right: 4px; ">
                                                <span  class="titulo-ficha" style="line-height: 1; padding-top: 2px; text-transform: uppercase; font-size:60%">
                                                    `+moment.utc(a.fecha).local().locale('es').fromNow()+`
                                                </span> 
                                                <i aria-hidden="true" class="v-icon notranslate mdi mdi-map-marker theme--light white--text" 
                                                    style="font-size: 11.5px; margin-left: 2px; margin-top: 2px !important;">
                                                </i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-v-7bfa9a42="" class="v-card__text pa-0">
                                    <div class="row no-gutters">
                                        <div class="pa-2 pt-0 pb-0 col col-3" style="max-width: 76px !important;">
                                            <img src="`+a.url+`" class="pa-1" style="width: 100%; max-width: 60px; margin-bottom: auto;">
                                        </div>
                                        
                                        <div class="col">
                                            <div class="v-card__title pa-0" style="line-height: 1;">
                                                    <span class="subtitulo-ficha" style="font-size:14px; margin-top:4px;"> 
                                                        `+vin+`
                                                    </span>
                                                </div>
                                                <div class="v-card__title pa-0" style="line-height: 1;">
                                                    <span class="subtitulo-ficha-bolder"style="font-size:16px; margin-top:2px; font-weight:600"> `+nombre+` </span>
                                                </div>
                                                <div class="v-card__title pa-0" style="line-height: 1;">
                                                    <span class="subtitulo-ficha" style="font-size:14px; margin-top:4px; word-break: break-word;"> Contrato: `+(a.nombreContrato ? a.nombreContrato : "")+` </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div >
                                            <div>
                                                <div class="pl-3  grey lighten-1">
                                                    `+ a.marca +` | `+ a.modelo +` | `+ a.familia +`
                                                </div>
                                            </div>
                                        </div>
                                        <div style="display:flex; font-size:14px; font-weight:600" class="pl-2 pt-1 grey lighten-2">
                                            <div class="" style="">
                                                <img src=`+this.odometroIcon +` style="width: 17px; margin-top:0px; margin-left:0px; margin-right:4px;" />
                                            </div>
                                            <div class="pr-2 mr-2" style="font-size:13x;  font-weight:bold;">
                                            `+(a.odometro != null ?  nf.format(Math.round(a.odometro))+ 'Kms': '')+`
                                            </div>
                                            <div class="">
                                                <img src="`+this.horometroIcon +`" style="width:20px; margin-top:0px; margin-left:0px;" />
                                            </div>
                                            <div class="pr-2 mr-2" style="font-size:13px; font-weight:bold">
                                            `+(a.horometro != null ?  nf.format(Math.round(a.horometro))+ 'Hrs': '')+`
                                            </div>
                                            <div class="" >
                                                <img src="`+this.remanenteIcon+`" style="width: 16px; margin-top:2px; margin-left:0px;" />
                                            </div>
                                            <div class="pr-0" style="font-size:13px; font-weight:bold">
                                                `+  Math.round(a.nivelCombustible) + '%' +`
                                            </div>
                                        </div>
                                        <div style="display:flex; font-size:14px; font-weight:600" class="pl-2 pt-0 grey lighten-4">    
                                            <div class="pr-0" style="font-size:13px; font-weight:lighter; width:160px;">
                                                `+'Status Garantía : '+`
                                            </div>                                            
                                            <div class="" >
                                                <img src="`+this.goIcon+`" style="width: 16px; margin-top:2px; margin-left:0px;" />
                                                <span class="ml-2" style="font-weight:400;"> Hasta `+moment(a.fecha).local().locale('es').format('DD MMM YYYY')+`</span>
                                            </div>                                                
                                        </div>
                                        <div style="display:flex; font-size:14px; font-weight:600" class="pl-2 pt-0 grey lighten-4">    
                                            <div class="pr-0" style="font-size:13px; font-weight:lighter; width: 160px;">
                                                `+'Status Muestra Aceite : '+`
                                            </div>                                            
                                            <div class="">
                                                <img src="`+this.stopIcon+`" style="width: 14px; margin-top:2px; margin-left:0px;" />
                                                <span class="ml-2" style="font-weight:400;"> Transmisión <small >(`+moment(a.fecha).local().locale('es').format('DD MMM YYYY')+`)</small></span>
                                            </div>                                                
                                        </div>
                                        <div style="display:flex; font-size:14px; font-weight:600" class="pl-2 pt-0 grey lighten-4">    
                                            <div class="pr-0" style="font-size:13px; font-weight:lighter; width:160px;" >
                                                `+'Próxima Mantención : '+`
                                            </div>                                            
                                            <div class="" >
                                                <img src="`+this.alertIcon+`" style="width: 15px; margin-top:1px; margin-left:0px;" />
                                                
                                                <span class="ml-2" style="font-weight:400;">`+moment(a.fecha).local().locale('es').format('DD MMM YYYY')+`</span>
                                            </div>                                                
                                        </div>
                                        <div>
                                            <div style="position: absolute; right: 0; top: 40px;">
                                                <div data-v-7bfa9a42="" class=" mt-0 azul white--text pl-2 pr-2 pl-2 " 
                                                    style="font-size: 70%; border-radius: 4px; width: 150px; text-align: center; margin-left: auto; margin-right: 8px; line-height: 2;">
                                                        `+ (a.sucursal) +`
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    `;
                return leyendaPop;
        },
        CreaIconoMarcadorDinamico(url){
            const icondinamico = L.icon({
                            iconUrl: url,
                            iconSize: [50, 50],
                            iconAnchor: [25, 40],
                        });
            return icondinamico;
        },
        Carga_prop_sucursales(){
            for(let i = 0; i < this.sucursales.length ; i++){
                if(this.sucursales[i].nombre && this.sucursalIcon){
                    L.marker(
                        { lat: this.sucursales[i].coord[1], lng: this.sucursales[i].coord[0] },
                        { icon: icondinamico },
                        { title: title},
                        { zIndexOffset: 2 }
                    )
                    .bindTooltip(this.sucursales[i].nombre)
                    .addTo(this.prop_sucursales);
                }
            }
        },
        Carga_prop_marcadores(){
         
            for(let i = 0; i < this.marcadores.length ; i++){
                if(this.marcadores[i].coordenadas ? this.marcadores[i].coordenadas[0] &&  this.marcadores[i].coordenadas[1] && this.marcadores[i].url: false){
                    const icondinamico = L.icon({
                            iconUrl: this.marcadores[i].url,
                            iconSize: [50, 50],
                            iconAnchor: [25, 40],
                        });
                    this.prop_marcadores[this.marcadores[i].vin] =
                    L.marker(
                         { lat: this.marcadores[i].coordenadas[1], lng: this.marcadores[i].coordenadas[0] },
                         { icon: icondinamico },
                         { zIndexOffset: 3 }
                    )
                    .bindPopup(this.CreaPopUpMarcadoresRaw(this.marcadores[i]), { width: '25vw;',  height: window.innerHeight })
                    .bindTooltip(this.CreaLeyendaMarcadoresraw(this.marcadores[i]));
                    // this.id_prop_marcadores[this.marcadores[i].vin] =
                    // L.marker(
                    //      { lat: this.marcadores[i].coordenadas[1], lng: this.marcadores[i].coordenadas[0] },
                    //      { icon: this.CreaIconoMarcadorDinamico(this.marcadores[i]) },
                    //      { zIndexOffset: 3 }
                    // )
                    // .bindPopup(this.CreaPopUpMarcadoresRaw(this.marcadores[i]), { width: '25vw;',  height: window.innerHeight })
                    // .bindTooltip(this.CreaLeyendaMarcadoresraw(this.marcadores[i]));
                }
            }
            this.MontarMarcadores();
        },
        MontarSucursales(){
            L.layerGroup(this.prop_sucursales);
        },
        MontarMarcadores(){
            try{
                this.map.removeLayer(this.id_prop_marcadores) ;
            }
            catch(ex){
                console.log(ex);
            }
            const valMap = L.markerClusterGroup();
            this.marcadores.filter((i) => i.coordenadas ? i.coordenadas[0] &&  i.coordenadas[1] && i.url: false).map(r => r.vin).map(t => this.prop_marcadores[t]).forEach( p =>{
                valMap.addLayer(p)
            });

            this.map.addLayer(valMap) ; 
            this.id_prop_marcadores = valMap;

        },
        MontaMapa() {
                this.map ? this.map.remove() : null;
                let self = this;
                let g = this.setView ? [this.setView.latitude, this.setView.longitude] : [-30,70];
                var map = L.map('mapa').setView(g, 4);
                map.invalidateSize();
                self.map = map;
                delete L.Icon.Default.prototype._getIconUrl;
                L.Icon.Default.mergeOptions({
                    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
                    iconUrl: require('leaflet/dist/images/marker-icon.png'),
                    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
                });
                const osmmap = L.tileLayer(this.capasMapa[0].url,{
                        maxZoom: this.capasMapa[0].maxZoom,
                        attribution: this.capasMapa[0].attribution,
                        active: false
                    });
                const esritopo = L.tileLayer(this.capasMapa[3].url,{
                        maxZoom: this.capasMapa[3].maxZoom,
                        attribution: this.capasMapa[3].attribution,
                        active: false
                    });
                const googlehibrido = L.tileLayer(this.capasMapa[7].url,{
                    maxZoom: this.capasMapa[7].maxZoom,
                    attribution: this.capasMapa[7].attribution,
                    // active: false
                });
                this.control = L.control.layers({
                        'OSM-MAP': osmmap,
                        // 'OSM-TOPO': osmtopo,
                        // 'OSM-CARTO': osmcarto,
                        'ESRI-TOPO': esritopo,
                        // 'ESRI-RUTA': esriruta,
                        // 'GOOGLE-MAP': googlemap,
                        // 'GOOGLE-TRÁFICO': googletrafico.addTo(this.map),
                        'GOOGLE-HÍBRIDO': googlehibrido.addTo(this.map)
                        // ,'GOOGLE-RUTA': googleruta.addTo(this.map)
                    }, /*this.overlays*/ ).addTo(self.map);


              
                
                this.map.scrollWheelZoom.enable();


                window.setTimeout(function () {
                    self.map.invalidateSize();
                    // if (polyLayers.length > 0) {
                    //     self.map.setZoom(8);
                    //     self.map.fitBounds(drawnItems.getBounds());
                    // }
                }, 500);
            }

    },
    watch:{
        marcadores: {
            handler(newVal, oldVal){
                if(newVal != undefined ? newVal != null ? newVal.length > 0: false : false){
                    if(oldVal != undefined ? oldVal != null ? oldVal.length > 0: false : false){
                        this.Carga_prop_marcadores();
                    }else{
                       
                        this.Carga_prop_marcadores();
                        
                    }
                }
            }, deep: true, immediate: false,
        },
        setView: {
                handler(newVal){
                    if(this.map && newVal){
                        if(this.setView){
                            let g = this.setView ? [this.setView.longitude, this.setView.latitude] : [-30,70];
                            this.map.setView(g,18);
                         
                                // setTimeout(function () {
                                //     this.map.invalidateSize(false);
                                // }, 100);
                          
                        }
                        
                    }
                }, deep: true, immediate: false, 
            }
    }
}
</script>

<style>
   .map { 
    
        height: calc(100vh - 100px); 
        width: calc(100%);  
        z-index: 4;        
    }

    .tablamapa{
        width:410px;
    }
    .tablamapa > tr > td {
        padding-right: 14px;
    }
    .tablamapa tr > td {
       border: thin solid rgba(0, 0, 0, 0.12)!important;
    }
    .leaflet-control-layers-base{
        text-align: start!important;
        margin-left:8px!important;
        margin-top:8px;
        margin-bottom: 12px;
        margin-right: 8px;
    }

    .leaflet-popup-content{
        min-width:25vw!important;
    }

    .leaflet-popup {
       min-width:25vw!important;
    }
    .leaflet-control-attribution{
        display:none!important
    }

    .marker-cluster-small {
	background-color:rgba(84, 110, 140, 0.75);
    color: aliceblue;
	}
    .marker-cluster-small div {
        background-color: rgba(0, 40, 85, 0.75);
        color: aliceblue;
        }

    .marker-cluster-medium {
        background-color:rgba(84, 110, 140, 0.75);
        color: aliceblue;
        }
    .marker-cluster-medium div {
        background-color: rgba(0, 40, 85, 0.75);
        }

    .marker-cluster-large {
        background-color:rgba(84, 110, 140, 0.75);
        color: aliceblue;
        }
    .marker-cluster-large div {
        background-color: rgba(0, 40, 85, 0.75);
        }

        /* IE 6-8 fallback colors */
    .leaflet-oldie .marker-cluster-small {
        background-color:rgba(84, 110, 140, 0.75);
        color: aliceblue;
        }
    .leaflet-oldie .marker-cluster-small div {
        background-color: rgba(0, 40, 85, 0.75);
        color: aliceblue;
        }

    .leaflet-oldie .marker-cluster-medium {
        background-color: rgba(0, 40, 85, 0.75);
        }
    .leaflet-oldie .marker-cluster-medium div {
        background-color: rgba(0, 40, 85, 0.75);
        }

    .leaflet-oldie .marker-cluster-large {
        background-color:rgba(84, 110, 140, 0.75);
        color: aliceblue;
        }
    .leaflet-oldie .marker-cluster-large div {
         background-color: rgba(0, 40, 85, 0.75);
    }

.marker-cluster {
	background-clip: padding-box;
	border-radius: 20px;
    z-index: 10;
	}
.marker-cluster div {
	width: 30px;
	height: 30px;
	margin-left: 5px;
	margin-top: 5px;
    z-index: 10;
	text-align: center;
	border-radius: 15px;
	font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
	}
.marker-cluster span {
	line-height: 30px;
	}

    .leaflet-popup-content-wrapper{
        width: 30vw!important;
        padding-right: 16px!important;
        min-width:440px
    }



</style>