import BoiletComponentBase from '@/shared/application/boilet-component-base';
import { Component } from 'vue-property-decorator';
import moment from 'moment';
import { RecomendacionCliente, Mensaje, LlaveValor } from '@/shared/models/shared/consolidados/RecomendacionCliente'
import { Organizacion } from '@/shared/models/shared/consolidados/Organizacion';
import AuthStore from '@/stores/auth-store';
import AppConsts from '@/shared/application/boilet';
import { Maquina } from '@/shared/models/shared/consolidados/Maquina';
import axios from "axios";
@Component
export default class AccesoResponsivoComponent extends BoiletComponentBase {
    public archivos: string | number = '';
    public accesoAutorizado = true;
    public asyncUsuarioActivo = {} as any;
    public errors: any[]  = [] as any[];
    public usuarioActivo = "";
    public organizaciones = [] as Organizacion[];
    public organizacion = "";
    public rules = [(v: any) => v.length <= 255 || 'Max 255 caracteres'];
    public  textoMensaje: string = '';
    public date = null;
    public menu= false;
    public menu1= false;
    public dialog = false;
    public plantilla = {} as RecomendacionCliente;
    public motivo = '';
    public idreco: any | null= null;
    public items = ['Servicio programado', 'Servicio descartado'];
    public dateservicio: any = null;
    public equipos = [] as any[];
    public linkacceso = '';
    get computedDateFormattedMomentjs () {
        return this.date ? moment(this.date).local().locale('es').format('dddd, D MMMM  YYYY') : ''
    }
    async created(){
        //@ts-ignore
        this.idreco = (this.$route.query).q;
        this.usuarioActivo = (this.$route.query).e as string;
    }

    async mounted(){
      this.Get0();
    }

    createGuid()  
    {  
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {  
            var r = Math.random()*16|0, v = c === 'x' ? r : (r&0x3|0x8);  
            return v.toString(16);  
        });  
    }

    // public async GetAuthReco(){

    //     if(!this.plantilla.clientes){
    //         this.plantilla.clientes = [] as LlaveValor[];
    //         const guid = this.createGuid();
    //                 //   console.log('hola')
    //                   await this.EnviaKey(guid)
    //                   AuthStore.setRecomendacion(this.idreco,guid);
    //                   this.accesoAutorizado = true;
    //     }
    //     if(this.idreco && this.usuarioActivo){
    //           let tk = AuthStore.getRecomendacion(this.idreco);
    //         //   console.log('3');
    //         //   console.log(tk);
              
    //           if(tk){
    //               const llaves = this.plantilla.destinatarios.findIndex(r => r == this.usuarioActivo);
    //               console.log('4')
    //               console.log(llaves);
    //               if(llaves > -1){
    //                   const gh = this.plantilla.clientes[llaves].valor;
    //                   console.log('VALIDAR')
    //                   const autoriza = (tk == gh ? true :false)
    //                   this.accesoAutorizado = autoriza;
    //               }else{
    //                   this.accesoAutorizado = false;
    //               }
    //           }else{
    //               //Pedirlos Key guardados y ver si no existe ya uno para este correo
    //               const llaves = this.plantilla.clientes.findIndex(r => r.email == this.usuarioActivo);
    //               if(llaves > -1){
    //                   this.accesoAutorizado = false;
    //               }else{
    //                   const guid = this.createGuid();
    //                 //   console.log('hola')
    //                   await this.EnviaKey(guid)
    //                   AuthStore.setRecomendacion(this.idreco,guid);
    //                   this.accesoAutorizado = true;
                      
    //               }
    //           }
    //     }
    // }

    public async GetAuthReco1(){
        // if(this.plantilla.clientes == undefined || this.plantilla.clientes == null ){
        //     this.plantilla.clientes = [] as any[];
        // }
        if(this.idreco && this.usuarioActivo){
              let tk = AuthStore.getRecomendacion(this.idreco);
              if(tk){
                  const llaves = this.plantilla.clientes.findIndex(r => r.email == this.usuarioActivo);
                  if(llaves > -1){
                      const gh = this.plantilla.clientes[llaves].valor;
                      const autoriza = (tk == gh ? true :false)
                      this.accesoAutorizado = autoriza;
                      if(!this.accesoAutorizado){
                          this.linkacceso = 'Solicitar Acceso';
                      }
                  }else{
                      this.accesoAutorizado = false;
                  }
              }else{
                  //Pedirlos Key guardados y ver si no existe ya uno para este correo
                if(this.plantilla.clientes){
                    const llaves = this.plantilla.clientes.findIndex(r => r.email == this.usuarioActivo);
                    if(llaves > -1){
                        this.accesoAutorizado = false;
                    }else{
                        const guid = this.createGuid();
                      //   console.log('hola')
                        await this.EnviaKey(guid)
                        AuthStore.setRecomendacion(this.idreco,guid);
                        this.accesoAutorizado = true;                      
                    }
                }


                 
              }
        }
    }

    public async Get(){
        // console.log((this.$route.query).q);
        this.boiletService.get<RecomendacionCliente>('/api/Recomendaciones/abiertos/'+  (this.$route.query).q, false)
        .then( async r => {
            if(!r.isError){
                this.plantilla = r.content;
                this.dateservicio = this.plantilla.fechaServicio ? moment(this.plantilla.fechaServicio).utc().format('YYYY-MM-DD'):null;
               await this.GetOrganizaciones();
            }else{
                this.errors = r.errors;
            }
            return this.plantilla;
        });
    }

    public async Get0(){
        // console.log((this.$route.query).q);
        this.boiletService.get<RecomendacionCliente>('/api/Recomendaciones/abiertos/'+  (this.$route.query).q, true)
        .then( async r => {
            if(!r.isError){
                this.plantilla = r.content;
                if(this.plantilla){
                    this.dateservicio = this.plantilla.fechaServicio ? moment(this.plantilla.fechaServicio).utc().format('YYYY-MM-DD'):null; 
                    await this.GetOrganizaciones().then(async ()  =>{
                         await this.SolicitarAcceso().then(() =>{
                              this.asyncUsuarioActivo = setInterval(this.Get, 5000);
                          })
                     });
                    }else{
                        window.location.href = "https://www.salfa.cl";
                }
              
            }else{
                this.errors = r.errors;
            }
            if(this.plantilla){
                return this.plantilla;
            }else{
                window.location.href = "https://www.salfa.cl";
            }
        });
    }

    public async GetOrganizaciones(){
        await this.boiletService.get<Organizacion>('/api/Organizaciones/'+this.plantilla.org_id,false)
        .then( a => {
            this.organizacion = a.content.nombre;
            this.organizaciones = [a.content] as Organizacion[];
        });
    }

    

    EnviarMensaje(){
        if(this.plantilla.fechaCierre == null){
            if(this.textoMensaje ? this.textoMensaje.length > 0: false){
                let msg = {} as Mensaje;
                msg.emailAutor = this.usuarioActivo;
                msg.emailRespondable = this.plantilla.responsable;
                msg.esMiembro = false;
                msg.fecha = moment.utc().toDate();
                msg.texto = this.textoMensaje;
                if(this.plantilla.mensajes){
                    this.plantilla.mensajes.push(msg);
                }else{
                    this.plantilla.mensajes = [] as Mensaje[];
                    this.plantilla.mensajes.push(msg);
                }
               
                this.boiletService.put<RecomendacionCliente>('/api/Recomendaciones/mensajes', JSON.stringify(this.plantilla))
                .then( r => {
                    if(!r.isError){
                        this.Get();
                        this.AutoRespuesta();
                    }else{
                        this.errors = r.errors;
                    }
                });
                this.textoMensaje = "";
            }
        }else{
            alert("Este caso ya ha sido cerrado");
        }
    }

    EnviarMensajeFoto(foto_id: string){
        if(this.plantilla.fechaCierre == null){
                let msg = {} as Mensaje;
                msg.emailAutor = this.usuarioActivo;
                msg.emailRespondable = this.plantilla.responsable;
                msg.esMiembro = false;
                msg.fecha = moment.utc().toDate();
                msg.texto = "";
                msg.foto_id = foto_id;
                if(this.plantilla.mensajes){
                    this.plantilla.mensajes.push(msg);
                }else{
                    this.plantilla.mensajes = [] as Mensaje[];
                    this.plantilla.mensajes.push(msg);
                }

                
                this.boiletService.put<RecomendacionCliente>('/api/Recomendaciones/mensajes', JSON.stringify(this.plantilla))
                .then( r => {
                    if(!r.isError){
                        this.Get();
                        this.AutoRespuesta();
                    }else{
                        this.errors = r.errors;
                    }
                });
                this.textoMensaje = "";
                this.textoMensaje = "";
            // }
        }else{
            alert("Este caso ya ha sido cerrado");
        }
    }

    get OrdenaChat(){
        if(this.plantilla.mensajes ? this.plantilla.mensajes.length > 0 : false){
            let u = this.plantilla.mensajes
                    .sort((a,b) => 
                        moment(a.fecha).unix()>  moment(b.fecha).unix() ? 
                        -1 : moment(a.fecha).unix() < moment(b.fecha).unix() ? 
                        1 : (a.emailAutor == 'confiabilidad@salfa.cl' ? -1 : 0 )
                    ) as any[];
            return u;
        }else{
            return [] as any[];
        }
    }
    
    Formateado(item: any){
        if(item)
        return  (moment(item).local().locale('es').format(' DD MMM YY HH:mm:ss')).toUpperCase();
    }

    get correlativo(){
        return "N° Caso: " + moment(this.plantilla.fechaEnvio).unix()
        // +'-'+this.MuestraRegion(this.plantilla);;
    }
    actionRequestUrl= AppConsts.baseApiUrl+'/api/Upload/UploadFile';
    public headers: { [key: string]: string } = {
        Authorization: `Bearer ${AuthStore.getToken()}`
    };
    public fileList= [] as any; //文件列表
    public dialogVisible= true;//Dialog显示状态

    openImporDialog() {
        this.dialogVisible = true;
    }
    handleClose() {
        this.dialogVisible = false;
    }
    submitUpload0() {
        console.log(this.fileList);
        if (this.fileList.length <= 0) {
          this.$message.error("请先选择需要上传的文件！");
          return false;
        }
        //@ts-ignore
        this.$refs.upload1.submit();
    }
    submitUpload1() {
        console.log(this.fileList);
        if (this.fileList.length <= 0) {
          this.$message.error("请先选择需要上传的文件！");
          return false;
        }
        //@ts-ignore
        this.$refs.upload1.submit();
    }
    submitUpload() {
        console.log(this.fileList);
        if (this.fileList.length <= 0) {
          this.$message.error("请先选择需要上传的文件！");
          return false;
        }
        //@ts-ignore
        this.$refs.upload.submit();
    }
    fileUploadFail(err: any, file: any, fileList: any[]) {
        console.log("Error al cargar el archivo", file, fileList);
    }

    fileUploadSuccess0(response: any, file: any, fileList: any[]) {
        // console.log("上传成功");
        console.log(response);
        //清空已上传的文件列表
        //@ts-ignore
        this.$refs.upload0.clearFiles();
        if (response.newFileName) {
            this.dialogVisible = false;
        //   this.$message({
        //     message: response.message,
        //     type: "success"
        //   });
        this.EnviarMensajeFoto(response.newFileName);
        } else {
            this.$message.error(response.message);
        }
    }
    fileUploadSuccess1(response: any, file: any, fileList: any[]) {
        // console.log("上传成功");
        console.log(response);
        //清空已上传的文件列表
        //@ts-ignore
        this.$refs.upload1.clearFiles();
        if (response.newFileName) {
            this.dialogVisible = false;
        //   this.$message({
        //     message: response.message,
        //     type: "success"
        //   });
        this.EnviarMensajeFoto(response.newFileName);
        } else {
            this.$message.error(response.message);
        }
    }
    fileUploadSuccess(response: any, file: any, fileList: any[]) {
        // console.log("上传成功");
        console.log(response);
        //清空已上传的文件列表
        //@ts-ignore
        this.$refs.upload.clearFiles();
        if (response.newFileName) {
            this.dialogVisible = false;
        //   this.$message({
        //     message: response.message,
        //     type: "success"
        //   });
        this.EnviarMensajeFoto(response.newFileName);
        } else {
            this.$message.error(response.message);
        }
    }
    fileChange(file: any, fileList: any[]) {
    //解决无法判断el-upload是否上传过文件问题
        this.fileList = fileList;
    // console.log("Seleccione el contenido que se muestra después de que el archivo se haya cargado correctamente :", file, fileList);
    }
    handleRemove(file: any, fileList: any[]) {
        this.fileList = [];
    }
    handlePreview(file: any) {
        console.log(file);
    }

    public Imagen(id: string){
        return  AppConsts.baseApiUrl+'/api/Upload/'+id
    }
    
    get Hora(){
        return moment().local().hour();
    }

    get Dia(){
        return moment().local().weekday();
    }

    public AutoRespuesta(){
        if(this.Dia > 0 && this.Dia < 6){
            if(this.Hora >= 9 && this.Hora <= 18){
                let cliente = this.OrdenaChat[this.OrdenaChat.length-1];
                if(!cliente.esMiembro){
                    if(this.OrdenaChat.length == 1){
                        this.EnviarMensajeAUtoRespuesta1();
                    }else{
                        let fechaMsg = moment.utc();
                        let ultimoAuto = this.OrdenaChat.filter(t => t.emailAutor == 'confiabilidad@salfa.cl').sort((a,b) => 
                                            moment(a.fecha).unix()>  moment(b.fecha).unix() ? 
                                            -1 : moment(a.fecha).unix() < moment(b.fecha).unix() ? 
                                            1 : (a.emailAutor == 'confiabilidad@salfa.cl' ? -1 : 0 )
                                        );
                        let fechaAUto = 0 as number;
                        if(ultimoAuto ? ultimoAuto.length >0 : false){
                            fechaAUto = fechaMsg.diff(moment(ultimoAuto[0].fecha),'hour');
                        }
                        let ultimoAnalista = this.OrdenaChat.filter(t => t.esMiembro).sort((a,b) => 
                                                moment(a.fecha).unix()>  moment(b.fecha).unix() ? 
                                                -1 : moment(a.fecha).unix() < moment(b.fecha).unix() ? 
                                                1 : (a.emailAutor == 'confiabilidad@salfa.cl' ? -1 : 0 )
                                            );
                        let fechaAnalista = 0 as number;
                        if(ultimoAnalista ? ultimoAnalista.length > 0 : false){
                            fechaAnalista = fechaMsg.diff(moment(ultimoAnalista[0].fecha),'hour');
                        }

                        if( fechaAUto >= 4 || fechaAnalista >= 4 ){
                            this.EnviarMensajeAUtoRespuesta1();
                        }
                    }
                }
            }else{
                this.EnviarMensajeAUtoRespuesta();
            }
        }else{
            this.EnviarMensajeAUtoRespuesta();
        }
    }

    EnviarMensajeAUtoRespuesta1(){
        if(this.plantilla.fechaCierre == null){
            // if(this.textoMensaje ? this.textoMensaje.length > 0: false){
                let msg = {} as Mensaje;
                msg.emailAutor = "confiabilidad@salfa.cl";
                msg.emailRespondable = this.plantilla.responsable;
                msg.esMiembro = false;
                msg.fecha = moment.utc().toDate();
                // let ua = this.organizaciones ? this.organizaciones.length == 1 ? this.organizaciones[0] : null : null;
                let nombre = "cliente";
                // if(ua != null){
                    let ixc = this.plantilla.clientes ? this.plantilla.clientes.findIndex(t => t.email == this.usuarioActivo) : -1;
                    if(ixc > -1){
                        let cn = this.plantilla.clientes[ixc].nombre;
                        let cnn = cn ? cn.length > 0 ? cn.split(' '):[] :[];
                        let cnnn = cnn ? cnn.length > 0 ? cnn[0] : 'cliente':'cliente';
                        if(cnnn != 'cliente'){
                            nombre = cnnn.charAt(0).toUpperCase() + cnnn.slice(1);
                        }else{
                            nombre = cnnn;
                        }
                        
                    // }else{
                    //     nombre = 'cliente';
                    // }
                }
                msg.texto = "Estimado "+nombre+", hemos enviado su mensaje a nuestros especialistas, estaremos tomando contacto con usted tan pronto uno de nuestros especialista esté disponible. Muchas gracias por su contacto.";
                if(this.plantilla.mensajes){
                    this.plantilla.mensajes.push(msg);
                }else{
                    this.plantilla.mensajes = [] as Mensaje[];
                    this.plantilla.mensajes.push(msg);
                }

                
                this.boiletService.put<RecomendacionCliente>('/api/Recomendaciones/mensajes', JSON.stringify(this.plantilla))
                .then( r => {
                    if(!r.isError){
                        // Object.assign(this.plantilla,{},r.content);
                        this.Get();
                    }else{
                        this.errors = r.errors;
                    }
                });
                this.textoMensaje = "";
                this.textoMensaje = "";
            // }
        }else{
            alert("Este caso ya ha sido cerrado");
        }
    }

    EnviarMensajeAUtoRespuesta(){
        if(this.plantilla.fechaCierre == null){
            // if(this.textoMensaje ? this.textoMensaje.length > 0: false){
                let msg = {} as Mensaje;
                msg.emailAutor = "confiabilidad@salfa.cl";
                msg.emailRespondable = this.plantilla.responsable;
                msg.esMiembro = false;
                msg.fecha = moment.utc().toDate();
                // let ua = this.organizaciones ? this.organizaciones.length == 1 ? this.organizaciones[0] : null : null;
                let nombre = "cliente";
                // if(ua != null){
                    let ixc = this.plantilla.clientes ? this.plantilla.clientes.findIndex(t => t.email == this.usuarioActivo) : -1;
                    if(ixc > -1){
                        let cn = this.plantilla.clientes[ixc].nombre;
                        let cnn = cn ? cn.length > 0 ? cn.split(' '):[] :[];
                        let cnnn = cnn ? cnn.length > 0 ? cnn[0] : 'cliente':'cliente';
                        if(cnnn != 'cliente'){
                            nombre = cnnn.charAt(0).toUpperCase() + cnnn.slice(1);
                        }else{
                            nombre = cnnn;
                        }
                        
                    // }else{
                    //     nombre = 'cliente';
                    // }
                }
                msg.texto = "Estimado "+nombre+", nuestro horario de atención es de Lunes a Viernes entre las 9am a 6pm. Estaremos respondiendo en este horario. Muchas gracias por su contacto.";
                if(this.plantilla.mensajes){
                    this.plantilla.mensajes.push(msg);
                }else{
                    this.plantilla.mensajes = [] as Mensaje[];
                    this.plantilla.mensajes.push(msg);
                }

                
                this.boiletService.put<RecomendacionCliente>('/api/Recomendaciones/mensajes', JSON.stringify(this.plantilla))
                .then( r => {
                    if(!r.isError){
                        // Object.assign(this.plantilla,{},r.content);
                        this.Get();
                    }else{
                        this.errors = r.errors;
                    }
                });
                this.textoMensaje = "";
                this.textoMensaje = "";
            // }
        }else{
            alert("Este caso ya ha sido cerrado");
        }
    }

    get AccesoOK(){
        return this.accesoAutorizado;
    }


    public async Access(){
        if(this.idreco){
            let eus = this.plantilla.destinatarios.findIndex(t => t == this.usuarioActivo);
            if(eus > -1){
                //Existen clientes
                if(this.plantilla.clientes ? this.plantilla.clientes.length > 0 :false){
                    const itx = this.plantilla.clientes.filter( r => r.email == this.usuarioActivo);
                    if(itx ? itx.length > 0 :false){
                        let valorLLave = AuthStore.getRecomendacion(this.idreco);
                        if(valorLLave == null || valorLLave == undefined){
                            const guid = this.createGuid();
                            AuthStore.setRecomendacion(this.idreco,guid);
                            valorLLave =  AuthStore.getRecomendacion(this.idreco);
                        }
                        const flv = itx.findIndex(k => k.valor == valorLLave);
                        if(flv > -1){
                            this.accesoAutorizado = true;
                        }else{
                            this.accesoAutorizado = false;
                            this.linkacceso = valorLLave ? valorLLave : '';
                        }

                    }else{
                         //Crea el usuario y lo carga
                        const guid = this.createGuid();
                        const pp= await this.EnviaKey(guid);
                        AuthStore.setRecomendacion(this.idreco,guid);
                        this.accesoAutorizado = true;
                        console.log(pp);
                    }
                }else{
                    //Crea el usuario y lo carga
                    const guid = this.createGuid();
                    const pp= await this.EnviaKey(guid);
                    AuthStore.setRecomendacion(this.idreco,guid);
                    this.accesoAutorizado = true;
                    console.log(pp);
                }
            }else{
                this.accesoAutorizado = false;
            }
        }else{
            this.accesoAutorizado = false;
        }
    }


    public async SolicitarAcceso(){
        if(this.idreco){
            const llave = this.idreco;
            let existelallavevalor =  AuthStore.getRecomendacion(this.idreco) != null &&  AuthStore.getRecomendacion(this.idreco) != undefined;
            if(existelallavevalor){

            }else{
                //Creamos la llavevalor;
                const guid = this.createGuid();
                
                const pp= await this.EnviaKey(guid);
                if(pp){
                    if(pp.clientes ? pp.clientes.length > 0 : false){
                        const fl = pp.clientes.findIndex(o => o.email == this.usuarioActivo && o.llave == this.idreco && o.valor == guid);
                        if(fl > -1){
                            this.accesoAutorizado = true;
                            AuthStore.setRecomendacion(this.idreco,guid);
                        }
                    }
                }
            }
        }
    }

    async EnviaKey(valor: string){
        if(this.plantilla.clientes == null){
            this.plantilla.clientes = [] as LlaveValor[];
        }

        let gf = {} as LlaveValor;
        gf.email = this.usuarioActivo;
        gf.llave = this.idreco;
        gf.valor = valor;
        this.plantilla.clientes.push(gf);
        const uu =  await this.boiletService.put<RecomendacionCliente>('/api/Recomendaciones/mensajes', JSON.stringify(this.plantilla))
                    .then( async r => {
                        if(!r.isError){
                            // this.Get();

                            let pp = await this.boiletService.get<RecomendacionCliente>('/api/Recomendaciones/abiertos/'+  (this.$route.query).q, false)
                                    .then( async r => {
                                        if(!r.isError){
                                            this.plantilla = r.content;
                                            this.dateservicio = this.plantilla.fechaServicio ? moment(this.plantilla.fechaServicio).utc().format('YYYY-MM-DD'):null;
                                        }else{
                                            this.errors = r.errors;
                                        }
                                        return this.plantilla;
                                    });
                            return pp;
                        }else{
                            this.errors = r.errors;
                            return null;
                        }
                    });
        return uu;
    }


    Descargar (url: any, nombre: any){
        axios({
            url:url,
            method:'GET',
            responseType: 'blob'
        })
        .then((response) => {
                const url = window.URL
                .createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', nombre);
                        document.body.appendChild(link);
                        link.click();
        })
    }


    



}