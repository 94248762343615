import BoiletComponentBase from '@/shared/application/boilet-component-base';
import Component from 'vue-class-component';
import { GestionActivosStore } from '@/gestion/stores/assets/activos';
import AppConsts from '@/shared/application/boilet';
import AuthStore from '@/stores/auth-store';
import { InfoActivo, Cliente, Contacto } from '@/shared/models/shared/consolidados/InfoActivo';
import { LlaveValor, RecomendacionCliente } from '@/shared/models/shared/consolidados/RecomendacionCliente';

@Component
export default class CasoZeroComponent extends BoiletComponentBase {
    public rtt = {} as RecomendacionCliente;

    public creaRecomendacion(){

        let r = {} as RecomendacionCliente;
        r._id = "--";
        r.antecedentes = "";    
        let clienteSeleccionado = {} as Cliente;
        clienteSeleccionado = this.cliente;
        if(clienteSeleccionado ? clienteSeleccionado._id : false){
            r.cliente_id = clienteSeleccionado._id;
            r.nombreCliente = clienteSeleccionado.nombre;
        }       
        let contactosSelecionados = clienteSeleccionado.contactos.filter( (t: Contacto) => 
            this.contactos.includes(t.correo.email)
        );
        if(contactosSelecionados.length > 0){
            let llaveValor = contactosSelecionados.map(u => {
                let ll = {} as LlaveValor;
                ll.email = u.correo.email;
                ll.funcion = u.funcion;
                ll.nombre = u.nombre;
                return ll;
            });
            r.clientes = llaveValor;
            r.destinatarios = this.contactos;
        }
        r.codigo = this.codigo;
        r.color = "";
        r.consecuencias = "";
        r.contadorAlertas = 1;
        r.diagnostico = this.diagnostico;     
        if(this.activos){
            let equppo = this.LISTADO_ACTIVOS.find( (r: any) => r.vin == this.activos.vin);
            if(equppo){
                r.familia = this.activos.familia;
                r.modelo = this.activos.modelo;
                r.marca = this.activos.marca;
                r.cliente_id = this.activos.cliente_id;
                r.nombreCliente = this.activos.nombreCliente;
                r.nombre = this.activos.nombre;
            }
        }
        r.responsable = this.authStore.getTokenData().sub;
        r.severidad = "INFORMATIVO";
        r.spn = this.codigo;
        r.tipoCodigo="RECOMENDACIÓN";
        r.urlImagen = this.urlImagen;
        r.urlImagen2 = this.urlImagen2;
        
        let reput = {} as RecomendacionCliente;
        this.boiletService.put<any>('/api/Recomendaciones/recomanual/',  JSON.stringify(r), true)
        .then((response) => {
            if (!response.isError) {
                reput = JSON.parse(response.content) as unknown as RecomendacionCliente;
            } else {
                this.errors = response.errors;
            }
        });
        this.rtt = reput;
    }
    public displayNuevoVin = false;
    public errors = [] as any[];
    public e1 :number = 1;
    public assetStore = GestionActivosStore();
    public LISTADO_CLIENTES = [] as any[];
    public cliente : null| any= null;
    public contactos = [] as any[];
    public activos : null| any= null;
    public tipoCodigo = "Recomendación";
    public severidad = "Informativo";
    public color = "";
    public codigo = "";
    public resumen = "";
    public diagnostico ="";
    public antecedentes ="";
    public consecuencias ="";
    public recomendacion ="";
    public urlImagen ="";
    public urlImagen2 ="";
    public isEditing  = false;
    public mensajeError = "";


    public nuevo = {
        vin: "",
        nombre: "",
        marca: "",
        modelo: "",
        familia: "",
        nombreCliente: "",
        cliente_id: ""
    } as InfoActivo;

    async mounted(){
        await this.ListarCliente();
    }

    public ListarCliente(){
        this.boiletService.get<any>('/api/Cliente/', true)
            .then((response) => {
                if (!response.isError) {
                    this.LISTADO_CLIENTES = JSON.parse(response.content) as unknown as any[];
                } else {
                    this.errors = response.errors;
                }
            });       
    }

    // public async CreaActivo(){

    //     var activoQuery = this.assetStore.activosConsolidadosArray.findIndex((r:any) => r.vin == this.nuevo.vin );
    //     if(activoQuery > -1){
    //         return "EL VIN YA EXISTE"
    //     }
    //     else{
    //         this.nuevo.cliente_id = this.cliente._id;
    //         this.nuevo.nombreCliente = this.cliente.nombre;
    //         this.boiletService.put<any>('/api/Activo/', JSON.stringify(this.nuevo), false)
    //         .then((response) => {
    //             if(!response.isError){
    //                 var tt = JSON.parse(response.content) as unknown as InfoActivo;
    //                 this.assetStore.addActivo(tt);
    //             }
    //             else{
    //                 this.errors = response.errors;
    //             }
    //         })
    //     }
    // }

    get LISTADO_CONTACTOS(){

        const ix = this.cliente ? this.LISTADO_CLIENTES.findIndex( (r: any) => r._id == this.cliente._id) : -1;
        if(ix > -1){
            return this.LISTADO_CLIENTES[ix].contactos;
        }
        else{
            return [] as any[];
        }
    }

    get LISTADO_ACTIVOS(){
        const ix = this.cliente ?  this.LISTADO_CLIENTES.findIndex( (r: any) => r._id == this.cliente._id) : -1;
        if(ix > -1){
            return this.LISTADO_CLIENTES[ix].activos.map((y:any) => this.assetStore.activosConsolidadosArray.find((o:any) => o._id == y));
        }
        else{
            return [] as any[];
        }
    }

    public fileList= [] as any; //文件列表
    public headers: { [key: string]: string } = { Authorization: `Bearer ${AuthStore.getToken()}`};
    public dialogVisible= true;//Dialog显示状态
    public actionRequestUrl= AppConsts.baseApiUrl+'/api/Upload/UploadFile';
    public baseRequestUrl= AppConsts.baseApiUrl+'/api/Upload/';
    openImporDialog() {
        this.dialogVisible = true;
    }
    handlePreview(file: any) {
        console.log(file);
    }
    fileChange(file: any, fileList: any[]) {
    //解决无法判断el-upload是否上传过文件问题
    this.fileList = fileList;
    // console.log("Seleccione el contenido que se muestra después de que el archivo se haya cargado correctamente :", file, fileList);
    }
    handleRemove(file: any, fileList: any[]) {
        this.fileList = [];
        // return this.$confirm(`确定移除 ${file.name}？`);
    }
    fileUploadFail(err: any, file: any, fileList: any[]) {
        console.log("Error al cargar el archivo", file, fileList);
    }
    fileUploadSuccess000(response: any, file: any, fileList: any[]) {
        //@ts-ignore
        this.$refs.upload000.clearFiles();
        if (response.newFileName) {
            this.dialogVisible = false;
        //   this.$message({
        //     message: response.message,
        //     type: "success"
        //   });
        this.urlImagen = response.newFileName;
        } else {
            this.$message.error(response.message);
        }
    }
    fileUploadSuccess002(response: any, file: any, fileList: any[]) {
        //@ts-ignore
        this.$refs.upload002.clearFiles();
        if (response.newFileName) {
            this.dialogVisible = false;
        //   this.$message({
        //     message: response.message,
        //     type: "success"
        //   });
        this.urlImagen2 = response.newFileName;
        } else {
            this.$message.error(response.message);
        }
    }
}