import BoiletComponentBase from '@/shared/application/boilet-component-base';
import { ModeloDataLabels, ModeloHeatmap, ModeloOpcionesChart, ModeloSeries, XY, XYzz } from '@/shared/models/shared/consolidados/GraficoSerie';
import moment from 'moment';
import { Component, Prop, Watch } from 'vue-property-decorator';
import html2pdf from "html2pdf.js";

@Component
export default class ClienteComponent extends BoiletComponentBase{
    @Prop() readonly activos_vins!: string[];
    @Prop() readonly activos!: any[];
    @Prop() readonly altura!: number;
    @Prop() readonly colores!: string[];
    @Prop() readonly tituloHorometro!: string;
    @Prop() readonly tituloOdometro!: string;
    @Prop() readonly tituloPrincipal!: string;
    @Prop() readonly desde!: string;
    @Prop() readonly hasta!: string;



    created(){


      this.Carga_Horometros(this.activos_vins)
      this.Carga_HorometrosRalenti(this.activos_vins)
      this.Carga_Odolitros(this.activos_vins)
      this.Carga_OdolitrosRalenti(this.activos_vins)
      this.Carga_Odometros(this.activos_vins)
      this.Carga_HorometrosEficiencia(this.activos_vins)
      this.Carga_OdolitrosEficiencia(this.activos_vins)
      this.Carga_HrLitro(this.activos_vins)
      this.Carga_KmLitro(this.activos_vins)
            //  this.Carga_Horometros(this.activos_vins).then(() =>{
            //     this.Crea_Grafico_Avance_Horometros();
            //      this.Carga_HorometrosRalenti(this.activos_vins).then(() => {
            //         this.Crea_Grafico_Avance_Horometro_Ralenti();
            //         this.Crea_Grafico_Avance_Rendmiento_Horometro_Ralenti();
            //          this.Carga_Odometros(this.activos_vins).then(() => {
            //             this.Crea_Grafico_Avance_Odometro();
            //              this.Carga_Odolitros(this.activos_vins).then(() =>{
            //                 this.Crea_Grafico_Avance_Odolitro();
            //                 this.Crea_Grafico_Avance_Rendimiento_Odometro_Combustible();
            //                 this.Crea_Grafico_Avance_Rendimiento_Horometro_Combustible();
            //             })
            //         })
            //     })
            // });
    }

    public cabecera_horometro = [
      { text: '', align: 'start', value: 'accion', width:'30px' , sort:false},
      { text: 'FAMILIA', value: 'familia'  ,align: 'start' ,width:'120px' },
      { text: 'MARCA', value: 'marca'  ,align: 'start' ,width:'120px' },
      { text: 'MODELO', value: 'modelo'  ,align: 'start' ,width:'120px' },
      { text: 'NOMBRE', value: 'nombre'  ,align: 'start'  ,width:'120px' },
      { text: 'VIN', align: 'vin', value: 'vin', width:'120px' },
      { text: 'CLIENTE', value: 'cliente'  ,align: 'start' },
      { text: 'HORÓMETRO', value: 'horometro'  ,align: 'start' },
      { text: 'HORAS USO PROMEDIO', value: 'promedio'  ,align: 'start'},
      { text: 'TENDENCIA HORAS', value: 'tendenciapromedio'  ,align: 'start'},
      { text: 'HORAS ACUMULADAS', value: 'acumulado'  ,align: 'start'},
      { text: 'EFICIENCIA PROMEDIO', value: 'eficienciaPromedio'  ,align: 'start'},
      { text: 'TENDENCIA EFICIENCIA', value: 'tendenciaEficiencia'  ,align: 'start'},
      { text: 'HR x LT PROMEDIO', value: 'hrltpromedio'  ,align: 'start'},
      { text: 'HR x LT TENDENCIA', value: 'hrltendencia'  ,align: 'start'},
    ];
    public get TablaHorometros(){
       const a = this.activos.map( r => {
        const ix = this.horometro_array.findIndex(t => t.vin == r.vin);
        const iz = this.horometro_eficiencia_array.findIndex(t => t.vin== r.vin);
        const ia = this.hrlitro_array.findIndex((t:any) => t.vin == r.vin);
          const x = {} as any;
          x.vin = r.vin;
          x.marca = r.marca;
          x.modelo = r.modelo;
          x.familia = r.familia;
          x.nombre = r.nombre;
          x.cliente = r.nombreCliente;
          x.horometro = r.horometro;

          x.promedio = 0;
          x.tendencia = 0;
          x.acumulado = 0;
          if(ix > -1){
            x.promedio = Math.round(10*this.horometro_array[ix].mediaIncremento)/10;
            x.tendenciapromedio = Math.round(10*this.horometro_array[ix].ecuacionIncremento.pendiente)/10;
            x.acumulado = this.horometro_array[ix].tablaTotal.length > 0 ?Math.round(10*this.horometro_array[ix].tablaTotal[this.horometro_array[ix].tablaTotal.length-1].y as number)/10:0;            
          }
          
  
          x.eficienciaPromedio = 0;
          x.tendenciaEficiencia = 0;
          
          if(iz > -1){
            x.eficienciaPromedio = Math.round(10*this.horometro_eficiencia_array[iz].mediaTasa)/10;
            x.tendenciaEficiencia = Math.round(10*this.horometro_eficiencia_array[iz].ecuacionTasa.pendiente)/10;
            
          }
          x.hrltpromedio = 0;
          x.hrlttendencia = 0;
          if(ia > -1){
            x.hrltpromedio =Math.round(10*this.hrlitro_array[ia].mediaTasa)/10;
            x.hrltendencia = Math.round(10*this.hrlitro_array[ia].ecuacionTasa.pendiente)/10;
          }         
          return x;
          
       });
       return a;
    }
    public cabecera_odolitro = [
      { text: '', align: 'start', value: 'accion', width:'30px' , sort:false},
      { text: 'FAMILIA', value: 'familia'  ,align: 'start' ,width:'180px' },
      { text: 'MARCA', value: 'marca'  ,align: 'start' ,width:'180px' },
      { text: 'MODELO', value: 'modelo'  ,align: 'start' ,width:'140px' },
      { text: 'NOMBRE', value: 'nombre'  ,align: 'start'  ,width:'180px' },
      { text: 'VIN', align: 'start', value: 'vin', width:'180px' },
      { text: 'CLIENTE', value: 'cliente'  ,align: 'start' },
      { text: 'NIVEL COMBUSTIBLE', value: 'nivelCombustible'  ,align: 'start' },
      { text: 'PROMEDIO COMBUSTIBLE CONSUMIDO', value: 'promedio'  ,align: 'start'   },
      { text: 'TENDENCIA CONSUMO', value: 'tendenciapromedio'  ,align: 'start'},
      { text: 'COMBUSTIBLE CONSUMIDO', value: 'acumulado'  ,align: 'start'   },
      { text: 'EFICIENCIA PROMEDIO', value: 'eficienciaPromedio'  ,align: 'start'},
      { text: 'TENDENCIA EFICIENCIA', value: 'tendenciaEficiencia'  ,align: 'start'},
    ];

    public get TablaOdolitros(){
      const a = this.activos.map( r => {
       const ix = this.odolitro_array.findIndex(t => t.vin == r.vin);
       const iz = this.odolitro_eficiencia_array.findIndex((t: any) => t.vin== r.vin);
       const ia = this.hrlitro_array.findIndex((t:any) => t.vin == r.vin);
         const x = {} as any;
         x.vin = r.vin;
         x.marca = r.marca;
         x.modelo = r.modelo;
         x.familia = r.familia;
         x.nombre = r.nombre;
         x.cliente = r.nombreCliente;
         x.odolitro = r.odolitro;
        x.nivelCombustible = r.nivelCombustible;
         x.promedio = 0;
         x.tendencia = 0;
         x.acumulado = 0;
         if(ix > -1){
           x.promedio = Math.round(10*this.odolitro_array[ix].mediaIncremento)/10;
           x.tendenciapromedio = Math.round(10*this.odolitro_array[ix].ecuacionIncremento.pendiente)/10;
           x.acumulado = this.odolitro_array[ix].tablaTotal.length > 0 ?Math.round(10*this.odolitro_array[ix].tablaTotal[this.odolitro_array[ix].tablaTotal.length-1].y as number)/10:0;            
         }
         
 
         x.eficienciaPromedio = 0;
         x.tendenciaEficiencia = 0;
         
         if(iz > -1){
           x.eficienciaPromedio = Math.round(10*this.odolitro_eficiencia_array[iz].mediaTasa)/10;
           x.tendenciaEficiencia = Math.round(10*this.odolitro_eficiencia_array[iz].ecuacionTasa.pendiente)/10;
           
         }
      
         return x;
         
      });
      return a;
   }

    public cabecera_odometro= [
      { text: '', align: 'start', value: 'accion', width:'30px' , sort:false},
      { text: 'FAMILIA', value: 'familia'  ,align: 'start' ,width:'180px' },
      { text: 'MARCA', value: 'marca'  ,align: 'start' ,width:'180px' },
      { text: 'MODELO', value: 'modelo'  ,align: 'start' ,width:'140px' },
      { text: 'NOMBRE', value: 'nombre'  ,align: 'start'  ,width:'180px' },
      { text: 'VIN', align: 'start', value: 'vin', width:'180px' },
      { text: 'CLIENTE', value: 'cliente'  ,align: 'start' },
      { text: 'ÓDOMETRO', value: 'odometro'  ,align: 'start'   },
      { text: 'KM PROMEDIO', value: 'promedio'  ,align: 'start'   },
      { text: 'TENDENCIA KM', value: 'tendenciapromedio'  ,align: 'start'},
      { text: 'KM ACUMULADOS', value: 'acumulado'  ,align: 'start'},
      { text: 'KM x LT PROMEDIO', value: 'kmltpromedio'  ,align: 'start'},
      { text: 'KM x LT TENDENCIA', value: 'kmltendencia'  ,align: 'start'},
    ];
    public get TablaOdometros(){
      const a = this.activos.map( r => {
       const ix = this.odometros_array.findIndex(t => t.vin == r.vin);
       const iz = this.odometros_array.findIndex(t => t.vin== r.vin);
       const ia = this.kmlitro_array.findIndex((t:any) => t.vin == r.vin);
         const x = {} as any;
         x.vin = r.vin;
         x.marca = r.marca;
         x.modelo = r.modelo;
         x.familia = r.familia;
         x.nombre = r.nombre;
         x.cliente = r.nombreCliente;
         x.odometro = r.odometro;

         x.promedio = 0;
         x.tendencia = 0;
         x.acumulado = 0;
         if(ix > -1){
           x.promedio = Math.round(10*this.odometros_array[ix].mediaIncremento)/10;
           x.tendenciapromedio = Math.round(10*this.odometros_array[ix].ecuacionIncremento.pendiente)/10;
           x.acumulado = this.odometros_array[ix].tablaTotal.length > 0 ?Math.round(10*this.odometros_array[ix].tablaTotal[this.odometros_array[ix].tablaTotal.length-1].y as number)/10:0;            
         }
         
         x.kmltpromedio = 0;
         x.kmlttendencia = 0;
         if(ia > -1){
           x.kmltpromedio =Math.round(10*this.kmlitro_array[ia].mediaTasa)/10;
           x.kmltendencia = Math.round(10*this.kmlitro_array[ia].ecuacionTasa.pendiente)/10;
         }         
         return x;
         
      });
      return a;
   }
    

    public errors = {} as any;
    public nombreCliente = "";
    public fechaEmision = moment().format('DD-MM-YYYY');
    public emitidoPor = "";
    public telefonoContacto = "";
    public nombreContacto = "";
    public rutCliente = "";
    public grafico_avance_horometros = {} as ModeloHeatmap;
    public grafico_avance_odometros = {} as ModeloHeatmap;
    public grafico_avance_odolitros = {} as ModeloHeatmap;
    public grafico_horometro_ralenti = {} as ModeloHeatmap;
    public grafico_combinado_odometro_odolitro = {} as any;    
    public grafico_rendimiento_horometro_ralenti = {} as any;
    public grafico_rendimiento_odometro_odolitro = {} as any;
    public grafico_rendimiento_horometro_odolitro = {} as any;
    public recomendacion = "";
    public horometro_actual = [] as any[];

    public get lectura_horometro(){
        let base = [] as any[];
        //@ts-ignore
        if(this.horometros ? this.horometros.registros  : false){
            //@ts-ignore
            this.horometros.registros.map(r => { return { n : r._id, data:  r.registros as any[]} }).forEach((p: any) => {
                let d = p._id;
                let mmax = Math.max(...p.data.map( (t: any)=> t.valorFinal) as number[]);
                let ix = base.findIndex( (t: XY) => t.x == d);
                if(ix == -1){
                    base.push({ x: d, y: Math.round(10 *mmax)/10 });
                }
            });
        }
        return base.filter( (r : any) => r.y > 0);
    }

    public get lectura_odometro(){
        let base = [] as any[];
        //@ts-ignore
        if(this.odometros ? this.odometros.registros  : false){
            //@ts-ignore
            this.odometros.registros.map(r => { return { n : r._id, data:  r.registros as any[]} }).forEach((p: any) => {
                let d = p._id;
                let mmax = Math.max(...p.data.map( (t: any)=> t.valorFinal) as number[]);
                let ix = base.findIndex( (t: XY) => t.x == d);
                if(ix == -1){
                    base.push({ x: d, y: Math.round(10 *mmax)/10 });
                }
            });
        }
        return base.filter( (r : any) => r.y > 0);
    }

    public get acumulados_horometros(){
        let base = [] as XY[];
        if(this.grafico_avance_horometros.series ? this.grafico_avance_horometros.series : false){
        this.grafico_avance_horometros.series.map(r => r.data as XY[]).forEach((p) => {
            p.forEach(o =>{
                let ix = base.findIndex( (t: XY) => t.x == o.x);
                if(ix > -1){
                    base[ix].y += o.y; 
                }
                else{
                    base.push(o);
                }
            })
        });
        let retorno = {} as any;

        retorno.series = [{ name: 'Acumulado diario', data : base.map(t => Math.round(10*t.y)/10)}];
        retorno.chartOptions = {} as any;
        retorno.chartOptions.chart = { type: 'bar', id: 'horo-bar'};
        retorno.chartOptions.colors = this.colores;
        retorno.chartOptions.plotOptions = {} as any;
        retorno.chartOptions.plotOptions.bar = {} as any;
        retorno.chartOptions.plotOptions.bar.horizontal = false;
        retorno.chartOptions.dataLabels = {} as any;
        retorno.chartOptions.dataLabels.enabled = false,
        retorno.chartOptions.xaxis ={} as any;
        retorno.chartOptions.xaxis.categories = base.map(t => t.x);
        retorno.chartOptions.yaxis = {} as any;
        retorno.chartOptions.yaxis.title = {} as any;
        retorno.chartOptions.yaxis.title.text = 'Horas';
        retorno.chartOptions.tooltip = {} as any;
        retorno.chartOptions.tooltip= { formatter: function (val: number) {
                                                return  Math.round(10 * val)/10 + " (Hr)";
                                    }};
        retorno.chartOptions.title ={} as any;
        retorno.chartOptions.title.text = 'Avance horómetros agrupado por día'
        retorno.chartOptions.fill = {opacity: 1};
        return retorno;
                                        }
                                        return {};
    }

    public get acumulados_odometros(){
        let base = [] as XY[];
        if(this.grafico_avance_odometros.series ? this.grafico_avance_odometros.series : false){
            this.grafico_avance_odometros.series.map(r => r.data as XY[]).forEach((p) => {
                p.forEach(o =>{
                    let ix = base.findIndex( (t: XY) => t.x == o.x);
                    if(ix > -1){
                        base[ix].y = Math.round(10*(o.y + base[ix].y ))/10; 
                    }
                    else{
                        base.push(o);
                    }
                })
            });
            let retorno = {} as any;

            retorno.series = [{ name: 'Acumulado diario', data : base.map(t => t.y) }];
            retorno.chartOptions = {} as any;
            retorno.chartOptions.chart = { type: 'bar', id:'odo-bar'};
            retorno.chartOptions.colors = this.colores;
            retorno.chartOptions.plotOptions = {} as any;
            retorno.chartOptions.plotOptions.bar = {} as any;
            retorno.chartOptions.plotOptions.bar.horizontal = false;
            retorno.chartOptions.dataLabels = {} as any;
            retorno.chartOptions.dataLabels.enabled = false,
            retorno.chartOptions.xaxis ={} as any;
            retorno.chartOptions.xaxis.categories = base.map(t => t.x);
            retorno.chartOptions.yaxis = {} as any;
            retorno.chartOptions.yaxis.title = {} as any;
            retorno.chartOptions.yaxis.title.text = 'Kilometros';
            retorno.chartOptions.tooltip = {} as any;
            retorno.chartOptions.tooltip = { 
                                                y:{ 
                                                    formatter: function (val: number) {
                                                    return  Math.round(10 * val)/10 + " (Km)";
                                                    }
                                                }
                                            };
            retorno.chartOptions.title ={} as any;
            retorno.chartOptions.title.text = 'Avance odómetros agrupado por día'
            retorno.chartOptions.fill = {opacity: 1};
            return retorno;
        }
        return {};
    }

    public get porcentual_ralenti(){
        let t = this.grafico_rendimiento_horometro_ralenti;
        t.chart =  {
            type: 'bar',
            stacked: true,
            stackType: '100%'
          };
        t.plotOptions= {
            bar: {
              horizontal: true,
              dataLabels: {
                position: 'top',
              },
            }
        };
        t.colors = [ '#E10600','#002855','#37ABC8','#000'];
        t.legend = {
          position: 'top',
                horizontalAlign: 'left',
          offsetX: 80
        };

        t.fill = {
            opacity: 1
          
        };
        t.title = {
            text: 'Expresado en %',
            align: 'left',
            offsetX: 110
        }
        t.yaxis = [
            {
             
              stacked: true,
              opposite:false,
              
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: true,
                color: '#002855'
              },
              labels: {
               show: true,
                style: {
                   
                  colors: '#002855',
                }
              },
              title: {
                text: "",
                style: {
                  color: '#002855',
                }
              },
              tooltip: {
                enabled: true
              }
            }
          ]
          return t;
    }
    
    public get vins_activos(){
        if((this.grafico_avance_horometros.series 
            ? this.grafico_avance_horometros.series.length > 0: 
            false)
            &&
            (this.grafico_avance_odometros.series 
                ? this.grafico_avance_odometros.series.length > 0: 
                false)
        ){
            return this.grafico_avance_horometros.series.map(y => y.name).concat(this.grafico_avance_odometros.series.map(y => y.name)).filter(this.onlyUnique);
        }else{
            if(this.grafico_avance_horometros.series 
                ? this.grafico_avance_horometros.series.length > 0: 
                false){
                return this.grafico_avance_horometros.series.map(y => y.name)
            }
            if(this.grafico_avance_odometros.series 
                ? this.grafico_avance_odometros.series.length > 0: 
                false){
                return this.grafico_avance_odometros.series.map(y => y.name)
            }
            return [] as any[];
        }
        
    }

    public get activos_condicion(){
        const vvin = this.vins_activos.length;
        let options  = {
            plotOptions: {
              pie: {
                donut: {
                  labels: {
                    show: true,
                    total: {
                      show: true,
                      label: 'Total',
                      formatter: () => this.activos_vins? this.activos_vins.length : 0
                    }
                  }
                }
              }
            },
            chart: { 
              type: 'donut',        
              // width: 400,
            },
            // title: {
            //   text: 'Activos informando'
            // },
            labels: ["Informando", "Sin informar"],
            series: [vvin, (this.activos_vins.length)],
            colors:["#002855", "#E10600"],
            legend: {
              position: 'bottom',
                    horizontalAlign: 'center',
             
            }
        };
        return options;
    }

    public horometros = [] as any[];
    public vins_sin_horometro = [] as string[];
    public vins_con_horometro = [] as string[];

    public horometrosRalenti = [] as any[];
    public vins_sin_horometrosRalenti = [] as string[];
    public vins_con_horometrosRalenti = [] as string[];

    public odometros = [] as any[];
    public vins_sin_odometro = [] as string[];
    public vins_con_odometro = [] as string[];

    public odolitros = [] as any[];
    public vins_sin_odolitro = [] as string[];
    public vins_con_odolitro = [] as string[];

    public async Carga_Horometros_Por_Vin_InfluxDb(vin: string){
      const respuesta = await this.boiletService.get<string>('/api/Series/vin/horometro/'+ vin+'/'+this.desde+'/'+this.hasta, true)
                              .then((u) =>{
                                  if(!u.isError){
                                    console.log(u.content);
                                      if(u.content ? u.content.length > 0 : false){
                                          const val = u.content ?  JSON.parse(u.content) as unknown as any : null;                           
                                          return val;
                                      }
                                  }else{
                                      this.errors = u.errors;
                                      return null;
                                  }
                              });
      return respuesta;
    }
    public async Carga_HorometrosRalenti_Por_Vin_InfluxDb(vin: string){
      const respuesta = await this.boiletService.get<string>('/api/Series/vin/horometroRalenti/'+ vin+'/'+this.desde+'/'+this.hasta, true)
                              .then((u) =>{
                                  if(!u.isError){
                                    console.log(u.content);
                                      if(u.content ? u.content.length > 0 : false){
                                          const val = u.content ?  JSON.parse(u.content) as unknown as any : null;                           
                                          return val;
                                      }
                                  }else{
                                      this.errors = u.errors;
                                      return null;
                                  }
                              });
      return respuesta;
    }
    public async Carga_Odolitros_Por_Vin_InfluxDb(vin: string){
      const respuesta = await this.boiletService.get<string>('/api/Series/vin/odolitro/'+ vin+'/'+this.desde+'/'+this.hasta, true)
                              .then((u) =>{
                                  if(!u.isError){
                                    console.log(u.content);
                                      if(u.content ? u.content.length > 0 : false){
                                          const val = u.content ?  JSON.parse(u.content) as unknown as any : null;                           
                                          return val;
                                      }
                                  }else{
                                      this.errors = u.errors;
                                      return null;
                                  }
                              });
      return respuesta;
    }
    public async Carga_OdolitrosRalenti_Por_Vin_InfluxDb(vin: string){
      const respuesta = await this.boiletService.get<string>('/api/Series/vin/odolitroRalenti/'+ vin+'/'+this.desde+'/'+this.hasta, true)
                              .then((u) =>{
                                  if(!u.isError){
                                    console.log(u.content);
                                      if(u.content ? u.content.length > 0 : false){
                                          const val = u.content ?  JSON.parse(u.content) as unknown as any : null;                           
                                          return val;
                                      }
                                  }else{
                                      this.errors = u.errors;
                                      return null;
                                  }
                              });
      return respuesta;
    }
    public async Carga_Odometros_Por_Vin_InfluxDb(vin: string){
      const respuesta = await this.boiletService.get<string>('/api/Series/vin/odometro/'+ vin+'/'+this.desde+'/'+this.hasta, true)
                              .then((u) =>{
                                  if(!u.isError){
                                    console.log(u.content);
                                      if(u.content ? u.content.length > 0 : false){
                                          const val = u.content ?  JSON.parse(u.content) as unknown as any : null;                           
                                          return val;
                                      }
                                  }else{
                                      this.errors = u.errors;
                                      return null;
                                  }
                              });
      return respuesta;
    }
    public async Carga_horometroEficiencia_Por_Vin_InfluxDb(vin: string){
      const respuesta = await this.boiletService.get<string>('/api/Series/vin/horometroEficiencia/'+ vin+'/'+this.desde+'/'+this.hasta, true)
                              .then((u) =>{
                                  if(!u.isError){
                                    console.log(u.content);
                                      if(u.content ? u.content.length > 0 : false){
                                          const val = u.content ?  JSON.parse(u.content) as unknown as any : null;                           
                                          return val;
                                      }
                                  }else{
                                      this.errors = u.errors;
                                      return null;
                                  }
                              });
      return respuesta;
    }
    public async Carga_odolitroEficiencia_Por_Vin_InfluxDb(vin: string){
      const respuesta = await this.boiletService.get<string>('/api/Series/vin/odolitroEficiencia/'+ vin+'/'+this.desde+'/'+this.hasta, true)
                              .then((u) =>{
                                  if(!u.isError){
                                    console.log(u.content);
                                      if(u.content ? u.content.length > 0 : false){
                                          const val = u.content ?  JSON.parse(u.content) as unknown as any : null;                           
                                          return val;
                                      }
                                  }else{
                                      this.errors = u.errors;
                                      return null;
                                  }
                              });
      return respuesta;
    }
    public async Carga_hrlitro_Por_Vin_InfluxDb(vin: string){
      const respuesta = await this.boiletService.get<string>('/api/Series/vin/hrlitro/'+ vin+'/'+this.desde+'/'+this.hasta, true)
                              .then((u) =>{
                                  if(!u.isError){
                                    console.log(u.content);
                                      if(u.content ? u.content.length > 0 : false){
                                          const val = u.content ?  JSON.parse(u.content) as unknown as any : null;                           
                                          return val;
                                      }
                                  }else{
                                      this.errors = u.errors;
                                      return null;
                                  }
                              });
      return respuesta;
    }
    public async Carga_kmlitro_Por_Vin_InfluxDb(vin: string){
      const respuesta = await this.boiletService.get<string>('/api/Series/vin/kmlitro/'+ vin+'/'+this.desde+'/'+this.hasta, true)
                              .then((u) =>{
                                  if(!u.isError){
                                    console.log(u.content);
                                      if(u.content ? u.content.length > 0 : false){
                                          const val = u.content ?  JSON.parse(u.content) as unknown as any : null;                           
                                          return val;
                                      }
                                  }else{
                                      this.errors = u.errors;
                                      return null;
                                  }
                              });
      return respuesta;
    }

    horometro_array = [] as any[];
    horometro_ralenti_array = [] as any[];
    odometros_array = [] as any[];
    odolitro_array = [] as any[];
    odolitro_ralenti_array = [] as any[];
    horometro_eficiencia_array = [] as any[];
    odolitro_eficiencia_array = [] as any;
    kmlitro_array = [] as any[];
    hrlitro_array = [] as any;


    public async Carga_Horometros(vins: any[]){
        for(let i = 0; i < vins.length; i++){
            const respuesta = await this.Carga_Horometros_Por_Vin_InfluxDb(vins[i]);
            console.log(respuesta);
            if(respuesta != null){
                const ix = this.horometro_array.findIndex(r => r.vin == vins[i]);
                if(ix == -1){
                    this.horometro_array.push(respuesta);
                }
            }
        }
    }
    public async Carga_HorometrosRalenti(vins: any[]){
      for(let i = 0; i < vins.length; i++){
          const respuesta = await this.Carga_HorometrosRalenti_Por_Vin_InfluxDb(vins[i]);
          if(respuesta != null){
              const ix = this.horometro_ralenti_array.findIndex(r => r.vin == vins[i]);
              if(ix == -1){
                  this.horometro_ralenti_array.push(respuesta);
              }
          }
      }
    }
    public async Carga_Odometros(vins: any[]){
      for(let i = 0; i < vins.length; i++){
          const respuesta = await this.Carga_Odometros_Por_Vin_InfluxDb(vins[i]);
          if(respuesta != null){
              const ix = this.odometros_array.findIndex(r => r.vin == vins[i]);
              if(ix == -1){
                  this.odometros_array.push(respuesta);
              }
          }
      }
    }
    public async Carga_OdolitrosRalenti(vins: any[]){
      for(let i = 0; i < vins.length; i++){
          const respuesta = await this.Carga_OdolitrosRalenti_Por_Vin_InfluxDb(vins[i]);
          if(respuesta != null){
              const ix = this.odolitro_ralenti_array.findIndex(r => r.vin == vins[i]);
              if(ix == -1){
                  this.odolitro_ralenti_array.push(respuesta);
              }
          }
      }
    }
    public async Carga_Odolitros(vins: any[]){
      for(let i = 0; i < vins.length; i++){
          const respuesta = await this.Carga_Odolitros_Por_Vin_InfluxDb(vins[i]);
          if(respuesta != null){
              const ix = this.odolitro_array.findIndex(r => r.vin == vins[i]);
              if(ix == -1){
                  this.odolitro_array.push(respuesta);
              }
          }
      }
    }
    public async Carga_HorometrosEficiencia(vins: any[]){
      for(let i = 0; i < vins.length; i++){
          const respuesta = await this.Carga_horometroEficiencia_Por_Vin_InfluxDb(vins[i]);
          if(respuesta != null){
              const ix = this.horometro_eficiencia_array.findIndex(r => r.vin == vins[i]);
              if(ix == -1){
                  this.horometro_eficiencia_array.push(respuesta);
              }
          }
      }
    }
    public async Carga_OdolitrosEficiencia(vins: any[]){
      for(let i = 0; i < vins.length; i++){
          const respuesta = await this.Carga_odolitroEficiencia_Por_Vin_InfluxDb(vins[i]);
          if(respuesta != null){
              const ix = this.odolitro_eficiencia_array.findIndex((r: any) => r.vin == vins[i]);
              if(ix == -1){
                  this.odolitro_eficiencia_array.push(respuesta);
              }
          }
      }
    }
    public async Carga_HrLitro(vins: any[]){
      for(let i = 0; i < vins.length; i++){
          const respuesta = await this.Carga_hrlitro_Por_Vin_InfluxDb(vins[i]);
          if(respuesta != null){
              const ix = this.hrlitro_array.findIndex((r: any) => r == vins[i]);
              if(ix == -1){
                  this.hrlitro_array.push(respuesta);
              }
          }
      }
    }
    public async Carga_KmLitro(vins: any[]){
      for(let i = 0; i < vins.length; i++){
          const respuesta = await this.Carga_kmlitro_Por_Vin_InfluxDb(vins[i]);
          if(respuesta != null){
              const ix = this.kmlitro_array.findIndex(r => r.vin == vins[i]);
              if(ix == -1){
                  this.kmlitro_array.push(respuesta);
              }
          }
      }
    }






    public async Carga_Horometros_Por_Vin(vin: string){
        const respuesta = await this.boiletService.get<string>('/api/Activo/horometro31/activo/'+ vin, true)
                                .then((u) =>{
                                    if(!u.isError){
                                        if(u.content ? u.content.length > 0 : false){
                                            const val = u.content ?  JSON.parse(u.content) as unknown as any : null;                           
                                            return val;
                                        }
                                    }else{
                                        this.errors = u.errors;
                                        return null;
                                    }
                                });
        return respuesta;
    }
    public async Carga_HorometrosRalenti00(vins: any[]){
        const temporales = [] as any[];
        for(let i = 0; i < vins.length; i++){
            const respuesta = await this.Carga_HorometrosRalenti_Por_Vin(vins[i]);
            if(respuesta != null){
                const ix = this.vins_con_horometrosRalenti.findIndex(r => r == vins[i]);
                if(ix == -1){
                    this.vins_con_horometrosRalenti.push(vins[i]);
                }
                temporales.push(respuesta);
            }else{
                const ix = this.vins_sin_horometrosRalenti.findIndex(r => r == vins[i]);
                if(ix == -1){
                    this.vins_sin_horometrosRalenti.push(vins[i]);
                }
            }
        }
        this.horometrosRalenti = temporales;
    }
    public async Carga_HorometrosRalenti_Por_Vin(vin: string){
        const respuesta = await this.boiletService.get<string>('/api/Activo/horometroRalenti31/activo/'+ vin, true)
                                .then((u) =>{
                                    if(!u.isError){
                                        if(u.content ? u.content.length > 0 : false){
                                            const val = u.content ?  JSON.parse(u.content) as unknown as any : null;                           
                                            return val;
                                        }
                                    }else{
                                        this.errors = u.errors;
                                        return null;
                                    }
                                });
        return respuesta;
    }
    public async Carga_Odometros00(vins: any[]){
        const temporales = [] as any[];
        for(let i = 0; i < vins.length; i++){
            const respuesta = await this.Carga_Odometros_Por_Vin(vins[i]);
            if(respuesta != null){
                const ix = this.vins_con_odometro.findIndex(r => r == vins[i]);
                if(ix == -1){
                    this.vins_con_odometro.push(vins[i]);
                }
                temporales.push(respuesta);
            }else{
                const ix = this.vins_sin_odometro.findIndex(r => r == vins[i]);
                if(ix == -1){
                    this.vins_sin_odometro.push(vins[i]);
                }
            }
        }
        this.odometros = temporales;
    }
    public async Carga_Odometros_Por_Vin(vin: string){
        const respuesta = await this.boiletService.get<string>('/api/Activo/odometro31/activo/'+ vin, true)
                                .then((u) =>{
                                    if(!u.isError){
                                        if(u.content ? u.content.length > 0 : false){
                                            const val = u.content ?  JSON.parse(u.content) as unknown as any : null;                           
                                            return val;
                                        }
                                    }else{
                                        this.errors = u.errors;
                                        return null;
                                    }
                                });
        return respuesta;
    }
    public async Carga_Odolitros00(vins: any[]){
        const temporales = [] as any[];
        for(let i = 0; i < vins.length; i++){
            const respuesta = await this.Carga_Odolitros_Por_Vin(vins[i]);
            if(respuesta != null){
                const ix = this.vins_con_odolitro.findIndex(r => r == vins[i]);
                if(ix == -1){
                    this.vins_con_odolitro.push(vins[i]);
                }
                temporales.push(respuesta);
            }else{
                const ix = this.vins_sin_odolitro.findIndex(r => r == vins[i]);
                if(ix == -1){
                    this.vins_sin_odolitro.push(vins[i]);
                }
            }
        }
        this.odolitros = temporales;
    }
    public async Carga_Odolitros_Por_Vin(vin: string){
        const respuesta = await this.boiletService.get<string>('/api/Activo/odolitros31/activo/'+ vin, true)
                                .then((u) =>{
                                    if(!u.isError){
                                        if(u.content ? u.content.length > 0 : false){
                                            const val = u.content ?  JSON.parse(u.content) as unknown as any : null;                           
                                            return val;
                                        }
                                    }else{
                                        this.errors = u.errors;
                                        return null;
                                    }
                                });
        return respuesta;
    }


    public Crea_Grafico_Avance_Horometros(){
        if(this.horometros.length > 0){
            const filterData = this.horometros.filter((x: any[]) => x[0]);
            if(filterData.length > 0){
                const series = filterData.map((r: any[]) => {
                                    const name =r[0]._id;
                                    const contador = 30;
                                    const ahora = new Date;
                                    const listado = [] as XYzz[];
                                    const listadoConsolidado = [] as XY[];
                                    let valor_maximo = Math.max(...r[0].registros.map((o: any) => o.valorInicial));
                                    for(let i = contador; i > -1; i--){
                                        const fechaABuscar = moment(ahora).add(-i,'day').toDate().toLocaleDateString();
                                        const busqueda =r[0].registros.findIndex((u : any) => moment.utc(u.dia.$date).toDate().toLocaleDateString() == fechaABuscar);
                                        if(busqueda > -1){
                                            valor_maximo = r[0].registros[busqueda].valorInicial;
                                            const lt = {} as XYzz;
                                            lt.x = moment(ahora).add(-i,'day').format('DD-MM');
                                            lt.y =r[0].registros[busqueda].delta;
                                            lt.zz =r[0].registros[busqueda].valorInicial;
                                            lt.tt =  moment(ahora).add(-i,'day').unix();
                                            listado.push(lt);
                                        }else{
                                          const lt = {} as XYzz;
                                          lt.x = moment(ahora).add(-i,'day').format('DD-MM');
                                          lt.y = 0;
                                          lt.zz = valor_maximo;
                                          lt.tt =  moment(ahora).add(-i,'day').unix();
                                          listado.push(lt);
                                        }
                                    }
                                    for(let ii = 1; ii < listado.length; ii++){
                                        let consolidado = {} as XY;
                                        const anterior = listado[ii-1].zz;
                                        const actual = listado[ii].zz;
                                        const diferencia = anterior != 0 ? (actual - anterior) : 0;
                                        const delta = listado[ii].y;
                                        const direccionMayor = delta > diferencia ? 1 : -1;
                                        if(direccionMayor == 1){
                                            consolidado.y = delta;
                                            consolidado.x = listado[ii].x.substring(0,5);
                                            listadoConsolidado.push(consolidado);
                                        }else if(direccionMayor == -1){
                                            if(actual == 0){
                                            let contadorAvance = 1;                              
                                            let diferencia_nueva = 0;
                                            for(let j = ii +1; j < listado.length; j++){
                                                let  avanzando = listado[j].zz;
                                                if(avanzando == 0){
                                                if(j < (listado.length-2)){
                                                    contadorAvance++;
                                                }else{
                                                    for(let k = ii+1; k <= j; k++){
                                                    let consolidado2 = {} as XY;
                                                    consolidado2.y = 0;
                                                    consolidado2.x = listado[k].x.substring(0,5);
                                                    listadoConsolidado.push(consolidado2);
                                                    }
                                                    ii = j;
                                                }                                    
                                                }
                                                else{
                                                for(let k = ii+1; k <= j; k++){
                                                    let consolidado2 = {} as XY;
                                                    diferencia_nueva = listado[ii].zz> 0 ? avanzando - listado[ii].zz :0; 
                                                    consolidado2.y = diferencia_nueva/(contadorAvance ?? 1);
                                                    consolidado2.x = listado[k].x.substring(0,5);
                                                    listadoConsolidado.push(consolidado2);
                                                }
                                                ii = j;
                                                }
                                            }
                                            }else{
                                            consolidado.y = diferencia;
                                            consolidado.x = listado[ii].x.substring(0,5);
                                            listadoConsolidado.push(consolidado);
                                            }                              
                                        }                            
                                    }
                                    let modelo = {} as ModeloSeries;
                                    modelo.name = name;
                                    modelo.data = listadoConsolidado;
                                    return modelo;
                                });
                const chart = { heigth : this.altura , type : 'heatmap',  id: 'horo-heat' } as ModeloOpcionesChart;
                const dataLabels = { enabled: false } as ModeloDataLabels;
                const colors = ["#A0A0A0"];
                const title = { text: this.tituloHorometro };
                const hmap = {
                                series: series,
                                chart: chart,
                                dataLabels: dataLabels,
                                colors: colors,
                                title: title,
                                plotOptions:{
                                        heatmap: {
                                            useFillColorAsStroke: false,
                                            shadeIntensity: 0,
                                            colorScale :{
                                                ranges: [
                                                    {
                                                        from:0,
                                                        to:1.0,
                                                        name: 'Menos de 1 hora ',
                                                        color:'#A0A0A0'
                                                    },
                                                    {
                                                        from:1.1,
                                                        to: 4.0,
                                                        name: 'De 1 a 4 horas',
                                                        color: '#6882a9'
                                                    },
                                                    {
                                                        from:4.1,
                                                        to: 8.0,
                                                        name: ' De 4 a 8 horas',
                                                        color: '#83a9e8'
                                                    },
                                                    {
                                                        from:8.1,
                                                        to: 12.0,
                                                        name: ' De 8 a 12 horas ',
                                                        color: '#3f79d0'
                                                    },
                                                    {
                                                        from:12.1,
                                                        to: 16.0,
                                                        name: ' De 12 a 16 horas ',
                                                        color: '#002855'
                                                    },
                                                    {
                                                        from:16.1,
                                                        to: 18.0,
                                                        name: 'De 17 as 18 horas',
                                                        color: '#ec9700'
                                                    },
                                                    {
                                                        from:18.1,  
                                                        to: 24.0,                                                      
                                                        name: ' De 19 a 24 horas ',
                                                        color: '#e10600'
                                                    },
                                                    {
                                                        from:24.1,  
                                                        to: Infinity,                                                      
                                                        name: 'Lectura anómala ',
                                                        color: '#af009c'
                                                    }
                                                ],
                                                min:0,
                                                
                                        }
                                    }
                                },
                                tooltip:{ 
                                    y:{
                                        formatter: function (val: number) {
                                            return  Math.round(10 * val)/10 + " (Hr)";
                                        }
                                    }
                                },
                                yaxis:{
                                    decimalsInFloat:1,
                                }
                            } as unknown as ModeloHeatmap;
                this.grafico_avance_horometros = hmap;
            }
        }
    }
    public Crea_Grafico_Avance_Odometro(){
        if(this.odometros.length > 0){
            const filterData = this.odometros.filter((x: any[]) => x[0]);
            if(filterData.length > 0){
                const series = filterData.map( r => {
                    const name =r[0]._id;
                    const contador = 30;
                    const ahora = new Date;
                    const listado = [] as XYzz[];
                    const listadoConsolidado = [] as XY[];
                    let valor_maximo = Math.max(...r[0].registros.map((o: any) => o.valorInicial));
                    for(let i = contador; i > -1; i--){
                          const fechaABuscar = moment(ahora).add(-i,'day').toDate().toLocaleDateString();
                          const busqueda =r[0].registros.findIndex((u : any) => moment.utc(u.dia.$date).toDate().toLocaleDateString() == fechaABuscar);
                          if(busqueda > -1){
                            valor_maximo = r[0].registros[busqueda].valorInicial;
                            const lt = {} as XYzz;
                            lt.x = moment(ahora).add(-i,'day').format('DD-MM');
                            lt.y =r[0].registros[busqueda].delta;
                            lt.zz =r[0].registros[busqueda].valorInicial;
                            lt.tt =  moment(ahora).add(-i,'day').unix();
                            listado.push(lt);
                        }else{
                          const lt = {} as XYzz;
                          lt.x = moment(ahora).add(-i,'day').format('DD-MM');;
                          lt.y = 0;
                          lt.zz = valor_maximo;
                          lt.tt =  moment(ahora).add(-i,'day').unix();
                          listado.push(lt);
                        }
                    }

                    for(let ii = 1; ii < listado.length; ii++){
                        let consolidado = {} as XY;
                        const anterior = listado[ii-1].zz;
                        const actual = listado[ii].zz;
                        const diferencia = anterior != 0 ? (actual - anterior) : 0;
                        const delta = listado[ii].y;
                        const direccionMayor = delta > diferencia ? 1 : -1;
                        if(direccionMayor == 1){
                          consolidado.y = delta;
                          consolidado.x = listado[ii].x.substring(0,5);
                          listadoConsolidado.push(consolidado);
                        }else if(direccionMayor == -1){
                          if(actual == 0){
                            let contadorAvance = 1;                              
                            let diferencia_nueva = 0;
                            for(let j = ii +1; j < listado.length; j++){
                              let  avanzando = listado[j].zz;
                              if(avanzando == 0){
                                if(j < (listado.length-2)){
                                  contadorAvance++;
                                }else{
                                  for(let k = ii+1; k <= j; k++){
                                    let consolidado2 = {} as XY;
                                    consolidado2.y = 0;
                                    consolidado2.x = listado[k].x.substring(0,5);
                                    listadoConsolidado.push(consolidado2);
                                  }
                                  ii = j;
                                }                                    
                              }
                              else{
                                for(let k = ii+1; k <= j; k++){
                                  let consolidado2 = {} as XY;
                                  diferencia_nueva = listado[ii].zz> 0 ? avanzando - listado[ii].zz :0; 
                                  consolidado2.y = diferencia_nueva/(contadorAvance ?? 1);
                                  consolidado2.x = listado[k].x.substring(0,5);
                                  listadoConsolidado.push(consolidado2);
                                }
                                ii = j;
                              }
                            }
                          }else{
                            consolidado.y = diferencia;
                            consolidado.x = listado[ii].x.substring(0,5);
                            listadoConsolidado.push(consolidado);
                          }                              
                        }                            
                    }
                    let modelo = {} as ModeloSeries;;
                    modelo.name = name;
                    modelo.data = listadoConsolidado;
                    return modelo;
                  });
                  const chart = { heigth : this.altura , type : 'heatmap' , id:'odro-heat'} as ModeloOpcionesChart;
                  const dataLabels = { enabled: false } as ModeloDataLabels;
                  const colors = this.colores;
                  const title = { text: this.tituloOdometro };
                  const hmap = {
                                  series: series,
                                  chart: chart,
                                  dataLabels: dataLabels,
                                  colors: colors,
                                  title: title,
                                  plotOptions:{
                                    heatmap: {
                                        useFillColorAsStroke: false,
                                        shadeIntensity: 0,
                                        colorScale :{
                                            ranges: [
                                                {
                                                    from:0,
                                                    to:99,
                                                    name: 'Menos de 100 Km ',
                                                    color:'#A0A0A0'
                                                },
                                                {
                                                    from:100,
                                                    to: 400,
                                                    name: 'De 100 a 400 Km',
                                                    color: '#6882a9'
                                                },
                                                {
                                                    from:401,
                                                    to: 800,
                                                    name: ' De 400 a 800 Km',
                                                    color: '#83a9e8'
                                                },
                                                {
                                                    from:801,
                                                    to: 1200,
                                                    name: ' De 800 a 1200 Km ',
                                                    color: '#3f79d0'
                                                },
                                                {
                                                    from:1201,
                                                    to: 1600,
                                                    name: ' De 1200 a 1600 Km ',
                                                    color: '#002855'
                                                },
                                                {
                                                    from:1601,
                                                    to: 1800,
                                                    name: 'De 1600 as 1800 Km',
                                                    color: '#ec9700'
                                                },
                                                {
                                                    from:1801,  
                                                    to: 2400,                                                      
                                                    name: ' De 1800 a 2400 Km ',
                                                    color: '#e10600'
                                                },
                                                {
                                                    from:2401,  
                                                    to: Infinity,                                                      
                                                    name: 'Lectura anómala ',
                                                    color: '#af009c'
                                                }
                                            ],
                                            min:0,
                                            
                                    }
                                }
                            },
                                  tooltip:{ 
                                    y:{
                                        formatter: function (val: number) {
                                            return  Math.round(10 * val)/10 + " (Km)";
                                        }
                                    }
                                }
                                } as unknown as ModeloHeatmap;
                    this.grafico_avance_odometros = hmap;
            }
        }
    }
    public Crea_Grafico_Avance_Odolitro(){
        if(this.odolitros.length > 0){
            const filterData = this.odolitros.filter((x: any[]) => x[0]);
            if(filterData.length > 0){
                const series = filterData.map( r => {
                    const name =r[0]._id;
                    const contador = 30;
                    const ahora = new Date;
                    const listado = [] as XYzz[];
                    const listadoConsolidado = [] as XY[];
                    let valor_maximo = Math.max(...r[0].registros.map((o: any) => o.valorInicial));
                    for(let i = contador; i > -1; i--){
                          const fechaABuscar = moment(ahora).add(-i,'day').toDate().toLocaleDateString();
                          const busqueda =r[0].registros.findIndex((u : any) => moment.utc(u.dia.$date).toDate().toLocaleDateString() == fechaABuscar);
                          if(busqueda > -1){
                            valor_maximo = r[0].registros[busqueda].valorInicial;
                            const lt = {} as XYzz;
                            lt.x = moment(ahora).add(-i,'day').format('DD-MM');
                            lt.y =r[0].registros[busqueda].delta;
                            lt.zz =r[0].registros[busqueda].valorInicial;
                            lt.tt =  moment(ahora).add(-i,'day').unix();
                            listado.push(lt);
                          }else{
                            const lt = {} as XYzz;
                            lt.x = moment(ahora).add(-i,'day').format('DD-MM');
                            lt.y = 0;
                            lt.zz = valor_maximo;
                            lt.tt =  moment(ahora).add(-i,'day').unix();
                            listado.push(lt);
                          }
                    }
                    for(let ii = 1; ii < listado.length; ii++){
                        let consolidado = {} as XY;
                        const anterior = listado[ii-1].zz;
                        const actual = listado[ii].zz;
                        const diferencia = anterior != 0 ? (actual - anterior) : 0;
                        const delta = listado[ii].y;
                        const direccionMayor = delta > diferencia ? 1 : -1;
                        if(direccionMayor == 1){
                          consolidado.y = delta;
                          consolidado.x = listado[ii].x.substring(0,5);
                          listadoConsolidado.push(consolidado);
                        }else if(direccionMayor == -1){
                          if(actual == 0){
                            let contadorAvance = 1;                              
                            let diferencia_nueva = 0;
                            for(let j = ii +1; j < listado.length; j++){
                              let  avanzando = listado[j].zz;
                              if(avanzando == 0){
                                if(j < (listado.length-2)){
                                  contadorAvance++;
                                }else{
                                  for(let k = ii+1; k <= j; k++){
                                    let consolidado2 = {} as XY;
                                    consolidado2.y = 0;
                                    consolidado2.x = listado[k].x.substring(0,5);
                                    listadoConsolidado.push(consolidado2);
                                  }
                                  ii = j;
                                }                                    
                              }
                              else{
                                for(let k = ii+1; k <= j; k++){
                                  let consolidado2 = {} as XY;
                                  diferencia_nueva = listado[ii].zz> 0 ? avanzando - listado[ii].zz :0; 
                                  consolidado2.y = diferencia_nueva/(contadorAvance ?? 1);
                                  consolidado2.x = listado[k].x.substring(0,5);
                                  listadoConsolidado.push(consolidado2);
                                }
                                ii = j;
                              }
                            }
                          }else{
                            consolidado.y = diferencia;
                            consolidado.x = listado[ii].x.substring(0,5);
                            listadoConsolidado.push(consolidado);
                          }                              
                        }                            
                    }
                    let modelo = {} as ModeloSeries;;
                    modelo.name = name;
                    modelo.data = listadoConsolidado;
                    return modelo;
                  });
                  const chart = { heigth : this.altura , type : 'heatmap' } as ModeloOpcionesChart;
                  const dataLabels = { enabled: false } as ModeloDataLabels;
                  const colors = this.colores;
                  const title = { text: this.tituloHorometro };
                  const hmap = {
                                  series: series,
                                  chart: chart,
                                  dataLabels: dataLabels,
                                  colors: colors,
                                  title: title
                                } as unknown as ModeloHeatmap;
                this.grafico_avance_odolitros = hmap;               

            }
        }
    }
    public Crea_Grafico_Avance_Horometro_Ralenti(){
        if(this.horometrosRalenti.length > 0){
            const filterData = this.horometrosRalenti.filter((x: any[]) => x[0]);
            if(filterData.length > 0){
                const series = filterData.map( r => {
                    const name =r[0]._id;
                    const contador = 30;
                    const ahora = new Date;
                    const listado = [] as XYzz[];
                    const listadoConsolidado = [] as XY[];
                    let valor_maximo = Math.max(...r[0].registros.map((o: any) => o.valorInicial));
                    for(let i = contador; i > -1; i--){
                          const fechaABuscar = moment(ahora).add(-i,'day').toDate().toLocaleDateString();
                          const busqueda =r[0].registros.findIndex((u : any) => moment.utc(u.dia.$date).toDate().toLocaleDateString() == fechaABuscar);
                          if(busqueda > -1){
                            valor_maximo = r[0].registros[busqueda].valorInicial;
                            const lt = {} as XYzz;
                            lt.x = moment(ahora).add(-i,'day').format('DD-MM');
                            lt.y =r[0].registros[busqueda].delta;
                            lt.zz =r[0].registros[busqueda].valorInicial;
                            lt.tt =  moment(ahora).add(-i,'day').unix();
                            listado.push(lt);
                        }else{
                          const lt = {} as XYzz;
                          lt.x = moment(ahora).add(-i,'day').format('DD-MM');
                          lt.y = 0;
                          lt.zz = valor_maximo;
                          lt.tt =  moment(ahora).add(-i,'day').unix();
                          listado.push(lt);
                        }
                    }
                    console.log(listado.length)
                    for(let ii = 1; ii < listado.length; ii++){
                        let consolidado = {} as XY;
                        const anterior = listado[ii-1].zz;
                        const actual = listado[ii].zz;
                        const diferencia = anterior != 0 ? (actual - anterior) : 0;
                        const delta = listado[ii].y;
                        const direccionMayor = delta > diferencia ? 1 : -1;
                        if(direccionMayor == 1){
                          consolidado.y = delta;
                          consolidado.x = listado[ii].x.substring(0,5);
                          listadoConsolidado.push(consolidado);
                        }else if(direccionMayor == -1){
                          if(actual == 0){
                            let contadorAvance = 1;                              
                            let diferencia_nueva = 0;
                            for(let j = ii +1; j < listado.length; j++){
                              let  avanzando = listado[j].zz;
                              if(avanzando == 0){
                                if(j < (listado.length-2)){
                                  contadorAvance++;
                                }else{
                                  for(let k = ii+1; k <= j; k++){
                                    let consolidado2 = {} as XY;
                                    consolidado2.y = 0;
                                    consolidado2.x = listado[k].x.substring(0,5);
                                    listadoConsolidado.push(consolidado2);
                                  }
                                  ii = j;
                                }                                    
                              }
                              else{
                                for(let k = ii+1; k <= j; k++){
                                  let consolidado2 = {} as XY;
                                  diferencia_nueva = listado[ii].zz> 0 ? avanzando - listado[ii].zz :0; 
                                  consolidado2.y = diferencia_nueva/(contadorAvance ?? 1);
                                  consolidado2.x = listado[k].x.substring(0,5);
                                  listadoConsolidado.push(consolidado2);
                                }
                                ii = j;
                              }
                            }
                          }else{
                            consolidado.y = diferencia;
                            consolidado.x = listado[ii].x.substring(0,5);
                            listadoConsolidado.push(consolidado);
                          }                              
                        }                            
                    }
                    let modelo = {} as ModeloSeries;;
                    modelo.name = name;
                    modelo.data = listadoConsolidado;
                    return modelo;
                  });
                  const chart = { type: 'bar', stacked: true, stackType: '100%' } as ModeloOpcionesChart;
                  const dataLabels = { enabled: false } as ModeloDataLabels;
                //   const colors = this.colores;
                  const title = { text: '% Rendimiento' };
                  const hmap = {
                                  series: series,
                                  chart: chart,
                                  dataLabels: dataLabels,
                                  title: title,
                                  plotOptions: {
                                    bar: {
                                      horizontal: true,
                                    },
                                  },
                                  stroke: {
                                    width: 1,
                                    colors: ['#fff']
                                  },
                                  xaxis: {
                                    categories:( (this.grafico_avance_horometros.series.filter((dd ) => {
                                                      const u = dd.data.map((oo: any) => oo.y).reduce((a: number, b: number) => a+b,0);
                                                      return u > 0
                                                  }).map(rt => rt.name).concat( 
                                                    this.grafico_horometro_ralenti.series ?
                                                this.grafico_horometro_ralenti.series
                                                    .filter((dd: any ) => {
                                                  const u = dd.data.map((oo: any) => oo.y).reduce((a: number, b: number) => a+b,0);
                                                  return u > 0
                                              }).map((rt: any) => rt.name) : [])) as string[]).filter(this.onlyUnique).sort(),
                                  },
                                  tooltip: {
                                    y: {
                                      formatter:  (val : any) =>{
                                        return val + "K"
                                      }
                                    }
                                  },
                                  fill: {
                                    opacity: 1
                                  
                                  },
                                  colors: ['#002855', '#E10600'],
                                  legend: {
                                    position: 'top',
                                          horizontalAlign: 'left',
                                    offsetX: 40
                                  }
                                } as unknown as ModeloHeatmap;
                this.grafico_horometro_ralenti = hmap;

            }
        }
    }
    public Crea_Grafico_Avance_Rendmiento_Horometro_Ralenti(){
        let activos_horometro = this.grafico_avance_horometros.series.map((dd ) => {
            const vin = dd.name
            return {
               vin: vin, 
              suma : dd.data.map((oo: any) => oo.y).reduce((a: number, b: number) => a+b,0)
            };
        }).filter(tyr => tyr.suma > 0);
        let activos_ralenti = this.grafico_horometro_ralenti.series.map((dd: any) => {
            const vin = dd.name
            return {
              vin: vin, 
              suma : dd.data.map((oo: any) => oo.y).reduce((a: number, b: number) => a+b,0)
            };
        }).filter((tyr: any) => tyr.suma > 0);
        let vins = activos_horometro.filter(rttp => rttp.suma > 0)
                                   .concat(activos_ralenti.filter((eerd: any) => eerd.suma > 0))
                                   .map(ghh => ghh.vin)
                                   .filter(this.onlyUnique);
        let horoetrosXX = [] as any[];
        let horometrosRalentiXX = [] as any[];   
        for(let g = 0; g < vins.length; g++){
            const horo = activos_horometro.findIndex( rp => rp.vin  == vins[g]);
            const rale = activos_ralenti.findIndex( (rp: any) => rp.vin  == vins[g]);
            if(horo > -1){
                horoetrosXX.push( {y: Math.round(10*activos_horometro[horo].suma)/10, x: vins[g]});
            }
            if(horo == -1){
              horoetrosXX.push({y: 0, x: vins[g]});
            }
             
            if(rale > -1){
                horoetrosXX[horoetrosXX.length-1].y = Math.round(10*horoetrosXX[horoetrosXX.length-1].y - activos_ralenti[rale].suma)/10;
                horometrosRalentiXX.push({ y: Math.round(10*activos_ralenti[rale].suma)/10, x:vins[g]});
            }
            if(rale == -1){
              horometrosRalentiXX.push({ y: 0, x:vins[g]});
            }
         }  
        let data ={
            series: [
             {
               name: 'Ralentí',
            //    type: 'bar',
               data: horometrosRalentiXX
             },
             {
              name: 'Horómetro',
            //   type: 'bar',
              data: horoetrosXX
            },]
        }; 
        const chartOptions = {
            chart: {
              type: 'bar',
              height: this.altura*2,
              stacked: true,
             //  stackType: '100%'
            },
            plotOptions: {
             bar: {
               horizontal: true,
               dataLabels: {
                 position: 'top',
               },
             }
           },
           
           dataLabels: {
             enabled: true
           },
            stroke: {
              width: [0, 0, 0],
              colors: ['#000']
            },
            title: {
              text: 'Expresado en horas acumuladas',
              align: 'left',
              offsetX: 110
            },
            xaxis: {
              categories: vins,
            },
            yaxis: [
             {
              
               stacked: true,
               opposite:false,
               
               axisTicks: {
                 show: true,
               },
               axisBorder: {
                 show: true,
                 color: '#002855'
               },
               labels: {
                show: true,
                 style: {
                    
                   colors: '#002855',
                 }
               },
               title: {
                 text: "",
                 style: {
                   color: '#002855',
                 }
               },
               tooltip: {
                 enabled: true
               }
             }
             
             
           ],
           tooltip: {
             y: {
               formatter:  (val : any,  series: any) =>{
                 return val + ' (Hr)' 
               }
             }
            },
            fill: {
              opacity: 1
            
            },
            colors: [ '#E10600','#002855','#37ABC8','#000'],
            legend: {
              position: 'top',
                    horizontalAlign: 'left',
              offsetX: 80
            }
        };
        this.grafico_rendimiento_horometro_ralenti = { series: data, chartOptions: chartOptions };                                  
    }
    public Crea_Grafico_Avance_Rendimiento_Odometro_Combustible(){
        
        let activos_odolitro = this.grafico_avance_odolitros.series ? this.grafico_avance_odolitros.series.map((dd: any ) => {
            const vin = dd.name
            return {
              vin: vin, 
              suma : dd.data.map((oo: any) => oo.y).reduce((a: number, b: number) => a+b,0)
            };
        }).filter((tyr: any) => tyr.suma > 0) : [] as any[];
        let activos_odometro = this.grafico_avance_odometros.series ? this.grafico_avance_odometros.series.map((dd ) => {
            const vin = dd.name
            return {
                vin: vin, 
                suma : dd.data.map((oo: any) => oo.y).reduce((a: number, b: number) => a+b,0)
            };
        }).filter(tyr => tyr.suma > 0) : [] as any[];
    
        let vins = activos_odometro.filter(rt => activos_odolitro.findIndex((uu: any) => uu.vin == rt.vin && uu.suma >= 1) > -1)
            .filter(xxx => xxx.suma >= 1)
            .map(ghh => ghh.vin)
            .filter(this.onlyUnique);
        let razon = [] as number[];
        for(let g = 0; g < vins.length; g++){         
            const odolitro = activos_odolitro.findIndex( (rp: any) => rp.vin  == vins[g]);
            const odometro = activos_odometro.findIndex( rp => rp.vin  == vins[g]);          
            if(odolitro > -1){
              razon.push( Math.round(10*(activos_odometro[odometro].suma / activos_odolitro[odolitro].suma))/10);
            }        
        }
        let data ={
            series: [{
              name: 'Rendimiento',
              data: razon
            }]
        };
        const chartOptions = {
            chart: {
                type: 'bar',            
                stacked: false,                
            },
            dataLabels: {
                enabled: true
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                },
            },
            stroke: {
                width: 1,
                colors: ['#fff']
            },
            title: {
                text: 'Odómetro vs Odólitro (Km/Lt)'
            },
            xaxis: {
                categories:vins,
            },
            tooltip: {
                y: {
                    formatter:  (val : any) =>{
                        return val + " (Km/Lt))"
                    }
                }
            },
            fill: {
                opacity: 1
            
            },
            colors: ['#002855', '#E10600'],
            legend: {
                position: 'top',
                    horizontalAlign: 'left',
                offsetX: 40
            }
        };
        this.grafico_rendimiento_odometro_odolitro = {  data: data, chartOptions: chartOptions };
    }
    public Crea_Grafico_Avance_Rendimiento_Horometro_Combustible(){
        
        let activos_odolitro = this.grafico_avance_odolitros.series ? this.grafico_avance_odolitros.series.map((dd: any ) => {
                const vin = dd.name
                return {
                vin: vin, 
                suma : dd.data.map((oo: any) => oo.y).reduce((a: number, b: number) => a+b,0)
                };
            }).filter((tyr: any) => tyr.suma > 0) : [] as any[];
        let activos_horometro = this.grafico_avance_horometros.series ? this.grafico_avance_horometros.series.map((dd ) => {
                const vin = dd.name
                return {
                    vin: vin, 
                    suma : dd.data.map((oo: any) => oo.y).reduce((a: number, b: number) => a+b,0)
                };
            }).filter(tyr => tyr.suma > 0) : [] as any[];
        
        let vins = activos_horometro.filter(rt => activos_odolitro.findIndex((uu: any) => uu.vin == rt.vin && uu.suma >= 1) > -1)
            .filter(xxx => xxx.suma >= 1)
            .map(ghh => ghh.vin)
            .filter(this.onlyUnique);
        let razon = [] as number[];
        for(let g = 0; g < vins.length; g++){         
            const odolitro = activos_odolitro.findIndex( (rp: any) => rp.vin  == vins[g]);
            const horometro = activos_horometro.findIndex( rp => rp.vin  == vins[g]);          
            if(odolitro > -1){
              razon.push( Math.round(10*(activos_horometro[horometro].suma / activos_odolitro[odolitro].suma))/10);
            }        
        }
        let data ={
            series: [{
              name: 'Rendimiento',
              data: razon
            }]
        };
        const chartOptions = {
        chart: {
            type: 'bar',
           
            stacked: false,
            
        },
        dataLabels: {
            enabled: true
        },
        plotOptions: {
            bar: {
            horizontal: true,
            },
        },
        stroke: {
            width: 1,
            colors: ['#fff']
        },
        title: {
            text: 'Horómetro vs Odólitro (Hr/Lt)'
        },
        xaxis: {
            categories:vins,
        },
        tooltip: {
            y: {
            formatter:  (val : any) =>{
                return val + " (Hr/Lt))"
            }
            }
        },
        fill: {
            opacity: 1
        
        },
        colors: ['#002855', '#E10600'],
        legend: {
            position: 'top',
                horizontalAlign: 'left',
            offsetX: 40
        }
        };
        this.grafico_rendimiento_horometro_odolitro = { data: data, chartOptions: chartOptions };
    }

    public get fechaGeneracion(){
        return moment().local().format('DD-MM-yyyy');
    }
    onlyUnique(value: any, index:any, self:any) {
        return self.indexOf(value) === index;
    }

    exportToPDF() {
        console.log(this.$refs.reporte)
        // html2pdf(this.$refs.reporte, {
        //     margin: 0,
        //     filename: "document.pdf",
        //     image: { type: "jpeg", quality: 0.98 },
        //     html2canvas: { dpi: 96, letterRendering: true, scale:2 },
        //     jsPDF: { unit: "cm", format: "A4", orientation: "landscape" },
        // });


        const opt = {
            margin: 0,
            filename: "document.pdf",
            image: { type: "jpeg", quality: 0.98 },
            html2canvas: { dpi: 96, letterRendering: true, scale:2 },
            jsPDF: { unit: "cm", format: "A4", orientation: "landscape" },
        };

        html2pdf().set(opt).from(this.$refs.reporte).save();
    }
}