import { IconosSvgStore } from '@/gestion/stores/assets/iconos';
import BoiletComponentBase from '@/shared/application/boilet-component-base';
import { RecomendacionCliente } from '@/shared/models/shared/consolidados/RecomendacionCliente';
import moment from 'moment';
import { Component } from 'vue-property-decorator';



@Component
export default class RankingCodigosComponent extends BoiletComponentBase{
    public selector = 1;
    public subselector = 10;
    public get desde(){
        return moment(this.desdeD).utc();
    };
    public get hasta(){
        return moment(this.hastaH).utc();
    };
    // public hasta=  moment.utc();
    public desdeC = moment.utc().add(-14,'day').toDate().toISOString().substr(0, 10);
    public hastaC=  moment.utc().toDate().toISOString().substr(0, 10);
    get desdeD (){
        return this.desdeC;
    }
    get hastaH(){
        return this.hastaC;
    }
    public menuDesde = false;
    public menuHasta = false;
    public recomendaciones = [] as any[];
    public diccionarioJdlink = [] as any[];
    public diccionarioGeotab = [] as any[];
    public jdlink = [] as any[];
    public geotab = [] as any[];
    public errors = [] as any[];
    public switchVacios = true;
    public switchF1 = true;
    public switchF2 = true;
    public m_organizacion = [] as any[];
    public m_spn = [] as any[];
    public m_fmi = [] as any[];
    get M_Organizacion(){
        const jd = this.jdlink.filter((r:any) =>{
            return (
                    (this.m_fmi.length > 0 ? this.m_fmi.includes((r.codigoModoDeFalla != undefined && r.codigoModoDeFalla != null ? Number.parseInt(r.codigoModoDeFalla) : -1)) : true)
                    &&
                    (this.m_spn.length > 0 ? this.m_spn.includes((r.codigo != undefined && r.codigo != null ? Number.parseInt(r.codigo): -1)) : true)
            )
        }).map( (r:any) => (r.cliente ? r.cliente : 'SIN INFORMACIÓN')).filter(this.onlyUnique);
        const gt = this.geotab.filter((r:any) =>{
            return (
                    (this.m_fmi.length > 0 ? this.m_fmi.includes((r.codigoModoDeFalla != undefined && r.codigoModoDeFalla != null ? Number.parseInt(r.codigoModoDeFalla) : -1)) : true)
                    &&
                    (this.m_spn.length > 0 ? this.m_spn.includes((r.codigo != undefined && r.codigo != null ? Number.parseInt(r.codigo): -1)) : true)
            )
        }).map((r:any) => (r.cliente ? r.cliente : 'SIN INFORMACIÓN')).filter(this.onlyUnique);
        return jd.concat(gt).filter(this.onlyUnique).sort((a:string,b:string) => { return (a >b ? 1 : a < b ? -1 : 0)});  
    }
    get M_Fmi(){
        const jd = this.jdlink.filter((r:any) =>{
            return (
                    (this.m_organizacion.length > 0 ? this.m_organizacion.includes((r.cliente ? r.cliente : 'SIN INFORMACIÓN')) : true)
                    &&
                    (this.m_spn.length > 0 ? this.m_spn.includes((r.codigo != undefined && r.codigo != null ? Number.parseInt(r.codigo): -1)) : true)
            )
        }).map( (r:any) => (r.codigoModoDeFalla != undefined && r.codigoModoDeFalla != null ? Number.parseInt(r.codigoModoDeFalla) : -1)).filter(this.onlyUnique);
        const gt = this.geotab.filter((r:any) =>{
            return (
                (this.m_organizacion.length > 0 ? this.m_organizacion.includes((r.cliente ? r.cliente : 'SIN INFORMACIÓN')) : true)
                    &&
                    (this.m_spn.length > 0 ? this.m_spn.includes((r.codigo != undefined && r.codigo != null ? Number.parseInt(r.codigo): -1)) : true)
            )
        }).map( (r:any) => (r.codigoModoDeFalla != undefined && r.codigoModoDeFalla != null ? Number.parseInt(r.codigoModoDeFalla): -1)).filter(this.onlyUnique);
        return jd.concat(gt).filter(this.onlyUnique).sort((a:number,b:number) => { return (a >b ? 1 : a < b ? -1 : 0)});        
    }

    get M_Spn(){
        const jd = this.jdlink.filter((r:any) =>{
            return (
                    (this.m_organizacion.length > 0 ? this.m_organizacion.includes((r.cliente ? r.cliente : 'SIN INFORMACIÓN')) : true)
                    &&
                    (this.m_fmi.length > 0 ? this.m_fmi.includes((r.codigoModoDeFalla != undefined && r.codigoModoDeFalla != null ? Number.parseInt(r.codigoModoDeFalla) : -1)) : true)
            )
        }).map( (r:any) => (r.codigo != undefined && r.codigo != null ? Number.parseInt(r.codigo) : -1)).filter(this.onlyUnique);
        const gt = this.geotab.filter((r:any) =>{
            return (
                    (this.m_organizacion.length > 0 ? this.m_organizacion.includes((r.cliente ? r.cliente : 'SIN INFORMACIÓN')) : true)
                    &&
                    (this.m_fmi.length > 0 ? this.m_fmi.includes((r.codigoModoDeFalla != undefined && r.codigoModoDeFalla != null ? Number.parseInt(r.codigoModoDeFalla) : -1)) : true)
            )
        }).map( (r:any) => (r.codigo != undefined && r.codigo != null ? Number.parseInt(r.codigo): -1)).filter(this.onlyUnique);
        return jd.concat(gt).filter(this.onlyUnique).sort((a:number,b:number) => { return (a >b ? 1 : a < b ? -1 : 0)});        
    }
    async mounted(){
        this.boilet.isLoading =true;
        await this.GetDiccionarioGeotab();
        await this.GetDiccionarioJdlink();
        await this.GetDtcJdlink();
        await this.GetDtcGeotab();  
        await this.GetRecomendaciones();
        this.boilet.isLoading =false;
    }
    
    public async GetDiccionarioJdlink(){
        await this.boiletService.get<any>('/api/Diccionarios/Diccionario_Diagnosticos_Jdlink', true)
        .then( a =>{
            if(!a.isError){
                const recomendaciones = JSON.parse(a.content) as unknown as any[];
                if(recomendaciones){
                    this.diccionarioJdlink = recomendaciones;
                }
            }
            else{
                this.errors = a.errors;
            }
        }); 
    }
    public async GetDiccionarioGeotab(){
        await this.boiletService.get<any>('/api/Diccionarios/Diccionario_Diagnosticos_Geotab', true)
        .then( a =>{
            if(!a.isError){
                const recomendaciones = JSON.parse(a.content) as unknown as any[];
                if(recomendaciones){
                    this.diccionarioGeotab = recomendaciones;
                }
            }
            else{
                this.errors = a.errors;
            }
        }); 
    }

    public async GetMineria(){
        await this.GetDtcJdlink();
        await this.GetDtcGeotab();
    }

    public GetDtcJdlink(){        
        this.boiletService.get<any>('/api/Alertas/DTC/Jdlink/'+this.desde.toISOString()+'/'+this.hasta.toISOString())
            .then((response) => {
                
                if (!response.isError) {
                    this.jdlink.splice(0);
                    this.jdlink = JSON.parse(JSON.parse(response.content));
                } else {
                    this.errors = response.errors;
                }
            });       
    }

    public GetDtcGeotab(){
        this.boiletService.get<any>('/api/Alertas/DTC/Geotab/'+this.desde.toISOString()+'/'+this.hasta.toISOString())
            .then((response) => {
                if (!response.isError) {
                    this.geotab.splice(0);
                    this.geotab = JSON.parse(JSON.parse(response.content));
                } else {
                    this.errors = response.errors;
                }
            });       
    }

    public async GetRecomendaciones(){
        await this.boiletService.get<RecomendacionCliente[]>('/api/Recomendaciones/desdehasta/'+this.desde.toISOString()+'/'+this.hasta.toISOString(), false)
        .then( a =>{
            if(!a.isError){
                this.recomendaciones = a.content as RecomendacionCliente[];
            }
            else{
                this.errors = a.errors;
            }
        }); 
    }

    public cabeceraGeotab = [
        { text: 'RSL', value: 'rsl' , width: '80px',},
        { text: 'MIL', value: 'mil' , width: '80px',},
        { text: 'AWL', value: 'awl' , width: '80px',},
        { text: 'PWL', value: 'pwl' , width: '80px',},
        { text: 'TIPO', value: 'tipo' ,width: '140px',},
        { text: 'CÓDIGO', value: 'codigo' , width: '120px',},
        { text: 'FMI', value: 'codigoModoDeFalla', width: '100px',},
        { text: 'BUS', value: 'bus' , width: '80px',},
        { text: 'ECM', value: 'codigoControlador'  , width: '80px',},
        { text: 'Marca', value: 'marca' , width: '120px',},
        { text: 'Modelo', value: 'modelo' , width: '240px',},
        { text: 'Familia', value: 'familia',width: '240px',},      
        {
            text: 'Cliente',
            align: 'start',
            sortable: true,
            value: 'cliente',
            width: '240px',
        },
        {
            text: 'Tipo Alerta',
            align: 'start',
            sortable: true,
            value: 'tipoReco',
            width: '120px',
        },
        {
            text: 'Primero',
            align: 'start',
            sortable: true,
            value: 'primeroFecha',
            width: '120px',
        },
        {
            text: 'Último',
            align: 'start',
            sortable: true,
            value: 'ultimoFecha',
            width: '120px',
        },
        {
            text: 'N° Maquinas',
            align: 'end',
            sortable: true,
            value: 'equipos'
        },
        {
            text: 'N° Avisos',
            align: 'end',
            sortable: true,
            value: 'avisos'
        },
        {
            text: 'Total Ocurrencias',
            align: 'end',
            sortable: true,
            value: 'ocurrencias'
        },
        
        {
            text: 'Recomendaciones',
            align: 'end',
            sortable: true,
            value: 'totalRecomendaciones',
        },
        {
            text: 'R. Cerradas',
            align: 'end',
            sortable: true,
            value: 'totalRecomendacionesCerradas',
        },
        {
            text: 'R. Descartadas',
            align: 'end',
            sortable: true,
            value: 'totalRecomendacionesCerradasDescartadas',
        },
        {
            text: 'R. En Proceso',
            align: 'end',
            sortable: true,
            value: 'totalRecomendacionesEnProceso',
        },
        {
            text: 'R. 1° Contacto',
            align: 'end',
            sortable: true,
            value: 'totalRecomendacionesPrimerContacto',
        },
        {
            text: 'R. 2° Contacto',
            align: 'end',
            sortable: true,
            value: 'totalRecomendacionesSegundoContacto',
        },
        { text: 'Descripcion', value: 'descripcion',width: '680px', },
    ];

    public cabeceraGeotab2 = [
        { text: 'RSL', value: 'rsl' , width: '80px'},
        { text: 'MIL', value: 'mil' , width: '80px'},
        { text: 'AWL', value: 'awl' , width: '80px'},
        { text: 'PWL', value: 'pwl' , width: '80px'},
        { text: 'TIPO', value: 'tipo' ,width: '140px'},
        { text: 'CÓDIGO', value: 'codigo' , width: '120px'},
        { text: 'FMI', value: 'codigoModoDeFalla', width: '100px'},
        { text: 'BUS', value: 'bus' , width: '80px'},
        { text: 'ECM', value: 'codigoControlador'  , width: '80px'},
        { text: 'Marca', value: 'marca' , width: '180px'},
        { text: 'Modelo', value: 'modelo' , width: '240px'},
        { text: 'Familia', value: 'familia',width: '240px'},      
        {
            text: 'Tipo Alerta',
            align: 'start',
            sortable: true,
            value: 'tipoReco',
            width: '120px',
        },
        {
            text: 'Primero',
            align: 'start',
            sortable: true,
            value: 'primeroFecha',
            width: '120px',
        },
        {
            text: 'Último',
            align: 'start',
            sortable: true,
            value: 'ultimoFecha',
            width: '120px',
        },
        
        {
            text: 'N° Clientes',
            align: 'end',
            sortable: true,
            value: 'cliente',
        },
        {
            text: 'N° Maquinas',
            align: 'end',
            sortable: true,
            value: 'equipos'
        },
        {
            text: 'N° Avisos',
            align: 'end',
            sortable: true,
            value: 'avisos'
        },
        {
            text: 'Total Ocurrencias',
            align: 'end',
            sortable: true,
            value: 'ocurrencias'
        },
        
        
        {
            text: 'Recomendaciones',
            align: 'end',
            sortable: true,
            value: 'totalRecomendaciones',
        },
        {
            text: 'R. Cerradas',
            align: 'end',
            sortable: true,
            value: 'totalRecomendacionesCerradas',
        },
        {
            text: 'R. Descartadas',
            align: 'end',
            sortable: true,
            value: 'totalRecomendacionesCerradasDescartadas',
        },
        {
            text: 'R. En Proceso',
            align: 'end',
            sortable: true,
            value: 'totalRecomendacionesEnProceso',
        },
        {
            text: 'R. 1° Contacto',
            align: 'end',
            sortable: true,
            value: 'totalRecomendacionesPrimerContacto',
        },
        {
            text: 'R. 2° Contacto',
            align: 'end',
            sortable: true,
            value: 'totalRecomendacionesSegundoContacto',
        },
        { text: 'Descripcion', value: 'descripcion',width: '680px', },
    ];

    public cabecera2 = [
        { text: 'Color', value: 'color' , width: '80px',},
        { text: 'Severidad', value: 'severidad', width: '120px',},
        { text: 'Tipo', value: 'tipo' , width: '140px',},
        { text: 'Código', value: 'codigo' , width: '120px',},
        { text: 'FMI', value: 'codigoModoDeFalla' , width: '100px',},
        { text: 'SA', value: 'sa', width: '80px',},
        { text: 'ECM', value: 'descripcionControlador' , width: '80px',},
        { text: 'Marca', value: 'marca' , width: '120px',},
        { text: 'Modelo', value: 'modelo' , width: '140px',},
        { text: 'Familia', value: 'familia' ,width: '240px',},        
       
        // {
        //     text: 'Tipo Spn',
        //     align: 'start',
        //     sortable: true,
        //     value: 'tipo',
        //     width: '140px',
        // },
        
        {
            text: 'Tipo Alerta',
            align: 'start',
            sortable: true,
            value: 'tipoReco',
            width: '120px',
        },
        {
            text: 'Primero',
            align: 'start',
            sortable: true,
            value: 'primeroFecha',
            width: '120px',
        },
        {
            text: 'Último',
            align: 'start',
            sortable: true,
            value: 'ultimoFecha',
            width: '120px',
        },
        {
            text: 'N° Clientes',
            align: 'end',
            sortable: true,
            value: 'cliente',
        },
        {
            text: 'N° Maquinas',
            align: 'end',
            sortable: true,
            value: 'equipos'
        },
        {
            text: 'N° Avisos',
            align: 'end',
            sortable: true,
            value: 'avisos'
        },
        {
            text: 'Total Ocurrencias',
            align: 'end',
            sortable: true,
            value: 'ocurrencias'
        },
        
        {
            text: 'Recomendaciones',
            align: 'end',
            sortable: true,
            value: 'totalRecomendaciones',
        },
        {
            text: 'R. Cerradas',
            align: 'end',
            sortable: true,
            value: 'totalRecomendacionesCerradas',
        },
        {
            text: 'R. Descartadas',
            align: 'end',
            sortable: true,
            value: 'totalRecomendacionesCerradasDescartadas',
        },
        {
            text: 'R. En Proceso',
            align: 'end',
            sortable: true,
            value: 'totalRecomendacionesEnProceso',
        },
        {
            text: 'R. 1° Contacto',
            align: 'end',
            sortable: true,
            value: 'totalRecomendacionesPrimerContacto',
        },
        {
            text: 'R. 2° Contacto',
            align: 'end',
            sortable: true,
            value: 'totalRecomendacionesSegundoContacto',
        },
        { text: 'Descripcion', value: 'descripcion',width: '680px', },
    ];

    public cabecera = [
        { text: 'Color', value: 'color' , width: '80px',},
        { text: 'Severidad', value: 'severidad', width: '120px',},
        { text: 'Tipo', value: 'tipo' , width: '140px',},
        { text: 'Código', value: 'codigo' , width: '120px',},
        { text: 'FMI', value: 'codigoModoDeFalla' , width: '100px',},
        { text: 'SA', value: 'sa', width: '80px',},
        { text: 'ECM', value: 'descripcionControlador' , width: '80px',},
        { text: 'Marca', value: 'marca' , width: '120px',},
        { text: 'Modelo', value: 'modelo' , width: '140px',},
        { text: 'Familia', value: 'familia' ,width: '240px',},        
        {
            text: 'Cliente',
            align: 'start',
            sortable: true,
            value: 'cliente',
            width: '240px',
        },
        // {
        //     text: 'Tipo Spn',
        //     align: 'start',
        //     sortable: true,
        //     value: 'tipo',
        //     width: '140px',
        // },
        {
            text: 'Tipo Alerta',
            align: 'start',
            sortable: true,
            value: 'tipoReco',
            width: '120px',
        },
        {
            text: 'Primero',
            align: 'start',
            sortable: true,
            value: 'primeroFecha',
            width: '120px',
        },
        {
            text: 'Último',
            align: 'start',
            sortable: true,
            value: 'ultimoFecha',
            width: '120px',
        },
        {
            text: 'N° Maquinas',
            align: 'end',
            sortable: true,
            value: 'equipos'
        },
        {
            text: 'N° Avisos',
            align: 'end',
            sortable: true,
            value: 'avisos'
        },
        {
            text: 'Total Ocurrencias',
            align: 'end',
            sortable: true,
            value: 'ocurrencias'
        },
       
        {
            text: 'Recomendaciones',
            align: 'end',
            sortable: true,
            value: 'totalRecomendaciones',
        },
        {
            text: 'R. Cerradas',
            align: 'end',
            sortable: true,
            value: 'totalRecomendacionesCerradas',
        },
        {
            text: 'R. Descartadas',
            align: 'end',
            sortable: true,
            value: 'totalRecomendacionesCerradasDescartadas',
        },
        {
            text: 'R. En Proceso',
            align: 'end',
            sortable: true,
            value: 'totalRecomendacionesEnProceso',
        },
        {
            text: 'R. 1° Contacto',
            align: 'end',
            sortable: true,
            value: 'totalRecomendacionesPrimerContacto',
        },
        {
            text: 'R. 2° Contacto',
            align: 'end',
            sortable: true,
            value: 'totalRecomendacionesSegundoContacto',
        },
        { text: 'Descripcion', value: 'descripcion',width: '680px', },
    ];

    onlyUnique(value: any, index:any, self:any) {
        return self.indexOf(value) === index;
    }

    public get AgrupacionPorCodigoJdLink(){
        let ll = [] as any[];        
        this.jdlink0.forEach((a : any) =>{
            let d = {} as any;
            d.sa = a.sa;
            d.severidad = a.severidad;
            d.color = a.color;
            d.tipo = a.tipo;
            d.codigo = a.codigo;
            d.codigoModoDeFalla = a.codigoModoDeFalla;
            d.bus = a.bus;
            d.descripcionControlador = a.descripcionControlador;
            d.marca = a.marca;
            d.modelo = a.modelo;
            d.familia = a.familia;
            let ix = ll.findIndex((t: any) => 
                t.diccionario.sa == d.sa &&
                t.diccionario.severidad == d.severidad &&
                t.diccionario.color == d.color &&
                t.diccionario.codigo == d.codigo &&
                t.diccionario.codigoModoDeFalla == d.codigoModoDeFalla &&
                t.diccionario.descripcionControlador == d.descripcionControlador &&
                t.diccionario.bus == d.bus &&
                t.diccionario.tipo == d.tipo &&
                t.diccionario.marca == d.marca &&
                t.diccionario.modelo == d.modelo &&
                t.diccionario.familia == d.familia
            );
            if(ix > -1){
                ll[ix].ocurrencias += a.ocurrenciasTotal;
                ll[ix].avisos += a.avisos;
                ll[ix].vins.push(a.vin);
                ll[ix].vins = ll[ix].vins.filter(this.onlyUnique);
                ll[ix].clientes.push(a.cliente);
                ll[ix].clientes = ll[ix].clientes.filter(this.onlyUnique);
                ll[ix].ultimoFecha = moment(ll[ix].ultimoFecha).unix > moment(a.ultimoFecha.$date).unix ? ll[ix].ultimoFecha :moment(a.ultimoFecha.$date);
                ll[ix].primeroFecha = moment(ll[ix].primeroFecha).unix < moment(a.primeroFecha.$date).unix ? ll[ix].primeroFecha : moment(a.primeroFecha.$date);
                ll[ix].recomendaciones = ll[ix].recomendaciones ? ll[ix].recomendaciones.concat(a.recomendaciones) : a.recomedaciones;
            }else{
                ll.push({ recomendaciones: a.recomendaciones, descripcion: a.descripcion, tipoReco: a.tipoReco,diccionario : d, vins: [a.vin], avisos: a.avisos, clientes:[a.cliente], ocurrencias: a.ocurrenciasTotal, primeroFecha: moment(a.primeroFecha.$date), ultimoFecha: moment(a.ultimoFecha.$date)})
            }
        })
        
        ll = ll.map((t :any) =>{
           
            return {
                sa : t.diccionario.sa,
                severidad: t.diccionario.severidad,
                color: t.diccionario.color,
                codigo: t.diccionario.codigo,
                codigoModoDeFalla: t.diccionario.codigoModoDeFalla,
                descripcionControlador: t.diccionario.descripcionControlador,
                bus: t.diccionario.bus,
                tipo: t.diccionario.tipo,
                marca: t.diccionario.marca,
                modelo: t.diccionario.modelo,
                familia: t.diccionario.familia,
                cliente: t.clientes.length,
                equipos: t.vins.length,
                vins: t.vins,
                avisos: t.avisos, 
                ocurrencias: t.ocurrencias,
                clientes: t.clientes,
                numclientes: t.clientes.length,
                descripcion: t.descripcion,
                tipoReco: t.tipoReco,
                alertaExperta: t.alertaExperta,
                primeroFecha: t.primeroFecha,
                ultimoFecha: t.ultimoFecha,
                recomedaciones: t.recomendaciones,
                totalRecomendaciones: (t.recomendaciones ? t.recomendaciones .length : 0)
            }
        });
        const vacios = ll.filter( (r: any) =>  r.tipoReco != 'F1' && r.tipoReco != 'F2' );
        const f1 = ll.filter((r:any) => r.tipoReco == 'F1' );
        const f2 = ll.filter((r:any) => r.tipoReco == 'F2' );

        if(this.switchF1 && this.switchF2 && this.switchVacios){
            ll = ll;
        }
        if(!this.switchF1 && !this.switchF2 && !this.switchVacios){
            ll = [] as any[];
        }else 
        if(!this.switchVacios){
            if(!this.switchF1 && this.switchF2){
                ll = f2;
            }else
            if(this.switchF1 && !this.switchF2){
                ll = f1;
            }else
            if(this.switchF1 && this.switchF2){               
                ll = f1.concat(f2);
            }
        }else
        if(this.switchVacios){
            if(!this.switchF1 && this.switchF2){
                ll =  vacios.concat(f2);
            }else
            if(this.switchF1 && !this.switchF2){
                ll =  vacios.concat(f1);
            }else
            if(!this.switchF1 && !this.switchF2){               
                ll =  vacios;
            }
        }
        return ll;
    }

    public get AgrupacionCodigoOrganizacionMarcaModeloFamiliaJdlink(){
        let ll = [] as any[];        
        this.jdlink0.forEach((a : any) =>{
            let d = {} as any;
            d.sa = a.sa;
            d.severidad = a.severidad;
            d.color = a.color;
            d.tipo = a.tipo;
            d.codigo = a.codigo;
            d.codigoModoDeFalla = a.codigoModoDeFalla;
            d.bus = a.bus;
            d.descripcionControlador = a.descripcionControlador;
            d.marca = a.marca;
            d.modelo = a.modelo;
            d.familia = a.familia;
            d.cliente = a.cliente;
            
            let ix = ll.findIndex((t: any) => 
                        t.diccionario.sa == d.sa &&
                        t.diccionario.severidad == d.severidad &&
                        t.diccionario.color == d.color &&
                        t.diccionario.codigo == d.codigo &&
                        t.diccionario.codigoModoDeFalla == d.codigoModoDeFalla &&
                        t.diccionario.descripcionControlador == d.descripcionControlador &&
                        t.diccionario.bus == d.bus &&
                        t.diccionario.tipo == d.tipo &&
                        t.diccionario.marca == d.marca &&
                        t.diccionario.modelo == d.modelo &&
                        t.diccionario.familia == d.familia &&
                        t.diccionario.cliente == d.cliente
                    );
            if(ix > -1){
                ll[ix].ocurrencias += a.ocurrenciasTotal;
                ll[ix].avisos += a.avisos;
                ll[ix].vins.push(a.vin);
                ll[ix].vins = ll[ix].vins.filter(this.onlyUnique);
                ll[ix].ultimoFecha = moment(ll[ix].ultimoFecha).unix > moment(a.ultimoFecha.$date).unix ? ll[ix].ultimoFecha :moment(a.ultimoFecha.$date);
                ll[ix].primeroFecha = moment(ll[ix].primeroFecha).unix < moment(a.primeroFecha.$date).unix ? ll[ix].primeroFecha : moment(a.primeroFecha.$date);
                ll[ix].recomendaciones = ll[ix].recomendaciones ? ll[ix].recomendaciones.concat(a.recomendaciones) : a.recomedaciones;
            }else{
                ll.push({ recomendaciones: a.recomendaciones,  descripcion: a.descripcion, tipoReco: a.tipoReco, diccionario : d, vins: [a.vin], avisos: a.avisos, ocurrencias: a.ocurrenciasTotal, primeroFecha: moment(a.primeroFecha.$date), ultimoFecha: moment(a.ultimoFecha.$date)})
            }
        })
       
        ll = ll.map((t :any) =>{
            return {
                sa : t.diccionario.sa,
                severidad: t.diccionario.severidad,
                color: t.diccionario.color,
                codigo: t.diccionario.codigo,
                codigoModoDeFalla: t.diccionario.codigoModoDeFalla,
                descripcionControlador: t.diccionario.descripcionControlador,
                bus: t.diccionario.bus,
                tipo: t.diccionario.tipo,
                marca: t.diccionario.marca,
                modelo: t.diccionario.modelo,
                familia: t.diccionario.familia,
                cliente: t.diccionario.cliente,
                equipos: t.vins.length,
                vins: t.vins,
                avisos: t.avisos, 
                ocurrencias: t.ocurrencias,
                descripcion: t.descripcion,
                tipoReco:  t.tipoReco,
                alertaExperta:  t.alertaExperta,
                primeroFecha: t.primeroFecha,
                ultimoFecha: t.ultimoFecha,
                recomendaciones: t.recomendaciones,
                totalRecomendaciones: (t.recomendaciones ? t.recomendaciones .length : 0)
            }
        });
        const vacios = ll.filter( (r: any) =>  r.tipoReco != 'F1' && r.tipoReco != 'F2' );
        const f1 = ll.filter((r:any) => r.tipoReco == 'F1' );
        const f2 = ll.filter((r:any) => r.tipoReco == 'F2' );

        if(this.switchF1 && this.switchF2 && this.switchVacios){
            ll = ll;
        }
        if(!this.switchF1 && !this.switchF2 && !this.switchVacios){
            ll = [] as any[];
        }else 
        if(!this.switchVacios){
            if(!this.switchF1 && this.switchF2){
                ll = f2;
            }else
            if(this.switchF1 && !this.switchF2){
                ll = f1;
            }else
            if(this.switchF1 && this.switchF2){               
                ll = f1.concat(f2);
            }
        }else
        if(this.switchVacios){
            if(!this.switchF1 && this.switchF2){
                ll =  vacios.concat(f2);
            }else
            if(this.switchF1 && !this.switchF2){
                ll =  vacios.concat(f1);
            }else
            if(!this.switchF1 && !this.switchF2){               
                ll =  vacios;
            }
        }
        return ll;
    }

    public get AgrupacionPorCodigoGeotab(){
        let ll = [] as any[];
        this.geotab0.forEach((a : any) =>{
            let d = {} as any;
            d.rsl = a.rsl;
            d.mil = a.mil;
            d.awl = a.awl;
            d.pwl = a.pwl;
            d.tipo = a.tipo;
            d.codigo = a.codigo;
            d.codigoModoDeFalla = a.codigoModoDeFalla;
            d.bus = a.bus;
            d.codigoControlador = a.codigoControlador;
            d.marca = a.marca;
            d.modelo = a.modelo;
            d.familia = a.familia;
            let ix = ll.findIndex((t: any) => 
                            t.diccionario.rsl == d.rsl &&
                            t.diccionario.mil == d.mil &&
                            t.diccionario.awl == d.awl &&
                            t.diccionario.pwl == a.pwl &&
                            t.diccionario.codigo == d.codigo &&
                            t.diccionario.codigoModoDeFalla == d.codigoModoDeFalla &&
                            t.diccionario.codigoControlador == d.codigoControlador &&
                            t.diccionario.bus == d.bus &&
                            t.diccionario.tipo == d.tipo &&
                            t.diccionario.marca == d.marca &&
                            t.diccionario.modelo == d.modelo &&
                            t.diccionario.familia == d.familia &&
                            t.diccionario.cliente == d.cliente
                        );
                        if(ix > -1){
                            ll[ix].ocurrencias += a.ocurrenciasTotal;
                            ll[ix].avisos += a.avisos;
                            ll[ix].vins.push(a.vin);
                            ll[ix].vins = ll[ix].vins.filter(this.onlyUnique);
                            ll[ix].clientes.push(a.cliente);
                            ll[ix].clientes = ll[ix].clientes.filter(this.onlyUnique);
                            ll[ix].ultimoFecha = moment(ll[ix].ultimoFecha).unix > moment(a.ultimoFecha.$date).unix ? ll[ix].ultimoFecha :moment(a.ultimoFecha.$date);            
                            ll[ix].primeroFecha = moment(ll[ix].primeroFecha).unix < moment(a.primeroFecha.$date).unix ? ll[ix].primeroFecha : moment(a.primeroFecha.$date);
                            ll[ix].recomendaciones = ll[ix].recomendaciones ? ll[ix].recomendaciones.concat(a.recomendaciones) : a.recomedaciones;
                        }else{
                            ll.push({ descripcion: a.descripcion, tipoReco: a.tipoReco, diccionario : d, 
                                vins: [a.vin], avisos: a.avisos, clientes:[a.cliente], 
                                ocurrencias: a.ocurrenciasTotal, primeroFecha: moment(a.primeroFecha.$date), 
                                ultimoFecha: moment(a.ultimoFecha.$date),
                                recomendaciones: a.recomendaciones
                            })
                        }
        })
       
        
        ll = ll.map((t :any) =>{
            return {
                rsl : t.diccionario.rsl,
                mil : t.diccionario.mil,
                awl: t.diccionario.awl,
                pwl: t.diccionario.pwl,
                codigo: t.diccionario.codigo,
                codigoModoDeFalla: t.diccionario.codigoModoDeFalla,
                codigoControlador: t.diccionario.codigoControlador,
                bus: t.diccionario.bus,
                tipo: t.diccionario.tipo,
                marca: t.diccionario.marca,
                modelo: t.diccionario.modelo,
                familia: t.diccionario.familia,
                cliente: t.clientes.length,
                equipos: t.vins.length,
                vins: t.vins,
                avisos: t.avisos, 
                ocurrencias: t.ocurrencias,
                clientes: t.clientes,
                numclientes: t.clientes.length,
                descripcion: t.descripcion,
                tipoReco:  t.tipoReco,
                alertaExperta:  t.alertaExperta,
                primeroFecha: t.primeroFecha,
                ultimoFecha: t.ultimoFecha,
                recomendaciones: t.recomendaciones,
                totalRecomendaciones: (t.recomendaciones ? t.recomendaciones .length : 0)
            }
        });
        const vacios = ll.filter( (r: any) =>  r.tipoReco != 'F1' && r.tipoReco != 'F2' );
        const f1 = ll.filter((r:any) => r.tipoReco == 'F1' );
        const f2 = ll.filter((r:any) => r.tipoReco == 'F2' );

        if(this.switchF1 && this.switchF2 && this.switchVacios){
            ll = ll;
        }
        if(!this.switchF1 && !this.switchF2 && !this.switchVacios){
            ll = [] as any[];
        }else 
        if(!this.switchVacios){
            if(!this.switchF1 && this.switchF2){
                ll = f2;
            }else
            if(this.switchF1 && !this.switchF2){
                ll = f1;
            }else
            if(this.switchF1 && this.switchF2){               
                ll = f1.concat(f2);
            }
        }else
        if(this.switchVacios){
            if(!this.switchF1 && this.switchF2){
                ll =  vacios.concat(f2);
            }else
            if(this.switchF1 && !this.switchF2){
                ll =  vacios.concat(f1);
            }else
            if(!this.switchF1 && !this.switchF2){               
                ll =  vacios;
            }
        }
        return ll;
    }

    public get AgrupacionCodigoOrganizacionMarcaModeloFamiliaGeotab(){
        let ll = [] as any[];
        this.geotab0.forEach((a : any) =>{
            let d = {} as any;
            d.rsl = a.rsl;
            d.mil = a.mil;
            d.awl = a.awl;
            d.pwl = a.pwl;
            d.tipo = a.tipo;
            d.codigo = a.codigo;
            d.codigoModoDeFalla = a.codigoModoDeFalla;
            d.bus = a.bus;
            d.codigoControlador = a.codigoControlador;
            d.marca = a.marca;
            d.modelo = a.modelo;
            d.familia = a.familia;
            d.cliente = a.cliente;
            
            let ix = ll.findIndex((t: any) => 
                            t.diccionario.rsl == d.rsl &&
                            t.diccionario.mil == d.mil &&
                            t.diccionario.awl == d.awl &&
                            t.diccionario.pwl == a.pwl &&
                            t.diccionario.codigo == d.codigo &&
                            t.diccionario.codigoModoDeFalla == d.codigoModoDeFalla &&
                            t.diccionario.codigoControlador == d.codigoControlador &&
                            t.diccionario.bus == d.bus &&
                            t.diccionario.tipo == d.tipo &&
                            t.diccionario.marca == d.marca &&
                            t.diccionario.modelo == d.modelo &&
                            t.diccionario.familia == d.familia &&
                            t.diccionario.cliente == d.cliente
                        );
            if(ix > -1){
                ll[ix].ocurrencias += a.ocurrenciasTotal;
                ll[ix].avisos += a.avisos;
                ll[ix].vins.push(a.vin);
                ll[ix].vins = ll[ix].vins.filter(this.onlyUnique);
                ll[ix].ultimoFecha = moment(ll[ix].ultimoFecha).unix > moment(a.ultimoFecha.$date).unix ? ll[ix].ultimoFecha :moment(a.ultimoFecha.$date);
                ll[ix].primeroFecha = moment(ll[ix].primeroFecha).unix < moment(a.primeroFecha.$date).unix ? ll[ix].primeroFecha : moment(a.primeroFecha.$date);
                ll[ix].recomendaciones = ll[ix].recomendaciones ? ll[ix].recomendaciones.concat(a.recomendaciones) : a.recomedaciones;
            }else{
                ll.push({ 
                    descripcion: a.descripcion, 
                    tipoReco: a.tipoReco, 
                    diccionario : d, 
                    vins: [a.vin], 
                    avisos: a.avisos, 
                    ocurrencias: a.ocurrenciasTotal, 
                    primeroFecha: moment(a.primeroFecha.$date), 
                    ultimoFecha: moment(a.ultimoFecha.$date),
                    recomendaciones: a.recomendaciones
                })
            }
        })
      
        ll = ll.map((t :any) =>{            
            return {
                rsl : t.diccionario.rsl,
                mil : t.diccionario.mil,
                awl: t.diccionario.awl,
                pwl: t.diccionario.pwl,
                codigo: t.diccionario.codigo,
                codigoModoDeFalla: t.diccionario.codigoModoDeFalla,
                codigoControlador: t.diccionario.codigoControlador,
                bus: t.diccionario.bus,
                tipo: t.diccionario.tipo,
                marca: t.diccionario.marca,
                modelo: t.diccionario.modelo,
                familia: t.diccionario.familia,
                cliente: t.diccionario.cliente,
                equipos: t.vins.length,
                vins: t.vins,
                avisos: t.avisos, 
                ocurrencias: t.ocurrencias,
                descripcion: t.descripcion,
                tipoReco:  t.tipoReco,
                alertaExperta:  t.alertaExperta,
                primeroFecha: t.primeroFecha,
                ultimoFecha: t.ultimoFecha,
                recomendaciones: t.recomendaciones,
                totalRecomendaciones: (t.recomendaciones ? t.recomendaciones .length : 0)
            }
        });
        const vacios = ll.filter( (r: any) =>  r.tipoReco != 'F1' && r.tipoReco != 'F2' );
        const f1 = ll.filter((r:any) => r.tipoReco == 'F1' );
        const f2 = ll.filter((r:any) => r.tipoReco == 'F2' );

        if(this.switchF1 && this.switchF2 && this.switchVacios){
            ll = ll;
        }
        if(!this.switchF1 && !this.switchF2 && !this.switchVacios){
            ll = [] as any[];
        }else 
        if(!this.switchVacios){
            if(!this.switchF1 && this.switchF2){
                ll = f2;
            }else
            if(this.switchF1 && !this.switchF2){
                ll = f1;
            }else
            if(this.switchF1 && this.switchF2){               
                ll = f1.concat(f2);
            }
        }else
        if(this.switchVacios){
            if(!this.switchF1 && this.switchF2){
                ll =  vacios.concat(f2);
            }else
            if(this.switchF1 && !this.switchF2){
                ll =  vacios.concat(f1);
            }else
            if(!this.switchF1 && !this.switchF2){               
                ll =  vacios;
            }
        }
        return ll;
    }

    public get AgrupacionCodigoOrganizacionJdlink(){
        let ll = [] as any[];        
        this.jdlink0.forEach((a : any) =>{
            let d = {} as any;
            d.cliente = a.cliente;         
            let ix = ll.findIndex((t: any) => 
                            t.cliente == a.cliente
                        );
            if(ix > -1){
                ll[ix].ocurrencias += a.ocurrenciasTotal;
                ll[ix].avisos += a.avisos;
                ll[ix].vins.push(a.vin);
                ll[ix].vins = ll[ix].vins.filter(this.onlyUnique);
                ll[ix].familias.push(a.familia);
                ll[ix].familias = ll[ix].familias.filter(this.onlyUnique);
                ll[ix].modelos.push(a.modelo);
                ll[ix].modelos = ll[ix].modelos.filter(this.onlyUnique);
                ll[ix].marcas.push(a.marca);
                ll[ix].marcas = ll[ix].marcas.filter(this.onlyUnique);
                ll[ix].codigos.push(a.codigo);
                ll[ix].codigos = ll[ix].codigos.filter(this.onlyUnique);
                
            }else{
                ll.push({ cliente : a.cliente, vins: [a.vin],familias: [a.familia],modelos: [a.modelo],marcas: [a.marca],
                    codigos: [
                            { sa: a.sa, severidad:a.severidad, color: a.color, codigo:a.codigo, codigoModoDeFalla: a.codigoModoDeFalla,
                            descripcionControlador: a.descripcionControlador, 
                            bus: a.bus, tipo: a.tipo }], 
                    avisos: a.avisos, ocurrencias: a.ocurrenciasTotal})
            }
        })
        
        return ll.map((t :any) =>{
            return {               
                cliente: t.cliente,
                equipos: t.vins.length,
                vins: t.vins,
                avisos: t.avisos, 
                ocurrencias: t.ocurrencias,
                marcas: t.marcas,
                numeroMarcas: t.marcas.length,
                numeroFamilias: t.familias.length,
                modelos: t.modelos,
                numeroModelos: t.modelos.length,
                familias: t.familias,
                codigos: t.codigos,
                numeroCodigos: t.codigos.length
            }
        });
    }

    public get AgrupacionCodigoOrganizacionGeotab(){
        let ll = [] as any[];        
        this.geotab0.forEach((a : any) =>{
            let d = {} as any;
            d.cliente = a.cliente;         
            let ix = ll.findIndex((t: any) => 
                            t.cliente == a.cliente
                        );
            if(ix > -1){
                ll[ix].ocurrencias += a.ocurrenciasTotal;
                ll[ix].avisos += a.avisos;
                ll[ix].vins.push(a.vin);
                ll[ix].vins = ll[ix].vins.filter(this.onlyUnique);
                ll[ix].familias.push(a.familia);
                ll[ix].familias = ll[ix].familias.filter(this.onlyUnique);
                ll[ix].modelos.push(a.modelo);
                ll[ix].modelos = ll[ix].modelos.filter(this.onlyUnique);
                ll[ix].marcas.push(a.marca);
                ll[ix].marcas = ll[ix].marcas.filter(this.onlyUnique);
                ll[ix].codigos.push(a.codigo);
                ll[ix].codigos = ll[ix].codigos.filter(this.onlyUnique);
            }else{
                ll.push({ cliente : a.cliente, vins: [a.vin],familias: [a.familia],modelos: [a.modelo],marcas: [a.marca],
                    codigos: [
                            { rsl:a.rsl,mil: a.mil, awl:a.awl, pwl: a.pwl, codigo:a.codigo, codigoModoDeFalla: a.codigoModoDeFalla,
                                codigoControlador: a.codigoControlador, 
                            bus: a.bus, tipo: a.tipo }], 
                    avisos: a.avisos, ocurrencias: a.ocurrenciasTotal})
            }
        });
    
        return ll.map((t :any) =>{
            return {               
                cliente: t.cliente,
                equipos: t.vins.length,
                vins: t.vins,
                avisos: t.avisos, 
                ocurrencias: t.ocurrencias,
                marcas: t.marcas,
                numeroMarcas: t.marcas.length,
                numeroFamilias: t.familias.length,
                modelos: t.modelos,
                numeroModelos: t.modelos.length,
                familias: t.familias,
                codigos: t.codigos,
                numeroCodigos: t.codigos.length
            }
        });
    }

    public cabeceraPorOrganizacion = [             
        {
            text: 'Cliente',
            align: 'start',
            sortable: true,
            value: 'cliente',
        },
        {
            text: 'Familias',
            align: 'end',
            sortable: true,
            value: 'numeroFamilias',
        },
        {
            text: 'Marcas',
            align: 'end',
            sortable: true,
            value: 'numeroMarcas',
        },
        {
            text: 'Modelos',
            align: 'end',
            sortable: true,
            value: 'numeroModelos',
        },
        {
            text: 'Códigos',
            align: 'end',
            sortable: true,
            value: 'numeroCodigos',
        },
        {
            text: 'Códigos F0',
            align: 'end',
            sortable: true,
            value: 'numeroF0',
        },
        {
            text: 'Códigos F1',
            align: 'end',
            sortable: true,
            value: 'numeroF1',
        },
        {
            text: 'Códigos F2',
            align: 'end',
            sortable: true,
            value: 'numeroF2',
        },
        
        {
            text: 'N° Maquinas',
            align: 'end',
            sortable: true,
            value: 'equipos'
        },
        {
            text: 'N° Avisos',
            align: 'end',
            sortable: true,
            value: 'avisos'
        },
        {
            text: 'N° Informes F0',
            align: 'end',
            sortable: true,
            value: 'numeroInformesF0'
        },
        {
            text: 'N° Informes F1',
            align: 'end',
            sortable: true,
            value: 'numeroInformesF1'
        },
        {
            text: 'N° Informes F2',
            align: 'end',
            sortable: true,
            value: 'numeroInformesF2'
        },
        {
            text: 'Total Ocurrencias',
            align: 'end',
            sortable: true,
            value: 'ocurrencias'
        },
        {
            text: 'T. Ocurrencias F0',
            align: 'end',
            sortable: true,
            value: 'occurrenciasF0'
        },
        {
            text: 'T. Ocurrencias F1',
            align: 'end',
            sortable: true,
            value: 'occurrenciasF1'
        },
        {
            text: 'T. Ocurrencias F2',
            align: 'end',
            sortable: true,
            value: 'occurrenciasF2'
        },
        {
            text: 'Recomendaciones',
            align: 'end',
            sortable: true,
            value: 'totalRecomendaciones',
        },
        {
            text: 'R. F1',
            align: 'end',
            sortable: true,
            value: 'numeroRecomendacionesF1',
        },
        {
            text: 'R. F2',
            align: 'end',
            sortable: true,
            value: 'numeroRecomendacionesF2',
        },
        {
            text: 'R. Cerradas',
            align: 'end',
            sortable: true,
            value: 'totalRecomendacionesCerradas',
        },
        {
            text: 'R. Descartadas',
            align: 'end',
            sortable: true,
            value: 'totalRecomendacionesCerradasDescartadas',
        },
        {
            text: 'R. En Proceso',
            align: 'end',
            sortable: true,
            value: 'totalRecomendacionesEnProceso',
        },
        {
            text: 'R. 1° Contacto',
            align: 'end',
            sortable: true,
            value: 'totalRecomendacionesPrimerContacto',
        },
        {
            text: 'R. 2° Contacto',
            align: 'end',
            sortable: true,
            value: 'totalRecomendacionesSegundoContacto',
        },
        // {
        //     text: 'Descripción',
        //     align: 'start',
        //     sortable: true,
        //     value: 'descripcion',
        // }
    ];

    public GetData(t: any){
        let ixd = this.diccionarioJdlink.findIndex((x: any) =>{
            x.codigo == t.codigo
        })
        if(ixd > -1){
            let t = {} as any;
            t.descripcion = this.diccionarioJdlink[ixd].descripcion;
            t.tipoReco = this.diccionarioJdlink[ixd].tipoReco;
            t.alertaExperta = this.diccionarioJdlink[ixd].alertaExperta;
            return t;
        }else{
            let t = {} as any;
            t.descripcion = "";
            t.tipoReco = "";
            t.alertaExperta = "";
            return t;
        }
        
    }

    get jdlink0(){
        const jd = this.jdlink.filter((r:any) =>{
            return (
                    (this.m_organizacion.length > 0 ? this.m_organizacion.includes((r.cliente ? r.cliente : 'SIN INFORMACIÓN')) : true)
                    &&
                    (this.m_spn.length > 0 ? this.m_spn.includes((r.codigo != undefined && r.codigo != null ? Number.parseInt(r.codigo): -1)) : true)
                    &&
                    (this.m_fmi.length > 0 ? this.m_fmi.includes((r.codigoModoDeFalla != undefined && r.codigoModoDeFalla != null ? Number.parseInt(r.codigoModoDeFalla) : -1)) : true)
            )
        });    


        
        return jd.map((t:any) =>{

           

            let ghf = this.diccionarioJdlink as any[];
            let ixd = ghf.findIndex((x: any) =>
                x.codigo == t.codigo && x.bus == t.bus && 
                x.codigoModoDeFalla ==t.codigoModoDeFalla &&
                x.color == t.color &&
                x.descripcionControlador == t.descripcionControlador && 
                x.severidad == t.severidad &&
                x.familia == t.familia && x.marca == t.marca && 
                x.modelo == t.modelo && x.sa == t.sa
            )
            if(ixd > -1){
                t.descripcion = this.diccionarioJdlink[ixd].descripcion;
                t.tipoReco = this.diccionarioJdlink[ixd].tipoReco;
                t.alertaExperta = this.diccionarioJdlink[ixd].alertaExperta;
            }

            let fgh = this.recomendaciones as any[];
            t.recomendaciones = fgh.filter((reco:any) =>
                          
                reco.codigo == t.codigo && reco.vin == t.vin
                && 
                moment(t.primeroFecha.$date).unix() >= moment(reco.fechaAcceso).unix()
                &&
                moment(t.ultimoFecha.$date).unix() <=  moment(reco.fechaUltimaAlerta).unix()
            );
            // if(t.recomendaciones.length > 0){
            //     console.log(t.recomendaciones)
            // }
            // const lt = this.recomendaciones.filter((p:any) => {
            //     console.log(p.spn + ' ' + t.codigo)
            //     console.log(t.codigo == p.spn)
            //  return  p.vin.toUpperCase() == t.vin.toUpperCase() &&              
            //    p.codigo.toUpperCase() == t.codigo.toUpperCase()                
            // }
            // );
            // if(lt ? lt.length > 0 : false){
            //     t.recomendaciones = lt.filter((reco : any) => {
            //             console.log(reco.fechaUltimaAlerta)
            //             console.log(t.primeroFecha.$date)
            //             console.log(t.spn)
            //             return (                                                    
            //                 moment(t.primeroFecha.$date).unix() >= moment(reco.fechaAcceso).unix() 
            //                 &&
            //                 moment(t.ultimoFecha.$date).unix() <=  moment(reco.fechaUltimaAlerta).unix() 
            //             );
            //             // &&
            //     }
            //             // moment(p.fechaUltimaAlerta).unix() <= moment(t.ultimoFecha.$date).unix()
            //         )
            //     // console.log(lt)
            //     console.log(t.recomendaciones)
            // }



            return t;
        })
    }

    get geotab0(){
        const jd = this.geotab.filter((r:any) =>{
            return (
                    (this.m_organizacion.length > 0 ? this.m_organizacion.includes((r.cliente ? r.cliente : 'SIN INFORMACIÓN')) : true)
                    &&
                    (this.m_spn.length > 0 ? this.m_spn.includes((r.codigo != undefined && r.codigo != null ? Number.parseInt(r.codigo): -1)) : true)
                    &&
                    (this.m_fmi.length > 0 ? this.m_fmi.includes((r.codigoModoDeFalla != undefined && r.codigoModoDeFalla != null ? Number.parseInt(r.codigoModoDeFalla) : -1)) : true)
            )
        });   

        return jd.map((t:any) =>
            {
                let ghf = this.diccionarioGeotab as any[];
                let ixd = ghf.findIndex((x: any) =>
                    x.codigo == t.codigo && x.bus == t.bus && 
                    x.codigoModoDeFalla ==t.codigoModoDeFalla &&
                    x.pwl == t.pwl && x.awl == t.awl && x.rsl == t.rsl && 
                    x.codigoControlador == t.codigoControlador &&
                    x.familia == t.familia && x.marca == t.marca && 
                    x.modelo == t.modelo && x.mil == t.mil
                )
                if(ixd > -1){
                    t.descripcion = this.diccionarioGeotab[ixd].descripcion + ' - '+
                    this.diccionarioGeotab[ixd].descripcionControlador + ' , ' +this.diccionarioGeotab[ixd].descripcionModoDeFalla;
                    t.tipoReco = this.diccionarioGeotab[ixd].tipoReco;
                    t.alertaExperta = this.diccionarioGeotab[ixd].alertaExperta;
                }
                const lt = this.recomendaciones.filter((p:any) =>

                (t.vin ? (p.vin ? p.vin.toUpperCase(): p.vin) == t.vin.toUpperCase(): false) &&              
                 ( t.codigo ? ( p.spn ?  p.spn.toUpperCase() : p.spn) == t.codigo.toUpperCase() : false)               
                );
                if(lt ? lt.length > 0 : false){
                    t.recomendaciones = lt.filter((p : any) => 
                            moment(p.fechaAcceso).unix() >= moment(t.primeroFecha.$date).unix()
                            //  &&
                            // moment(p.fechaUltimaAlerta).unix() <= moment(t.ultimoFecha.$date).unix()
                        )
                    // console.log(lt)
                    // console.log(t.recomendaciones)
                }
                return t;
        });
    }

    CsvExporta(cabecera: any[], datos: any[], nombreArchivo: string){
        const aline = [] as string[];
        const arrData = [] as any[];
        datos.forEach((e:any) => {
            let a = {} as any[];
            let line = [] as string[];
            cabecera.filter(
                (t:any) => 
                    t.value != "alertaExperta" 
                ).forEach((t:any) =>{
                    if(!(e[t.value] != null &&e[t.value] != undefined)){
                        a[t.value] = '';
                    }
                    else{
                        a[t.value] = e[t.value]; 
                    }
                line.push((e[t.value] ? e[t.value]: '').toString().replace(/;/ig," ")
                                                .replace(/-/ig," ")
                                                .replace(/#/ig, ' ')
                                                .replace(/\[/ig, '')
                                                .replace(/\]/ig, '')
                                                .replace(/\(/ig, '')
                                                .replace(/\)/ig, '')
                                                .replace(/\&/ig, '')
                                                .replace(/\[/ig, '')
                                                .replace(/\%/ig, '')
                                                .replace(/\~/ig, '')
                                                .replace(/\n/ig,"")); 
                                                arrData.push(a);            
            })
            aline.push(line.join('|'));
        }); 
        let final ="data:text/csv;charset=utf-8,";
        final += Object.keys(arrData[0]).join("|")+ "\n"
        final += aline.join("\n")
        .replace(/(^\[)|(\]$)/gm, "");
        const data = encodeURI(final);
        console.log(data)
        const link = document.createElement("a");
        link.setAttribute("href", data);
        link.setAttribute("download", nombreArchivo+".csv");
        link.click();
    }

    get CabeceraGotabCsv(){
        return [
            { text: 'VIN', value: 'vin' , width: '80px',},
            { text: 'RSL', value: 'rsl' , width: '80px',},
            { text: 'MIL', value: 'mil' , width: '80px',},
            { text: 'AWL', value: 'awl' , width: '80px',},
            { text: 'PWL', value: 'pwl' , width: '80px',},
            { text: 'TIPO', value: 'tipo' ,width: '140px',},
            { text: 'CÓDIGO', value: 'codigo' , width: '120px',},
            { text: 'FMI', value: 'codigoModoDeFalla', width: '100px',},
            { text: 'BUS', value: 'bus' , width: '80px',},
            { text: 'ECM', value: 'codigoControlador'  , width: '80px',},
            { text: 'Marca', value: 'marca' , width: '120px',},
            { text: 'Modelo', value: 'modelo' , width: '240px',},
            { text: 'Familia', value: 'familia',width: '240px',},
            { text: 'Ocurrencias Total', value: 'ocurrenciasTotal' ,width: '240px',},
            { text: 'Ocurrencias Promedio', value: 'ocurrenciasPromedio' ,width: '240px',},
            { text: 'Ocurrencias Mínimo', value: 'ocurrenciasMinimo' ,width: '240px',},
            { text: 'Ocurrencias Máximo', value: 'ocurrenciasMaximo' ,width: '240px',},
            { text: 'Duración Máximo', value: 'duracionMaximo' ,width: '240px',},
            { text: 'Duración Mínimo', value: 'duracionMinimo' ,width: '240px',},
            { text: 'Duración Promedio', value: 'duracionPromedio' ,width: '240px',},
            { text: 'Duración Total', value: 'duracionTotal' ,width: '240px',},
            { text: 'Avisos', value: 'avisos' ,width: '240px',},    
            { text: 'Tipo Recomendación', value: 'tipoReco' ,width: '240px',},    
            { text: 'Día', value: 'dia' ,width: '240px',},   
            { text: 'Primero', value: 'primeroFecha' ,width: '240px',},   
            { text: 'Ultimo', value: 'ultimoFecha' ,width: '240px',},
        ];
    }

    get CabeceraJdlinkCsv(){
        return[
            { text: 'VIN', value: 'vin' , width: '80px',},
            { text: 'Color', value: 'color' , width: '80px',},
            { text: 'Severidad', value: 'severidad', width: '120px',},
            { text: 'Tipo', value: 'tipo' , width: '140px',},
            { text: 'Código', value: 'codigo' , width: '120px',},
            { text: 'FMI', value: 'codigoModoDeFalla' , width: '100px',},
            { text: 'SA', value: 'sa', width: '80px',},
            { text: 'ECM', value: 'descripcionControlador' , width: '80px',},
            { text: 'Marca', value: 'marca' , width: '120px',},
            { text: 'Modelo', value: 'modelo' , width: '140px',},
            { text: 'Familia', value: 'familia' ,width: '240px',},
            { text: 'Ocurrencias Total', value: 'ocurrenciasTotal' ,width: '240px',},
            { text: 'Ocurrencias Promedio', value: 'ocurrenciasPromedio' ,width: '240px',},
            { text: 'Ocurrencias Mínimo', value: 'ocurrenciasMinimo' ,width: '240px',},
            { text: 'Ocurrencias Máximo', value: 'ocurrenciasMaximo' ,width: '240px',},
            { text: 'Duración Máximo', value: 'duracionMaximo' ,width: '240px',},
            { text: 'Duración Mínimo', value: 'duracionMinimo' ,width: '240px',},
            { text: 'Duración Promedio', value: 'duracionPromedio' ,width: '240px',},
            { text: 'Duración Total', value: 'duracionTotal' ,width: '240px',},
            { text: 'Avisos', value: 'avisos' ,width: '240px',}, 
            { text: 'Tipo Recomendación', value: 'tipoReco' ,width: '240px',}, 
            { text: 'Día', value: 'dia' ,width: '240px',},   
            { text: 'Primero', value: 'primeroFecha' ,width: '240px',},   
            { text: 'Ultimo', value: 'ultimoFecha' ,width: '240px',},
        ]
    }
}
