import BoiletService from '@/shared/application/boilet-service-proxy';
import AuthStore from '@/stores/auth-store';
import LanguageStore  from '@/stores/language-store';
import { appConst } from "../../settings";
import moment from 'moment';

const boilet = {
    baseApiUrl: appConst.webApiUrl,
    baseClientUrl: appConst.webClientUrl,
    isLoading: false,
    appVersion: '1.0.0',
    auth: {
        grantedPermissions: [] as IPermissionDto[],
        isGranted(permissionName: string) {
            return this.grantedPermissions.filter((p) => p.name == permissionName).length > 0;
        },
        removeProps() {
            this.grantedPermissions = [];
        },
        fillProps() {
            const boiletService = new BoiletService();
            boiletService.get<IPermissionDto[]>(
                '/api/permissions?userNameOrEmail=' + AuthStore.getTokenData().sub
            ).then((response) => {
                if (!response.isError) {
                    this.grantedPermissions = response.content as IPermissionDto[];
                }
            });
        }
    },
    seleccionIcono (marca: any,familia: any, modelo: any, almacenIconos: any){
        const logo: any = (
            marca == 'MACK' ? almacenIconos.Svg_Mack_Truck :
            marca == 'RENAULT TRUCKS' ? almacenIconos.Svg_Renault_Truck :
            marca == 'JOHN DEERE' ?
                (
                    (familia == undefined || familia == null || familia == '' || familia == 'NO DISPONIBLE' )?  
                        (modelo == 'E380' || modelo == 'E140' ? almacenIconos.Svg_JohnDeere_Excavator
                                                                 : (modelo == '648H') ? almacenIconos.Svg_JohnDeere_Skidders 
                                                                 : almacenIconos.Svg_JohnDeereNegro ) : 
                    familia == 'EXCAVATOR' ? almacenIconos.Svg_JohnDeere_Excavator :
                    familia.toUpperCase().includes ('4WD LOADER')  ? almacenIconos.Svg_JohnDeere_4wd_Loaders : 
                    familia == 'ARTICULATED DUMP TRUCKS' ? almacenIconos.Svg_JohnDeere_ArticuledDumpTrucks : 
                    familia == 'BACKHOES' ? almacenIconos.Svg_JohnDeere_Backhoes : 
                    familia.toUpperCase().includes ('EXCAVATOR') ? almacenIconos.Svg_JohnDeere_Excavator :  
                    familia.toUpperCase().includes ('CRAWLER') ? almacenIconos.Svg_JohnDeere_Crawler :
                    familia.toUpperCase().includes ('MOTOR GRADER') ? almacenIconos.Svg_JohnDeere_MotorGraders :
                    familia == 'COMBINE HARVESTING'? almacenIconos.Svg_JohnDeere_Combine_Hardvesting :  
                    familia == 'FELLER BUNCHER' ? almacenIconos.Svg_JohnDeere_FellerBuncher : 
                    familia.toUpperCase().includes ('FORESTRY SWING MACHINE')  ? almacenIconos.Svg_JohnDeere_ForestrySwingMachines :
                    familia.toUpperCase().includes ('FORWARDER') ? almacenIconos.Svg_JohnDeere_Forwarders : 
                    familia == 'HARVESTER' ? almacenIconos.Svg_JohnDeere_Harvester :
                    familia.toUpperCase().includes ('KNUCKLEBOOM LOG LOADER') ? almacenIconos.Svg_JohnDeere_KnuckleboomLogLoaders :
                    familia.toUpperCase().includes ('SKIDDER')  ? almacenIconos.Svg_JohnDeere_Skidders  : 
                    familia == 'HAY & FORAGE' ? almacenIconos.Svg_JohnDeere_Hay_Forage :
                    familia == 'SPRAYER' ? almacenIconos.Svg_JohnDeere_Sprayer :
                    familia == 'TRACKED' ? almacenIconos.Svg_JohnDeere_Tracked :
                    familia == 'COMBINE' ? almacenIconos.SvgCombine :
                    familia.toUpperCase().includes ('TWO-WHEEL DRIVE TRACTOR')  ? almacenIconos.SvgTractosRuedas :
                    familia.toUpperCase().includes ('TRACKED HARVESTER')  ? almacenIconos.SvgTracedHardvester :
                    familia == 'TRACTOR' ? almacenIconos.Svg_JohnDeere_Tractor :
                    almacenIconos.Svg_JohnDeereNegro
                ):
            marca == 'HAMM' ?
                (
                    familia == 'COMPACTOR' ? almacenIconos.Svg_Hamm_Compactor :  familia == 'PNEUMATIC TIRE ROLLER' ? almacenIconos.Svg_Hamm_PneumaticTireRoller : familia == 'TANDEM ROLLER' ? almacenIconos.Svg_Hamm :  almacenIconos.Svg_Hamm
                ):
            marca == 'KLEEMANN' ?
                (
                    familia == 'MOBILE IMPACT CRUSHER' ? almacenIconos.Svg_Kleemann_MobileImpactCrusher : familia == 'MOBILE SCREENING PLANT' ? almacenIconos.Svg_Kleemann_MobileScreeningPlant : almacenIconos.Svg_Kleemann
                ) :
            marca == 'VOEGELE' ?
                (
                    (familia == 'ROAD PAVER' || familia == 'ROAD PAVER') ? almacenIconos.Svg_Voegele_Paver :  almacenIconos.Svg_Voegele
                ):
            marca == 'WIRTGEN' ?
                (
                    familia == 'COLD RECYCLER' ? almacenIconos.Svg_Wirtgen_ColdRecycler : 
                    familia == 'COMPACT MILLING MACHINE' ? almacenIconos.Svg_Wirtgen_CompactMillingMachine 
                                : familia == 'LARGE MILLING MACHINE' ? almacenIconos.Svg_Wirtgen_LargeMillingMachine 
                                : familia == 'SLIPFORM PAVER' ? almacenIconos.Svg_Wirtgen_Splitplatform 
                                : almacenIconos.Svg_Wirtgen
                ):
            marca == 'MERCEDES-BENZ' ? almacenIconos.Svg_MercedesBenz:
            marca == 'HITACHI' ? familia.toUpperCase().includes ('EXCAVATOR') ? almacenIconos.Svg_Hitachi_Excavator 
                                : almacenIconos.Svg_Hitachi :  almacenIconos.Svg_Desconocido
        );
        return logo;
    
    },
    FormatoFecha(val: Date | null){
        if(val){
            return moment(val).locale(LanguageStore.getLanguage().languageCode).format(' DD-MM-YYYY HH:mm');
        }
        return ''
    },
    FormatoFechaHace(val: any | null){
        if(val){
            return moment(val).local().locale(LanguageStore.getLanguage().languageCode).fromNow();
        }
        return ''
    },
    CargaColorFecha(fecha: any | null | undefined): string{
        if(fecha){
            const dd = moment.utc().diff(fecha, 'hour');
            if(dd > 24){
                return "grey white--text";
            }else 
            if(dd > 14){
                return "rojo white--text"
            }else if(dd > 8){
                return "naranja white--text"
            }else{
                return "verde white--text";
            }
        }else{
            return "grey ligthten-4 ";
        }
    }
};

export default boilet;