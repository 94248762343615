import Vue from "vue";
import Vuetify from "vuetify/lib";
import LanguageStore from '@/stores/language-store';

Vue.use(Vuetify);

import en from 'vuetify/src/locale/en';
import tr from 'vuetify/src/locale/tr';
import es from 'vuetify/src/locale/es';

export default new Vuetify({
    lang: {
        locales: { en, tr, es },
        current: LanguageStore.getLanguage().languageCode,
    },
    icons: {
        iconfont: "mdi"
    },
    theme: {
        themes: {
            light: {
                primary: '#212121', // #E53935
                secondary: '#002855', // '#353535', // #FFCDD2
                tertiary:  '#4c4c4c', // #3F51B5
                quaternary: '#00061c',//'#7d7d7d',
                alerta: '#000',
                amarillo:'#f1c40f',
                naranja:'#ff7100',
                rojo:'#e10600', //'#C62828',
                verde:'#1B5E20',
                azul:'#002855',
                celeste:'#2196f3',
                plomo: '#606266'
            },
        },
    },
});