import { defineStore } from 'pinia';

export const useGestionStore = defineStore({
    id: 'gestion',
    state: () => ({
        rawItems: [] as any[],
        carga: false,
        webSize: {} as any
    }),
    getters: {
        items: (state: any) =>
        state.rawItems.reduce((items: any[], item: any) => {
            const existingItem = items.find((it) => it === item)
    
            if (!existingItem) {
              items.push(item)
            } else {
              existingItem.amount++
            }
    
            return items
          }, []),
          estado: (state: any) => state.carga,
          tamano: (state: any) => state.webSize
    },
    actions: {
        /**
         * Add item to the cart
         * @param {string} name
         */
        addItem(t: any) {

            const ixa = this.rawItems.
                findIndex( x => 
                    x.code == t.code && 
                    x.pin == t.pin
            );
            if(ixa > -1){
                if(this.rawItems[ixa].index < t.index){
                    this.rawItems.splice(ixa,1,t);
                }
            }else{
                this.rawItems.push(t);
            }
        },
        actualiza(t: boolean){
          this.carga = t;
        },
    
        /**
         * Remove item from the cart
         * @param {string} name
         */
        removeItem(name: any) {
          const i = this.rawItems.lastIndexOf(name)
          if (i > -1) this.rawItems.splice(i, 1)
        },
        updateSize(t: any){
            this.webSize = t;
        }
      },
});