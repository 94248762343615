import BoiletComponentBase from '@/shared/application/boilet-component-base';
import { Component, Prop } from 'vue-property-decorator';
import moment from 'moment';
import { RecomendacionCliente, Mensaje } from '@/shared/models/shared/consolidados/RecomendacionCliente';
import { useGestionStore } from '@/gestion/stores/assets/gestion';
import { Organizacion } from '@/shared/models/shared/consolidados/Organizacion';
import AppConsts from '@/shared/application/boilet';
import AuthStore from '@/stores/auth-store';
import { IconosSvgStore } from '@/gestion/stores/assets/iconos';
// import { VisorPdf } from '@/gestion/components/visor-pdf/visor-pdf.ts';
import axios from "axios";

@Component
({
    components: {
        VisorPdf
        : require('@/gestion/components/visor-pdf/visor-pdf.vue').default,
        } 
})
export default class PostVentaComponent extends BoiletComponentBase {
    public iconosStore = IconosSvgStore();
    @Prop({default: ''})  public idSeguimiento!: string;
    @Prop({default: ''})  public region!: string;
    public organizaciones = [] as Organizacion[];
    public organizacion = "";
    public asyncUsuarioActivo = {} as any;
    public useAlertasStore = useGestionStore();
    public elem: any = null;
    public container: any = null;
    public switch1: boolean = false;
    public errors: any[]  = [] as any[];
    public usuarioActivo = "";
    public rules = [(v: any) => v.length <= 255 || 'Max 255 caracteres'];
    public  textoMensaje: string = '';
    public date: any | null = null;
    public menu= false;
    public menu1= false;
    public dialog = false;
    public plantilla = {} as RecomendacionCliente;
    public motivo = '';
    public estadoDeCierre = '';
    public items = ['Servicio programado', 'Servicio descartado'];
    public dateservicio: any = null;
    public equipos = [] as any[];
    public show = true;
    // public mensajes = [] as Mensaje[];

  


    get computedDateFormattedMomentjs () {
        return this.date ? moment(this.date).local().locale('es').format('dddd, D MMMM  YYYY') : ''
    }

    created(){
    }

    mounted(){
        this.Get();
        this.asyncUsuarioActivo = setInterval(this.Get, 5000);
        // this.scrollToElement()
        // this.elem = document.getElementById ( "scrolled-content" );
        // this.container = document.getElementById ( "scroll-target" );
        // this.container.scrollTop = Math.floor ( this.elem.offsetHeight );
    
    }
    destroy() {
        clearInterval(this.asyncUsuarioActivo)
        this.asyncUsuarioActivo = null;
    }
    beforeDestroy () {
        clearInterval(this.asyncUsuarioActivo)
        this.asyncUsuarioActivo = null;
    }

    scrollToElement() {
        //@ts-ignore
        const el = this.$refs.scrolltarget;
    
        if (el) {
          // Use el.scrollIntoView() to instantly scroll to the element
          //@ts-ignore
          el.scrollIntoView({behavior: 'smooth'});
        }
      }

      DefineColorEstado(item: RecomendacionCliente){
        if(item){
            const contactos = item.destinatarios ? item.destinatarios.length > 0 : false;
            if(!contactos){
                return 'mdi-sign-caution'
            }else
            if(item.fechaCierre == null){
                const notienemensajes = item.mensajes ? item.mensajes.length == 0 : true;
                // const notienefecha = item.fechaServicio ? false : true;
                // if(notienemensajes && notienefecha)
                if(notienemensajes ){
                    return 'secondary';
                }else{
                    const r = item.mensajes;
                    if(r){
                        if(r.length > 0){
                            const t = r.filter( u => u.esMiembro);
                            if(t){
                                if(t.length > 0){
                                    return 'celeste white--text';
                                }else{
                                    //Hay mensajes del cliente pero no del analista
                                    return 'naranja';
                                }
                            }else{
                                //Hay mensajes del cliente pero no del analista
                                return 'naranja';
                            }
                        }
                    }else{
                        return 'secondary';
                    }
                }
            }else{
                if(item.estadoDeCierre.toLowerCase() != ("Servicio programado").toLowerCase()){
                    return 'verde white--text';
                }else{
                    return 'verde white--text';
                }
            }
        }
    }


    DefineEstado(item: RecomendacionCliente){
        
        if(item){
            const notienemensajes = item.mensajes ? item.mensajes.length == 0 : true;
            const notienefecha = item.fechaEnvio ? false : true;
            const notienefechaServicio = item.fechaServicio ? false : true;
            const contactos = item.destinatarios ? item.destinatarios.length > 0 : false;
            if(item.fechaCierre == null){
                
                
                // if(notienemensajes && notienefecha)
                if(notienemensajes && !notienefecha)
                {
                    return 'PRIMER CONTACTO';
                }
                else if(!contactos ){
                    return 'SIN CONTACTOS';
                }else if(!notienemensajes){
                    const r = item.mensajes;
                    if(r){
                        if(r.length > 0){
                            const t = r.filter( u => u.esMiembro);
                            if(t){
                                if(t.length > 0){
                                    return 'EN PROCESO';
                                }else{
                                    return 'SIN GESTIÓN';
                                }
                            }else{
                                //Hay mensajes del cliente pero no del analista
                                return 'PENDIENTE';
                            }
                        }
                    }else{
                        return 'PRIMER CONTACTO';
                    }
                }
            }else{
                if(item.fechaCierre != null){
                    return 'CERRADO';
                }
                else if(!contactos){
                    return 'SIN CONTACTOS';
                }else{
                    return 'DESCONOCIDO';
                }
            }
        }else{
            return '';
        }
    }
     get MuestraRegion(){
        return this.region;
    }
    //  async GetRegion(item: RecomendacionCliente){
    //     let ubicacion = {} as any;
       
    //     console.log(item)
    //     // let isBadRequest = false;
    //     var requestOptions = {
    //         method: 'GET',
    //         redirect: 'follow'
    //     } as any;
    //     if(item){
    //         if(item.coord){
    //             let coord = item.coord; 
                
    //             let u = await fetch("https://maps.googleapis.com/maps/api/geocode/json?latlng="+coord[0]+","+coord[1]+"&key=AIzaSyBWQ6V_x9GvsarN3abfeXiB3bzFJWAix78", requestOptions)
    //             .then(response => response.text())
    //             .then(result =>  result)
    //             .catch(error => error);
                

    //             ubicacion =  u;
    //             const ix = this.equipos.findIndex(y => y.vin == item.vin);
    //             if(ix == -1){
    //                 if(ubicacion ? ubicacion.length > 1 : false){
    //                     let result = JSON.parse(ubicacion);
    //                     if(result ? result.results ? result.results[0].address_components ?  result.results[0].address_components ? result.results[0].address_components.length-2 > -1 : false : false : false : false){
    //                         let sn = result.results[0].address_components[result.results[0].address_components.length-2].short_name as string;
    //                             sn =   sn.toLowerCase().includes('arica') ? 'XV':
    //                                 sn.toLowerCase().includes('tarapac') ? 'I':
    //                                 sn.toLowerCase().includes('antofagasta') ? 'II':
    //                                 sn.toLowerCase().includes('atacama') ? 'III':
    //                                 sn.toLowerCase().includes('coquimbo') ? 'IV':
    //                                 sn.toLowerCase().includes('valparaiso') ? 'V':
    //                                 sn.toLowerCase().includes('santiago') ? 'RM':
    //                                 sn.toLowerCase().includes('libertador') ? 'VI':
    //                                 sn.toLowerCase().includes('maule') ? 'VII':
    //                                 sn.toLowerCase().includes('uble') ? 'IX':
    //                                 sn.toLowerCase().includes('biob') ? 'VIII':
    //                                 sn.toLowerCase().includes('arauca') ? 'IX':
    //                                 sn.toLowerCase().includes('los r') ? 'XIV':
    //                                 sn.toLowerCase().includes('los lagos') ? 'X':
    //                                 sn.toLowerCase().includes('ays') ? 'XI':
    //                                 sn.toLowerCase().includes('magalla') ? 'XII':
    //                                 '';
    //                         this.equipos.push({vin:item.vin, ubicacion:sn })
    //                     }else{
    //                         this.equipos.push({vin:item.vin, ubicacion:'' })
    //                     }
    //                 }
    //             }

                    
    //         }else{

    //            const yu =await  this.boiletService.get<Maquina>('/api/Maquinas/'+item.vin, false).then( (async r =>{
    //                  if(!r.isError){
    //                      let m = r.content;
    //                     if(m.coordenadas? m.coordenadas.latitude && m.coordenadas.longitude : false){
    //                         let coord = [m.coordenadas?.latitude, m.coordenadas?.longitude]; 


    //                         let u = await fetch("https://maps.googleapis.com/maps/api/geocode/json?latlng="+coord[0]+","+coord[1]+"&key=AIzaSyBWQ6V_x9GvsarN3abfeXiB3bzFJWAix78", requestOptions)
    //                         .then(response => response.text())
    //                         .then(result =>  result)
    //                         .catch(error => error);
                            

    //                         ubicacion =  u;
    //                         return ubicacion;
    //                     }else{
    //                         return ubicacion;
    //                     }
    //                  }else{
    //                     return ubicacion;
    //                 }
    //              }))
    //            ubicacion = yu;
    //            const ix = this.equipos.findIndex(y => y.vin == item.vin);
    //            if(ix == -1){
    //                if(ubicacion ? ubicacion.length > 1 : false){
    //                    let result = JSON.parse(ubicacion);
    //                    let sn = result.results[0].address_components[result.results[0].address_components.length-2].short_name as string;
    //                    if(result ? result.results ? result.results[0].address_components ?  result.results[0].address_components ? result.results[0].address_components.length-2 > -1 : false : false : false : false){
    //                     let sn = result.results[0].address_components[result.results[0].address_components.length-2].short_name as string;
    //                         sn =   sn.toLowerCase().includes('arica') ? 'XV':
    //                             sn.toLowerCase().includes('tarapac') ? 'I':
    //                             sn.toLowerCase().includes('antofagasta') ? 'II':
    //                             sn.toLowerCase().includes('atacama') ? 'III':
    //                             sn.toLowerCase().includes('coquimbo') ? 'IV':
    //                             sn.toLowerCase().includes('valparaiso') ? 'V':
    //                             sn.toLowerCase().includes('santiago') ? 'RM':
    //                             sn.toLowerCase().includes('libertador') ? 'VI':
    //                             sn.toLowerCase().includes('maule') ? 'VII':
    //                             sn.toLowerCase().includes('uble') ? 'IX':
    //                             sn.toLowerCase().includes('biob') ? 'VIII':
    //                             sn.toLowerCase().includes('arauca') ? 'IX':
    //                             sn.toLowerCase().includes('los r') ? 'XIV':
    //                             sn.toLowerCase().includes('los lagos') ? 'X':
    //                             sn.toLowerCase().includes('ays') ? 'XI':
    //                             sn.toLowerCase().includes('magalla') ? 'XII':
    //                             '';
    //                     this.equipos.push({vin:item.vin, ubicacion:sn })
    //                 }else{
    //                     this.equipos.push({vin:item.vin, ubicacion:'' })
    //                 }
    //                }
    //            }
    //         }
    //     }
    // }
    // public async GetOrganizaciones8(){
    //     await this.boiletService.get<Organizacion[]>('/api/Organizaciones',false)
    //     .then( a => {
    //         if(!a.isError){
    //             this.organizaciones = a.content as Organizacion[];
    //             const ix = this.organizaciones.findIndex( x => x._id == this.plantilla.org_id);
    //             if(ix > -1){
    //                 this.organizacion = this.organizaciones[ix].nombre;
    //             }
    //         }else{
    //             this.errors = a.errors;
    //         }
    //     });
    // }

    public async GetOrganizaciones(){
        await this.boiletService.get<Organizacion>('/api/Organizaciones/'+this.plantilla.org_id,false)
        .then( a => {
            console.log(444)
            this.organizacion = a.content.nombre 
        });
    }

    public async Get(){
      let idreco =(this.idSeguimiento);
      if(this.dialog == false){
      this.boiletService.get<RecomendacionCliente>('/api/Recomendaciones/abiertos/'+ idreco, false)
      .then( r => {
        console.log(888)
          if(!r.isError){
              if(this.plantilla)
              {
                  if(this.plantilla.mensajes){
                      if(r.content){
                          if(r.content.mensajes){
                              if(r.content.mensajes.length > this.plantilla.mensajes.length){
                                this.plantilla = Object.assign({}, this.plantilla, r.content);
                                // console.log(this.plantilla)
                                // this.GetRegion(this.plantilla);
                              }
                          }
                      }
                  }else{
                    this.plantilla = Object.assign({}, this.plantilla, r.content);
                    // this.GetRegion(this.plantilla);
                  }
              }else{
                this.plantilla = Object.assign({}, this.plantilla, r.content);
                // this.GetRegion(this.plantilla);
              }
            
             
            this.estadoDeCierre = this.plantilla.estadoDeCierre ? this.plantilla.estadoDeCierre : '';
            this.motivo = this.plantilla.motivoDeCierre ? this.plantilla.motivoDeCierre : '';
            this.date = this.plantilla.fechaServicio ? moment(this.plantilla.fechaServicio).format('YYYY-MM-DD'):null;
            this.switch1 = this.plantilla.fechaCierre ? true :false;
            //   this.mensajes = this.plantilla.mensajes ? this.plantilla.mensajes as Mensaje[] : [] as Mensaje[];
            this.GetOrganizaciones();

          }else{
              this.errors = r.errors;
          }
        //   console.log(this.plantilla)
      });
    }
  }

    // EnviarMensaje22(){
    //     if(this.plantilla.responsable == this.authStore.getTokenData().sub){
    //         if(this.plantilla.fechaCierre == null){
    //             if(this.textoMensaje ? this.textoMensaje.length > 0: false){
    //                     let msg = {} as Mensaje;
    //                     msg.emailAutor = this.authStore.getTokenData().sub;
    //                     msg.emailRespondable = this.plantilla.responsable;
    //                     msg.esMiembro = true;
    //                     msg.fecha = moment.utc().toDate();
    //                     msg.texto = this.textoMensaje;
    //                     if(this.plantilla.mensajes){
    //                         this.plantilla.mensajes.push(msg);
    //                     }else{
    //                         this.plantilla.mensajes = [] as Mensaje[];
    //                         this.plantilla.mensajes.push(msg);
    //                     }
    //                     this.boiletService.put<RecomendacionCliente>('/api/Recomendaciones/mensajes', JSON.stringify(this.plantilla))
    //                     .then( r => {
    //                         if(!r.isError){
                                                
    //                             Object.assign(this.plantilla,{},r.content);
    //                             this.elem = document.getElementById ( "scrolled-content" );
    //                             this.container = document.getElementById ( "scroll-target" );
    //                             this.container.scrollTop = Math.floor ( this.elem.offsetHeight );
    //                         }else{
    //                             this.errors = r.errors;
    //                         }
    //                     });
    //                     this.textoMensaje = "";
    //             }
    //         }else{
    //             alert("Este seguimiento ya ha sido cerrado");
    //         }
    //     }
    //     else{
    //         alert("Solo el Analista responsable puede interactuar con el cliente");
    //     }
    // }

    
    EnviarMensajeFoto(foto_id: string){
        const e = this.plantilla;
        if(this.plantilla.responsable == this.authStore.getTokenData().sub){
            if(this.plantilla.fechaCierre == null){
                // if(this.textoMensaje ? this.textoMensaje.length > 0: false){
                    let msg = {} as Mensaje;
                    msg.emailAutor = this.Autor;
                    msg.emailRespondable = e.responsable;
                    msg.esMiembro = true;
                    msg.fecha = moment.utc().toDate();
                    msg.texto = "";
                    msg.foto_id = foto_id;
                    if(e.mensajes){
                        e.mensajes.push(msg);
                    }else{
                        e.mensajes = [] as Mensaje[];
                        e.mensajes.push(msg);
                    }
    
                    
                    this.boiletService.put<RecomendacionCliente>('/api/Recomendaciones/mensajes', JSON.stringify(e), true)
                    .then( r => {
                        if(!r.isError){
                            // this.plantilla = Object.assign({}, this.plantilla, r.content);
                            this.Get();
                        }else{
                            this.errors = r.errors;
                        }
                    });
                    this.textoMensaje = "";
                    // this.textoMensaje = "";
                // }
            }else{
                alert("Este caso ya ha sido cerrado");
            }
        }else{
            alert("Solo el Analista responsable puede interactuar con el cliente");
        }
    }

    EnviarMensaje(){
    // const e = this.plantilla ;

        if(this.plantilla.responsable == this.authStore.getTokenData().sub){
            if(this.plantilla.fechaCierre == null){
                if(this.textoMensaje ? this.textoMensaje.length > 0: false){
                    let msg = {} as Mensaje;
                    msg.emailAutor =this.authStore.getTokenData().sub;
                    msg.emailRespondable = this.plantilla.responsable;
                    msg.esMiembro = true;
                    msg.fecha = moment.utc().toDate();
                    msg.texto = this.textoMensaje;
                    if(this.plantilla.mensajes){
                        this.plantilla.mensajes.push(msg);
                    }else{
                        this.plantilla.mensajes = [] as Mensaje[];
                        this.plantilla.mensajes.push(msg);
                    }
    
                    
                    this.boiletService.put<RecomendacionCliente>('/api/Recomendaciones/mensajes', JSON.stringify(this.plantilla))
                    .then( r => {
                        if(!r.isError){
                            this.plantilla = Object.assign({}, this.plantilla, r.content);
                            // this.plantilla = e;
                            this.Get();
                        }else{
                            this.errors = r.errors;
                        }
                    });
                    this.textoMensaje = "";
                    this.textoMensaje = "";
                }
            }else{
                alert("Este caso ya ha sido cerrado");
            }
        }else{
            alert("Solo el Analista responsable puede interactuar con el cliente");
        }

       
    }


    Formateado(item: any){
        if(item)
        return  (moment(item).local().locale('es').format(' DD-MM-YYYY, HH:mm:ss')).toUpperCase();
    }


    get BloqueoFechas(){
        if(this.estadoDeCierre == 'Servicio descartado'){
            return true;
        }else{
            return false;
        }
    }

    ActualizarGestion(){
        if(this.plantilla.responsable == this.authStore.getTokenData().sub){
            if(this.plantilla.fechaCierre == null){
                
                this.plantilla.motivoDeCierre = this.motivo;
                this.plantilla.estadoDeCierre = this.estadoDeCierre;

                if(this.estadoDeCierre){
                    if(this.estadoDeCierre.trim().length > 0){
                        if(this.BloqueoFechas ){
                            if(this.motivo){
                                if(this.motivo.length > 0){
                                    if(this.switch1){
                                        this.plantilla.fechaCierre = moment.utc().toDate();
                                        this.plantilla.fechaServicio =moment.utc().toDate();
                                    }
                                    this.boiletService.put<RecomendacionCliente>('/api/Recomendaciones/completo', JSON.stringify(this.plantilla))
                                        .then( r => {
                                            if(!r.isError){
                                                Object.assign(this.plantilla,{},r.content);
                                                this.useAlertasStore.actualiza(true);
                                            }else{
                                                this.errors = r.errors;
                                            }
                                            this.dialog = false;
                                        });
                                        this.dialog = false;
                                }
                            }
                            else{
                                    alert("Debe redactar un motivo para el descarte");
                            }
                        }
                        else{
                            // if(this.date){
                                if(this.switch1){
                                    this.plantilla.fechaCierre = moment.utc().toDate();
                                    this.plantilla.fechaServicio =moment.utc().toDate();
                                }
                                // this.plantilla.fechaServicio = moment.utc(this.date).toDate();
                                this.boiletService.put<RecomendacionCliente>('/api/Recomendaciones/completo', JSON.stringify(this.plantilla))
                                .then( r => {
                                    if(!r.isError){
                                        Object.assign(this.plantilla,{},r.content);
                                    }else{
                                        this.errors = r.errors;
                                    }
                                
                                });
                                this.dialog = false;
                            // }else{
                            //     alert("Debe seleccionar una fecha para programar el servicio");
                            // }                       
                        }             
                    }else{
                        alert("Debe seleccionar un cierre");
                    }
                }else{
                    alert("Debe seleccionar un cierre");
                }
            }else{
                alert("Este caso ya ha sido cerrado");
            }
        }else{
            alert("Solo el Analista responsable puede interactuar con el cliente");
        }
    }
    CierraCuadro(){
        this.dialog = false;
    }

    get Size(){
        return this.useAlertasStore.tamano;
     }
 
     get FullHd(){
        //  return this.Size.x > 1880
        return true;
     }
 
     get correlativo(){
         return "N° Caso: " + moment(this.plantilla.fechaEnvio).unix()+'-'+this.MuestraRegion;
     }

     get seg(){
      return  moment(this.plantilla.fechaEnvio).unix()
     }


     get Hoy(){
        return moment().format("YYYY-MM-DD");
     }

     public Imagen(id: string){
        return  AppConsts.baseApiUrl+'/api/Upload/'+id
    }



    public headers: { [key: string]: string } = {
        Authorization: `Bearer ${AuthStore.getToken()}`
    };
    public fileList= [] as any; //Lista de archivos.
    public dialogVisible= true;//Estado de visualización del diálogo.
    
    actionRequestUrl= AppConsts.baseApiUrl+'/api/Upload/UploadFile';
    openImporDialog() {
        this.dialogVisible = true;
    }
    handleClose() {
        this.dialogVisible = false;
    }
    submitUpload() {
        // console.log(this.fileList);
        if (this.fileList.length <= 0) {
            //@ts-ignore
          this.$message.error("Por favor, seleccione el archivo que desea cargar.！");
          return false;
        }
        //@ts-ignore
        this.$refs.upload.submit();
    }
    fileUploadFail(err: any, file: any, fileList: any[]) {
        console.log("Error al cargar el archivo", file, fileList);
    }
    fileUploadSuccess(response: any, file: any, fileList: any[]) {
        // console.log("Carga exitosa.");
        console.log(response);
        //Vaciar la lista de archivos cargados previamente.
        //@ts-ignore
        this.$refs.upload.clearFiles();
        if (response.newFileName) {
            this.dialogVisible = false;
        //   this.$message({
        //     message: response.message,
        //     type: "success"
        //   });
        this.EnviarMensajeFoto(response.newFileName);
        } else {
            //@ts-ignore
            this.$message.error(response.message);
        }
    }
    fileChange(file: any, fileList: any[]) {
    //解决无法判断el-upload是否上传过文件问题
    // this.fileList = fileList;
    // console.log("Seleccione el contenido que se muestra después de que el archivo se haya cargado correctamente :", file, fileList);
    }
    handleRemove(file: any, fileList: any[]) {
        // this.fileList = [];
        // return this.$confirm(`确定移除 ${file.name}？`);
    }
    handlePreview(file: any) {
        console.log(file);
    }
   
    get OrdenaChat(){
        if(this.plantilla.mensajes ? this.plantilla.mensajes.length > 0 : false){
            let u = this.plantilla.mensajes
                    .sort((a,b) => 
                        moment(a.fecha).unix()>  moment(b.fecha).unix() ? 
                        -1 : moment(a.fecha).unix() < moment(b.fecha).unix() ? 
                        1 : (a.emailAutor == 'confiabilidad@salfa.cl' ? -1 : 0 )
                    ) as any[];
            // u = u ? u.length > 0 ? u as any[] : [] as any[] : [] as any[];
            return u;
        }else{
            return [] as any[];
        }
        // return this.plantilla.mensajes ? this.plantilla.mensajes.length > 0 ? this.plantilla.mensajes.sort((a,b) => moment(a.fecha).unix()>  moment(b.fecha).unix() ? -1 : moment(a.fecha).unix() < moment(b.fecha).unix() ? 1 : 0) : null : null;
    }

    get Autor(){
        return this.authStore.getTokenData().sub;
    }

    MensajeLink(s: string){
        if(this.plantilla.responsable == this.authStore.getTokenData().sub){
            if(this.plantilla.fechaCierre == null){
                if(s ? s.length > 0: false){
                    let msg = {} as Mensaje;
                    msg.emailAutor =this.authStore.getTokenData().sub;
                    msg.emailRespondable = this.plantilla.responsable;
                    msg.esMiembro = true;
                    msg.fecha = moment.utc().toDate();
                    msg.texto = s;
                    if(this.plantilla.mensajes){
                        this.plantilla.mensajes.push(msg);
                    }else{
                        this.plantilla.mensajes = [] as Mensaje[];
                        this.plantilla.mensajes.push(msg);
                    }
    
                    
                    this.boiletService.put<RecomendacionCliente>('/api/Recomendaciones/mensajes', JSON.stringify(this.plantilla))
                    .then( r => {
                        if(!r.isError){
                            // this.plantilla = Object.assign({}, this.plantilla, r.content);
                            // this.plantilla = e;
                            this.Get();
                        }else{
                            this.errors = r.errors;
                        }

                        
                         window.open(s);
                    });
                   
                }
            }else{
                alert("Este caso ya ha sido cerrado");
            }
        }else{
            alert("Solo el Analista responsable puede interactuar con el cliente");
        }
    }
    get IconHorometro(){
        return this.iconosStore.Svg_ArenaIcon
    }

    get IconOdometro(){
        return this.iconosStore.Svg_RoadIcon
    }

    get IconRemanente(){
        return this.iconosStore.Svg_RemanenteIcon
    }

    // openHandler(pdfApp) {
    //     window._pdfApp = pdfApp;
    //   }

    // Descargar (url: any, nombre: any) {
    //     const data = window.URL.createObjectURL(url);
    //     // window.location.assign(data);
    //     var link = document.createElement("a");
    //     link.href = data;
    //     link.download = nombre + ".pdf";
    //     link.click();
    //     setTimeout(function () {
    //       // For Firefox it is necessary to delay revoking the ObjectURL
    //       window.URL.revokeObjectURL(data);
    //     }, 100);
    //   }

    Descargar (url: any, nombre: any){
        axios({
            url:url,
            method:'GET',
            responseType: 'blob'
        })
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', nombre);
            document.body.appendChild(link);
            link.click();
        })
    }

}