import BoiletComponentBase from '@/shared/application/boilet-component-base';
import { Component, Watch } from 'vue-property-decorator';
import { GestionActivosStore } from '@/gestion/stores/assets/activos';
import { IconosSvgStore } from '@/gestion/stores/assets/iconos';
import moment from 'moment';
import { Activo, Maquina } from '@/shared/models/shared/consolidados/Maquina';
import { Organizacion } from '@/shared/models/shared/consolidados/Organizacion';
import { RecomendacionCliente } from '@/shared/models/shared/consolidados/RecomendacionCliente';
import { getDistance } from 'geolib';
import { CoordenadasActivosStore } from '@/gestion/stores/assets/coordenadas'
import AppConsts from '@/shared/application/boilet';
import AuthStore from '@/stores/auth-store';

@Component
export default class GrillaDiccionariosComponent extends BoiletComponentBase {
    public errors: any = null;
    public diccionarioJdlink = [] as any[];
    public diccionarioEventoJdlink = [] as any[]
    public diccionarioGeotab = [] as any[];
    public diccionarioWitos = [] as any[];
    public diccionarioEventoGeotab = [] as any[]
    public diccionarioEventoGarddog = [] as any[];
    public diccionarioEventoExpertas = [] as any[];
    
    public editedIndex = {} as any;
    public editedIndex1 = {} as any;
    public editedIndex2 = {} as any;
    public editedIndex3 = {} as any;
    public editedIndex4 = {} as any;
    public editedIndex5 = {} as any;
    public editedIndex6 = {} as any;
    public dd = "DDDD";
    public editaItem = {} as any;
    public modelosCargados00 = [] as any;
    public modelosCargados11 = [] as any;
    public modelosCargados22 = [] as any;
    public modelosCargados33 = [] as any;
    public modelosCargados44 = [] as any;
    public modelosCargados55 = [] as any;
    public modelosCargados66 = [] as any;

    public modelosSeleccionados00 = [] as any;
    public modelosSeleccionados11 = [] as any;
    public modelosSeleccionados22 = [] as any;
    public modelosSeleccionados33 = [] as any;
    public modelosSeleccionados44 = [] as any;
    public modelosSeleccionados55 = [] as any;
    public modelosSeleccionados66 = [] as any;

    public editaItem00 = {} as any;
    public dialog00 = false;
    public editedIndex00 = {} as any;

    public editaItem11 = {} as any;
    public dialog11 = false;
    public editedIndex11 = {} as any;

    public editaItem22 = {} as any;
    public dialog22 = false;
    public editedIndex22 = {} as any;

    public editaItem33 = {} as any;
    public dialog33 = false;
    public editedIndex33 = {} as any;

    public editaItem44 = {} as any;
    public dialog44 = false;
    public editedIndex44 = {} as any;

    public editaItem55 = {} as any;
    public dialog55 = false;
    public editedIndex55 = {} as any;

    public editaItem66 = {} as any;
    public dialog66 = false;
    public editedIndex66 = {} as any;

    public dialog = false;
    
    public editaItem1 = {} as any;
    public dialog1 = false;

    public editaItem2 = {} as any;
    public dialog2 = false;

    public editaItem3 = {} as any;
    public dialog3 = false;

    public editaItem4 = {} as any;
    public dialog4 = false;

    public editaItem5 = {} as any;
    public dialog5 = false;

    public editaItem6 = {} as any;
    public dialog6 = false;

    public mounted(){
        this.GetDiccionarioJdlink();
        this.GetDiccionarioEventoJdlink();
        this.GetDiccionarioGeotab();
        this.GetDiccionarioEventoGeotab();
        this.GetDiccionarioExpertas();
        this.GetDiccionarioGuarddog();
        this.GetDiccionarioWitos();
    }
    public async GetDiccionarioWitos(){
        // console.log("api")
        await this.boiletService.get<any>('/api/Diccionarios/Diccionario_Diagnosticos_Witos', true)
        .then( a =>{
            // console.log(a)
            if(!a.isError){
                // let items = a.content as RecomendacionCliente[];
                // console.log(a.content)
                const recomendaciones = JSON.parse(a.content) as unknown as any[];
                if(recomendaciones){
                    // this.assetStore.bulkRecomendacion(recomendaciones);
                    this.diccionarioWitos= recomendaciones;
                }
            }
            else{
                this.errors = a.errors;
            }
        }); 
    }
    public async GetDiccionarioJdlink(){
        // console.log("api")
        await this.boiletService.get<any>('/api/Diccionarios/Diccionario_Diagnosticos_Jdlink', true)
        .then( a =>{
            // console.log(a)
            if(!a.isError){
                // let items = a.content as RecomendacionCliente[];
                // console.log(a.content)
                const recomendaciones = JSON.parse(a.content) as unknown as any[];
                if(recomendaciones){
                    // this.assetStore.bulkRecomendacion(recomendaciones);
                    this.diccionarioJdlink = recomendaciones;
                }
            }
            else{
                this.errors = a.errors;
            }
        }); 
    }
    public async GetDiccionarioGeotab(){
        // console.log("api")
        await this.boiletService.get<any>('/api/Diccionarios/Diccionario_Diagnosticos_Geotab', true)
        .then( a =>{
            // console.log(a)
            if(!a.isError){
                // let items = a.content as RecomendacionCliente[];
                // console.log(a.content)
                const recomendaciones = JSON.parse(a.content) as unknown as any[];
                if(recomendaciones){
                    // this.assetStore.bulkRecomendacion(recomendaciones);
                    this.diccionarioGeotab = recomendaciones;
                }
            }
            else{
                this.errors = a.errors;
            }
        }); 
    }
    public async GetDiccionarioEventoJdlink(){
        // console.log("api")
        await this.boiletService.get<any>('/api/Diccionarios/Diccionario_Eventos_Jdlink', true)
        .then( a =>{
            // console.log(a)
            if(!a.isError){
                // let items = a.content as RecomendacionCliente[];
                // console.log(a.content)
                const recomendaciones = JSON.parse(a.content) as unknown as any[];
                if(recomendaciones){
                    // this.assetStore.bulkRecomendacion(recomendaciones);
                    this.diccionarioEventoJdlink = recomendaciones;
                }
            }
            else{
                this.errors = a.errors;
            }
        }); 
    }
    public async GetDiccionarioEventoGeotab(){
        // console.log("api")
        await this.boiletService.get<any>('/api/Diccionarios/Diccionario_Eventos_Geotab', true)
        .then( a =>{
            // console.log(a)
            if(!a.isError){
                // let items = a.content as RecomendacionCliente[];
                // console.log(a.content)
                const recomendaciones = JSON.parse(a.content) as unknown as any[];
                if(recomendaciones){
                    // this.assetStore.bulkRecomendacion(recomendaciones);
                    this.diccionarioEventoGeotab = recomendaciones;
                }
            }
            else{
                this.errors = a.errors;
            }
        }); 
    }
    public async GetDiccionarioExpertas(){
      //  console.log("api")
        await this.boiletService.get<any>('/api/Diccionarios/Diccionario_Expertas_Jdlink', true)
        .then( a =>{
     //       console.log(a)
            if(!a.isError){
                // let items = a.content as RecomendacionCliente[];
      //          console.log(a.content)
                const recomendaciones = JSON.parse(a.content) as unknown as any[];
                if(recomendaciones){
                    // this.assetStore.bulkRecomendacion(recomendaciones);
                    this.diccionarioEventoExpertas = recomendaciones;
                }
            }
            else{
                this.errors = a.errors;
            }
        }); 
    }
    public async GetDiccionarioGuarddog(){
        // console.log("api")
        await this.boiletService.get<any>('/api/Diccionarios/Diccionario_Expertas_Guarddog', true)
        .then( a =>{
            // console.log(a)
            if(!a.isError){
                // let items = a.content as RecomendacionCliente[];
                // console.log(a.content)
                const recomendaciones = JSON.parse(a.content) as unknown as any[];
                if(recomendaciones){
                    // this.assetStore.bulkRecomendacion(recomendaciones);
                    this.diccionarioEventoGarddog = recomendaciones;
                }
            }
            else{
                this.errors = a.errors;
            }
        }); 
    }

    get cabeceraWITOS(){
        return [
            { text: 'ALERTA', value: 'tipoReco' ,align: 'starter',},
            { text: 'COLOR', value: 'color' ,align: 'starter',},
            { text: 'SEVERIDAD', value: 'severidad' ,align: 'starter' },
            // { text: 'TIPO', value: 'tipo' ,align: 'starter' },
            { text: 'CÓDIGO', value: 'codigo'  ,align: 'starter'},
            // { text: 'FMI', value: 'codigoModoDeFalla'  ,align: 'starter'},
            { text: 'SA', value: 'source' ,align: 'starter'},
            // { text: 'ECM', value: 'descripcionControlador' ,align: 'starter' },
            { text: 'MARCA', value: 'marca' ,align: 'starter' },
            { text: 'MODELO', value: 'modelo'  ,align: 'starter'},
            { text: 'FAMILIA', value: 'familia'  ,align: 'starter'},
            { text: 'DESCRIPCIÓN', value: 'descripcion' ,align: 'starter' },
        ];
    }
    get cabeceraWITOSplantilla(){
        return [
            { text: 'ALERTA', value: 'tipoReco' ,align: 'starter',},
            { text: 'RECOMENDACIÓN', value: 'alertaExperta'},
            { text: 'COLOR', value: 'color' ,align: 'starter',},
            { text: 'SEVERIDAD', value: 'severidad' ,align: 'starter' },
            // { text: 'TIPO', value: 'tipo' ,align: 'starter' },
            { text: 'CÓDIGO', value: 'codigo'  ,align: 'starter'},
            // { text: 'FMI', value: 'codigoModoDeFalla'  ,align: 'starter'},
            { text: 'SA', value: 'source' ,align: 'starter'},
            // { text: 'ECM', value: 'descripcionControlador' ,align: 'starter' },
            { text: 'MARCA', value: 'marca' ,align: 'starter' },
            { text: 'MODELO', value: 'modelo'  ,align: 'starter'},
            { text: 'FAMILIA', value: 'familia'  ,align: 'starter'},
            { text: 'DESCRIPCIÓN', value: 'descripcion' ,align: 'starter' },
            
        ];
    }
    get cabeceraJdlink(){
        return [
            { text: 'ALERTA', value: 'tipoReco' ,align: 'starter',},
            { text: 'COLOR', value: 'color' ,align: 'starter',},
            { text: 'SEVERIDAD', value: 'severidad' ,align: 'starter' },
            { text: 'TIPO', value: 'tipo' ,align: 'starter' },
            { text: 'CÓDIGO', value: 'codigo'  ,align: 'starter'},
            { text: 'FMI', value: 'codigoModoDeFalla'  ,align: 'starter'},
            { text: 'SA', value: 'sa' ,align: 'starter'},
            { text: 'ECM', value: 'descripcionControlador' ,align: 'starter' },
            { text: 'MARCA', value: 'marca' ,align: 'starter' },
            { text: 'MODELO', value: 'modelo'  ,align: 'starter'},
            { text: 'FAMILIA', value: 'familia'  ,align: 'starter'},
            { text: 'DESCRIPCIÓN', value: 'descripcion' ,align: 'starter' },
        ];
    }
    get cabeceraJdlinkPlantilla(){
        return [
            { text: 'ALERTA', value: 'tipoReco' },            
            { text: 'RECOMENDACIÓN', value: 'alertaExperta'},
            { text: 'AUTOR', value: 'autor'},
            { text: 'COLOR', value: 'color' },
            { text: 'SEVERIDAD', value: 'severidad' },
            { text: 'TIPO', value: 'tipo' },
            { text: 'CÓDIGO', value: 'codigo' },
            { text: 'FMI', value: 'codigoModoDeFalla' },
            { text: 'SA', value: 'sa'},
            { text: 'ECM', value: 'descripcionControlador' },
            { text: 'MARCA', value: 'marca' },
            { text: 'MODELO', value: 'modelo' },
            { text: 'FAMILIA', value: 'familia' },
            { text: 'DESCRIPCIÓN', value: 'descripcion' },
        ];
    }
    get cabeceraGeoab(){
        return [
            { text: 'ALERTA', value: 'tipoReco' ,align: 'starter' },
            { text: 'RSL', value: 'rsl' ,align: 'starter'},
            { text: 'MIL', value: 'mil' ,align: 'starter' },
            { text: 'AWL', value: 'awl' ,align: 'starter' },
            { text: 'PWL', value: 'pwl'  ,align: 'starter'},
            { text: 'TIPO', value: 'tipo' ,align: 'starter' },
            { text: 'CÓDIGO', value: 'codigo'  ,align: 'starter'},
            { text: 'FMI', value: 'codigoModoDeFalla' ,align: 'starter'},
            { text: 'BUS', value: 'bus'  ,align: 'starter'},
            { text: 'ECM', value: 'descripcionControlador' ,align: 'starter' },
            { text: 'MARCA', value: 'marca'  ,align: 'starter'},
            { text: 'MODELO', value: 'modelo' ,align: 'starter' },
            { text: 'FAMILIA', value: 'familia' ,align: 'starter' },
            { text: 'DESCRIPCIÓN', value: 'descripcion' ,align: 'starter' },
        ];
    }
    get cabeceraGeoabPlantilla(){
        return [
            { text: 'ALERTA', value: 'tipoReco' ,align: 'starter' },
            
            { text: 'RECOMENDACIÓN', value: 'alertaExperta' ,align: 'starter' },
            { text: 'AUTOR', value: 'autor' ,align: 'starter' },
            { text: 'LAMP', value: 'lampara' ,align: 'starter' },
            // { text: 'RSL', value: 'rsl' },
            // { text: 'MIL', value: 'mil' },
            // { text: 'AWL', value: 'awl' },
            // { text: 'PWL', value: 'pwl' },
            { text: 'TIPO', value: 'tipo'  ,align: 'starter' },
            { text: 'CÓDIGO', value: 'codigo' ,align: 'starter' },
            { text: 'FMI', value: 'codigoModoDeFalla' ,align: 'starter' },
            { text: 'BUS', value: 'bus' ,align: 'starter' },
            { text: 'ECM', value: 'descripcionControlador'  ,align: 'starter' },
            { text: 'MARCA', value: 'marca' ,align: 'starter' },
            { text: 'MODELO', value: 'modelo' ,align: 'starter' },
            { text: 'FAMILIA', value: 'familia'  ,align: 'starter' },
            { text: 'DESCRIPCIÓN', value: 'descripcion' ,align: 'starter' },,
        ];
    }
    get cabeceraEventoGeoabPlantilla(){
        return [
            { text: 'ALERTA', value: 'tipoReco'  ,align: 'starter' },          
            { text: 'RECOMENDACIÓN', value: 'alertaExperta' ,align: 'starter' },
            { text: 'AUTOR', value: 'autor' ,align: 'starter' },
            // { text: 'RSL', value: 'rsl' },
            // { text: 'MIL', value: 'mil' },
            // { text: 'AWL', value: 'awl' },
            // { text: 'PWL', value: 'pwl' },
            { text: 'TIPO', value: 'tipo'  ,align: 'starter' },
            { text: 'CÓDIGO', value: 'codigo'  ,align: 'starter' },
            { text: 'BUS', value: 'bus' ,align: 'starter' },
            { text: 'ECM', value: 'descripcionControlador'  ,align: 'starter' },
            { text: 'MARCA', value: 'marca'  ,align: 'starter' },
            { text: 'MODELO', value: 'modelo'  ,align: 'starter' },
            { text: 'FAMILILA', value: 'familia'  ,align: 'starter' },
            { text: 'DESCRIPCIÓN', value: 'descripcion' ,align: 'starter' },
        ];
    }
    get cabeceraEventoGeoab(){
        return [
            // { text: 'RSL', value: 'rsl' },
            // { text: 'MIL', value: 'mil' },
            // { text: 'AWL', value: 'awl' },
            // { text: 'PWL', value: 'pwl' },
            { text: 'ALERTA', value: 'tipoReco' ,align: 'starter' },
            { text: 'TIPO', value: 'tipo'  ,align: 'starter' },
            { text: 'CÓDIGO', value: 'codigo'  ,align: 'starter' },
            { text: 'BUS', value: 'bus'  ,align: 'starter' },
            { text: 'ECM', value: 'descripcionControlador'  ,align: 'starter' },
            { text: 'MARCA', value: 'marca'  ,align: 'starter' },
            { text: 'MODELO', value: 'modelo'  ,align: 'starter' },
            { text: 'FAMILIA', value: 'familia' ,align: 'starter' },
            { text: 'DESCRIPCIÓN', value: 'descripcion'  ,align: 'starter' },
        ];
    }
    get cabeceraEventosJdlinkPlantilla(){
        return [
            { text: 'ALERTA', value: 'tipoReco'  ,align: 'starter' },        
            { text: 'RECOMENDACIÓN', value: 'alertaExperta' ,align: 'starter' },
            { text: 'AUTOR', value: 'autor' ,align: 'starter' },
            { text: 'COLOR', value: 'color'  ,align: 'starter' },
            { text: 'SEVERIDAD', value: 'severidad' ,align: 'starter' },
            { text: 'TIPO', value: 'tipo'  ,align: 'starter' },
            { text: 'MARCA', value: 'marca'  ,align: 'starter' },
            { text: 'MODELO', value: 'modelo' ,align: 'starter' },
            { text: 'FAMILIA', value: 'familia'  ,align: 'starter' },
            { text: 'DESCRIPCIÓN', value: 'descripcion'  ,align: 'starter' },
        ];
    }
    get cabeceraEventosJdlink(){
        return [
            { text: 'ALERTA', value: 'tipoReco'  ,align: 'starter' },
            { text: 'COLOR', value: 'color'  ,align: 'starter' },
            { text: 'SEVERIDAD', value: 'severidad'  ,align: 'starter' },
            { text: 'TIPO', value: 'tipo'  ,align: 'starter' },
            { text: 'MARCA', value: 'marca'  ,align: 'starter' },
            { text: 'MODELO', value: 'modelo'  ,align: 'starter' },
            { text: 'FAMILIA', value: 'familia'  ,align: 'starter' },
            { text: 'DESCRIPCIÓN', value: 'descripcion'  ,align: 'starter' },
        ];
    }
    get cabeceraExpertasGuarddog(){
        return [
            { text: 'ALERTA', value: 'tipoReco'  ,align: 'starter' },
            { text: 'COLOR', value: 'severidad'  ,align: 'starter' },            
            { text: 'TIPO', value: 'tipoCodigo' ,align: 'starter' },
            { text: 'ECU', value: 'ecu' ,align: 'starter' },
            { text: 'CÓDIGO', value: 'codigo'  ,align: 'starter' },
            { text: 'FMI', value: 'fmi' ,align: 'starter' },
            // { text: 'Modelo', value: 'modelo' },
            // { text: 'Familia', value: 'familia' },
            { text: 'DESCRIPCIÓN', value: 'descripcionCodigo'  ,align: 'starter' },
            { text: 'FMI DESC', value: 'fmiDescripcion' ,align: 'starter' },
            { text: 'INSTRUCCIONES', value: 'instruccionesReparacion'  ,align: 'starter' },            
        ];
    }
    get cabeceraExpertasGuarddogPlantilla(){
        return [
            { text: 'ALERTA', value: 'tipoReco'  ,align: 'starter' },           
            { text: 'RECOMENDACIÓN', value: 'alertaExperta' ,align: 'starter' },
            { text: 'COLOR', value: 'severidad'  ,align: 'starter' },            
            { text: 'TIPO', value: 'tipoCodigo' ,align: 'starter' },
            { text: 'ECU', value: 'ecu' ,align: 'starter' },
            // { text: 'CÓDIGO', value: 'codigo'  ,align: 'starter' },
            { text: 'FMI', value: 'fmi' ,align: 'starter' },
            { text: 'TIPO', value: 'tipoCodigo'  ,align: 'starter' },
            { text: 'CÓDIGO', value: 'codigo'  ,align: 'starter' },
            { text: 'DESCRIPCIÓN', value: 'descripcionCodigo'  ,align: 'starter' },
            { text: 'FMI DESC', value: 'fmiDescripcion' ,align: 'starter' },
            { text: 'INSTRUCCIONES', value: 'instruccionesReparacion'  ,align: 'starter' },
        ];
    }
    get cabeceraExpertasJdlink(){
        return [
            { text: 'ALERTA', value: 'tipoReco'  ,align: 'starter' },
            // { text: 'Color', value: 'color' },
            // { text: 'Color', value: 'severidad' },
            { text: 'TIPO', value: 'tipoRecomendacion'  ,align: 'starter' },
            { text: 'CÓDIGO', value: 'dtac'  ,align: 'starter' },
            // { text: 'Tipo Antecedentes', value: 'tipoAntecedentes' },
            // { text: 'Marca', value: 'marca' },
            { text: 'MODELO', value: 'modelo'  ,align: 'starter' },
            // { text: 'Familia', value: 'familia' },
            // { text: 'Descripcion', value: 'descripcion' },
            { text: 'RESUMEN', value: 'resumen'  ,align: 'starter' },
            // { text: 'Recomendación', value: 'recomendacion' },
            { text: 'INSTRUCCIONES', value: 'instruccionesReparacion' ,align: 'starter' },
        ];
    }
    get cabeceraExpertasJdlinkPlantilla(){
        return [
            { text: 'ALERTA', value: 'tipoReco'  ,align: 'starter' },           
            { text: 'RECOMENDACIÓN', value: 'alertaExperta' ,align: 'starter' },
            { text: 'AUTOR', value: 'autor' ,align: 'starter' },
            { text: 'TIPO', value: 'tipoRecomendacion'  ,align: 'starter' },
            { text: 'CÓDIGO', value: 'dtac'  ,align: 'starter' },
            // { text: 'Tipo', value: 'tipo' },
            // { text: 'Marca', value: 'marca' },
            { text: 'MODELO', value: 'modelo' ,align: 'starter' },
            // { text: 'Familia', value: 'familia' },
            { text: 'DESCRIPCIÓN', value: 'descripcion'  ,align: 'starter' },
            { text: 'INSTRUCCIONES', value: 'instruccionesReparacion'  ,align: 'starter' },
        ];
    }

    public fileList= [] as any; //文件列表
    public headers: { [key: string]: string } = { Authorization: `Bearer ${AuthStore.getToken()}`};
    public dialogVisible= true;//Dialog显示状态
    public actionRequestUrl= AppConsts.baseApiUrl+'/api/Upload/UploadFile';
    public baseRequestUrl= AppConsts.baseApiUrl+'/api/Upload/';
    openImporDialog() {
        this.dialogVisible = true;
    }
    handlePreview(file: any) {
        console.log(file);
    }
    fileChange(file: any, fileList: any[]) {
    //解决无法判断el-upload是否上传过文件问题
    this.fileList = fileList;
    // console.log("Seleccione el contenido que se muestra después de que el archivo se haya cargado correctamente :", file, fileList);
    }
    handleRemove(file: any, fileList: any[]) {
        this.fileList = [];
        // return this.$confirm(`确定移除 ${file.name}？`);
    }
    fileUploadFail(err: any, file: any, fileList: any[]) {
        console.log("Error al cargar el archivo", file, fileList);
    }

    fileUploadSuccess000(response: any, file: any, fileList: any[]) {
        // console.log("上传成功");
        console.log(response);
        //清空已上传的文件列表
        //@ts-ignore
        this.$refs.upload000.clearFiles();
        if (response.newFileName) {
            this.dialogVisible = false;
        //   this.$message({
        //     message: response.message,
        //     type: "success"
        //   });
        this.editaItem00.alertaExperta.urlImagen = response.newFileName;
        } else {
            this.$message.error(response.message);
        }
    }
    fileUploadSuccess002(response: any, file: any, fileList: any[]) {
        // console.log("上传成功");
        console.log(response);
        //清空已上传的文件列表
        //@ts-ignore
        this.$refs.upload002.clearFiles();
        if (response.newFileName) {
            this.dialogVisible = false;
        //   this.$message({
        //     message: response.message,
        //     type: "success"
        //   });
        this.editaItem00.alertaExperta.urlImagen2 = response.newFileName;
        } else {
            this.$message.error(response.message);
        }
    }
    fileUploadSuccess110(response: any, file: any, fileList: any[]) {
        // console.log("上传成功");
        console.log(response);
        //清空已上传的文件列表
        //@ts-ignore
        this.$refs.upload110.clearFiles();
        if (response.newFileName) {
            this.dialogVisible = false;
        //   this.$message({
        //     message: response.message,
        //     type: "success"
        //   });
        this.editaItem11.alertaExperta.urlImagen = response.newFileName;
        } else {
            this.$message.error(response.message);
        }
    }
    fileUploadSuccess112(response: any, file: any, fileList: any[]) {
        // console.log("上传成功");
        console.log(response);
        //清空已上传的文件列表
        //@ts-ignore
        this.$refs.upload112.clearFiles();
        if (response.newFileName) {
            this.dialogVisible = false;
        //   this.$message({
        //     message: response.message,
        //     type: "success"
        //   });
        this.editaItem11.alertaExperta.urlImagen2 = response.newFileName;
        } else {
            this.$message.error(response.message);
        }
    }
    fileUploadSuccess220(response: any, file: any, fileList: any[]) {
        // console.log("上传成功");
        console.log(response);
        //清空已上传的文件列表
        //@ts-ignore
        this.$refs.upload220.clearFiles();
        if (response.newFileName) {
            this.dialogVisible = false;
        //   this.$message({
        //     message: response.message,
        //     type: "success"
        //   });
        this.editaItem22.alertaExperta.urlImagen = response.newFileName;
        } else {
            this.$message.error(response.message);
        }
    }
    fileUploadSuccess222(response: any, file: any, fileList: any[]) {
        // console.log("上传成功");
        console.log(response);
        //清空已上传的文件列表
        //@ts-ignore
        this.$refs.upload222.clearFiles();
        if (response.newFileName) {
            this.dialogVisible = false;
        //   this.$message({
        //     message: response.message,
        //     type: "success"
        //   });
        this.editaItem22.alertaExperta.urlImagen2 = response.newFileName;
        } else {
            this.$message.error(response.message);
        }
    }
    fileUploadSuccess330(response: any, file: any, fileList: any[]) {
        // console.log("上传成功");
        console.log(response);
        //清空已上传的文件列表
        //@ts-ignore
        this.$refs.upload330.clearFiles();
        if (response.newFileName) {
            this.dialogVisible = false;
        //   this.$message({
        //     message: response.message,
        //     type: "success"
        //   });
        this.editaItem33.alertaExperta.urlImagen = response.newFileName;
        } else {
            this.$message.error(response.message);
        }
    }
    fileUploadSuccess332(response: any, file: any, fileList: any[]) {
        // console.log("上传成功");
        console.log(response);
        //清空已上传的文件列表
        //@ts-ignore
        this.$refs.upload332.clearFiles();
        if (response.newFileName) {
            this.dialogVisible = false;
        //   this.$message({
        //     message: response.message,
        //     type: "success"
        //   });
        this.editaItem33.alertaExperta.urlImagen2 = response.newFileName;
        } else {
            this.$message.error(response.message);
        }
    }
    fileUploadSuccess440(response: any, file: any, fileList: any[]) {
        // console.log("上传成功");
        console.log(response);
        //清空已上传的文件列表
        //@ts-ignore
        this.$refs.upload440.clearFiles();
        if (response.newFileName) {
            this.dialogVisible = false;
        //   this.$message({
        //     message: response.message,
        //     type: "success"
        //   });
        this.editaItem44.alertaExperta.urlImagen = response.newFileName;
        } else {
            this.$message.error(response.message);
        }
    }
    fileUploadSuccess442(response: any, file: any, fileList: any[]) {
        // console.log("上传成功");
        console.log(response);
        //清空已上传的文件列表
        //@ts-ignore
        this.$refs.upload442.clearFiles();
        if (response.newFileName) {
            this.dialogVisible = false;
        //   this.$message({
        //     message: response.message,
        //     type: "success"
        //   });
        this.editaItem44.alertaExperta.urlImagen2 = response.newFileName;
        } else {
            this.$message.error(response.message);
        }
    }
    fileUploadSuccess550(response: any, file: any, fileList: any[]) {
        // console.log("上传成功");
        console.log(response);
        //清空已上传的文件列表
        //@ts-ignore
        this.$refs.upload550.clearFiles();
        if (response.newFileName) {
            this.dialogVisible = false;
        //   this.$message({
        //     message: response.message,
        //     type: "success"
        //   });
        this.editaItem55.alertaExperta.urlImagen = response.newFileName;
        } else {
            this.$message.error(response.message);
        }
    }
    fileUploadSuccess552(response: any, file: any, fileList: any[]) {
        // console.log("上传成功");
        console.log(response);
        //清空已上传的文件列表
        //@ts-ignore
        this.$refs.upload552.clearFiles();
        if (response.newFileName) {
            this.dialogVisible = false;
        //   this.$message({
        //     message: response.message,
        //     type: "success"
        //   });
        this.editaItem55.alertaExperta.urlImagen2 = response.newFileName;
        } else {
            this.$message.error(response.message);
        }
    }
    fileUploadSuccess660(response: any, file: any, fileList: any[]) {
        // console.log("上传成功");
        console.log(response);
        //清空已上传的文件列表
        //@ts-ignore
        this.$refs.upload660.clearFiles();
        if (response.newFileName) {
            this.dialogVisible = false;
        //   this.$message({
        //     message: response.message,
        //     type: "success"
        //   });
        this.editaItem66.alertaExperta.urlImagen = response.newFileName;
        } else {
            this.$message.error(response.message);
        }
    }
    fileUploadSuccess662(response: any, file: any, fileList: any[]) {
        // console.log("上传成功");
        console.log(response);
        //清空已上传的文件列表
        //@ts-ignore
        this.$refs.upload662.clearFiles();
        if (response.newFileName) {
            this.dialogVisible = false;
        //   this.$message({
        //     message: response.message,
        //     type: "success"
        //   });
        this.editaItem66.alertaExperta.urlImagen2 = response.newFileName;
        } else {
            this.$message.error(response.message);
        }
    }





    public Carga(a: any){
        this.editedIndex = this.diccionarioGeotab.findIndex( x => x._id == a._id );
        this.editaItem = Object.assign({}, this.diccionarioGeotab[this.editedIndex]);
        this.dialog = true;
    }
    public async Guardar(a: any){
        if(this.editaItem.tipoReco == "NINGUNA"){
            this.editaItem.tipoReco = null;
        }


        await this.boiletService.put<any>('/api/Diccionarios/Diccionario_Diagnosticos_Geotab/'+this.editaItem._id, JSON.stringify(this.editaItem))
        .then(r =>{
            if(!r.isError){
                this.diccionarioGeotab.splice(this.editedIndex,1,this.editaItem);
                this.editedIndex = -1;
                this.dialog = false;
            }else{
                this.errors.push(r.errors);
            }
        })

        
    }
    public Cancelar(){
        this.editedIndex = -1;
        this.editaItem = {} as any;
        this.dialog = false;

    }

    public async Guardar00(a: any){
        if(this.editaItem00.tipoReco == "NINGUNA"){
            this.editaItem00.tipoReco = null;
        }
        this.editaItem00.autor =this.authStore.getTokenData().sub;
        await this.boiletService.put<any>('/api/Diccionarios/Diccionario_Diagnosticos_Geotab/'+this.editaItem00._id, JSON.stringify(this.editaItem00))
        .then(r =>{
            if(!r.isError){
                this.diccionarioGeotab.splice(this.editedIndex00,1,this.editaItem00);
                this.modelosSeleccionados00.forEach(async (element: any) => {
                    var yu = this.diccionarioGeotab.findIndex(r => r._id == element);
                    if(yu > -1){
                        let tempo =  Object.assign({}, this.diccionarioGeotab[yu]); 
                        tempo.alertaExperta = Object.assign({}, this.editaItem00.alertaExperta);
                        tempo.autor =this.authStore.getTokenData().sub;
                        await this.boiletService.put<any>('/api/Diccionarios/Diccionario_Diagnosticos_Geotab/'+this.editaItem00._id, JSON.stringify(tempo))
                        .then((rt) =>{
                            if(!rt.isError){
                                this.diccionarioGeotab.splice(this.editedIndex00,1,tempo);
                            }
                        })  
                    }
                });
                this.editedIndex00 = -1;
                this.dialog00 = false;
                this.modelosSeleccionados00.splice(0);
            }else{
                this.errors.push(r.errors);
            }
        })
        
    }
    public Carga00(a: any){
        this.editedIndex00 = this.diccionarioGeotab.findIndex( x => x._id == a._id );
        let ae =  this.diccionarioGeotab[this.editedIndex00] as any;
        let modelos = this.diccionarioGeotab.filter( a => 
                            a.awl == ae.awl && 
                            a.mil == ae.mil && 
                            a.rsl == ae.rsl && 
                            a.pwl == ae.pwl && 
                            a.codigo == ae.codigo && 
                            a.tipo == ae.tipo && 
                            a.bus == ae.bus && 
                            a.tipoReco == ae.tipoReco &&
                            a.codigoControlador == ae.codigoControlador && 
                            a.codigoModoDeFalla == ae.codigoModoDeFalla).map( a => { return { _id: a._id, modelo: a.modelo  } }).filter(a => a.modelo).filter(e => e._id != ae._id);
        this.modelosCargados00 =  Object.assign([], modelos);
        console.log(ae)
        if(!ae.alertaExperta){
            ae.alertaExperta = {} as any;
        }
        if(!ae.alertaExperta.diagnostico){
            ae.alertaExperta.diagnostico = "";
        }
        if(!ae.alertaExperta.antecedentes){
            ae.alertaExperta.antecedentes = "";
        }
        if(!ae.alertaExperta.consecuencias){
            ae.alertaExperta.consecuencias = "";
        }
        if(!ae.alertaExperta.recomendacion){
            ae.alertaExperta.recomendacion = "";
        }
        if(!ae.alertaExperta.urlImagen){
            ae.alertaExperta.urlImagen = "";
        }
        if(!ae.alertaExperta.urlImagen2){
            ae.alertaExperta.urlImagen2 = "";
        }
        this.editaItem00 = Object.assign({}, ae);
        this.dialog00 = true;
    }
    public Cancelar00(){
        this.editedIndex00 = -1;
        this.editaItem00 = {} as any;
        this.dialog00 = false;
        this.modelosSeleccionados00.splice(0);
    }

    public async Guardar11(a: any){
        if(this.editaItem11.tipoReco == "NINGUNA"){
            this.editaItem11.tipoReco = null;
        }
        this.editaItem00.autor =this.authStore.getTokenData().sub;
        await this.boiletService.put<any>('/api/Diccionarios/Diccionario_Eventos_Geotab/'+this.editaItem11._id, JSON.stringify(this.editaItem11))
        .then(r =>{
            if(!r.isError){
                this.diccionarioEventoGeotab.splice(this.editedIndex11,1,this.editaItem11);
                this.modelosSeleccionados11.forEach(async (element: any) => {
                    var yu = this.diccionarioEventoGeotab.findIndex(r => r._id == element);
                    if(yu > -1){
                        let tempo =  Object.assign({}, this.diccionarioEventoGeotab[yu]); 
                        tempo.alertaExperta = Object.assign({}, this.editaItem11.alertaExperta);
                        tempo.autor =this.authStore.getTokenData().sub;
                        await this.boiletService.put<any>('/api/Diccionarios/Diccionario_Eventos_Geotab/'+this.editaItem11._id, JSON.stringify(tempo))
                        .then((rt) =>{
                            if(!rt.isError){
                                this.diccionarioEventoGeotab.splice(this.editedIndex11,1,tempo);
                            }
                        })  
                    }
                });
                this.editedIndex11 = -1;
                this.dialog11 = false;
                this.modelosSeleccionados11.splice(0);
                this.modelosCargados11.splice(0);
            }else{
                this.errors.push(r.errors);
            }
        })
        
    }
    public Carga11(a: any){
        this.editedIndex11 = this.diccionarioEventoGeotab.findIndex( x => x._id == a._id );
        let ae =  this.diccionarioEventoGeotab[this.editedIndex11] as any;
        let modelos = this.diccionarioGeotab.filter( a => 
                            // a.awl == ae.awl && 
                            // a.mil == ae.mil && 
                            // a.rsl == ae.rsl && 
                            // a.pwl == ae.pwl && 
                            a.codigo == ae.codigo && 
                            a.tipo == ae.tipo && 
                            a.bus == ae.bus && 
                            a.tipoReco == ae.tipoReco &&
                            a.codigoControlador == ae.codigoControlador 
                            // && 
                            // a.codigoModoDeFalla == ae.codigoModoDeFalla
                            ).map( a => { return { _id: a._id, modelo: a.modelo  } }).filter(a => a.modelo).filter(e => e._id != ae._id);
        this.modelosCargados11 =  Object.assign([], modelos);
        console.log(ae)
        if(!ae.alertaExperta){
            ae.alertaExperta = {} as any;
        }
        if(!ae.alertaExperta.diagnostico){
            ae.alertaExperta.diagnostico = "";
        }
        if(!ae.alertaExperta.antecedentes){
            ae.alertaExperta.antecedentes = "";
        }
        if(!ae.alertaExperta.consecuencias){
            ae.alertaExperta.consecuencias = "";
        }
        if(!ae.alertaExperta.recomendacion){
            ae.alertaExperta.recomendacion = "";
        }
        if(!ae.alertaExperta.urlImagen){
            ae.alertaExperta.urlImagen = "";
        }
        if(!ae.alertaExperta.urlImagen2){
            ae.alertaExperta.urlImagen2 = "";
        }
        this.editaItem11 = Object.assign({}, ae);
        this.dialog11 = true;
    }
    public Cancelar11(){
        this.editedIndex11 = -1;
        this.editaItem11 = {} as any;
        this.dialog11 = false;
        this.modelosSeleccionados11.splice(0);
        if(this.modelosCargados11){
            this.modelosCargados11.splice(0);
            }
    }

    public async Guardar22(a: any){
        if(this.editaItem22.tipoReco == "NINGUNA"){
            this.editaItem22.tipoReco = null;
        }
        this.editaItem00.autor =this.authStore.getTokenData().sub;
        await this.boiletService.put<any>('/api/Diccionarios/Diccionario_Diagnosticos_Jdlink/'+this.editaItem22._id, JSON.stringify(this.editaItem22))
        .then(r =>{
            if(!r.isError){
                this.diccionarioJdlink.splice(this.editedIndex22,1,this.editaItem22);
                this.modelosSeleccionados22.forEach(async (element: any) => {
                    var yu = this.diccionarioJdlink.findIndex(r => r._id == element);
                    if(yu > -1){
                        let tempo =  Object.assign({}, this.diccionarioJdlink[yu]); 
                        tempo.alertaExperta = Object.assign({}, this.editaItem22.alertaExperta);
                        tempo.autor =this.authStore.getTokenData().sub;
                        await this.boiletService.put<any>('/api/Diccionarios/Diccionario_Diagnosticos_Jdlink/'+tempo._id, JSON.stringify(tempo))
                        .then((rt) =>{
                            if(!rt.isError){
                                this.diccionarioJdlink.splice(this.editedIndex22,1,tempo);
                            }
                        })  
                    }
                });
                this.editedIndex22 = -1;
                this.dialog22 = false;
                this.modelosSeleccionados22.splice(0);
                this.modelosCargados22.splice(0);
            }else{
                this.errors.push(r.errors);
            }
        })
        
    }
    public Carga22(a: any){
        this.editedIndex22 = this.diccionarioJdlink.findIndex( x => x._id == a._id );
        let ae =  this.diccionarioJdlink[this.editedIndex22] as any;
        let modelos = this.diccionarioJdlink.filter( a => 
                             a.dtc_jdlink_id == ae.dtc_jdlink_id &&
                             a.tipoReco == ae.tipoReco 
                            ).map( a => { return { _id: a._id, modelo: a.modelo  } }).filter(a => a.modelo).filter(e => e._id != ae._id);
        this.modelosCargados22 =  Object.assign([], modelos);
        console.log(ae)
        if(!ae.alertaExperta){
            ae.alertaExperta = {} as any;
        }
        if(!ae.alertaExperta.diagnostico){
            ae.alertaExperta.diagnostico = "";
        }
        if(!ae.alertaExperta.antecedentes){
            ae.alertaExperta.antecedentes = "";
        }
        if(!ae.alertaExperta.consecuencias){
            ae.alertaExperta.consecuencias = "";
        }
        if(!ae.alertaExperta.recomendacion){
            ae.alertaExperta.recomendacion = "";
        }
        if(!ae.alertaExperta.urlImagen){
            ae.alertaExperta.urlImagen = "";
        }
        if(!ae.alertaExperta.urlImagen2){
            ae.alertaExperta.urlImagen2 = "";
        }
        this.editaItem22 = Object.assign({}, ae);
        this.dialog22 = true;
    }
    public Cancelar22(){
        this.editedIndex22 = -1;
        this.editaItem22 = {} as any;
        this.dialog22 = false;
        this.modelosSeleccionados22.splice(0);
        if(this.modelosCargados22){
            this.modelosCargados22.splice(0);
            }
    }

    public async Guardar33(a: any){
        if(this.editaItem33.tipoReco == "NINGUNA"){
            this.editaItem33.tipoReco = null;
        }
        this.editaItem00.autor =this.authStore.getTokenData().sub;
        await this.boiletService.put<any>('/api/Diccionarios/Diccionario_Eventos_Jdlink/'+this.editaItem33._id, JSON.stringify(this.editaItem33))
        .then(r =>{
            if(!r.isError){
                this.diccionarioEventoJdlink.splice(this.editedIndex33,1,this.editaItem33);
                this.modelosSeleccionados33.forEach(async (element: any) => {
                    var yu = this.diccionarioEventoJdlink.findIndex(r => r._id == element);
                    if(yu > -1){
                        let tempo =  Object.assign({}, this.diccionarioEventoJdlink[yu]); 
                        tempo.alertaExperta = Object.assign({}, this.editaItem33.alertaExperta);
                        tempo.autor =this.authStore.getTokenData().sub;
                        await this.boiletService.put<any>('/api/Diccionarios/Diccionario_Eventos_Jdlink/'+tempo._id, JSON.stringify(tempo))
                        .then((rt) =>{
                            if(!rt.isError){
                                this.diccionarioEventoJdlink.splice(this.editedIndex33,1,tempo);
                            }
                        })  
                    }
                });
                this.editedIndex33 = -1;
                this.dialog33 = false;
                this.modelosSeleccionados33.splice(0);
                this.modelosCargados33.splice(0);
    
            }else{
                this.errors.push(r.errors);
            }
        })
        
    }
    public Carga33(a: any){
        this.editedIndex33 = this.diccionarioEventoJdlink.findIndex( x => x._id == a._id );
        let ae =  this.diccionarioEventoJdlink[this.editedIndex33] as any;
        let modelos = this.diccionarioEventoJdlink.filter( a => 
                             a.descripcion == ae.descripcion &&
                             a.tipoReco == ae.tipoReco 
                           
                            ).map( a => { return { _id: a._id, modelo: a.modelo  } }).filter(a => a.modelo).filter(e => e._id != ae._id);
        this.modelosCargados33 =  Object.assign([], modelos);
        console.log(ae)
        if(!ae.alertaExperta){
            ae.alertaExperta = {} as any;
        }
        if(!ae.alertaExperta.diagnostico){
            ae.alertaExperta.diagnostico = "";
        }
        if(!ae.alertaExperta.antecedentes){
            ae.alertaExperta.antecedentes = "";
        }
        if(!ae.alertaExperta.consecuencias){
            ae.alertaExperta.consecuencias = "";
        }
        if(!ae.alertaExperta.recomendacion){
            ae.alertaExperta.recomendacion = "";
        }
        if(!ae.alertaExperta.urlImagen){
            ae.alertaExperta.urlImagen = "";
        }
        if(!ae.alertaExperta.urlImagen2){
            ae.alertaExperta.urlImagen2 = "";
        }
        this.editaItem33 = Object.assign({}, ae);
        this.dialog33 = true;
    }
    public Cancelar33(){
        this.editedIndex33 = -1;
        this.editaItem33 = {} as any;
        this.dialog33 = false;
        this.modelosSeleccionados33.splice(0);
        if(this.modelosCargados33){
            this.modelosCargados33.splice(0);
            }
    }

    public async Guardar44(a: any){
        if(this.editaItem44.tipoReco == "NINGUNA"){
            this.editaItem44.tipoReco = null;
        }
        this.editaItem00.autor =this.authStore.getTokenData().sub;
        await this.boiletService.put<any>('/api/Diccionarios/Diccionario_Expertas_Jdlink/'+this.editaItem44._id, JSON.stringify(this.editaItem44))
        .then(r =>{
            if(!r.isError){
                this.diccionarioEventoExpertas.splice(this.editedIndex44,1,this.editaItem44);
                this.modelosSeleccionados44.forEach(async (element: any) => {
                    var yu = this.diccionarioEventoExpertas.findIndex(r => r._id == element);
                    if(yu > -1){
                        let tempo =  Object.assign({}, this.diccionarioEventoExpertas[yu]); 
                        tempo.alertaExperta = Object.assign({}, this.editaItem44.alertaExperta);
                        tempo.autor =this.authStore.getTokenData().sub;
                        await this.boiletService.put<any>('/api/Diccionarios/Diccionario_Expertas_Jdlink/'+tempo._id, JSON.stringify(tempo))
                        .then((rt) =>{
                            if(!rt.isError){
                                this.diccionarioEventoExpertas.splice(this.editedIndex44,1,tempo);
                            }
                        })  
                    }
                });
                this.editedIndex44 = -1;
                this.dialog44 = false;
                this.modelosSeleccionados44.splice(0);
                this.modelosCargados44.splice(0);
            }else{
                this.errors.push(r.errors);
            }
        })
        
    }
    public Carga44(a: any){
        this.editedIndex44 = this.diccionarioEventoExpertas.findIndex( x => x._id == a._id );
        let ae =  this.diccionarioEventoExpertas[this.editedIndex44] as any;
        let modelos = this.diccionarioEventoExpertas.filter( a => 
                             a.dtac == ae.dtac &&
                             a.tipoReco == ae.tipoReco 
                           
                            ).map( a => { return { _id: a._id, modelo: a.modelo  } }).filter(a => a.modelo).filter(e => e._id != ae._id);
        this.modelosCargados44 =  Object.assign([], modelos);
        console.log(ae)
        if(!ae.alertaExperta){
            ae.alertaExperta = {} as any;
        }
        if(!ae.alertaExperta.diagnostico){
            ae.alertaExperta.diagnostico = "";
        }
        if(!ae.alertaExperta.antecedentes){
            ae.alertaExperta.antecedentes = "";
        }
        if(!ae.alertaExperta.consecuencias){
            ae.alertaExperta.consecuencias = "";
        }
        if(!ae.alertaExperta.recomendacion){
            ae.alertaExperta.recomendacion = "";
        }
        if(!ae.alertaExperta.urlImagen){
            ae.alertaExperta.urlImagen = "";
        }
        if(!ae.alertaExperta.urlImagen2){
            ae.alertaExperta.urlImagen2 = "";
        }
        this.editaItem44 = Object.assign({}, ae);
        this.dialog44 = true;
    }
    public Cancelar44(){
        this.editedIndex44 = -1;
        this.editaItem44 = {} as any;
        this.dialog44 = false;
        this.modelosSeleccionados44.splice(0);
        if(this.modelosCargados44){
            this.modelosCargados44.splice(0);
            }
    }

    public async Guardar55(a: any){
        if(this.editaItem55.tipoReco == "NINGUNA"){
            this.editaItem55.tipoReco = null;
        }
        
        await this.boiletService.put<any>('/api/Diccionarios/Diccionario_Expertas_Guarddog/'+this.editaItem55._id, JSON.stringify(this.editaItem55))
        .then(r =>{
            if(!r.isError){
                this.diccionarioEventoExpertas.splice(this.editedIndex55,1,this.editaItem55);
                this.modelosSeleccionados55.forEach(async (element: any) => {
                    var yu = this.diccionarioEventoExpertas.findIndex(r => r._id == element);
                    if(yu > -1){
                        let tempo =  Object.assign({}, this.diccionarioEventoExpertas[yu]); 
                        tempo.alertaExperta = Object.assign({}, this.editaItem55.alertaExperta);
                        tempo.autor =this.authStore.getTokenData().sub;
                        await this.boiletService.put<any>('/api/Diccionarios/Diccionario_Expertas_Guarddog/'+tempo._id, JSON.stringify(tempo))
                        .then((rt) =>{
                            if(!rt.isError){
                                this.diccionarioEventoExpertas.splice(this.editedIndex55,1,tempo);
                            }
                        })  
                    }
                });
                this.editedIndex55 = -1;
                this.dialog55 = false;
                this.modelosSeleccionados55.splice(0);
                this.modelosCargados55.splice(0);
            }else{
                this.errors.push(r.errors);
            }
        })
        
    }
    public Carga55(a: any){
        this.editedIndex55 = this.diccionarioEventoGarddog.findIndex( x => x._id == a._id );
        let ae =  this.diccionarioEventoGarddog[this.editedIndex55] as any;
        let modelos = this.diccionarioEventoGarddog.filter( a => 
                             a.severidad == ae.severidad &&
                             a.tipoCodigo == ae.tipoCodigo &&
                             a.codigo == ae.codigo &&
                             a.ecu == ae.ecu &&
                             a.fmi == ae.fmi &&
                             a.tipoReco == ae.tipoReco 
                           
                            ).map( a => { return { _id: a._id, modelo: a.modelo  } }).filter(a => a.modelo).filter(e => e._id != ae._id);
        this.modelosCargados55 =  Object.assign([], modelos);
        console.log(ae)
        if(!ae.alertaExperta){
            ae.alertaExperta = {} as any;
        }
        if(!ae.alertaExperta.diagnostico){
            ae.alertaExperta.diagnostico = "";
        }
        if(!ae.alertaExperta.antecedentes){
            ae.alertaExperta.antecedentes = "";
        }
        if(!ae.alertaExperta.consecuencias){
            ae.alertaExperta.consecuencias = "";
        }
        if(!ae.alertaExperta.recomendacion){
            ae.alertaExperta.recomendacion = "";
        }
        if(!ae.alertaExperta.urlImagen){
            ae.alertaExperta.urlImagen = "";
        }
        if(!ae.alertaExperta.urlImagen2){
            ae.alertaExperta.urlImagen2 = "";
        }
        this.editaItem55 = Object.assign({}, ae);
        this.dialog55 = true;
    }
    public Cancelar55(){
        this.editedIndex55 = -1;
        this.editaItem55 = {} as any;
        this.dialog55 = false;
        this.modelosSeleccionados55.splice(0);
        // this.modelosCargados55.splice(0);
        if(this.modelosCargados55){
            this.modelosCargados55.splice(0);
            }
    }

    public async Guardar66(a: any){
        if(this.editaItem66.tipoReco == "NINGUNA"){
            this.editaItem66.tipoReco = null;
        }
        
        await this.boiletService.put<any>('/api/Diccionarios/Diccionario_Diagnosticos_Witos/'+this.editaItem66._id, JSON.stringify(this.editaItem66))
        .then(r =>{
            if(!r.isError){
                this.diccionarioWitos.splice(this.editedIndex66,1,this.editaItem66);
                this.modelosSeleccionados66.forEach(async (element: any) => {
                    var yu = this.diccionarioWitos.findIndex(r => r._id == element);
                    if(yu > -1){
                        let tempo =  Object.assign({}, this.diccionarioWitos[yu]); 
                        tempo.alertaExperta = Object.assign({}, this.editaItem66.alertaExperta);
                        tempo.autor =this.authStore.getTokenData().sub;
                        await this.boiletService.put<any>('/api/Diccionarios/Diccionario_Diagnosticos_Witos/'+tempo._id, JSON.stringify(tempo))
                        .then((rt) =>{
                            if(!rt.isError){
                                this.diccionarioWitos.splice(this.editedIndex66,1,tempo);
                            }
                        })  
                    }
                });
                this.editedIndex66 = -1;
                this.dialog66 = false;
                this.modelosSeleccionados66.splice(0);
                this.modelosCargados66.splice(0);
            }else{
                this.errors.push(r.errors);
            }
        })
        
    }
    public Carga66(a: any){
        this.editedIndex66 = this.diccionarioWitos.findIndex( x => x._id == a._id );
        let ae =  this.diccionarioWitos[this.editedIndex66] as any;
        let modelos = this.diccionarioWitos.filter( a => 
                             a.severidad == ae.severidad &&
                             a.color == ae.color &&
                             a.codigo == ae.codigo &&
                            //  a.modelo == ae.modelo &&
                             a.tipoReco == ae.tipoReco 
                           
                            ).map( a => { return { _id: a._id, modelo: a.modelo  } }).filter(a => a.modelo).filter(e => e._id != ae._id) as any[];
        this.modelosCargados66=  Object.assign([], modelos);
        console.log(ae)
        if(!ae.alertaExperta){
            ae.alertaExperta = {} as any;
        }
        if(!ae.alertaExperta.diagnostico){
            ae.alertaExperta.diagnostico = "";
        }
        if(!ae.alertaExperta.antecedentes){
            ae.alertaExperta.antecedentes = "";
        }
        if(!ae.alertaExperta.consecuencias){
            ae.alertaExperta.consecuencias = "";
        }
        if(!ae.alertaExperta.recomendacion){
            ae.alertaExperta.recomendacion = "";
        }
        if(!ae.alertaExperta.urlImagen){
            ae.alertaExperta.urlImagen = "";
        }
        if(!ae.alertaExperta.urlImagen2){
            ae.alertaExperta.urlImagen2 = "";
        }
        this.editaItem66 = Object.assign({}, ae);
        this.dialog66 = true;
    }
    public Cancelar66(){
        this.editedIndex66 = -1;
        this.editaItem66 = {} as any;
        this.dialog66 = false;
        this.modelosSeleccionados66.splice(0);
        if(this.modelosCargados66){
        this.modelosCargados66.splice(0);
        }
    }


    public Carga1(a: any){
        this.editedIndex1 = this.diccionarioEventoGeotab.findIndex( x => x._id == a._id );
        this.editaItem1 = Object.assign({}, this.diccionarioEventoGeotab[this.editedIndex1]);
        this.dialog1 = true;
    }
    public async Guardar1(a: any){
        
       if(this.editaItem1.tipoReco == "NINGUNA"){
            this.editaItem1.tipoReco = null;
        }

        await this.boiletService.put<any>('/api/Diccionarios/Diccionario_Eventos_Geotab/'+this.editaItem1._id, JSON.stringify(this.editaItem1))
        .then(r =>{
            if(!r.isError){
                this.diccionarioEventoGeotab.splice(this.editedIndex1,1,this.editaItem1);
                this.editedIndex1 = -1;
                this.dialog1 = false;
            }else{
                this.errors.push(r.errors);
            }
        })



    }
    public Cancelar1(){
        this.editedIndex1 = -1;
        this.editaItem1 = {} as any;
        this.dialog1 = false;
    }
    public Carga2(a: any){
        this.editedIndex2 = this.diccionarioJdlink.findIndex( x => x._id == a._id );
        this.editaItem2 = Object.assign({}, this.diccionarioJdlink[this.editedIndex2]);
        this.dialog2 = true;
    }
    public async Guardar2(a: any){
        if(this.editaItem2.tipoReco == "NINGUNA"){
            this.editaItem2.tipoReco = null;
        }

        await this.boiletService.put<any>('/api/Diccionarios/Diccionario_Diagnosticos_Jdlink/'+this.editaItem2._id, JSON.stringify(this.editaItem2))
        .then(r =>{
            if(!r.isError){
                this.diccionarioJdlink.splice(this.editedIndex2,1,this.editaItem2);
                this.editedIndex2 = -1;
                this.dialog2 = false;
            }else{
                this.errors.push(r.errors);
            }
        })
    }
    public Cancelar2(){
        this.editedIndex2 = -1;
        this.editaItem2 = {} as any;
        this.dialog2 = false;
    }
    public Carga3(a: any){
        this.editedIndex3 = this.diccionarioEventoJdlink.findIndex( x => x._id == a._id );
        this.editaItem3 = Object.assign({}, this.diccionarioEventoJdlink[this.editedIndex3]);
        this.dialog3 = true;
    }
    public async Guardar3(a: any){
        // if(this.editaItem3.tipoReco == "NINGUNA"){
        //     this.editaItem3.tipoReco = null;
        // }
        // this.diccionarioEventoJdlink.splice(this.editedIndex3,1,this.editaItem3);
        // this.editedIndex3 = -1;
        // this.dialog3 = false;



        // if(this.editaItem3.tipoReco == "NINGUNA"){
        //     this.editaItem3.tipoReco = null;
        // }
        // this.diccionarioJdlink.splice(this.editedIndex3,1,this.editaItem3);
        // this.editedIndex3 = -1;
        // this.dialog3 = false;

        if(this.editaItem3.tipoReco == "NINGUNA"){
            this.editaItem3.tipoReco = null;
        }

        await this.boiletService.put<any>('/api/Diccionarios/Diccionario_Eventos_Jdlink/'+this.editaItem3._id, JSON.stringify(this.editaItem3))
        .then(r =>{
            if(!r.isError){
                this.diccionarioEventoJdlink.splice(this.editedIndex3,1,this.editaItem3);
                this.editedIndex3 = -1;
                this.dialog3 = false;
            }else{
                this.errors.push(r.errors);
            }
        })



        
    }
    public Cancelar3(){
        this.editedIndex3 = -1;
        this.editaItem3 = {} as any;
        this.dialog3 = false;
    }
    public Carga4(a: any){
        this.editedIndex4 = this.diccionarioEventoExpertas.findIndex( x => x._id == a._id );
        this.editaItem4 = Object.assign({}, this.diccionarioEventoExpertas[this.editedIndex4]);
        this.dialog4 = true;
    }
    public Guardar4(a: any){
        if(this.editaItem4.tipoReco == "NINGUNA"){
            this.editaItem4.tipoReco = null;
        }
        this.diccionarioEventoExpertas.splice(this.editedIndex4,1,this.editaItem4);
        this.editedIndex4 = -1;
        this.dialog4 = false;
    }
    public Cancelar4(){
        this.editedIndex4 = -1;
        this.editaItem4 = {} as any;
        this.dialog4 = false;
    }
    public Carga5(a: any){
        this.editedIndex5 = this.diccionarioEventoGarddog.findIndex( x => x._id == a._id );
        this.editaItem5 = Object.assign({}, this.diccionarioEventoGarddog[this.editedIndex5]);
        this.dialog5 = true;
    }
    public Guardar5(a: any){
        if(this.editaItem5.tipoReco == "NINGUNA"){
            this.editaItem5.tipoReco = null;
        }
        this.diccionarioEventoGarddog.splice(this.editedIndex5,1,this.editaItem5);
        this.editedIndex5 = -1;
        this.dialog5 = false;
    }
    public Cancelar5(){
        this.editedIndex5 = -1;
        this.editaItem5 = {} as any;
        this.dialog5 = false;
    }

    public Carga6(a: any){
        this.editedIndex6 = this.diccionarioWitos.findIndex( x => x._id == a._id );
        this.editaItem6 = Object.assign({}, this.diccionarioWitos[this.editedIndex6]);
        this.dialog6 = true;
    }
    public async Guardar6(a: any){
        if(this.editaItem6.tipoReco == "NINGUNA"){
            this.editaItem6.tipoReco = null;
        }
        

        await this.boiletService.put<any>('/api/Diccionarios/Diccionario_Diagnosticos_Witos/'+this.editaItem6._id, JSON.stringify(this.editaItem6))
        .then(r =>{
            if(!r.isError){
                this.diccionarioWitos.splice(this.editedIndex6,1,this.editaItem6);
                this.editedIndex6 = -1;
                this.dialog6 = false;
            }else{
                this.errors.push(r.errors);
            }
        })
    }
    public Cancelar6(){
        this.editedIndex6 = -1;
        this.editaItem6 = {} as any;
        this.dialog6 = false;
    }




    CsvExporta(cabecera: any[], datos: any[], nombreArchivo: string){
        const aline = [] as string[];
        const arrData = [] as any[];
        datos.forEach((e:any) => {
            let a = {} as any[];
            let line = [] as string[];
            cabecera.filter(
                (t:any) => 
                    t.value != "alertaExperta" 
                ).forEach((t:any) =>{
                    if(!(e[t.value] != null &&e[t.value] != undefined)){
                        a[t.value] = '';
                    }
                    else{
                        a[t.value] = e[t.value]; 
                    }
                line.push((e[t.value] ? e[t.value]: '').toString().replace(/;/ig," ")
                                                .replace(/-/ig," ")
                                                .replace(/#/ig, ' ')
                                                .replace(/\[/ig, '')
                                                .replace(/\]/ig, '')
                                                .replace(/\(/ig, '')
                                                .replace(/\)/ig, '')
                                                .replace(/\&/ig, '')
                                                .replace(/\[/ig, '')
                                                .replace(/\%/ig, '')
                                                .replace(/\~/ig, '')
                                                .replace(/\n/ig,"")); 
                                                arrData.push(a);   
                                                // console.log(a)         
            })
            aline.push(line.join('|'));
        }); 
        let final ="data:text/csv;charset=utf-8,";
        final += Object.keys(arrData[0]).join("|")+ "\n"
        final += aline.join("\n")
        .replace(/(^\[)|(\]$)/gm, "");
        const data = encodeURI(final);
        console.log(data)
        const link = document.createElement("a");
        link.setAttribute("href", data);
        link.setAttribute("download", nombreArchivo+".csv");
        link.click();
    }

    CsvExportaReco(cabecera: any[], datos: any[], nombreArchivo: string){
        const aline = [] as string[];
        const arrData = [] as any[];
        datos.forEach((e:any) => {
            let a = {} as any[];
            let line = [] as string[];
            cabecera.forEach((t:any) =>{
                if(t.value == "alertaExperta" ){
                    if(e[t.value]){
                        const r = e[t.value];
                        if(r["diagnostico"]){
                            const nombre = "diagnostico" as any;
                            a[nombre] = (r["diagnostico"] ? r["diagnostico"]: '')
                            .toString().replace(/;/ig," ")
                            .replace(/-/ig," ")
                            .replace(/#/ig, ' ')
                            .replace(/\[/ig, '')
                            .replace(/\]/ig, '')
                            .replace(/\(/ig, '')
                            .replace(/\)/ig, '')
                            .replace(/\&/ig, '')
                            .replace(/\[/ig, '')
                            .replace(/\%/ig, '')
                            .replace(/\~/ig, '')
                            .replace(/\n/ig,"");
                            line.push((r["diagnostico"] ? r["diagnostico"]: '')
                            .toString().replace(/;/ig," ")
                            .replace(/-/ig," ")
                            .replace(/#/ig, ' ')
                            .replace(/\[/ig, '')
                            .replace(/\]/ig, '')
                            .replace(/\(/ig, '')
                            .replace(/\)/ig, '')
                            .replace(/\&/ig, '')
                            .replace(/\[/ig, '')
                            .replace(/\%/ig, '')
                            .replace(/\~/ig, '')
                            .replace(/\n/ig,""));
                        }else{
                            const nombre = "diagnostico" as any;
                            a[nombre] = "";
                            line.push("");
                        }
                        if(r["antecedentes"]){
                            const nombre = "antecedentes" as any;
                            a[nombre] = (r["antecedentes"] ? r["antecedentes"]: '')
                            .toString().replace(/;/ig," ")
                            .replace(/-/ig," ")
                            .replace(/#/ig, ' ')
                            .replace(/\[/ig, '')
                            .replace(/\]/ig, '')
                            .replace(/\(/ig, '')
                            .replace(/\)/ig, '')
                            .replace(/\&/ig, '')
                            .replace(/\[/ig, '')
                            .replace(/\%/ig, '')
                            .replace(/\~/ig, '')
                            .replace(/\n/ig,"");
                            line.push((r["antecedentes"] ? r["antecedentes"]: '')
                            .toString().replace(/;/ig," ")
                            .replace(/-/ig," ")
                            .replace(/#/ig, ' ')
                            .replace(/\[/ig, '')
                            .replace(/\]/ig, '')
                            .replace(/\(/ig, '')
                            .replace(/\)/ig, '')
                            .replace(/\&/ig, '')
                            .replace(/\[/ig, '')
                            .replace(/\%/ig, '')
                            .replace(/\~/ig, '')
                            .replace(/\n/ig,""));
                        }else{
                            const nombre = "antecedentes" as any;
                            a[nombre] = "";
                            line.push("");
                        }
                        if(r["consecuencias"]){
                            const nombre = "consecuencias" as any;
                            a[nombre] = (r["consecuencias"] ? r["consecuencias"]: '')
                            .toString().replace(/;/ig," ")
                            .replace(/-/ig," ")
                            .replace(/#/ig, ' ')
                            .replace(/\[/ig, '')
                            .replace(/\]/ig, '')
                            .replace(/\(/ig, '')
                            .replace(/\)/ig, '')
                            .replace(/\&/ig, '')
                            .replace(/\[/ig, '')
                            .replace(/\%/ig, '')
                            .replace(/\~/ig, '')
                            .replace(/\n/ig,"");
                            line.push((r["consecuencias"] ? r["consecuencias"]: '')
                            .toString().replace(/;/ig," ")
                            .replace(/-/ig," ")
                            .replace(/#/ig, ' ')
                            .replace(/\[/ig, '')
                            .replace(/\]/ig, '')
                            .replace(/\(/ig, '')
                            .replace(/\)/ig, '')
                            .replace(/\&/ig, '')
                            .replace(/\[/ig, '')
                            .replace(/\%/ig, '')
                            .replace(/\~/ig, '')
                            .replace(/\n/ig,""));
                        }else{
                            const nombre = "consecuencias" as any;
                            a[nombre] = "";
                            line.push("");
                        }
                        if(r["recomendacion"]){
                            const nombre = "recomendacion" as any;
                            a[nombre] = (r["recomendacion"] ? r["recomendacion"]: '')
                            .toString().replace(/;/ig," ")
                            .replace(/-/ig," ")
                            .replace(/#/ig, ' ')
                            .replace(/\[/ig, '')
                            .replace(/\]/ig, '')
                            .replace(/\(/ig, '')
                            .replace(/\)/ig, '')
                            .replace(/\&/ig, '')
                            .replace(/\[/ig, '')
                            .replace(/\%/ig, '')
                            .replace(/\~/ig, '')
                            .replace(/\n/ig,"");
                            line.push((r["recomendacion"] ? r["recomendacion"]: '')
                            .toString().replace(/;/ig," ")
                            .replace(/-/ig," ")
                            .replace(/#/ig, ' ')
                            .replace(/\[/ig, '')
                            .replace(/\]/ig, '')
                            .replace(/\(/ig, '')
                            .replace(/\)/ig, '')
                            .replace(/\&/ig, '')
                            .replace(/\[/ig, '')
                            .replace(/\%/ig, '')
                            .replace(/\~/ig, '')
                            .replace(/\n/ig,""));
                        }else{
                            const nombre = "recomendacion" as any;
                            a[nombre] = "";
                            line.push("");
                        }
                        if(r["urlImagen"]){
                            line.push((r["urlImagen"] ? r["urlImagen"]: '')
                            .toString().replace(/;/ig," ")
                            .replace(/-/ig," ")
                            .replace(/#/ig, ' ')
                            .replace(/\[/ig, '')
                            .replace(/\]/ig, '')
                            .replace(/\(/ig, '')
                            .replace(/\)/ig, '')
                            .replace(/\&/ig, '')
                            .replace(/\[/ig, '')
                            .replace(/\%/ig, '')
                            .replace(/\~/ig, '')
                            .replace(/\n/ig,""));
                        }else{
                            const nombre = "urlImagen" as any;
                            a[nombre] = "";
                            line.push("");
                        }
                        if(r["urlImagen2"]){
                            line.push((r["urlImagen2"] ? r["urlImagen2"]: '')
                            .toString().replace(/;/ig," ")
                            .replace(/-/ig," ")
                            .replace(/#/ig, ' ')
                            .replace(/\[/ig, '')
                            .replace(/\]/ig, '')
                            .replace(/\(/ig, '')
                            .replace(/\)/ig, '')
                            .replace(/\&/ig, '')
                            .replace(/\[/ig, '')
                            .replace(/\%/ig, '')
                            .replace(/\~/ig, '')
                            .replace(/\n/ig,""));
                        }else{
                            const nombre = "urlImagen2" as any;
                            a[nombre] = "";
                            line.push("");
                        }
                    }else{
                        line.push("");
                        const nombre4 = "urlImagen" as any;
                        a[nombre4] = "";
                        const nombre3 = "urlImagen2" as any;
                        a[nombre3] = "";
                        const nombre2 = "diagnostico" as any;
                        a[nombre2] = "";
                        const nombre1 = "antecedentes" as any;
                        a[nombre1] = "";
                        const nombre0 = "consecuencias" as any;
                        a[nombre0] = "";
                        const nombre = "recomendacion" as any;
                        a[nombre] = "";
                        line.push("");
                        line.push("");
                        line.push("");
                        line.push("");
                        line.push("");
                    }
                }else{
                    if(!(e[t.value] != null &&e[t.value] != undefined)){
                        a[t.value] = '';
                    }
                    else{
                        a[t.value] = e[t.value]; 
                    }
                    line.push((e[t.value] ? e[t.value]: '')
                        .toString().replace(/;/ig," ")
                        .replace(/-/ig," ")
                        .replace(/#/ig, ' ')
                        .replace(/\[/ig, '')
                        .replace(/\]/ig, '')
                        .replace(/\(/ig, '')
                        .replace(/\)/ig, '')
                        .replace(/\&/ig, '')
                        .replace(/\[/ig, '')
                        .replace(/\%/ig, '')
                        .replace(/\~/ig, '')
                        .replace(/\n/ig,"")); 
                    arrData.push(a);  
                }          
            });
            aline.push(line.join('|'));
            
        }); 
        let final ="data:text/csv;charset=utf-8,";
        final += Object.keys(arrData[0]).join("|")+ "\n"
        final += aline.join("\n")
        .replace(/(^\[)|(\]$)/gm, "");
        const data = encodeURI(final);
        console.log(data)
        const link = document.createElement("a");
        link.setAttribute("href", data);
        link.setAttribute("download", nombreArchivo+".csv");
        link.click();
    }
}