import BoiletComponentBase from '@/shared/application/boilet-component-base';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class MiniItemComponent extends BoiletComponentBase {
    @Prop({default: ''})  public texto!: string;
    @Prop({default: ''})  public leyenda!: string;
    @Prop({default: '#002855'})  public color!: string;
    @Prop({default: 0})  public cantidad!: number;
    @Prop({default: 630})  public largo!: number;
    get numero(){
        return this.cantidad;
    }
}