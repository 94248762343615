import BoiletComponentBase from '@/shared/application/boilet-component-base';
import { Component } from 'vue-property-decorator';

@Component({
    components: {
        // AsideMenu: require('@/admin/components/menu/aside-menu/aside-menu.vue').default,
        // TopMenu: require('@/admin/components/menu/top-menu/top-menu.vue').default,
        // FooterMenu: require('@/admin/components/menu/footer-menu/footer-menu.vue').default

        AsideMenu: require('@/gestion/components/menu/aside-menu/aside-menu.vue').default,
        TopMenu: require('@/gestion/components/menu/top-menu/top-menu.vue').default,
        FooterMenu: require('@/gestion/components/menu/footer-menu/footer-menu.vue').default
    }
})
export default class AdminLayoutComponent extends BoiletComponentBase {
    public created() {
        this.boilet.auth.fillProps();
    }
}