import BoiletComponentBase from '@/shared/application/boilet-component-base';
import { Activo, Maquina } from '@/shared/models/shared/consolidados/Maquina';
import { Organizacion } from '@/shared/models/shared/consolidados/Organizacion';
import { Component, Watch } from 'vue-property-decorator';
import { GestionActivosStore } from '@/gestion/stores/assets/activos';
import { Sucursales } from '@/shared/models/shared/consolidados/Sucursales';
import { RecomendacionCliente } from '@/shared/models/shared/consolidados/RecomendacionCliente';
import { CoordenadasActivosStore } from '@/gestion/stores/assets/coordenadas'
import moment from 'moment';
import { IconosSvgStore } from '@/gestion/stores/assets/iconos';
// import { useGestionStore } from './stores/assets/gestion';


@Component({
    components: {
        AsideMenu: require('./components/menu/aside-menu/aside-menu.vue').default,
        TopMenu: require('./components/menu/top-menu/top-menu.vue').default,
        FooterMenu: require('./components/menu/footer-menu/footer-menu.vue').default
    }
})
export default class GestionLayoutComponent extends BoiletComponentBase {
    public cargaAsyncronaActivos = {} as any;
    public errors: any = null;
    public organizaciones : Organizacion[] = [] as Organizacion[];
    public assetStore = GestionActivosStore();
    public hasta = new Date().toISOString().substr(0, 10);
    public desde = moment().add(-14, 'days').toISOString().substr(0, 10);
    public iconosStore = IconosSvgStore();
    public coordenadas = CoordenadasActivosStore();
    public windowsSize = { x: 0, y: 0};
    public userData: any = null;
    public asyncUsuarioActivo = {} as any;
    // public useSize = useGestionStore();

    // @Watch('windowsSize')
    // func(val:any){
    //     this.useSize.updateSize(val);
    // }


    public beforeMount() {
        // this.selectedLanguage = LanguageStore.getLanguage();
        // this.windowsSize =  { x: window.innerWidth , y: window.innerHeight };
        // this.useSize.updateSize(this.windowsSize);
    }

    // public onResize(){
    //     this.windowsSize =  { x: window.innerWidth , y: window.innerHeight };
    //     this.useSize.updateSize(this.windowsSize);
    //     // SharedStore.setSize(this.windowsSize);
    // }

    // get Size(){
    //     return this.useSize.tamano;
    //  }

    //  ActualizaTodo(){
    //     // this.GetPosiciones();
    //     this.GetActivoss();
    //  }

    public async created() {
        this.boilet.auth.fillProps();
        // this.cargaAsyncronaActivos = setInterval(this.ActualizaTodo, 30000);
        this.asyncUsuarioActivo = setInterval(this.CargarEstadoActivo, 30000);
    }
    
    public async mounted(){
       
        // await this.GetActivoss();
        // this.GetSucursales();
        // this.GetOrganizaciones();
        // this.GetPosiciones();
        // this.GetActivoss();
        this.windowsSize =  { x: window.innerWidth , y: window.innerHeight };
        // this.useSize.updateSize(this.windowsSize);
    }

    destroy() {
        clearInterval(this.asyncUsuarioActivo)
        this.asyncUsuarioActivo = null;
    }
    beforeDestroy () {
        clearInterval(this.asyncUsuarioActivo)
        this.asyncUsuarioActivo = null;
    }


    @Watch('ConsolidadoMapa', { deep:true})
    onPaginationChanged() {
        this.assetStore.bulkConsolidado(this.ConsolidadoMapa);
    }

    public CargarEstadoActivo(){
        this.userData = this.authStore.getTokenData().sub;
        
        this.boiletService.put<any>('/api/Latidos', this.userData, false)
        .then( r => {
            if(!r.isError){
            }else{
                this.errors = r.errors;
            }
        });
    }

    // public async GetActivoss(){
    //     await this.boiletService.get<string>('/api/Activo',false)
    //     .then( r => {
    //         if(!r.isError){
                
    //             const sucursales = JSON.parse(r.content) as unknown as any[];
    //             if(sucursales){
                   
    //                 // console.log(r.content)
    //                 this.assetStore.bulkActivos(
    //                     sucursales.map(t =>{
    //                         let g = t;
    //                         g.url = this.SeleccionaIcon(g.marca, g.familia, g.modelo);
    //                         return g;
    //                     })
                        
    //                 );
    //                this.boilet.isLoading = false;
    //             }
    //             // console.log(this.assetStore.activosConsolidadosArray)
    //         }else{
    //             this.errors = r.errors;
    //         }
    //     });
    // }
    // public async GetPosiciones(){
    //     await this.boiletService.get<string>('/api/Coordenadas',false)
    //     .then( r => {
    //         if(!r.isError){
    //             if(r.content.length > 0){
    //                 const sucursales = JSON.parse(r.content) as unknown as any[];
    //                 if(sucursales){
    //                     this.coordenadas.bulk(sucursales);
    //                 }
    //             }
    //         }else{
    //             this.errors = r.errors;
    //         }
    //         // console.log(this.coordenadas.coordenadasArray)
    //     });
    // }

    public async GetOrganizaciones(){
         this.boiletService.get<Organizacion[]>('/api/Organizaciones/',true)
        .then( async a => {
            if(!a.isError){
                this.organizaciones = a.content as Organizacion[];
                if(this.organizaciones){
                    this.assetStore.bulkOrganizaciones(this.organizaciones)
                    // await this.GetEquipos();
                }
            }else{
                this.errors = a.errors;
            }

             
        });
    }

    // public async GetEquipos() {
    //     return this.boiletService.get<Maquina[]>('/api/Maquinas/', true)
    //         .then(r => {
    //         if (!r.isError) {
    //             const equipos = [] as Activo[];

    //             r.content .forEach(element => {
    //                 const ixd = this.organizaciones.find((a: any) => a._id == element.org_id);
    //                 let act = element as unknown as Activo;
                    
    //                 if(ixd){
    //                     act.organizacion = ixd.nombre;
    //                 }
    //                 equipos.push(act);
    //             });


    //             if(equipos){
    //                 this.assetStore.bulkMaquinas(equipos);
    //             }
    //             this.GetRecomendaciones();
    //         }
    //         else {
    //             this.errors = r.errors;
    //         }
    //     });
    // }

    public async GetSucursales(){
        await this.boiletService.get<Sucursales[]>('/api/Sucursales',false)
        .then( r => {
            if(!r.isError){
                const sucursales = r.content as unknown as Sucursales[];
                if(sucursales){
                    this.assetStore.bulkSucursal(sucursales);
                }
            }else{
                this.errors = r.errors;
            }
        });
    }

    public async GetRecomendaciones(){
        await this.boiletService.get<RecomendacionCliente[]>('/api/Recomendaciones/desdehasta/'+this.desde+'/'+this.hasta, false)
        .then( a =>{
            if(!a.isError){
                // let items = a.content as RecomendacionCliente[];
                const recomendaciones = a.content as unknown as RecomendacionCliente[];
                if(recomendaciones){
                    this.assetStore.bulkRecomendacion(recomendaciones);
                }
            }
            else{
                this.errors = a.errors;
            }
        }); 
    }



    get ConsolidadoMapa(){
        let d = this.coordenadas.coordenadasArray.map((x : any) =>{
            let rer = x;
            var tt = this.assetStore.activosArray.findIndex( (w :any)   => w.vin == x.vin);
            if(tt > -1){
                    rer = this.assetStore.activosArray[tt];
                    rer.coordenada = {};
                    rer.coordenada.latitud = x.latitud;
                    rer.coordenada.longitud = x.longitud;
                    rer.coordenada.fecha = x.fecha.$date; 
                    // rer.url = this.SeleccionaIcon(rer.marca, rer.familia, rer.modelo);

                    
                }else{
                    rer.coordenada = undefined;
                    // rer.url = this.SeleccionaIcon(rer.marca, rer.familia, rer.modelo);
                }
                return rer;
        }).filter( (s:any) => s.coordenada != undefined  ? s.coordenada.fecha != undefined && s.coordenada.fecha != null : false);
        return d;
    }

   

    // get Organizaciones(){
    //     return this.assetStore.organizacionesArray;
    // }

    // get Maquinas(){
    //     return this.assetStore.maquinasArray;
    // }
}