import { render, staticRenderFns } from "./App.vue?vue&type=template&id=104e8f88&"
import script from "./App.ts?vue&type=script&lang=js&"
export * from "./App.ts?vue&type=script&lang=js&"
import style0 from "./App.vue?vue&type=style&index=0&id=104e8f88&prod&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VApp,VContainer,VDialog,VImg,VLayout})
