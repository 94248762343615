import BoiletComponentBase from '@/shared/application/boilet-component-base';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class FichaSimpleHorizontalComponent extends BoiletComponentBase {
    @Prop({default: 0})  public numero3!: number;
    @Prop({default: 0})  public numero2!: number;
    @Prop({default: 0})  public numero!: number;
    @Prop({default: ''})  public titulo!: string;
    @Prop({default: ''})  public unidad!: string;
    @Prop({default: ''})  public unidad2!: string;
    @Prop({default: ''})  public unidad3!: string;
    @Prop({default: ''})  public tooltip!: string;
    @Prop({default: ''})  public color!: string;
}