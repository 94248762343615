<template>
  <vue-pdf-app :pdf="url" @open="openHandler"></vue-pdf-app>
</template>

<script>
// VuePdfApp component is registered in src/main.js

export default {
  name: "Basic",
  props:{
      url : String,
  },
  methods: {
    openHandler(pdfApp) {
      window._pdfApp = pdfApp;
    },
  },
};
</script>
